import React from 'react';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Input,
	Button,
	Table,
	Modal,
	message,
	Alert,
	Select,
	Tooltip,
	Spin,
	Form,
	Skeleton
} from 'antd';

import moment from 'moment';

import Config from '../../Config';
import { Helmet } from "react-helmet";
import queryString from 'query-string';

import '../../assets/laporan.css';


const configClass = {
	title: 'LAPORAN NOTICE',
	apiUrl: {
		preview: 'laporan/api/bendahara/',
		userProfile: "auth/api/user/info"
	},

}

class BendaharawanKhususPenerimaanPajakBbnkb extends React.Component {
	config;

	constructor(props) {
		super(props);
		this.config = new Config();
		this.state = {
			loading: false,
			data: null,
			err: false,

			namauptppd: 'SAMSAT PALANGKARAYA',
		}

		// this.preview(this.props.match.params.id);

	}

	componentDidMount() {
		var uri_params = "";
		const uri_string = queryString.parse(this.props.location.search);
		var uri_params = "";
		for (var key in uri_string) {
			if (uri_params == "") {
				uri_params = "?" + key + "=" + uri_string[key];
			} else {
				uri_params += "&" + key + "=" + uri_string[key];
			}
		}
		this.preview(uri_params);
	}

	async preview(uri_params) {
		message.loading({ content: 'Menampilkan laporan...', duration: 0, key: 'toast' });
		this.setState({
			loading: true,
			err: false,
			data: null,
		});

		this.props.ajaxViewHandler('get', configClass.apiUrl.preview + uri_params)
			.then(() => {
				const data = this.props.responseMsg;
				if (this.props.isSuccess) {
					message.destroy();
					this.setState({
						loading: false,
						data: data,
						err: false
					});
					setTimeout(function () {
						window.print();

					}, 1300);
				} else {
					const errTitle = 'error get laporan';
					this.setState({
						loading: false,
						data: null,
						err: true,
						errMsg: this.props.responseMsg
					});
					message.error({ content: errTitle, duration: 2, key: 'toast' });
				}
			}).catch((response) => {
				this.setState({
					loading: false,
					data: null,
					err: true,
					errMsg: 'unknown response'
				});
				console.log('gagal_get_laporan', response);
				message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			});
	}

	render() {
		return (
			<>
				<Helmet>
					<title>{configClass.title}</title>
				</Helmet>
				<div className="kertas-laporan">
					<div className="judul-laporan teks-tengah">
						{configClass.title}
					</div>
					<Row>
						<Col span={12}>{this.state.namauptppd}</Col>
					</Row>
					<table className="laporan-table" border="1" cellPadding="5">
						<thead>
							<tr>
								<th rowspan="2">No.</th>
								<th rowspan="2">JENIS KENDARAAN</th>
								<th colspan="4">BBNKB</th>
								<th colspan="4">PKB</th>
								<th colspan="4">SWDKLLJ</th>
								<th rowspan="2">JUMLAH KEND</th>
								<th rowspan="2">TOTAL</th>
							</tr>
							<tr>
								<th>POKOK</th>
								<th>DENDA</th>
								<th>TUNGGAKAN</th>
								<th>JUMLAH</th>
								<th>POKOK</th>
								<th>DENDA</th>
								<th>TUNGGAKAN</th>
								<th>JUMLAH</th>
								<th>POKOK</th>
								<th>DENDA</th>
								<th>TUNGGAKAN</th>
								<th>JUMLAH</th>
							</tr>
						</thead>
						<tbody>
							{(this.state.data != null && this.state.data.data.map((item, id) => (
								<tr>
									<td className="teks-kanan">{item.jenis_kendaraan != undefined ? id + 1 : ''}</td>
									<td className={item.jenis_kendaraan != undefined ? 'teks-kiri' : 'teks-kanan tebal'}>{item.jenis_kendaraan.nama}</td>

									<td className={item.jenis_kendaraan != undefined ? 'teks-kanan' : 'teks-kanan tebal'}>{item.jenis_kendaraan.bbnkb.pokok.toLocaleString('id')}</td>
									<td className={item.jenis_kendaraan != undefined ? 'teks-kanan' : 'teks-kanan tebal'}>{item.jenis_kendaraan.bbnkb.denda.toLocaleString('id')}</td>
									<td className={item.jenis_kendaraan != undefined ? 'teks-kanan' : 'teks-kanan tebal'}>{item.jenis_kendaraan.bbnkb.tunggakan.toLocaleString('id')}</td>
									<td className={item.jenis_kendaraan != undefined ? 'teks-kanan' : 'teks-kanan tebal'}>{item.jenis_kendaraan.bbnkb.jumlah.toLocaleString('id')}</td>

									<td className={item.jenis_kendaraan != undefined ? 'teks-kanan' : 'teks-kanan tebal'}>{item.jenis_kendaraan.pkb.pokok.toLocaleString('id')}</td>
									<td className={item.jenis_kendaraan != undefined ? 'teks-kanan' : 'teks-kanan tebal'}>{item.jenis_kendaraan.pkb.denda.toLocaleString('id')}</td>
									<td className={item.jenis_kendaraan != undefined ? 'teks-kanan' : 'teks-kanan tebal'}>{item.jenis_kendaraan.pkb.tunggakan.toLocaleString('id')}</td>
									<td className={item.jenis_kendaraan != undefined ? 'teks-kanan' : 'teks-kanan tebal'}>{item.jenis_kendaraan.pkb.jumlah.toLocaleString('id')}</td>

									<td className={item.jenis_kendaraan != undefined ? 'teks-kanan' : 'teks-kanan tebal'}>{item.jenis_kendaraan.swdkllj.pokok.toLocaleString('id')}</td>
									<td className={item.jenis_kendaraan != undefined ? 'teks-kanan' : 'teks-kanan tebal'}>{item.jenis_kendaraan.swdkllj.denda.toLocaleString('id')}</td>
									<td className={item.jenis_kendaraan != undefined ? 'teks-kanan' : 'teks-kanan tebal'}>{item.jenis_kendaraan.swdkllj.tunggakan.toLocaleString('id')}</td>
									<td className={item.jenis_kendaraan != undefined ? 'teks-kanan' : 'teks-kanan tebal'}>{item.jenis_kendaraan.swdkllj.jumlah.toLocaleString('id')}</td>

									<td className={item.jenis_kendaraan != undefined ? 'teks-kanan' : 'teks-kanan tebal'}>{item.jenis_kendaraan.jumlah_kendaraan.toLocaleString('id')}</td>
									<td className={item.jenis_kendaraan != undefined ? 'teks-kanan' : 'teks-kanan tebal'}>{item.jenis_kendaraan.total.toLocaleString('id')}</td>
								</tr>
								/*
								<tr>
									<td className="teks-kanan">{detail != 'jumlah' ? id+1 : ''}</td>
									<td className={detail != 'jumlah' ? 'teks-kiri' : 'teks-kanan tebal'}>{detail}</td>
									<td className={detail != 'jumlah' ? 'teks-kanan' : 'teks-kanan tebal'}>{this.state.data.data[detail].bbnkb.pokok.toLocaleString('id')}</td>
									<td className={detail != 'jumlah' ? 'teks-kanan' : 'teks-kanan tebal'}>{this.state.data.data[detail].bbnkb.denda.toLocaleString('id')}</td>
									<td className={detail != 'jumlah' ? 'teks-kanan' : 'teks-kanan tebal'}>{this.state.data.data[detail].bbnkb.tunggakan.toLocaleString('id')}</td>
									<td className={detail != 'jumlah' ? 'teks-kanan' : 'teks-kanan tebal'}>{this.state.data.data[detail].bbnkb.jumlah.toLocaleString('id')}</td>
									<td className={detail != 'jumlah' ? 'teks-kanan' : 'teks-kanan tebal'}>{this.state.data.data[detail].pkb.pokok.toLocaleString('id')}</td>
									<td className={detail != 'jumlah' ? 'teks-kanan' : 'teks-kanan tebal'}>{this.state.data.data[detail].pkb.denda.toLocaleString('id')}</td>
									<td className={detail != 'jumlah' ? 'teks-kanan' : 'teks-kanan tebal'}>{this.state.data.data[detail].pkb.tunggakan.toLocaleString('id')}</td>
									<td className={detail != 'jumlah' ? 'teks-kanan' : 'teks-kanan tebal'}>{this.state.data.data[detail].pkb.jumlah.toLocaleString('id')}</td>
									<td className={detail != 'jumlah' ? 'teks-kanan' : 'teks-kanan tebal'}>{this.state.data.data[detail].swdkllj.pokok.toLocaleString('id')}</td>
									<td className={detail != 'jumlah' ? 'teks-kanan' : 'teks-kanan tebal'}>{this.state.data.data[detail].swdkllj.denda.toLocaleString('id')}</td>
									<td className={detail != 'jumlah' ? 'teks-kanan' : 'teks-kanan tebal'}>{this.state.data.data[detail].swdkllj.tunggakan.toLocaleString('id')}</td>
									<td className={detail != 'jumlah' ? 'teks-kanan' : 'teks-kanan tebal'}>{this.state.data.data[detail].swdkllj.jumlah.toLocaleString('id')}</td>
									<td className={detail != 'jumlah' ? 'teks-kanan' : 'teks-kanan tebal'}>{this.state.data.data[detail].jumlah_kendaraan.toLocaleString('id')}</td>
									<td className={detail != 'jumlah' ? 'teks-kanan' : 'teks-kanan tebal'}>{this.state.data.data[detail].total.toLocaleString('id')}</td>
								</tr>
								*/
							)))}
						</tbody>
					</table>
					{(this.state.data != null && this.state.data.ttd != undefined &&
						<Row justify="space-between" style={{ marginTop: 10 }}>
							{(this.state.data.ttd.kiri != undefined &&
								<Col span={10} className="teks-tengah">
									MENGETAHUI<br />
									{this.state.data.ttd.kiri.jabatan}
									<div style={{ marginTop: 50 }}>
										<u>{this.state.data.ttd.kiri.nama}</u><br />
										NIP : {this.state.data.ttd.kiri.nip}
									</div>
								</Col>
							)}
							{(this.state.data.ttd.kanan != undefined &&
								<Col span={10} className="teks-tengah">
									{this.state.namauptppd}, {moment().format('DD-MM-YYYY')}<br />
									{this.state.data.ttd.kanan.jabatan}
									<div style={{ marginTop: 50 }}>
										<u>{this.state.data.ttd.kanan.nama}</u><br />
										NIP : {this.state.data.ttd.kanan.nip}
									</div>
								</Col>
							)}
						</Row>
					)}
				</div>
			</>
		)
	}

}

const mapStateToProps = function (state) {
	return state.configReducers
}

export default connect(
	mapStateToProps,
	{
		initHandler,
		setConfig,
		ajaxViewHandler,
		ajaxHandler,
		setConfig
	}
)(BendaharawanKhususPenerimaanPajakBbnkb);