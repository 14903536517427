import React from "react";
import { Link, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Breadcrumb,
  Card,
  Row,
  Col,
  Avatar,
  Form,
  Input,
  Button,
  Space,
  Select,
  DatePicker,
  message,
  Alert,
  Spin,
  Modal,
  Skeleton,
  Table,
  Tag,
  Divider
} from "antd";
import {
  DiffOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DollarOutlined,
  BarcodeOutlined,
  UserAddOutlined,
  PrinterOutlined,
  UserOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  CarOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import moment from "moment";
import NumberFormat from "react-number-format";

import axios from "axios";
import Config from "../../Config";

import { connect } from "react-redux";
import {
  initHandler,
  ajaxViewHandler,
  ajaxHandler,
  setConfig,
} from "../../store/actions";
import { Helmet } from "react-helmet";

const FormItem = Form.Item;

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 19,
      offset: 5,
    },
    lg: {
      span: 19,
      offset: 5,
    },
  },
};

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const configClass = {
  title: "cari data kbm",
  apiUrl: {
    penetapan: "kendaraan/api/kendaraan/",
  },
  pageUrl: {
    cetak_no: "/addon/cetak_kutipan_skp/",
  },
  breadCrumb: [
    <span>
      <InfoCircleOutlined /> informasi
    </span>,
    "cari data kbm",
  ],
};

const config = new Config();

class CariDataKbm extends React.Component {
  formRef = React.createRef();
  formRefScroll = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      errComponent: false,
      errTitle: "",
      errMsg: "",
      loadingButton: false,

      pagination: {pageSize: 8},

      searchText: "",

      cari_no_pol: undefined,

      loadingData: false,

      aksi: "tambah",
      method: "post",
      idLama: "",

      dataSource: [],
      columns: [
        {
          title: 'NOMOR POLISI',
          width: 200,
          dataIndex: 'nomor_polisi',
          key: 'nomor_polisi'
        },
        {
          title: 'JENIS DAFTAR',
          width: 150,
          dataIndex: 'layanan_nama',
          key: 'layanan_nama'
        },
        {
          title: 'NAMA WP/ PEMILIK',
          width: 200,
          dataIndex: 'nama_wp',
          key: 'nama_wp'
        },
        {
          title: 'ALAMAT WP/ PEMILIK',
          width: 200,
          dataIndex: 'alamat_wp',
          key: 'alamat_wp'
        },
        {
          title: 'JENIS KENDARAAN',
          width: 150,
          dataIndex: 'jenis_kendaraan',
          key: 'jenis_kendaraan'
        },
        {
          title: 'MEREK KENDARAAN',
          width: 200,
          dataIndex: 'merk_kendaraan',
          key: 'merk_kendaraan'
        },
        {
          title: 'TIPE KENDARAAN',
          width: 150,
          dataIndex: 'tipe_kendaraan',
          key: 'tipe_kendaraan'
        },
        {
          title: 'THN. BUAT/ RAKIT',
          width: 150,
          dataIndex: 'thn_buat',
          key: 'thn_buat'
        },
        {
          title: 'CC',
          width: 150,
          dataIndex: 'cc',
          key: 'cc'
        },
        {
          title: 'WARNA TNK',
          width: 150,
          dataIndex: 'warna_tnk',
          key: 'warna_tnk'
        },
        {
          title: 'KENDARAAN KE',
          width: 150,
          dataIndex: 'ke',
          key: 'ke'
        },
        {
          title: 'PENGGUNAAN',
          width: 150,
          dataIndex: 'fungsi_nama',
          key: 'fungsi_nama'
        },
        {
          title: 'MASA BERLAKU STNK',
          width: 200,
          dataIndex: 'masa_pajak',
          key: 'masa_pajak'
        },
        {
          title: 'POKOK BBNKB',
          width: 150,
          dataIndex: 'pokok_bbnkb',
          key: 'pokok_bbnkb'
        },
        {
          title: 'DENDA BBNKB',
          width: 150,
          dataIndex: 'denda_bbnkb',
          key: 'denda_bbnkb'
        },
        {
          title: 'JUMLAH BBNKB',
          width: 150,
          dataIndex: 'jumlah_bbnkb',
          key: 'jumlah_bbnkb'
        },
        {
          title: 'TUNGGAKAN POKOK BBNKB',
          width: 250,
          dataIndex: 'tunggakan_pokok_bbnkb',
          key: 'tunggakan_pokok_bbnkb'
        },
        {
          title: 'TUNGGAKAN DENDA BBNKB',
          width: 250,
          dataIndex: 'tunggakan_denda_bbnkb',
          key: 'tunggakan_denda_bbnkb'
        },
         {
          title: 'TUNGGAKAN JUMLAH BBNKB',
          width: 250,
          dataIndex: 'tunggakan_jumlah_bbnkb',
          key: 'tunggakan_jumlah_bbnkb'
        },
        {
          title: 'POKOK PKB',
          width: 200,
          dataIndex: 'pokok_pkb',
          key: 'pokok_pkb'
        },
        {
          title: 'DENDA PKB',
          width: 200,
          dataIndex: 'denda_pkb',
          key: 'denda_pkb'
        },
        {
          title: 'JUMLAH PKB',
          width: 200,
          dataIndex: 'jumlah_pkb',
          key: 'jumlah_pkb'
        },
        {
          title: 'TUNGGAKAN POKOK PKB',
          width: 200,
          dataIndex: 'tunggakan_pokok_pkb',
          key: 'tunggakan_pokok_pkb'
        },
        {
          title: 'TUNGGAKAN DENDA PKB',
          width: 200,
          dataIndex: 'tunggakan_denda_pkb',
          key: 'tunggakan_denda_pkb'
        },
        {
          title: 'TUNGGAKAN JUMLAH PKB',
          width: 200,
          dataIndex: 'tunggakan_jumlah_pkb',
          key: 'tunggakan_jumlah_pkb'
        },
        {
          title: 'POKOK SWDKLLJ',
          width: 200,
          dataIndex: 'pokok_swdkllj',
          key: 'pokok_swdkllj'
        },
        {
          title: 'DENDA SWDKLLJ',
          width: 200,
          dataIndex: 'denda_swdkllj',
          key: 'denda_swdkllj'
        },
        {
          title: 'JUMLAH SWDKLLJ',
          width: 200,
          dataIndex: 'jumlah_swdkllj',
          key: 'jumlah_swdkllj'
        },
        {
          title: 'TUNGGAKAN POKOK SWDKLLJ',
          width: 200,
          dataIndex: 'tunggakan_pokok_swdkllj',
          key: 'tunggakan_pokok_swdkllj'
        },
        {
          title: 'TUNGGAKAN DENDA SWDKLLJ',
          width: 200,
          dataIndex: 'tunggakan_denda_swdkllj',
          key: 'tunggakan_denda_swdkllj'
        },
        {
          title: 'TUNGGAKAN JUMLAH SWDKLLJ',
          width: 200,
          dataIndex: 'tunggakan_jumlah_swdkllj',
          key: 'tunggakan_jumlah_swdkllj'
        },
        {
          title: 'POKOK ADM. STNK',
          width: 200,
          dataIndex: 'pokok_admstnk',
          key: 'pokok_admstnk'
        },
        {
          title: 'DENDA ADM. STNK',
          width: 200,
          dataIndex: 'denda_admstnk',
          key: 'denda_admstnk'
        },
        {
          title: 'JUMLAH ADM. STNK',
          width: 200,
          dataIndex: 'jumlah_admstnk',
          key: 'jumlah_admstnk'
        },
        {
          title: 'TUNGGAKAN POKOK ADM. STNK',
          width: 250,
          dataIndex: 'tunggakan_pokok_admstnk',
          key: 'tunggakan_pokok_admstnk'
        },
        {
          title: 'TUNGGAKAN DENDA ADM. STNK',
          width: 250,
          dataIndex: 'tunggakan_denda_admstnk',
          key: 'tunggakan_denda_admstnk'
        },
        {
          title: 'TUNGGAKAN JUMLAH ADM. STNK',
          width: 250,
          dataIndex: 'tunggakan_jumlah_admstnk',
          key: 'tunggakan_jumlah_admstnk'
        },
        {
          title: 'POKOK ADM. TNKB',
          width: 200,
          dataIndex: 'pokok_admtnk',
          key: 'pokok_admtnk'
        },
        {
          title: 'DENDA ADM. TNKB',
          width: 200,
          dataIndex: 'denda_admtnk',
          key: 'denda_admtnk'
        },
        {
          title: 'JUMLAH ADM. TNKB',
          width: 200,
          dataIndex: 'jumlah_admtnk',
          key: 'jumlah_admtnk'
        },
        {
          title: 'TUNGGAKAN POKOK ADM. TNKB',
          width: 250,
          dataIndex: 'tunggakan_pokok_admtnk',
          key: 'tunggakan_pokok_admtnk'
        },
        {
          title: 'TUNGGAKAN DENDA ADM. TNKB',
          width: 250,
          dataIndex: 'tunggakan_denda_admtnk',
          key: 'tunggakan_denda_admtnk'
        },
        {
          title: 'TUNGGAKAN JUMLAH ADM. TNKB',
          width: 250,
          dataIndex: 'tunggakan_jumlah_admtnk',
          key: 'tunggakan_jumlah_admtnk'
        },
        {
          title: 'POKOK SP3',
          width: 200,
          dataIndex: 'pokok_sp3',
          key: 'pokok_sp3'
        },
        {
          title: 'DENDA SP3',
          width: 200,
          dataIndex: 'denda_sp3',
          key: 'denda_sp3'
        },
        {
          title: 'JUMLAH SP3',
          width: 200,
          dataIndex: 'jumlah_sp3',
          key: 'jumlah_sp3'
        },
        {
          title: 'TUNGGAKAN POKOK SP3',
          width: 200,
          dataIndex: 'tunggakan_pokok_sp3',
          key: 'tunggakan_pokok_sp3'
        },
        {
          title: 'TUNGGAKAN DENDA SP3',
          width: 200,
          dataIndex: 'tunggakan_denda_sp3',
          key: 'tunggakan_denda_sp3'
        },
        {
          title: 'TUNGGAKAN JUMLAH SP3',
          width: 200,
          dataIndex: 'tunggakan_jumlah_sp3',
          key: 'tunggakan_jumlah_sp3'
        },
      ]

    };
  }

  UNSAFE_componentWillMount() {
    this.props.initHandler();
  }

  componentDidMount() {
    this.previewData();    
  }

  searchKendaraan = (no_polisi) => {
    this.previewData(no_polisi);
  };

  async previewData(no_polisi="", limit=this.state.pagination.pageSize, offset=0, search=this.state.searchText) {
    message.loading({
      content: "Proses pencarian...",
      duration: 0,
      key: "toast",
    });

    this.setState({
      errTitle: '',
      errMsg: '',
      dataSource: [],
      loadingData: true,
    });

    var url = no_polisi == "" ? configClass.apiUrl.penetapan+'?limit='+limit+'&offset='+offset+'&search='+search : configClass.apiUrl.penetapan + "?nomor_polisi=" + no_polisi+'&limit='+limit+'&offset='+offset+'&search='+search

    this.props
      .ajaxViewHandler(
        "get",
        url
      )
      .then(() => {
      	const datas = this.props.responseMsg;
        if (this.props.isSuccess) {
          var pagination = { ...this.state.pagination };
          pagination.total = datas.count;

          if(datas.results.length == 0){
        		message.error({ content: "nomor kendaraan tidak ditemukan", duration: 2, key: "toast" });
        	}else{
            message.destroy();
        		this.setState({
        			dataSource: datas.results,
              pagination
        		});	          
	        }
        } else {
          const errTitle = "error preview data lama";
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
          message.error({ content: errTitle, duration: 2, key: "toast" });
        }
        this.setState({
          loadingData: false
        });
      })
      .catch((response) => {
        console.log("gagal_get_preview", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({
          loadingData: false
        });
      });
  }

  handleTableChange = async (pagination, filters, sorter) =>{
      const newPagination = { ...this.state.pagination };
      newPagination.pageSize = pagination.pageSize;
    this.setState({
      loadingData: true,
      pagination
    });
    if(pagination.current==1){
      this.previewData(this.state.cari_no_pol, pagination.pageSize);
    }else{
      this.previewData(this.state.cari_no_pol, pagination.pageSize, (pagination.current - 1)*pagination.pageSize);
    }
    
  }

  handleSubmit = (values) => {
    this.postDataPenetapan(values);
  };

  async getPasienById(val) {
    message.loading({
      content: "Display data pasien...",
      duration: 0,
      key: "toast",
    });
    this.setState({
      errTitle: "",
      errMsg: "",
      dataPasien: null,
      skeletonPasien: true,
    });
    this.props
      .ajaxViewHandler("get", configClass.apiUrl.pasien + "/" + val)
      .then(() => {
        this.setState({
          skeletonPasien: false,
        });
        if (this.props.isSuccess) {
          message.destroy();

          var response = this.props.responseMsg;
          this.setState({
            dataPasien: response,
          });
        } else {
          const errTitle = "gagal menampilkan data pasien";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
        }
      })
      .catch((response) => {
        console.log("gagal_preview_pasien", response);
        this.setState({
          skeletonPasien: false,
        });
        message.error({
          content: "kesalahan pengiriman data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  reset = () =>{
    this.setState({
      cari_no_pol: undefined,
      isOpenForm: false,
    });
  };

  async postDataPenetapan(datas) {
    message.loading({ content: "Memproses...", duration: 0, key: "toast" });
    this.setState({
      errTitle: "",
      errMsg: "",
      loadingButton: true,
    });
    this.props
      .ajaxHandler("put", configClass.apiUrl.penetapan+this.state.idLama, datas, false, true)
      .then(() => {
        if (this.props.isSuccess) {
          var response = this.props.responseMsg;
          var that = this;

          message.success({ content: 'berhasil menyimpan data penetapan', duration: 2, key: 'toast' });
          this.setState({
            loadingButton: false,
          });

          Modal.confirm({
              title: 'Penetapan Biaya Berhasil',
              icon: <CheckCircleOutlined style={{color: 'green'}} />,
              content: 'tekan cetak untuk mencetak, tekan selesai untuk melanjutkan',
              okText: <a href={configClass.pageUrl.cetak_no+response.id} target="_blank"><PrinterOutlined /> Cetak</a>,
              cancelText: 'Selesai',
              onOk() {
                that.reset()
              },
              onCancel() {
                that.reset()
              }
          });

        } else {
          const errTitle = "gagal menyimpan data penetapan";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            loadingButton: false,
            errTitle: errTitle,
            errMsg: JSON.stringify(this.props.responseMsg),
          });
        }
      })
      .catch((response) => {
        console.log("gagal_post_penetapan", response);
        this.setState({
          loadingButton: false,
        });
        message.error({
          content: "kesalahan pengiriman data penetapan, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  // async postDataKendaraan(datas, idPemilik) {
  //   var dataKendaraan = datas;
  //   dataKendaraan.pemilik = idPemilik;
  //   dataKendaraan.nomor_polisi =
  //     datas.no_polisi_depan + datas.no_polisi_tengah + datas.no_polisi_belakang;
  //   dataKendaraan.nomor_polisi_lama =
  //     datas.no_polisi_depan_lama +
  //     datas.no_polisi_tengah_lama +
  //     datas.no_polisi_belakang_lama;

  //   message.loading({ content: "Memproses...", duration: 0, key: "toast" });
  //   this.props
  //     .ajaxHandler(
  //       this.state.method,
  //       configClass.apiUrl.kendaraan + this.state.idLama,
  //       dataKendaraan,
  //       true,
  //       true
  //     )
  //     .then(() => {
  //       if (this.props.isSuccess) {
  //         message.success({
  //           content: "berhasil menyimpan pendaftaran",
  //           duration: 2,
  //           key: "toast",
  //         });
  //         this.setState({
  //           loadingButton: false,
  //         });
  //       } else {
  //         const errTitle = "gagal menyimpan data kendaraan";
  //         message.error({ content: errTitle, duration: 2, key: "toast" });
  //         this.setState({
  //           loadingButton: false,
  //           errTitle: errTitle,
  //           errMsg: JSON.stringify(this.props.responseMsg),
  //         });
  //       }
  //     })
  //     .catch((response) => {
  //       console.log("gagal_post_kendaraan", response);
  //       this.setState({
  //         loadingButton: false,
  //       });
  //       message.error({
  //         content: "kesalahan pengiriman data kendaraan, coba lagi",
  //         duration: 2,
  //         key: "toast",
  //       });
  //     });
  // }

  render() {
    // if(this.props.layanan_str.length>0 && this.props.layanan_str.includes('Pembelian / Pemesanan Obat') == false){
    // 	return <Redirect to="/admin/forbidden" />
    // }
    // if(this.props.isRedirect){
    // 	return <Redirect to={configClass.pageUrl.data} />
    // }

    return (
      <div>
        <Helmet>
          <title>
            {configClass.title + " | " + config.get_site_info().name}
          </title>
        </Helmet>
        <Breadcrumb>
          {configClass.breadCrumb.map((item) => (
            <Breadcrumb.Item>{item}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <div className="card" id="panel-transaksi">
          <Card
            className="konten"
            title={null}
          >
            <Form
              layout="horizontal"
              name="fdata"
              onFinish={this.handleSubmit}
              size="small"
              ref={this.formRef}
              initialValues={{
                kode_bbn_kb: "001",
                nama_bbn_kb: "BBN KB",
                kode_pkb: "002",
                nama_pkb: "PKB",
                kode_swdkllj: "003",
                nama_swdkllj: "SWDKLLJ",
                kode_admstnk: "004",
                nama_admstnk: "BIAYA ADM.STNK",
                kode_admtnk: "005",
                nama_admtnk: "BIAYA ADM.TNKB",
                kode_sp3: "006",
                nama_sp3: "SP3",
              }}
            >
              <Row className="isi">
                {this.state.errTitle != null && this.state.errTitle != "" && (
                  <Alert
                    message={this.state.errTitle}
                    description={this.state.errMsg}
                    type="error"
                    showIcon
                    style={{
                      marginBottom: 10,
                    }}
                  />
                )}

                <Col span={24}>
                  <Row justify="start">
                    <Col span={24}>
                    	<Input.Group compact>
	                      
		                      	<Input
		                      		allowClear
		                      		style={{width: "100%"}}
		                          placeholder="input nomer polisi, cth : KH1234XY"
		                          onChange={(e) => {
		                            this.setState({
		                              cari_no_pol: e.target.value,
		                            });
		                          }}
		                          value={this.state.cari_no_pol}
		                          name="cari_no_pol"
		                          onKeyPress={(event) => {
		                            if (event.key === "Enter") {
		                              event.preventDefault();
		                              this.searchKendaraan(this.state.cari_no_pol);
		                            }
		                          }}
		                        />
		                    
	                    </Input.Group>
                      <Divider />
                    </Col>
                    <Col span={24}>
                      <Table 
                        bordered
                        columns={this.state.columns} 
                        dataSource={this.state.dataSource} 
                        loading={this.state.loadingData} 
                        scroll={{ x: 240, y: 450 }}
                        size="small"
                        pagination={this.state.pagination}
                        onChange={this.handleTableChange}
                      />
                    </Col>
                  </Row>
                </Col>

                
              </Row>
            </Form>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  initHandler,
  setConfig,
  ajaxViewHandler,
  ajaxHandler,
})(CariDataKbm);
