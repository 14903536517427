import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import Config from './Config';
import {
	Modal,
} from 'antd';

let config = new Config();

export const LayananRoute = ({ component: Component, isAkses, ...rest }) => {
    const [counter, setCounter] = useState(3);
    const [isForbidden, setIsForbidden] = useState(false);
    const [isModalForbidden, setIsModalForbidden] = useState(false);

    useEffect(() => {
        // Update the document title using the browser API
        if (isAkses == false) {
            setIsModalForbidden(true);
            doCounter();
        }
    });

    function doCounter(){
        setTimeout(() => {
            if (counter > 0) {
                let temp = counter;
                temp -= 1;
                setCounter(temp);
                doCounter();
            } else {
                setIsForbidden(true)
            }
        }, 1000);
    }

    return (
        <Route {...rest} render={props => (
            isAkses
                ? <Component {...props} />
                :
                isForbidden
                    ?
                    <Redirect to={{ pathname: "/admin/forbidden", state: { from: props.location } }} />
                    :
                    <Modal
                        title="Akses Terbatas"
                        visible={isModalForbidden}
                        footer={[]}
                        closable={false}
                        width={"50%"}
                    >
                        <div
                            style={{
                                textAlign: "center",
                                fontSize: 24
                            }}
                        >
                            Akses Terbatas, Halaman ini akan ditutup dalam
                            <br />
                            <div style={{ color: "red" }}>
                                {counter}
                            </div>
                        </div>
                    </Modal>
        )} />
    )
};







// import React from 'react';
// import { Route, Redirect } from 'react-router-dom';
// import Cookies from 'js-cookie';
// import Config from './Config';

// let config = new Config();

// export const LayananRoute = ({ component: Component, isAkses, ...rest }) => (
//     <Route {...rest} render={props => (
//         isAkses
//             ? <Component {...props} />
//             : <Redirect to={{ pathname: "/admin/forbidden", state: { from: props.location } }} />
//     )} />
// );