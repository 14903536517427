import React, { PureComponent } from 'react'
import { Redirect, Link } from "react-router-dom";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Avatar,
	Form, 
	Input,
	Button,
	Space,
	Select,
	DatePicker,
	message,
	Alert,
	Spin,
	Skeleton
} from 'antd';
import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../store/actions";
import {Helmet} from "react-helmet";
import '../assets/stnk.css';
import Config from '../Config';
import moment from 'moment';

import QRCode from 'qrcode.react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from './Logo';
import 'jspdf-autotable'


const configClass = {
	title: 'cetak kutipan surat ketetapan pajak',
	apiUrl: {
		// preview: "transaksi/api/transaksi/",
		preview: "transaksi/api/tagihan/",
		userProfile: "auth/api/user/info"
	}
}

class CetakStnk extends PureComponent {
	config;
	
	constructor(props){
	    super(props);
	    this.config = new Config();
	    this.state = {
	    	loading: true,
	    	data_kendaraan: null,
	    	dataResult: null,
	    }

	    // this.preview(this.props.match.params.id);
	    
	}

	componentDidMount(){
		// this.preview(this.props.match.params.id);
		
	}

	

	async preview(id) {
		message.loading({ content: 'Menampilkan data...', duration: 0, key: 'toast' });

		this.setState({
			errTitle: '',
			errMsg: '',
			loading: true
		});

		this.props.ajaxViewHandler('get', configClass.apiUrl.preview+id)
		.then(() =>{
			const data = this.props.responseMsg;
			var that = this;
			if(this.props.isSuccess){
				message.destroy();
				this.setState({
					loading: false,
					data_kendaraan: data
				}, () =>{
					setTimeout(function(){
						// window.print();
						that.jsPdfGenerator();

					}, 1000);
				});
	        }else{
	        	const errTitle = 'error preview data registrasi';
	        	this.setState({
	        		loading: false,
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data registrasi, coba lagi', duration: 2, key: 'toast' });
			this.setState({
				loading: false
			});
		});
	}

	jsPdfGenerator = () => {
        // Example From https://parall.ax/products/jspdf
        const pjg = this.config.cm_to_pixel(15);
        const lbr = this.config.cm_to_pixel(13);
        var doc = new jsPDF('l', 'pt', [pjg, lbr]);

        let x = this.config.cm_to_pixel(0.36);
	    let y = this.config.cm_to_pixel(0.36);

	    doc.setFontType('bold');
		doc.setFontSize(12);
		doc.text(x, y, "KUTIPAN SURAT KETETAPAN PAJAK");
		x+=this.config.cm_to_pixel(7);
		doc.text(x, y, this.state.data_kendaraan.layanan_nama);

		y += this.config.cm_to_pixel(0.5);
		x = this.config.cm_to_pixel(0.36);
		doc.setFontType('normal');
		doc.setFontSize(12);
		doc.text(x, y, "Nopol");
		x += this.config.cm_to_pixel(2);
		doc.text(x, y, ": "+this.state.data_kendaraan.nomor_polisi);
		x += this.config.cm_to_pixel(5.5);
		doc.setFontType('normal');
		doc.setFontSize(12);
		doc.text(x, y, "No, Tgl Dft");
		x += this.config.cm_to_pixel(2.5);
		doc.text(x, y, ": "+this.state.data_kendaraan.no_daftar+", "+moment(this.state.data_kendaraan.tgl_daftar).format("DD-MM-YYYY"));

		y += this.config.cm_to_pixel(0.36);
		x = this.config.cm_to_pixel(0.36);
		doc.setFontType('normal');
		doc.setFontSize(12);
		doc.text(x, y, "Nama");
		x += this.config.cm_to_pixel(2);
		doc.text(x, y, ": "+this.state.data_kendaraan.nama_wp);
		x += this.config.cm_to_pixel(5.5);
		doc.setFontType('normal');
		doc.setFontSize(12);
		doc.text(x, y, "No Rangka");
		x += this.config.cm_to_pixel(2.5);
		doc.text(x, y, ": "+this.state.data_kendaraan.nomor_rangka);

		y += this.config.cm_to_pixel(0.36);
		x = this.config.cm_to_pixel(0.36);
		doc.setFontType('normal');
		doc.setFontSize(12);
		doc.text(x, y, "Alamat");
		x += this.config.cm_to_pixel(2);
		doc.text(x, y, ": "+this.state.data_kendaraan.alamat_wp);
		x += this.config.cm_to_pixel(5.5);
		doc.setFontType('normal');
		doc.setFontSize(12);
		doc.text(x, y, "No Mesin");
		x += this.config.cm_to_pixel(2.5);
		doc.text(x, y, ": "+this.state.data_kendaraan.nomor_mesin);

		y += this.config.cm_to_pixel(0.36);
		x = this.config.cm_to_pixel(0.36);
		doc.setFontType('normal');
		doc.setFontSize(12);
		doc.text(x, y, "Merk/ Tipe");
		x += this.config.cm_to_pixel(2);
		doc.text(x, y, ": "+this.state.data_kendaraan.merk_kendaraan+"/ "+this.state.data_kendaraan.tipe_kendaraan);

		y += this.config.cm_to_pixel(0.36);
		x = this.config.cm_to_pixel(0.36);
		doc.setFontType('normal');
		doc.setFontSize(12);
		doc.text(x, y, "Jenis");
		x += this.config.cm_to_pixel(2);
		doc.text(x, y, ": "+this.state.data_kendaraan.jenis_kendaraan);
		x += this.config.cm_to_pixel(5.5);
		doc.setFontType('normal');
		doc.setFontSize(12);
		doc.text(x, y, "Nopol Lama");
		x += this.config.cm_to_pixel(2.5);
		doc.text(x, y, ": "+this.state.data_kendaraan.nomor_polisi_lama);

		y += this.config.cm_to_pixel(0.36);
		x = this.config.cm_to_pixel(0.36);
		doc.setFontType('normal');
		doc.setFontSize(12);
		doc.text(x, y, "Th. Buat/ CC");
		x += this.config.cm_to_pixel(2);
		doc.text(x, y, ": "+this.state.data_kendaraan.thn_buat+"/ "+this.state.data_kendaraan.cc);
		x += this.config.cm_to_pixel(5.5);
		doc.setFontType('normal');
		doc.setFontSize(12);
		doc.text(x, y, "Bbm/ kend. ke");
		x += this.config.cm_to_pixel(2.5);
		doc.text(x, y, ": "+this.state.data_kendaraan.bahan_bakar_nama+"/ "+this.state.data_kendaraan.ke);

		y += this.config.cm_to_pixel(0.36);
		x = this.config.cm_to_pixel(0.36);
		doc.setFontType('normal');
		doc.setFontSize(12);
		doc.text(x, y, "Warna");
		x += this.config.cm_to_pixel(2);
		doc.text(x, y, ": "+this.state.data_kendaraan.warna_tnk);
		x += this.config.cm_to_pixel(5.5);
		doc.setFontType('normal');
		doc.setFontSize(12);
		doc.text(x, y, "Warna Plat");
		x += this.config.cm_to_pixel(2.5);
		doc.text(x, y, ": "+this.state.data_kendaraan.warna_plat_nama);

		y += this.config.cm_to_pixel(0.36);
		x = this.config.cm_to_pixel(0.36);
		doc.setFontType('normal');
		doc.setFontSize(12);
		doc.text(x, y, "Masa Pajak");
		x += this.config.cm_to_pixel(2);
		doc.text(x, y, ": "+this.state.data_kendaraan.masa_pajak);
		x += this.config.cm_to_pixel(5.5);
		doc.setFontType('normal');
		doc.setFontSize(12);
		doc.text(x, y, "Tgl TTP");
		x += this.config.cm_to_pixel(2.5);
		doc.text(x, y, ": "+moment(this.state.data_kendaraan.tgl_penetapan).format("DD-MM-YYYY"));


		x = this.config.cm_to_pixel(0.36);
		y += this.config.cm_to_pixel(0.5);
		doc.autoTable({
		  tableLineColor: [189, 195, 199],
 		  tableLineWidth: 0.75,
		  startY: y,
		  margin: {left: x, right: x},
		  // head: [['Name', 'Email', 'Country']],
		  body: [
		  	[
		  		{ content: 'PAJAK BERJALAN', colSpan: 4, rowSpan: 1, styles: { halign: 'center' } },
		  		{ content: 'PAJAK TUNGGAKAN', colSpan: 4, rowSpan: 1, styles: { halign: 'center' } }
		  	],
		    ['', 'POKOK', 'DENDA', 'JUMLAH', '', 'POKOK', 'DENDA', 'JUMLAH'],
		    [
		    	'BBN. KB', 
		    	this.state.data_kendaraan.pokok_bbnkb.toLocaleString('id'), 
		    	this.state.data_kendaraan.denda_bbnkb.toLocaleString('id'), 
		    	this.state.data_kendaraan.jumlah_bbnkb.toLocaleString('id'), 
		    	'BBN. KB',
		    	this.state.data_kendaraan.tunggakan_pokok_bbnkb.toLocaleString('id'), 
		    	this.state.data_kendaraan.tunggakan_denda_bbnkb.toLocaleString('id'), 
		    	this.state.data_kendaraan.tunggakan_jumlah_bbnkb.toLocaleString('id')
		  	],
		  	[
		    	'PKB', 
		    	this.state.data_kendaraan.pokok_pkb.toLocaleString('id'), 
		    	this.state.data_kendaraan.denda_pkb.toLocaleString('id'), 
		    	this.state.data_kendaraan.jumlah_pkb.toLocaleString('id'), 
		    	'PKB',
		    	this.state.data_kendaraan.tunggakan_pokok_pkb.toLocaleString('id'), 
		    	this.state.data_kendaraan.tunggakan_denda_pkb.toLocaleString('id'), 
		    	this.state.data_kendaraan.tunggakan_jumlah_pkb.toLocaleString('id')
		  	],
		  	[
		    	'SWDKLLJ', 
		    	this.state.data_kendaraan.pokok_swdkllj.toLocaleString('id'), 
		    	this.state.data_kendaraan.denda_swdkllj.toLocaleString('id'), 
		    	this.state.data_kendaraan.jumlah_swdkllj.toLocaleString('id'), 
		    	'SWDKLLJ',
		    	this.state.data_kendaraan.tunggakan_pokok_swdkllj.toLocaleString('id'), 
		    	this.state.data_kendaraan.tunggakan_denda_swdkllj.toLocaleString('id'), 
		    	this.state.data_kendaraan.tunggakan_jumlah_swdkllj.toLocaleString('id')
		  	],
		  	[
		    	'ADM STNK', 
		    	this.state.data_kendaraan.pokok_admstnk.toLocaleString('id'), 
		    	this.state.data_kendaraan.denda_admstnk.toLocaleString('id'), 
		    	this.state.data_kendaraan.jumlah_admstnk.toLocaleString('id'), 
		    	'SWDKLLJ',
		    	this.state.data_kendaraan.tunggakan_pokok_admstnk.toLocaleString('id'), 
		    	this.state.data_kendaraan.tunggakan_denda_admstnk.toLocaleString('id'), 
		    	this.state.data_kendaraan.tunggakan_jumlah_admstnk.toLocaleString('id')
		  	],
		  	[
		    	'ADM TNK', 
		    	this.state.data_kendaraan.pokok_admtnk.toLocaleString('id'), 
		    	this.state.data_kendaraan.denda_admtnk.toLocaleString('id'), 
		    	this.state.data_kendaraan.jumlah_admtnk.toLocaleString('id'), 
		    	'ADM TNK',
		    	this.state.data_kendaraan.tunggakan_pokok_admtnk.toLocaleString('id'), 
		    	this.state.data_kendaraan.tunggakan_denda_admtnk.toLocaleString('id'), 
		    	this.state.data_kendaraan.tunggakan_jumlah_admtnk.toLocaleString('id')
		  	],
		  	[
		    	'JUMLAH', 
		    	(this.state.data_kendaraan.pokok_bbnkb+this.state.data_kendaraan.pokok_pkb+this.state.data_kendaraan.pokok_swdkllj+this.state.data_kendaraan.pokok_admstnk+this.state.data_kendaraan.pokok_admtnk).toLocaleString('id'), 
		    	(this.state.data_kendaraan.denda_bbnkb+this.state.data_kendaraan.denda_pkb+this.state.data_kendaraan.denda_swdkllj+this.state.data_kendaraan.denda_admstnk+this.state.data_kendaraan.denda_admtnk).toLocaleString('id'), 
		    	(this.state.data_kendaraan.jumlah_bbnkb+this.state.data_kendaraan.jumlah_pkb+this.state.data_kendaraan.jumlah_swdkllj+this.state.data_kendaraan.jumlah_admstnk+this.state.data_kendaraan.jumlah_admtnk).toLocaleString('id'), 
		    	'JUMLAH',
		    	(this.state.data_kendaraan.tunggakan_pokok_bbnkb+this.state.data_kendaraan.tunggakan_pokok_pkb+this.state.data_kendaraan.tunggakan_pokok_swdkllj+this.state.data_kendaraan.tunggakan_pokok_admstnk+this.state.data_kendaraan.tunggakan_pokok_admtnk).toLocaleString('id'), 
		    	(this.state.data_kendaraan.tunggakan_denda_bbnkb+this.state.data_kendaraan.tunggakan_denda_pkb+this.state.data_kendaraan.tunggakan_denda_swdkllj+this.state.data_kendaraan.tunggakan_denda_admstnk+this.state.data_kendaraan.tunggakan_denda_admtnk).toLocaleString('id'), 
		    	(this.state.data_kendaraan.tunggakan_jumlah_bbnkb+this.state.data_kendaraan.tunggakan_jumlah_pkb+this.state.data_kendaraan.tunggakan_jumlah_swdkllj+this.state.data_kendaraan.tunggakan_jumlah_admstnk+this.state.data_kendaraan.tunggakan_jumlah_admtnk).toLocaleString('id')
		  	]
		  ]
		});
		
		x = this.config.cm_to_pixel(0.36);
		y += this.config.cm_to_pixel(5);
		doc.setFontType('normal');
		doc.setFontSize(12);
		doc.text(x, y, "TOTAL : (BIASA + TUNGGAKAN) Rp."+(this.state.data_kendaraan.jumlah_bbnkb+this.state.data_kendaraan.jumlah_pkb+this.state.data_kendaraan.jumlah_swdkllj+this.state.data_kendaraan.jumlah_admstnk+this.state.data_kendaraan.jumlah_admtnk+this.state.data_kendaraan.tunggakan_jumlah_bbnkb+this.state.data_kendaraan.tunggakan_jumlah_pkb+this.state.data_kendaraan.tunggakan_jumlah_swdkllj+this.state.data_kendaraan.tunggakan_jumlah_admstnk+this.state.data_kendaraan.tunggakan_jumlah_admtnk).toLocaleString('id'));
		

		x = this.config.cm_to_pixel(0.36);
		y += this.config.cm_to_pixel(0.36);
		
		doc.autoTable({
		  theme: "plain",
		  startY: y,
		  margin: {left: x, right: x},
		  // head: [['Name', 'Email', 'Country']],
		  body: [
		  	[
		  		{ content: 'PETUGAS PENETAPAN', styles: { halign: 'center' } },
		  		{ content: 'PALANGKARAYA, '+moment(this.state.data_kendaraan.tgl_penetapan).format("DD-MM-YYYY"), styles: { halign: 'center' } }
		  	],
		    [
		  		{ content: '', colSpan: 4, rowSpan: 1, styles: { halign: 'center' } },
		  		{ content: 'KOREKTOR'}
		  	],
		    ['', ''],
		    [
		  		{ content: this.state.data_kendaraan.petugas_penetapan_nama, styles: { halign: 'center' } },
		  		{ content: this.state.data_kendaraan.korektor_nama, styles: { halign: 'center' } },
		  	]
		  ]
		});











   //      var counter = 1;
        
   //      	console.log('item', item);
   //      	if(item.tipe == "2"){
	  //       	let x = this.config.cm_to_pixel(0.36);
	  //       	let y = this.config.cm_to_pixel(0.36);

	  //       	if(counter>1){
			// 		doc.addPage();
			// 		// x = this.config.cm_to_pixel(0.36);
	  //   //     		y = this.config.cm_to_pixel(0.36);
			// 	}

		 //        // const splitNamaPasien = doc.splitTextToSize(this.state.data_kendaraan.pasien_nama.substring(0, 50), this.config.cm_to_pixel(5));
		 //        doc.setFontType('bold');
		 //        doc.setFontSize(12);
			// 	doc.text(x, y, "KUTIPAN SURAT KETETAPAN PAJAK");
		


		 //  		// doc.addImage(Logo, 'PNG', this.config.cm_to_pixel(3+0.36), 2.2, this.config.cm_to_pixel(1.5), this.config.cm_to_pixel(1.5));

			// 	y+=this.config.cm_to_pixel(0.3);

		 //        // const splitNamaObat = doc.splitTextToSize(item.produk_nama.substring(0, 50), this.config.cm_to_pixel(7));
		 //        doc.setFontType('normal');
		 //        doc.setFontSize(8);
			// 	doc.text(x, y, item.produk_nama.substring(0, 40));

			// 	y+=this.config.cm_to_pixel(0.3);

			// 	var dosis = item.dosis_nama;
			// 	const splitDosis = doc.splitTextToSize(dosis, this.config.cm_to_pixel(7));
			// 	// const splitDosis = item.keterangan;
		 //        doc.setFontType('normal');
		 //        doc.setFontSize(8);
			// 	doc.text(x, y, splitDosis);

			// 	y+=this.config.cm_to_pixel(0.3);

			// 	var aturan_makan = item.aturan_makan_nama;
			// 	const splitAturanMakan = doc.splitTextToSize(aturan_makan, this.config.cm_to_pixel(7));
			// 	// const splitDosis = item.keterangan;
		 //        doc.setFontType('normal');
		 //        doc.setFontSize(8);
			// 	doc.text(x, y, splitAturanMakan);

			// 	y+=this.config.cm_to_pixel(0.3);

			// 	var waktu = item.waktu_nama;
			// 	const splitWaktu = doc.splitTextToSize(waktu, this.config.cm_to_pixel(7));
			// 	// const splitDosis = item.keterangan;
		 //        doc.setFontType('normal');
		 //        doc.setFontSize(8);
			// 	doc.text(x, y, splitWaktu);

				
			// 	if(item.keterangan != null && item.keterangan != ''){
			// 		y+=this.config.cm_to_pixel(0.3);

			// 		var keterangan = item.keterangan;
			// 		const splitKeterangan = doc.splitTextToSize(keterangan, this.config.cm_to_pixel(7));
			// 		// const splitDosis = item.keterangan;
			//         doc.setFontType('normal');
			//         doc.setFontSize(8);
			// 		doc.text(x, y, splitKeterangan);
			// 	}

			// 	counter+=1;
			// }
		


        // Save the Data
        this.setState({
        	dataResult: doc.output('datauristring')
        });
        doc.save('kutipan_surat_ketetapan_pajak_'+this.state.data_kendaraan.id+'.pdf');
    }

    setCenterText(doc, text, y) {
	    var textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
	    var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
	    doc.text(textOffset, y, text);
	}

	render() {		
    	return (
			  <div>
			  		<Helmet>
			  			<title>cetak surat ketetapan pajak</title>
			  		</Helmet>
			  		
			  		{/*
			  		<React.Fragment>
			  			<iframe frameBorder={0} src={this.state.dataResult} width={"100%"} height={500} />
			  		</React.Fragment>
			  		*/}
			  		
			  		<table className="label-kiri-pertama" border="0">
			  			<tr>
			  				<td className="label-kiri"></td>
			  				<td className="label-kanan">KH 39355 YC</td>
			  			</tr>
			  			<tr>
			  				<td className="label-kiri"></td>
			  				<td className="label-kanan">MAURIANUS ARYANTO RANNA</td>
			  			</tr>
			  			<tr>
			  				<td className="label-kiri"></td>
			  				<td className="label-alamat">JL. BUKIT PARAWEN NO. 11 RT.005/ 002 PALANKA KEC. JEKAN RAYA KOTA P. RAMA</td>
			  			</tr>

			  		</table>

			  		<table className="label-kiri-kedua" border="0" style={{width: this.config.cm_to_pixel(9)}}>
			  			<tr>
			  				<td className="label-kiri"></td>
			  				<td colspan="3">TOYOTA/AVANZA 1300 G (F01RM GMFF))</td>
			  			</tr>
			  			<tr>
			  				<td className="label-kiri"></td>
			  				<td style={{width: "3.2cm"}}>MINIBUS</td>
			  				<td className="label-kanan"></td>
			  				<td>BENSIN</td>
			  			</tr>
			  			<tr>
			  				<td className="label-kiri"></td>
			  				<td style={{width: "3.2cm"}}>2007/2007/1298CC</td>
			  				<td className="label-kanan"></td>
			  				<td>HITAM</td>
			  			</tr>
			  			<tr>
			  				<td className="label-kiri"></td>
			  				<td style={{width: "3.2cm"}}>SILVER METALIK</td>
			  				<td className="label-kanan"></td>
			  				<td>KH 4321 XY</td>
			  			</tr>
			  			<tr>
			  				<td className="label-kiri"></td>
			  				<td style={{width: "3.2cm"}}>MHFM1BA3J7K060673</td>
			  				<td className="label-kanan"></td>
			  				<td>1200 KG</td>
			  			</tr>
			  			<tr>
			  				<td className="label-kiri"></td>
			  				<td style={{width: "3.2cm"}}>DC46771</td>
			  				<td className="label-kanan"></td>
			  				<td>4</td>
			  			</tr>
			  			<tr>
			  				<td className="label-kiri"></td>
			  				<td style={{width: "3.2cm"}}>E7181843J</td>
			  			</tr>
			  		</table>

			  		<table className="label-kiri-ketiga" border="0" style={{width: this.config.cm_to_pixel(5.6)}}>
			  			<tr>
			  				<td className="label-kiri"></td>
			  				<td><b>08/06/2018</b></td>
			  			</tr>
			  		</table>


			  		<table className="label-kanan-pertama" border="0" style={{width: this.config.cm_to_pixel(6.1)}}>
			  			<tr>
			  				<td className="label-kiri"></td>
			  				<td>1235VI / XYZABC / 12345</td>
			  			</tr>
			  			<tr>
			  				<td className="label-kiri"></td>
			  				<td>NOSKUM124</td>
			  			</tr>
			  			<tr>
			  				<td className="label-kiri"></td>
			  				<td>KOHIR1234</td>
			  			</tr>
			  		</table>

			  		<div className="jam">11:47:18</div>

			  		<div className="jenis-layanan">PENDAFTARAN ULANG</div>

			  		<table className="label-kanan-kedua" border="0" style={{width: this.config.cm_to_pixel(6.1)}}>
			  			<tr>
			  				<td className="pokok">1.244.300</td>
			  				<td className="sanksi">34.100</td>
			  				<td className="jumlah">1.555.400</td>
			  			</tr>
			  			<tr>
			  				<td className="pokok">1.244.300</td>
			  				<td className="sanksi">34.100</td>
			  				<td className="jumlah">1.555.400</td>
			  			</tr>
			  			<tr>
			  				<td className="pokok">1.244.300</td>
			  				<td className="sanksi">34.100</td>
			  				<td className="jumlah">1.555.400</td>
			  			</tr>
			  			<tr>
			  				<td className="pokok">1.244.300</td>
			  				<td className="sanksi">34.100</td>
			  				<td className="jumlah">1.555.400</td>
			  			</tr>
			  			<tr>
			  				<td className="pokok">1.244.300</td>
			  				<td className="sanksi">34.100</td>
			  				<td className="jumlah">1.555.400</td>
			  			</tr>
			  			<tr>
			  				<td className="pokok">1.244.300</td>
			  				<td className="sanksi">34.100</td>
			  				<td className="jumlah">1.555.400</td>
			  			</tr>
			  			
			  		</table>

			  		<div className="tgl-penetapan">
			  			13/0/207
			  		</div>
			  		<div className="petugas-penetapan">
			  			ASILY PANARUNG,SE
			  		</div>
			  		<div className="korektor">
			  			DOHOLINAWETIS, SE
			  		</div>

			  		<div className="ttd1">
			  			<span className="jabatan">An. KEPALA KEPOLISIAN DAERAH KALTENG<br />DIREKTUR LALU LINTAS<br /></span>
			  			<span className="nama">ARMANDO PAULA S.I.K, M.Hum<br /></span>
			  			<div className="footer-ttd">KOMISARIS BESAR POLISI NRP 70110324</div>
			  		</div>
			  		<div className="ttd2">
			  			<span className="jabatan">KEPALA DINAS PENDAPATAN DAERAH<br />PROVINSI KALIMANTAN TENGAH<br /></span>
			  			<span className="nama">Drs. H. JAYA SAPUTRA, MM<br /></span>
			  			<div className="footer-ttd">Pembina Utama Madya<br />NIP. 1900000809 1 001</div>
			  		</div>
			  		<div className="ttd3">
			  			<span className="note">PERHATIAN: pembayaran sah apabila telah divalidasi<br /></span>
			  			<span className="jabatan">KEPALA PT. JASA RAHARJA (Persero)<br />CABANG KALIMANTAN TENGAH<br /></span>
			  			<span className="nama">SUSATYO<br /></span>
			  			<div className="footer-ttd">NPP 709817199</div>
			  		</div>

			  		
			  		
			   </div>

    	)
    }


}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler
		}
	)(CetakStnk);