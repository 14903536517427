import React from "react";
import { Link, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Breadcrumb,
  Card,
  Row,
  Col,
  Avatar,
  Form,
  Input,
  Button,
  Space,
  Select,
  DatePicker,
  message,
  Alert,
  Spin,
  Modal,
  Skeleton,
  Table,
  Tag,
  Divider,
} from "antd";
import {
  DiffOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DollarOutlined,
  BarcodeOutlined,
  UserAddOutlined,
  PrinterOutlined,
  UserOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  CarOutlined,
  UserSwitchOutlined,
  EyeOutlined
} from "@ant-design/icons";
import moment from "moment";
import NumberFormat from "react-number-format";

import axios from "axios";
import Config from "../../Config";

import { connect } from "react-redux";
import {
  initHandler,
  ajaxViewHandler,
  ajaxHandler,
  setConfig,
} from "../../store/actions";
import { Helmet } from "react-helmet";

const FormItem = Form.Item;

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 19,
      offset: 5,
    },
    lg: {
      span: 19,
      offset: 5,
    },
  },
};

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const configClass = {
  title: "form pendaftaran",
  apiUrl: {
    kabupaten: "master/api/kabupaten/",
    kendaraan: "transaksi/api/tagihan/",
    iwkbu: "transaksi/api/tagihan/"
  },
  pageUrl: {
    cetak_no: "/addon/cetak_antrian/",
    redirect_url: "/admin/transaksi/iwkbu/"
  },
  breadCrumb: [
    <span>
      <CarOutlined /> transaksi
    </span>,
    "iwkbu",
  ],
};

const config = new Config();

class FormIwkbu extends React.Component {
  formRef = React.createRef();
  formRefScroll = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      errComponent: false,
      errTitle: "",
      errMsg: "",

      isSukses: false,
      redirect_nopol: "",

      loadingButton: false,

      isModalRiwayat: false,

      cari_no_pol: undefined,

      isOpenForm: false,

      loadingDataKendaraan: false,
      dataKendaraan: null,

      pemilik_lama: null,

      merek_dipilih: "",
      jenis_kendaraan_dipilih: "",

      jenis: "Kendaraan Lama",

      aksi: "tambah",
      method: "post",
      idLama: "",

      modalBaru: false,

      field_requirements: {},

      kabupaten_dipilih: "",
      kecamatan_dipilih: "",

      modalKonfirmasi: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.initHandler();
  }

  componentDidMount() {
    // this.getDataRiwayat();
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    //clearFilters();
    this.setState({ searchText: '', isOpenForm: false, cari_no_pol: undefined });
  };



  searchKendaraan = (no_polisi) => {
    this.setState({
      errComponent: false,
      errTitle: "",
    })
    this.previewData(no_polisi);
  };

  async previewData(no_polisi) {
    message.loading({
      content: "Proses pencarian...",
      duration: 0,
      key: "toast",
    });

    this.props
      .ajaxViewHandler(
        "get",
        configClass.apiUrl.kendaraan + "?nomor_polisi=KH" + no_polisi
      )
      .then(() => {
        const datas = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (datas.results.length == 0) {
            this.setState({
              isOpenForm: false,
            });
            message.error({ content: "nomor kendaraan tidak ditemukan", duration: 2, key: "toast" });
          } else {
            this.setState({
              isOpenForm: true,
            }, () => {
              this.formRefScroll.current.scrollTo(0, 0);
            });
            const data = datas.results[0];
            //this.formRef.current.setFieldsValue(data);
            this.setState({
              pemilik_lama: data.pemilik,
              dataKendaraan: data,
              merek_dipilih: data.merek,
              jenis_kendaraan_dipilih: data.jenis_kendaraan,
              kabupaten_dipilih: data.kabupaten,
              kecamatan_dipilih: data.kecamatan,
              // modalKonfirmasi: data.tunggakan.is_popup != undefined && data.tunggakan.is_popup ? true : false
            }, () => {
              this.formRef.current.setFieldsValue(data);
              for (var key in data) {
                const dateRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4}$/;
                const dateTimeRegex =
                  /^[0-9]{2}\-[0-9]{2}\-[0-9]{4} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;

                const dateRegex2 = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
                const dateTimeRegex2 =
                  /^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
                if (dateRegex.test(data[key])) {
                  //jika format tanggal
                  this.formRef.current.setFieldsValue({
                    [key + "_picker"]: moment(data[key]),//.format("DD-MM-YYYY"),
                    [key]: data[key]
                    //[key]: moment(data[key], 'DD-MM-YYYY')
                  });
                } else if (dateTimeRegex.test(data[key])) {
                  //jika format tanggal dan jam
                  this.formRef.current.setFieldsValue({
                    [key + "_picker"]: moment(data[key]),//.format("DD-MM-YYYY HH:mm:ss"),
                    [key]: data[key]
                  });
                } else if (dateRegex2.test(data[key])) {
                  //jika format tanggal
                  // console.log(key, data[key]);
                  this.formRef.current.setFieldsValue({
                    [key + "_picker"]: moment(data[key]),
                    [key]: data[key]
                    //[key]: moment(data[key], 'DD-MM-YYYY')
                  });
                } else if (dateTimeRegex2.test(data[key])) {
                  //jika format tanggal dan jam
                  this.formRef.current.setFieldsValue({
                    [key + "_picker"]: moment(data[key]),
                    [key]: data[key]
                  });
                } else if (key == "masa_pajak_mulai" || key == "masa_pajak_sampai" || key == "sd_stnk" || key == "sd_swdkllj") {
                  if (data[key] != null) {
                    this.formRef.current.setFieldsValue({
                      [key + "_picker"]: moment(data[key]),
                      [key]: data[key]
                    });
                  }
                }
                // if(key == 'gambar'){
                // 	this.setState({
                // 		preview_gambar: data[key]
                // 	});
                // }else{
                // 	this.formRef.current.setFieldsValue({
                // 		[key]: data[key]
                // 	});
                // }
              }

              message.destroy();
              this.setState({
                idLama: data.id + "/",
              });
            })
          }
        } else {
          const errTitle = "error preview data lama";
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
          message.error({ content: errTitle, duration: 2, key: "toast" });
        }
      })
      .catch((response) => {
        console.log("gagal_get_preview", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  handleSubmit = (values) => {
    this.postDataPemilik(values);
  };

  reset() {
    this.formRef.current.setFieldsValue({
      pasien: undefined,
      jenis: "barcode",
    });
    this.resetPasien();
    this.setState({
      dataPasien: null,
      jenis: "barcode",
    });
    this.getDataRiwayat();
  }

  resetPasien() {
    // if (
    //   this.formRefPasien != undefined &&
    //   this.formRefPasien.current != undefined
    // ) {
    //   this.formRefPasien.current.resetFields();
    // }
  }

  async getPasienById(val) {
    message.loading({
      content: "Display data pasien...",
      duration: 0,
      key: "toast",
    });
    this.setState({
      errTitle: "",
      errMsg: "",
      dataPasien: null,
      skeletonPasien: true,
    });
    this.props
      .ajaxViewHandler("get", configClass.apiUrl.pasien + "/" + val)
      .then(() => {
        this.setState({
          skeletonPasien: false,
        });
        if (this.props.isSuccess) {
          message.destroy();

          var response = this.props.responseMsg;
          this.setState({
            dataPasien: response,
          });
        } else {
          const errTitle = "gagal menampilkan data pasien";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
        }
      })
      .catch((response) => {
        console.log("gagal_preview_pasien", response);
        this.setState({
          skeletonPasien: false,
        });
        message.error({
          content: "kesalahan pengiriman data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  async getDataPemilik(nik) {
    message.loading({
      content: "Pengecekan kepemilikan...",
      duration: 0,
      key: "toast",
    });
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler(
        "get",
        configClass.apiUrl.pemilik +
        "?nik=" +
        nik
      )
      .then(() => {
        const datas = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (datas.results != undefined) {
            const data = datas.results;
            if (data.length == 0) {
              message.warning({ content: "data kepemilikan tidak ditemukan", duration: 2, key: "toast" });
            } else {
              message.destroy();
            }
            for (var key in data) {
              const dateRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4}$/;
              const dateTimeRegex =
                /^[0-9]{2}\-[0-9]{2}\-[0-9]{4} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;

              const dateRegex2 = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
              const dateTimeRegex2 =
                /^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
              if (dateRegex.test(data[key])) {
                //jika format tanggal
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key], "DD-MM-YYYY"),
                  //[key]: moment(data[key], 'DD-MM-YYYY')
                });
              } else if (dateTimeRegex.test(data[key])) {
                //jika format tanggal dan jam
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key], "DD-MM-YYYY HH:mm:ss"),
                });
              } else if (dateRegex2.test(data[key])) {
                //jika format tanggal
                // console.log(key, data[key]);
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key], "DD-MM-YYYY"),
                  //[key]: moment(data[key], 'DD-MM-YYYY')
                });
              } else if (dateTimeRegex2.test(data[key])) {
                //jika format tanggal dan jam
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key], "DD-MM-YYYY HH:mm:ss"),
                });
              } else {
                this.formRef.current.setFieldsValue({
                  [key]: data[key],
                });
              }
            }
          }
        } else {
          const errTitle = "error get data";
          message.error({ content: errTitle, duration: 2, key: "toast" });
        }
      })
      .catch((response) => {
        console.log("gagal_get_wp", response);
        message.error({
          content: "gagal cek wp, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  async postDataPemilik(datas) {
    message.loading({ content: "Memproses...", duration: 0, key: "toast" });
    this.setState({
      errTitle: "",
      errMsg: "",
      loadingButton: true,
    });
    this.props
      .ajaxHandler("post", configClass.apiUrl.iwkbu + this.state.idLama + "iwkbu/", datas, true, true)
      .then(() => {
        if (this.props.isSuccess) {
          message.success({ content: 'berhasil mengaprove IWKBU', duration: 2, key: 'toast' });

          var response = this.props.responseMsg;
          this.handleReset();
          // this.postDataKendaraan(datas, response.id);
        } else {
          const errTitle = "gagal mengaprove IWKBU";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            loadingButton: false,
            errTitle: errTitle,
            errMsg: JSON.stringify(this.props.responseMsg),
          });
        }
      })
      .catch((response) => {
        console.log("gagal_post_pemilik", response);
        this.setState({
          loadingButton: false,
        });
        message.error({
          content: "kesalahan pengiriman data pemilik, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  async postDataKendaraan(datas, idPemilik) {
    var dataKendaraan = datas;
    // if (this.state.pemilik_lama == null) {
    //   dataKendaraan.pemilik = idPemilik;
    // } else {
    //   dataKendaraan.pemilik = this.state.pemilik_lama;
    // }
    dataKendaraan.pemilik = idPemilik;

    dataKendaraan.nomor_polisi =
      datas.no_polisi_depan + datas.no_polisi_tengah + datas.no_polisi_belakang;
    dataKendaraan.nomor_polisi_lama =
      datas.no_polisi_depan_lama +
      datas.no_polisi_tengah_lama +
      datas.no_polisi_belakang_lama;

    message.loading({ content: "Memproses...", duration: 0, key: "toast" });
    this.props
      .ajaxHandler(
        this.state.method,
        configClass.apiUrl.kendaraan + this.state.idLama,
        dataKendaraan,
        true,
        true
      )
      .then(() => {
        if (this.props.isSuccess) {
          message.success({
            content: "berhasil menyimpan pendaftaran",
            duration: 2,
            key: "toast",
          });
          this.setState({
            pemilik_lama: null,
            loadingButton: false,
            isOpenForm: false,

            isSukses: true,
            redirect_nopol: datas.no_polisi_tengah + datas.no_polisi_belakang,
          });
        } else {
          const errTitle = "gagal menyimpan data kendaraan";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            loadingButton: false,
            errTitle: errTitle,
            errMsg: JSON.stringify(this.props.responseMsg),
          });
        }
      })
      .catch((response) => {
        console.log("gagal_post_kendaraan", response);
        this.setState({
          loadingButton: false,
        });
        message.error({
          content: "kesalahan pengiriman data kendaraan, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  handleReset() {
    this.setState({
      isOpenForm: false,
    });
  }

  handleSubmitPasien = (values) => {
    var datas = new FormData();
    for (var key in values) {
      if (values[key] == undefined) {
        datas.append(key, "");
      } else {
        if (key == "nama" || key == "tempat_lahir") {
          datas.append(key, values[key].toString().toUpperCase());
        } else if (key == "email") {
          datas.append(key, values[key].toString().toLowerCase());
        } else {
          datas.append(key, values[key].toString());
        }
      }
    }
    // datas.append('provider', this.props.id);
    this.postDataPasien(datas);
  };

  async postDataPasien(datas) {
    message.loading({ content: "Memproses...", duration: 0, key: "toast" });
    this.setState({
      errTitle: "",
      errMsg: "",
    });
    this.props
      .ajaxHandler("post", configClass.apiUrl.pasien + "/", datas, true, true)
      .then(() => {
        this.setState({
          loadingButton: false,
        });
        if (this.props.isSuccess) {
          message.success({
            content: "berhasil menyimpan data",
            duration: 2,
            key: "toast",
          });
          var response = this.props.responseMsg;
          this.formRef.current.setFieldsValue({
            pasien: response.id + "",
          });
          //preview yang berbentuk list
          const rule = [
            {
              destination: {
                stateProgressName: "loadingPasien_searching",
                endPoint: configClass.apiUrl.pasien + "/" + response.id,
                stateDestination: "listPasien",
              },
              nextDestination: null,
            },
          ];

          this.setState({
            errComponent: false,
            modalBaru: false,
          });
          rule.map((item, index) => {
            this.getMasterList(item);
          });
          this.resetPasien();
          this.getPasienById(response.id);
        } else {
          const errTitle = "gagal menyimpan data";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
        }
      })
      .catch((response) => {
        console.log("gagal_post", response);
        this.setState({
          loadingButton: false,
        });
        message.error({
          content: "kesalahan pengiriman data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  loadMasterList() {
    const rule = [
      {
        destination: {
          stateProgressName: "loadingKabupaten",
          endPoint: configClass.apiUrl.kabupaten,
          stateDestination: "listKabupaten",
        },
        nextDestination: null
        // nextDestination: {
        //   stateProgressName: "loadingKecamatan",
        //   endPoint: configClass.apiUrl.kecamatan,
        //   stateDestination: "listKecamatan",
        //   next: null,
        // },
      },
      {
        destination: {
          stateProgressName: "loadingKepemilikan",
          endPoint: configClass.apiUrl.kepemilikan,
          stateDestination: "listKepemilikan",
        },
        nextDestination: {
          stateProgressName: "loadingFungsi",
          endPoint: configClass.apiUrl.fungsi,
          stateDestination: "listFungsi",
          next: null,
        },
      },
      {
        destination: {
          stateProgressName: "loadingJenisKendaraan",
          endPoint: configClass.apiUrl.jenis_kendaraan,
          stateDestination: "listJenisKendaraan",
        },
        nextDestination: {
          stateProgressName: "loadingMerek",
          endPoint: configClass.apiUrl.merek,
          stateDestination: "listMerek",
          next: null,
        },
      },
      {
        destination: {
          stateProgressName: "loadingTipe",
          endPoint: configClass.apiUrl.tipe,
          stateDestination: "listTipe",
        },
        nextDestination: {
          stateProgressName: "loadingBahanBakar",
          endPoint: configClass.apiUrl.bahan_bakar,
          stateDestination: "listBahanBakar",
          next: null,
        },
      },
      {
        destination: {
          stateProgressName: "loadingWarnaPlat",
          endPoint: configClass.apiUrl.warna_plat,
          stateDestination: "listWarnaPlat",
        },
        nextDestination: {
          stateProgressName: "loadingJenisLayanan",
          endPoint: configClass.apiUrl.jenis_layanan,
          stateDestination: "listJenisLayanan",
          next: null,
        },
      },
    ];

    this.setState({
      errComponent: false,
    });

    rule.map((item, index) => {
      if (index == rule.length - 1) {
        this.getMasterList(item, true);
      } else {
        this.getMasterList(item);
      }
    });
  }

  async getMasterList(rule, isFinal = false) {
    var destination = rule.destination;
    var nextDestination = rule.nextDestination;
    this.setState({ [destination.stateProgressName]: true });
    this.props
      .ajaxViewHandler("get", destination.endPoint)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (data.count != undefined) {
            let newState = null;
            if (this.state[destination.stateDestination].length == 0) {
              newState = data;
              // if(destination.stateProgressName.toLowerCase().includes("JenisLayanan")){
              //   let templistJenisLayananRequirement = data.results
              // }
            } else {
              if (destination.stateProgressName.includes("_searching")) {
                newState = data;
              } else {
                const oldState = this.state[destination.stateDestination];
                newState = {
                  count: data.count,
                  next: data.next,
                  previous: data.previous,
                  results: [...oldState.results, ...data.results],
                };
              }
            }
            this.setState({
              [destination.stateDestination]: newState,
            });
          } else if (data.results != undefined) {
            this.setState({
              [destination.stateDestination]: data.results,
            });
          } else {
            if (Array.isArray(data)) {
              this.setState({
                [destination.stateDestination]: data,
              });
            } else {
              this.setState({
                [destination.stateDestination]: [data],
              });
            }
          }
          if (isFinal) {
            if (this.props.match.params.id != undefined) {
              // this.preview(this.props.match.params.id);
            }
          }
        } else {
          this.setState({
            errComponent: true,
          });
        }
        this.setState({ [destination.stateProgressName]: false });
      })
      .catch((response) => {
        //console.log('gagal_get_master', response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({
          errTitle: "gagal request data master",
          errMsg: response,
        });
        this.setState({ [destination.stateProgressName]: false });
      });
    //next function
    if (nextDestination != null) {
      const next = {
        destination: {
          stateProgressName: nextDestination.stateProgressName,
          endPoint: nextDestination.endPoint,
          stateDestination: nextDestination.stateDestination,
        },
        nextDestination: nextDestination.next,
      };
      this.getMasterList(next);
    }
  }

  cekIsRequired(fieldName) {
    let result = false;
    // const data = this.state.field_requirements;
    // for (var key in data) {
    //   if (fieldName == key) {
    //     result = data[key]
    //   }
    // }
    // console.log("field_name", fieldName)
    // console.log("result", result)
    return result;
  }

  render() {
    // if(this.props.layanan_str.length>0 && this.props.layanan_str.includes('Pembelian / Pemesanan Obat') == false){
    // 	return <Redirect to="/admin/forbidden" />
    // }
    if (this.state.isSukses) {
      return <Redirect to={configClass.pageUrl.redirect_url + "?no_pol=" + this.state.redirect_nopol} />
    }

    return (
      <>
        <div>
          <Helmet>
            <title>
              {configClass.title + " | " + config.get_site_info().name}
            </title>
          </Helmet>
          <Breadcrumb>
            {configClass.breadCrumb.map((item) => (
              <Breadcrumb.Item>{item}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
          <div className="card" id="panel-transaksi">
            <Card
              className="konten"
              title={null}
            >
              <Form
                layout="horizontal"
                name="fdata"
                onFinish={this.handleSubmit}
                size="small"
                ref={this.formRef}
                initialValues={{
                  jenis: this.state.jenis,
                  no_polisi_depan: "KH",
                  is_next: false,
                }}
              >
                <Form.Item
                  name="is_next"
                  style={{
                    display: "none",
                  }}
                >
                  <Input type="hidden" />
                </Form.Item>

                <Row className="isi">
                  {this.state.errTitle != null && this.state.errTitle != "" && (
                    <Alert
                      closable
                      message={this.state.errTitle}
                      description={this.state.errMsg}
                      type="error"
                      showIcon
                      style={{
                        marginBottom: 10,
                        width: "100%"
                      }}
                    />
                  )}

                  <Col span={24}>
                    <Row justify="start">
                      <Col span={24}>
                        <Input.Group compact>
                          <Form.Item name="jenis" style={{ width: "20%" }}>
                            <Select
                              size="large"
                              showSearch
                              placeholder="pilih jenis"
                              optionFilterProp="children"
                              style={{
                                width: "100%",
                              }}
                              disabled
                              onChange={(val) => {
                                this.setState(
                                  {
                                    jenis: val,
                                    isOpenForm: val == "Kendaraan Lama" ? false : true
                                    // modalBaru: val == 'pasien baru' ? true : false
                                  },
                                  () => {
                                    // this.formRef.pasien.current.focus({cursor: 'start'});
                                    this.formRef.current.setFieldsValue({
                                      // pasien: undefined
                                    });
                                    if (val == "Kendaraan Baru") {
                                      this.formRefScroll.current.scrollTo(0, 0);
                                    }
                                  }
                                );
                              }}
                            >
                              <Select.Option value={"Kendaraan Lama"}>
                                <BarcodeOutlined /> Kendaraan Lama
                              </Select.Option>
                              <Select.Option value={"Kendaraan Baru"}>
                                <CarOutlined /> Kendaraan Baru
                              </Select.Option>
                            </Select>
                          </Form.Item>
                          {this.state.jenis == "Kendaraan Lama"
                            ?
                            <Input
                              allowClear
                              style={{ width: "80%" }}
                              placeholder="input nomer polisi, cth : KH1234XY"
                              onChange={(e) => {
                                const regex = /^\d+\.?\d*$/;
                                let startIdx = 0;
                                if (regex.test(e.target.value.charAt(0))) {
                                  startIdx = 0;
                                } else {
                                  if (regex.test(e.target.value.charAt(1))) {
                                    startIdx = 1;
                                  } else {
                                    startIdx = 2;
                                  }
                                }

                                const noKendaraan = e.target.value.substring(startIdx);

                                this.setState({
                                  // cari_no_pol: e.target.value,
                                  cari_no_pol: noKendaraan,
                                });
                              }}
                              prefix="KH"
                              value={this.state.cari_no_pol}
                              name="cari_no_pol"
                              size="large"
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  this.searchKendaraan(this.state.cari_no_pol);
                                }
                              }}
                            />
                            :
                            <Input
                              size="large"
                              style={{ width: "80%" }}
                              disabled
                            />
                          }

                        </Input.Group>
                      </Col>
                    </Row>
                  </Col>

                  {this.state.isOpenForm == true && (
                    <Col
                      span={24}
                      style={{
                        overflowY: "auto",
                        maxHeight: "calc(100vh - 205px)",
                        // background: "rgb(11,93,37, 0.2)", 
                        padding: 10,
                        // boxShadow: "5px 10px"
                      }}
                      ref={this.formRefScroll}>
                      <Row className="form-penetapan-biaya">
                        <Col span={24}>
                          <Row justify="start">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Nomor Polisi
                            </Col>
                            <Col span={5} style={{ paddingLeft: 5 }}>
                              <Form.Item name="nomor_polisi">
                                <Input placeholder="KH1234XY" disabled />
                              </Form.Item>
                            </Col>
                            <Col span={1} style={{ paddingLeft: 5 }}>
                              SKUM
                            </Col>
                            <Col span={2} style={{ paddingLeft: 5 }}>
                              <Form.Item name="no_skum">
                                <Input disabled={!this.cekIsRequired("skum1")} />
                              </Form.Item>
                            </Col>
                            <Col span={2} style={{ paddingLeft: 5 }}>
                              Kohir
                            </Col>
                            <Col span={3} style={{ paddingLeft: 5 }}>
                              <Form.Item name="no_kohir">
                                <Input disabled={!this.cekIsRequired("kohir1")} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="start">
                            <Col span={3} style={{ textAlign: "right" }}>
                              No Identitas
                            </Col>
                            <Col span={21} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="pemilik_nik"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input
                                  disabled={!this.cekIsRequired("nik")}
                                  placeholder="cth: 1234567890123456"
                                  onChange={(e) => {
                                    this.getDataPemilik(e.target.value);
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={3} style={{ textAlign: "right" }}>
                              Nama WP/Pemilik
                            </Col>
                            <Col span={21} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="pemilik_nama"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("nama")} placeholder="cth: Budi Raharjo" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="start">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Alamat WP/Pemilik
                            </Col>
                            <Col span={21} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="alamat_wp"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("alamat")} placeholder="cth: Jl. Raya Indonesia No 75, Bumi Insan Pertiwi" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="start">
                            <Col span={3} style={{ textAlign: "right" }}>
                              No Hp
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="pemilik_no_hp"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("no_hp")} placeholder="cth: 081234567890" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="start">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Kepemilikan
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="kepemilikan_nama"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("kepemilikan_ke")} placeholder="cth: 1" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="start">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Kode Fungsi
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="fungsi_nama"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("kepemilikan_ke")} placeholder="cth: 1" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="space-between">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Jenis
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="jenis_kendaraan"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("kepemilikan_ke")} placeholder="cth: 1" />
                              </Form.Item>
                            </Col>
                            <Col span={3} style={{ textAlign: "right" }}>
                              Merk
                            </Col>
                            <Col span={11} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="merk_kendaraan"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("kepemilikan_ke")} placeholder="cth: 1" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="start">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Tipe
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="tipe_kendaraan"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("kepemilikan_ke")} placeholder="cth: 1" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="space-between">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Tahun Buat
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="tahun_buat"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("tahun_buat")} placeholder="cth: 2021" />
                              </Form.Item>
                            </Col>
                            <Col span={3} style={{ textAlign: "right" }}>
                              CC
                            </Col>
                            <Col span={11} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="cc"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("silinder")} placeholder="cth: 150" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="space-between">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Warna
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="warna"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("warna")} placeholder="cth: merah" />
                              </Form.Item>
                            </Col>
                            <Col span={3} style={{ textAlign: "right" }}>
                              No. Rangka
                            </Col>
                            <Col span={11} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="nomor_rangka"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("nomor_rangka")} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="space-between">
                            <Col span={3} style={{ textAlign: "right" }}>
                              No Mesin
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="nomor_mesin"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("nomor_mesin")} />
                              </Form.Item>
                            </Col>
                            <Col span={3} style={{ textAlign: "right" }}>
                              Kode BBM
                            </Col>
                            <Col span={11} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="bahan_bakar_nama"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("nomor_mesin")} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="space-between">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Warna Plat
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="warna_plat_nama"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("nomor_mesin")} />
                              </Form.Item>
                            </Col>
                            <Col span={3} style={{ textAlign: "right" }}>
                              No. BPKB
                            </Col>
                            <Col span={11} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="no_bpkb"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("no_bpkb")} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="space-between">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Tgl STNK
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item name="sd_stnk_picker">
                                <DatePicker
                                  placeholder="dd-mm-yyyy"
                                  format="DD-MM-YYYY"
                                  style={{ width: "100%" }}
                                  onChange={(date, dateString) => {
                                    this.formRef.current.setFieldsValue({
                                      stnk_mulai: dateString,
                                    });
                                  }}
                                  disabled={!this.cekIsRequired("tgl_pkb")}
                                />
                              </Form.Item>
                              <Form.Item
                                name="sd_stnk"
                                style={{
                                  display: "none",
                                }}
                              >
                                <Input type="hidden" />
                              </Form.Item>
                            </Col>
                            <Col span={4} style={{ textAlign: "right" }}>
                              Tgl SWDKLLJ
                            </Col>
                            <Col span={10} style={{ paddingLeft: 5 }}>
                              <Form.Item name="sd_swdkllj_picker">
                                <DatePicker
                                  placeholder="dd-mm-yyyy"
                                  format="DD-MM-YYYY"
                                  style={{ width: "100%" }}
                                  onChange={(date, dateString) => {
                                    this.formRef.current.setFieldsValue({
                                      sd_swdkllj: dateString,
                                    });
                                  }}
                                  disabled={!this.cekIsRequired("sd_swdkllj")}
                                />
                              </Form.Item>
                              <Form.Item
                                name="sd_swdkllj"
                                style={{
                                  display: "none",
                                }}
                              >
                                <Input type="hidden" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>

                        <Col span={24}>
                          <Row justify="start">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Tgl IWKBU
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="tgl_iwkbu_picker"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus diisi'
                                  }
                                ]}
                              >
                                <DatePicker
                                  placeholder="dd-mm-yyyy"
                                  format="DD-MM-YYYY"
                                  style={{ width: "100%" }}
                                  onChange={(date, dateString) => {
                                    this.formRef.current.setFieldsValue({
                                      tgl_iwkbu: dateString,
                                    });
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                name="tgl_iwkbu"
                                style={{
                                  display: "none",
                                }}
                              >
                                <Input type="hidden" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="end">
                            <Col span={3} style={{ padding: 3 }}>
                              <Button
                                style={{ width: "100%" }}
                                key="btn-simpan"
                                loading={this.state.loadingButton}
                                type="primary"
                                htmlType="submit"
                              //disabled={this.state.errComponent ? true : false}
                              >
                                {!this.state.loadingButton && (
                                  <CheckCircleOutlined />
                                )}
                                Approve
                              </Button>
                            </Col>
                            <Col span={3} style={{ padding: 3 }}>
                              <Button
                                type="danger"
                                key="btn-cancel"
                                disabled={this.state.loadingButton ? true : false}
                                style={{ width: "100%" }}
                                onClick={() => this.handleReset()}
                              >
                                <CloseCircleOutlined /> Reset
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Form>
            </Card>
          </div>
        </div>
        <Modal
          title="Riwayat Pemilik Kendaraan"
          visible={this.state.isModalRiwayat}
          footer={[]}
          closable={true}
          width={"80%"}
          onCancel={() => this.setState({ isModalRiwayat: false })}
        >
          <Table
            title={() =>
              <Row>
                <Col lg={8} sm={24} xs={24}><span className="konten-judul">Total {this.state.paginationRiwayat.total} record</span></Col>
                <Col lg={{ span: 8, offset: 8 }} sm={24} xs={24}>
                  <Input.Search
                    size="small"
                    placeholder="ketikkan pencarian"
                    onSearch={value => {
                      this.setState({
                        searchText: value
                      }, () => {
                        this.getDataRiwayat();
                      });
                    }}
                    allowClear
                  />
                </Col>
              </Row>
            }
            bordered
            columns={this.state.columnsRiwayat}
            dataSource={this.state.dataSourceRiwayat}
            loading={this.state.loadingDataRiwayat}
            scroll={{ x: 240, y: 450 }}
            size="small"
            pagination={this.state.paginationRiwayat}
            onChange={this.handleTableChangeRiwayat}
          />

        </Modal>

        <Modal
          title={this.state.dataModal != null ? this.state.dataModal.judul : ""}
          centered
          visible={this.state.visibleModal}
          // onOk={() => this.setVisible(false)}
          onCancel={() => this.setState({ visibleModal: false })}
          width={700}
          footer={[]}
        >

          <Row>
            <Col span={24}>

              {(this.state.dataModal != null && this.state.dataModal.is_flat == true && Object.entries(this.state.dataModal.konten.value).map(([key, value]) => (
                <Row style={{ borderBottom: "1px solid #F0F2F6" }}>
                  <Col span={12}>{config.toSpace(key).toUpperCase()}</Col>
                  <Col span={12}>
                    {Array.isArray(value) ? ": " + JSON.stringify(value) : ": " + (value == null ? "-" : value)}
                  </Col>
                </Row>
              )))}
            </Col>
          </Row>
          <Row justify="end">
            <Col style={{ padding: 5 }}>
              <Button
                type="danger"
                key="btn-cancel"
                size="small"
                onClick={() => this.setState({ visibleModal: false })}
              >
                <CloseCircleOutlined /> Tutup
              </Button>
            </Col>
          </Row>


        </Modal>


      </>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  initHandler,
  setConfig,
  ajaxViewHandler,
  ajaxHandler,
})(FormIwkbu);
