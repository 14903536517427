import React from "react";
import { browserHistory } from 'react-router';
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import {
	Layout,
	Menu,
	Drawer,
	Result,
	Button,
	message,
	notification,
	Modal,
	Row,
	Col,
	Spin,
	Skeleton,
	Card,
	Form,
	Alert,
	Input
} from 'antd';
import {
	CheckCircleOutlined,
	CloseCircleOutlined,
} from '@ant-design/icons';
import { WarningOutlined } from '@ant-design/icons';
import { enquireScreen, unenquireScreen } from 'enquire-js'
import 'antd/dist/antd.css';
import '../assets/style.css';

import 'react-perfect-scrollbar/dist/css/styles.css';

import { connect } from 'react-redux';
import {
	setConfig,
	ajaxViewHandler,
	ajaxHandler
} from "../store/actions";

import axios from 'axios';
import Config from '../Config';

import Cookies from 'js-cookie';
import Sider from '../components/AdminSider';
import Header from '../components/AdminHeader';
import Footer from '../components/AdminFooter';

import { LayananRoute } from "../LayananRoute";

import Home from '../views/Home';
import Forbidden from '../views/Forbidden';

import DataNegara from '../views/negara/DataNegara';
import FormNegara from '../views/negara/FormNegara';

import DataTipe from '../views/tipe/DataTipe';
import FormTipe from '../views/tipe/FormTipe';

import DataMerek from '../views/merek/DataMerek';
import FormMerek from '../views/merek/FormMerek';

import DataJenisKendaraan from '../views/jenis_kendaraan/DataJenisKendaraan';
import FormJenisKendaraan from '../views/jenis_kendaraan/FormJenisKendaraan';

import DataBahanBakar from '../views/bahan_bakar/DataBahanBakar';
import FormBahanBakar from '../views/bahan_bakar/FormBahanBakar';

import DataWarna from '../views/warna/DataWarna';
import FormWarna from '../views/warna/FormWarna';

import DataPekerjaan from '../views/pekerjaan/DataPekerjaan';
import FormPekerjaan from '../views/pekerjaan/FormPekerjaan';

import DataKepemilikan from '../views/kepemilikan/DataKepemilikan';
import FormKepemilikan from '../views/kepemilikan/FormKepemilikan';

import DataLayanan from '../views/layanan/DataLayanan';
import FormLayanan from '../views/layanan/FormLayanan';

import DataDealer from '../views/dealer/DataDealer';
import FormDealer from '../views/dealer/FormDealer';

import DataPayment from '../views/payment/DataPayment';
import FormPayment from '../views/payment/FormPayment';

import DataGolongan from '../views/golongan/DataGolongan';
import FormGolongan from '../views/golongan/FormGolongan';

import DataWarnaPlat from '../views/warna_plat/DataWarnaPlat';
import FormWarnaPlat from '../views/warna_plat/FormWarnaPlat';

import DataFungsi from '../views/fungsi/DataFungsi';
import FormFungsi from '../views/fungsi/FormFungsi';

import FormPendaftaran from '../views/transaksi/FormPendaftaran';
import FormPenetapanBiaya from '../views/transaksi/FormPenetapanBiaya';
import FormIwkbu from '../views/transaksi/FormIwkbu';
import FormBlokir from '../views/transaksi/FormBlokir';

import FormInformasiKendaraan from '../views/informasi/FormInformasiKendaraan';
import CariDataKbm from '../views/informasi/CariDataKbm';

import DataNjkb from '../views/njkb/DataNjkb';
import FormNjkb from '../views/njkb/FormNjkb';

import DataSwdkllj from '../views/swdkllj/DataSwdkllj';
import FormSwdkllj from '../views/swdkllj/FormSwdkllj';

import DataAdmstnk from '../views/admstnk/DataAdmstnk';
import FormAdmstnk from '../views/admstnk/FormAdmstnk';

import DataAdmtnkb from '../views/admtnkb/DataAdmtnkb';
import FormAdmtnkb from '../views/admtnkb/FormAdmtnkb';

import DataDendaPkb from '../views/denda_pkb/DataDendaPkb';
import FormDendaPkb from '../views/denda_pkb/FormDendaPkb';

import DataDendaSwdkllj from '../views/denda_swdkllj/DataDendaSwdkllj';
import FormDendaSwdkllj from '../views/denda_swdkllj/FormDendaSwdkllj';
import SuratKeteranganFiskal from '../views/surat_fiskal/SuratKeteranganFiskal';

import FormPembayaran from '../views/transaksi/FormPembayaran';

import DataTandaTangan from '../views/tanda_tangan/DataTandaTangan';
import FormTandaTangan from '../views/tanda_tangan/FormTandaTangan';

import Laporan from '../views/laporan/Laporan';

import BbnkbDanPkb from '../views/laporan/BbnkbDanPkb';
import RekapitulasiPenerimaan from '../views/laporan/RekapitulasiPenerimaan';
import LapNotice from '../views/laporan/LapNotice';
import LapNoticeRusak from '../views/laporan/LapNoticeRusak';

import CancelNotice from '../views/transaksi/CancelNotice';

import Notice from '../views/notice/Notice';

import LapSwdkllj from '../views/laporan/LapSwdkllj';

import LapKasir from '../views/laporan/LapKasir';
import LapPerRekening from '../views/laporan/LapPerRekening';

import LapCetakStnk from '../views/laporan/LapCetakStnk';

import LapBendaharaPenerimaan from '../views/laporan/LapBendaharaPenerimaan';

import LapPenetapanPkb from '../views/laporan/LapPenetapanPkb';

import LapPenerimaanPkbWarnaPlat from '../views/laporan/LapPenerimaanPkbWarnaPlat';

import LapRealisasiPenerimaan from '../views/laporan/LapRealisasiPenerimaan';

import Pemutihan from '../views/laporan/Pemutihan';

import LaporanPnbp from '../views/laporan/LaporanPnbp';

import LapStss from '../views/laporan/LapStss';
import StssHarian from '../views/laporan/StssHarian';

import DataJabatan from '../views/jabatan/DataJabatan';
import FormJabatan from '../views/jabatan/FormJabatan';

import DataUpt from '../views/upt/DataUpt';
import FormUpt from '../views/upt/FormUpt';

import DataBidang from '../views/bidang/DataBidang';
import FormBidang from '../views/bidang/FormBidang';

import DataPegawai from '../views/pegawai/DataPegawai';
import FormPegawai from '../views/pegawai/FormPegawai';

import DataTandaTanganLaporan from '../views/tanda_tangan_laporan/DataTandaTanganLaporan';
import FormTandaTanganLaporan from '../views/tanda_tangan_laporan/FormTandaTanganLaporan';

import DataPengaturanPrinter from '../views/printer/DataPengaturanPrinter';
import FormPengaturanPrinter from '../views/printer/FormPengaturanPrinter';

import DataMigrasi from '../views/migrasi/DataMigrasi';

import Umum from '../views/umum/Umum';

import DataJasaraharja from '../views/transaksi/DataJasaraharja';

import DataDiskon from '../views/diskon/DataDiskon';
import FormDiskon from '../views/diskon/FormDiskon';

import DataWaktuOperasional from '../views/waktu_operasional/DataWaktuOperasional';
import FormWaktuOperasional from '../views/waktu_operasional/FormWaktuOperasional';

import DataImportNjkb from '../views/njkb/DataImportNjkb';

import moment from 'moment';

import 'moment/locale/id'  // without this line it didn't work
moment.locale('id')

// import Forbidden from '../views/forbidden/Forbidden';
// import { askForPermissioToReceiveNotifications, logoutFirebase } from '../push-notification';

const { Content } = Layout;

const configClass = {
	apiUrl: {
		userProfile: "auth/api/user/info/",
		upt: "pegawai/api/upt/",
		pegawai: "pegawai/api/pegawai/set_upt/",
		notice: "kendaraan/api/pengaturan/notice/",
	}
}

class AdminLayout extends React.Component {
	config;
	formRef = React.createRef();

	constructor(props) {
		super(props);
		this.config = new Config();
		this.state = {
			counter: 3,
			isForbidden: false,
			isModalUpt: false,
			listUpt: [],
			loadingUpt: true,

			errPilihUpt: null,
			loadingPilihUpt: false,

			isNotice: false,
			modalNotice: false,

			aksi: 'tambah',
			method: 'post',
			idLama: '',
			errMsg: '',
		}
	}

	handleCloseDrawer = () => {
		this.props.setConfig({ collapsed: true });
		// console.log('drawer_close', this.props.collapsed);
	}

	componentDidMount() {
		// if (!("Notification" in window)) {
		//     alert("browser tidak support notifikasi, notifikasi chat tidak akan aktif di browser ini");
		// }else if(Notification.permission !== "denied"){
		// 	Notification.requestPermission().then(function (permission) {
		//       // If the user accepts, let's create a notification
		//       if (permission === "granted") {
		//         //var notification = new Notification("notifikasi berhasil diaktifkan!");
		//       }
		//     });
		// }
		// this.connect();

		this.enquireHandler = enquireScreen(mobile => {
			const { isMobile } = this.props
			if (isMobile !== mobile) {
				this.props.setConfig({
					isMobile: mobile
				});
				if (mobile) {
					this.props.setConfig({
						collapsed: false
					});
				}
			}
		});

		this.getProfile();

		// if (this.props.location.pathname != "/admin/forbidden") {
		// 	setTimeout(() => {
		// 		this.cekRouteAccess();
		// 	}, 1000);
		// } else {
		// 	console.log('forbidden')
		// }

		// this.props.setConfig({
		// 	listUpt: [
		// 		{
		// 			id: 1,
		// 			nama: "UPT Kab Bantul"
		// 		},
		// 		{
		// 			id: 2,
		// 			nama: "UPT Kab Sleman"
		// 		},
		// 		{
		// 			id: 3,
		// 			nama: "UPT Kab Wates"
		// 		},
		// 		{
		// 			id: 4,
		// 			nama: "UPT Kab Yogyakarta"
		// 		},
		// 		{
		// 			id: 5,
		// 			nama: "UPT Kab Wonosari"
		// 		}
		// 	]
		// })

		// setTimeout(() => {
		// 	if (Cookies.get(this.config.get_cookie_name() + "_upt") == undefined) {
		// 		this.setState({
		// 			// isModalUpt: true
		// 		})

		// 	}

		// }, 100)
	}


	componentDidUpdate(prevProps) {



		// if (prevProps.location.pathname != this.props.location.pathname) {
		// 	console.log('prev_route', prevProps.location.pathname)
		// 	console.log('this_route', this.props.location.pathname)
		// 	if (this.props.location.pathname != "/admin/forbidden") {
		// 		setTimeout(() => {
		// 			console.log('jalan')
		// 			this.cekRouteAccess();
		// 		}, 1200);
		// 	} else {
		// 		console.log('forbidden2')
		// 		window.location.reload();
		// 	}
		// }



	}

	// cekRouteAccess() {
	// 	this.setState({
	// 		counter: 3
	// 	});
	// 	if (this.props.location.pathname != "/admin/forbidden") {
	// 		if (this.props.location.pathname == "/admin/tanda_tangan" && this.props.akses != null && this.props.akses != undefined && this.props.akses.master_tanda_tangan == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/negara" && this.props.akses != null && this.props.akses != undefined && this.props.akses.master_negara == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/merek" && this.props.akses != null && this.props.akses != undefined && this.props.akses.master_merek == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/tipe" && this.props.akses != null && this.props.akses != undefined && this.props.akses.master_Tipe == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/jenis-kendaraan" && this.props.akses != null && this.props.akses != undefined && this.props.akses.master_jenis_kendaraan == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/bahan-bakar" && this.props.akses != null && this.props.akses != undefined && this.props.akses.master_bahan_bakar == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/warna" && this.props.akses != null && this.props.akses != undefined && this.props.akses.master_warna == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/pekerjaan" && this.props.akses != null && this.props.akses != undefined && this.props.akses.master_pekerjaan == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/kepemilikan" && this.props.akses != null && this.props.akses != undefined && this.props.akses.master_kepemilikan == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/layanan" && this.props.akses != null && this.props.akses != undefined && this.props.akses.master_layanan == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/dealer" && this.props.akses != null && this.props.akses != undefined && this.props.akses.master_dealer == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/payment" && this.props.akses != null && this.props.akses != undefined && this.props.akses.master_payment == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/golongan" && this.props.akses != null && this.props.akses != undefined && this.props.akses.master_golongan == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/warna-plat" && this.props.akses != null && this.props.akses != undefined && this.props.akses.master_warna_plat == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/fungsi" && this.props.akses != null && this.props.akses != undefined && this.props.akses.master_fungsi == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/jabatan" && this.props.akses != null && this.props.akses != undefined && this.props.akses.pegawai_jabatan == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/upt" && this.props.akses != null && this.props.akses != undefined && this.props.akses.pegawai_upt == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/bidang" && this.props.akses != null && this.props.akses != undefined && this.props.akses.pegawai_bidang == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/pegawai" && this.props.akses != null && this.props.akses != undefined && this.props.akses.pegawai_pegawai == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/pengaturan/njkb" && this.props.akses != null && this.props.akses != undefined && this.props.akses.pengaturan_njkb == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/pengaturan/swdkllj" && this.props.akses != null && this.props.akses != undefined && this.props.akses.pengaturan_swdkllj == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/pengaturan/admstnk" && this.props.akses != null && this.props.akses != undefined && this.props.akses.pengaturan_adm_stnk == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/pengaturan/admtnkb" && this.props.akses != null && this.props.akses != undefined && this.props.akses.pengaturan_adm_tnkb == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/pengaturan/denda-pkb" && this.props.akses != null && this.props.akses != undefined && this.props.akses.pengaturan_denda_pkb == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/pengaturan/denda-swdkllj" && this.props.akses != null && this.props.akses != undefined && this.props.akses.pengaturan_denda_swdkllj == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/pengaturan/notice" && this.props.akses != null && this.props.akses != undefined && this.props.akses.pengaturan_notice == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/informasi/informasi-kendaraan" && this.props.akses != null && this.props.akses != undefined && this.props.akses.informasi_informasi_kendaraan == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/informasi/cari-data-kbm" && this.props.akses != null && this.props.akses != undefined && this.props.akses.informasi_cari_data_kbm == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/transaksi/pendaftaran" && this.props.akses != null && this.props.akses != undefined && this.props.akses.transaksi_pendaftaran == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/transaksi/penetapan-biaya" && this.props.akses != null && this.props.akses != undefined && this.props.akses.transaksi_penetapan == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/transaksi/surat-keterangan-fiskal" && this.props.akses != null && this.props.akses != undefined && this.props.akses.transaksi_surat_keterangan_fiskal == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/transaksi/pembayaran" && this.props.akses != null && this.props.akses != undefined && this.props.akses.transaksi_pembayaran == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/transaksi/cancel-notice" && this.props.akses != null && this.props.akses != undefined && this.props.akses.transaksi_cancle_notice == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/laporan/bbnkb-pkb" && this.props.akses != null && this.props.akses != undefined && this.props.akses.laporan_penerimaan_bbnkb_pkb == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/laporan/notice" && this.props.akses != null && this.props.akses != undefined && this.props.akses.laporan_notice == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 		if (this.props.location.pathname == "/admin/laporan/notice-rusak" && this.props.akses != null && this.props.akses != undefined && this.props.akses.laporan_notice_rusak == false) {
	// 			this.setState({
	// 				isModalForbidden: true
	// 			});
	// 			this.doCounter();
	// 		}
	// 	}
	// }



	// doCounter() {
	// 	setTimeout(() => {
	// 		if (this.state.counter > 0) {
	// 			let temp = this.state.counter;
	// 			temp -= 1;
	// 			this.setState({
	// 				counter: temp
	// 			});
	// 			this.doCounter();
	// 		} else {
	// 			this.setState({
	// 				isForbidden: true
	// 			});
	// 		}
	// 	}, 1000);
	// }

	componentWillUnmount() {
		unenquireScreen(this.enquireHandler)
	}

	// connect = () => {
	//         var ws = new WebSocket(this.config.get_web_socket()+"?token="+Cookies.get(this.config.get_cookie_name()).replace("Bearer ", ""));
	//         let that = this; // cache the this
	//         var connectInterval;

	//         // websocket onopen event listener
	//         ws.onopen = () => {
	//             console.log("web socket connected with token "+Cookies.get(this.config.get_cookie_name()).replace("Bearer ", ""));

	//             this.props.setConfig({
	//               ws: ws
	//             });

	//             that.timeout = 250; // reset timer to 250 on open of websocket connection 
	//             clearTimeout(connectInterval); // clear Interval on on open of websocket connection
	//         };

	//         ws.onmessage = evt => {
	//         	const pesan = JSON.parse(evt.data);
	// 		    //this.setState({dataFromServer: pesan});
	// 		    //console.log(pesan);
	// 		    if(pesan.status != null && pesan.status == 'online'){
	// 		    	//notif konek ke websocket
	// 		    }else{
	// 		    	// console.log('pesan_masuk', pesan);

	// 		    	if(pesan.tipe == 'Pembelian / Pemesanan Obat'){
	// 		    		notification['info']({
	// 					    message: pesan.tipe+' Baru',
	// 					    description: 'ada transaksi '+pesan.tipe+' Baru, silahkan konfirmasi segera.',
	// 					    placement: 'topRight'
	// 					});
	// 		    		this.props.setConfig({
	// 		    			latestId_PembelianObat: pesan.transaksi
	// 		    		});
	// 		    	}else if(pesan.tipe == 'Periksa Laboratorium'){
	// 		    		notification['info']({
	// 					    message: pesan.tipe+' Baru',
	// 					    description: 'ada transaksi '+pesan.tipe+' Baru, silahkan konfirmasi segera.',
	// 					    placement: 'topRight'
	// 					});
	// 		    		this.props.setConfig({
	// 		    			latestId_PeriksaLab: pesan.transaksi
	// 		    		});
	// 		    	}else if(pesan.tipe == 'Home Care'){
	// 		    		notification['info']({
	// 					    message: pesan.tipe+' Baru',
	// 					    description: 'ada transaksi '+pesan.tipe+' Baru, silahkan konfirmasi segera.',
	// 					    placement: 'topRight'
	// 					});
	// 		    		this.props.setConfig({
	// 		    			latestId_HomeCare: pesan.transaksi
	// 		    		});
	// 		    	}else if(pesan.tipe == 'Ambulan / Mobil Janazah'){
	// 		    		notification['info']({
	// 					    message: pesan.tipe+' Baru',
	// 					    description: 'ada transaksi '+pesan.tipe+' Baru, silahkan konfirmasi segera.',
	// 					    placement: 'topRight'
	// 					});
	// 		    		this.props.setConfig({
	// 		    			latestId_Ambulans: pesan.transaksi
	// 		    		});
	// 		    	}else if(pesan.tipe == 'Emergency Call'){
	// 		    		notification['info']({
	// 					    message: pesan.tipe+' Baru',
	// 					    description: 'ada transaksi '+pesan.tipe+' Baru, silahkan konfirmasi segera.',
	// 					    placement: 'topRight'
	// 					});
	// 		    		this.props.setConfig({
	// 		    			latestId_Emergency: pesan.transaksi
	// 		    		});
	// 		    	}


	// 			}

	//         };

	//         // websocket onclose event listener
	//         ws.onclose = e => {
	//             console.log(
	//                 `Socket is closed. Reconnect will be attempted in ${Math.min(
	//                     10000 / 1000,
	//                     (that.timeout + that.timeout) / 1000
	//                 )} second.`,
	//                 e.reason
	//             );

	//             that.timeout = that.timeout + that.timeout; //increment retry interval
	//             connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
	//         };

	//         // websocket onerror event listener
	//         ws.onerror = err => {
	//             console.error(
	//                 "Socket encountered error: ",
	//                 err.message,
	//                 "Closing socket"
	//             );

	//             ws.close();
	//         };
	// };

	// /**
	//   * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
	//   */
	// check = () => {
	//       const { ws } = this.props;
	//       if (!ws || ws.readyState == WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
	// };

	async getProfile() {
		this.props.ajaxViewHandler('get', configClass.apiUrl.userProfile)
			.then(() => {
				const data = this.props.responseMsg;
				// console.log('data_profile', data);
				if (data != undefined) {
					this.props.setConfig({
						username: data.username,
						id: data.id,
						// pp: this.config.get_site_info().logo,
						nama: data.pegawai[0].nama,
						akses: data.access,
						printer: data.pegawai[0].serial_printer != undefined ? data.pegawai[0].serial_printer : "pengaturan printer belum dipilih",
						nama_printer: data.pegawai[0].nama_printer != undefined ? data.pegawai[0].nama_printer : "pengaturan printer belum dipilih",
					});

					if (data.pegawai[0].kabupaten_id != undefined) {
						if (Cookies.get(this.config.get_cookie_name() + "_upt") == undefined && data.pegawai[0].cakupan_user == 3) {
							this.setState({
								isModalUpt: true,
								isNotice: data.pegawai[0].is_notice
							}, () => {
								this.getUpt(data.pegawai[0].kabupaten_id);
								if (data.pegawai[0].is_notice) {
									Cookies.set(this.config.get_cookie_name() + "_isNotice", true, { expires: 1 })
								}
							})

						}
					}

					// if (data.pegawai[0].is_notice != undefined) {
					// 	if(Cookies.get(this.config.get_cookie_name() + "_isNotice"))
					// 	this.setState({
					// 		isNotice: data.pegawai[0].is_notice
					// 	}, () => {
					// 		if (data.pegawai[0].is_notice) {
					// 			Cookies.set(this.config.get_cookie_name() + "_isNotice", true, { expires: 1 })
					// 		}
					// 	})
					// }

					if (Cookies.get(this.config.get_cookie_name() + "_upt") != undefined && data.pegawai[0].cakupan_user == 3 && Cookies.get(this.config.get_cookie_name() + "_isNotice")) {
						if (JSON.parse(Cookies.get(this.config.get_cookie_name() + "_isNotice")) == true) {
							this.setState({
								modalNotice: true
							}, () => {
								this.getNotice()
							})
						}
					}

					if (data.waktu_habis.start_at != undefined) {
						this.props.setConfig({
							start_at: data.waktu_habis.start_at,
							timeout_at: data.waktu_habis.timeout_at,
						})

						setTimeout(() => {
							this.startCountDown();
						}, 200);
					}



					// askForPermissioToReceiveNotifications(data[0].tempatTugas);

					// if(!this.props.switchable){
					// 	// this.props.setConfig({
					// 	// 	isSuperadmin: data[0].is_superuser
					// 	// });
					// }
				}
			}).catch((response) => {
				console.log('gagal_getprofile', response);
				message.error({ content: 'gagal request data profile, coba lagi', key: 'toast' });
			});
	}

	async getNotice() {
		message.loading({ content: 'Menampilkan data notice...', duration: 0, key: 'toast' });

		this.props.ajaxViewHandler('get', configClass.apiUrl.notice)
			.then(() => {
				const data = this.props.responseMsg;
				if (this.props.isSuccess) {
					if (data.results.length > 0) {
						this.formRef.current.setFieldsValue(data.results[0]);
						this.setState({
							aksi: 'edit',
							method: 'put',
							idLama: data.results[0].id + '/'
						});
					} else {
						this.setState({
							aksi: 'tambah',
							method: 'post',
							idLama: ""
						});
					}
					message.destroy();
				} else {
					const errTitle = 'error preview data lama';
					this.setState({
						errTitle: errTitle,
						errMsg: this.props.responseMsg
					});
					message.error({ content: errTitle, duration: 2, key: 'toast' });
				}
			}).catch((response) => {
				console.log('gagal_get_preview', response);
				message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			});
	}

	handleSubmitNotice = (values) => {
		var datas = values;
		this.postDataNotice(datas);
	}

	async postDataNotice(datas) {
		this.setState({
			errMsg: ''
		})
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler(this.state.method, configClass.apiUrl.notice + this.state.idLama, datas, false, true)
			.then(() => {
				this.setState({
					loadingButton: false
				});
				if (this.props.isSuccess) {
					message.success({ content: 'berhasil menyimpan data', duration: 2, key: 'toast' });
					Cookies.set(this.config.get_cookie_name() + "_isNotice", false, { expires: 1 })
					this.setState({
						modalNotice: false,
						isNotice: false,
						is_notice: false
					});
				} else {
					const errTitle = 'gagal menyimpan data'
					message.error({ content: errTitle, duration: 2, key: 'toast' });
					this.setState({
						errTitle: errTitle,
						errMsg: this.props.responseMsg
					});
				}
			}).catch((response) => {
				console.log('gagal_post', response);
				this.setState({
					loadingButton: false
				});
				message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
			});
	}

	startCountDown() {
		if (Cookies.get(this.config.get_cookie_name() + "_upt") != undefined) {
			if (this.props.start_at != null) {
				if (moment(this.props.start_at) > moment()) {
					var now = moment();
					this.props.setConfig({
						jam_countdown: "Buka " + moment(this.props.start_at).startOf('minutes').fromNow() + " lagi"
					})

					setTimeout(() => {
						this.startCountDown();
					}, 1000);

				} else {
					var now = moment();
					let jenis = "Buka";
					let countDownMnt = parseInt((moment.duration(now.diff(this.props.timeout_at))).asMinutes());
					let countDownScd = parseInt((moment.duration(now.diff(this.props.timeout_at))).asSeconds() % 60);

					if (parseInt((moment.duration(now.diff(this.props.timeout_at))).asMinutes()) < 0) {
						if (countDownScd < 0) {
							jenis = "Buka";
							countDownMnt = countDownMnt * -1;
							countDownScd = countDownScd * -1;
						} else {
							jenis = "Buka";
							countDownMnt = countDownMnt * -1;
							countDownScd = countDownScd * -1;
						}
					} else {
						if (countDownScd < 0) {
							jenis = "Buka";
							countDownMnt = countDownMnt * -1;
							countDownScd = countDownScd * -1;
						} else {
							jenis = "Tutup";
							countDownMnt = countDownMnt * -1;
							countDownScd = countDownMnt == 0 ? countDownScd : countDownScd * -1;
						}

						//jenis = "Tutup";
					}
					this.props.setConfig({
						// jam_countdown: moment(this.props.timeout_at).startOf('minutes').fromNow(),
						jam_countdown: jenis == "Buka" ? "Tutup dalam = " + countDownMnt + ":" + countDownScd : "Tutup",
						tutup_countdown: parseInt((moment.duration(now.diff(this.props.timeout_at))).asMinutes()),
					})

					//alert((moment.duration(now.diff(this.props.timeout_at))).asMinutes())

					setTimeout(() => {
						this.startCountDown();
					}, 1000);
				}

			}
		}
	}

	async getUpt(kab_id) {
		this.setState({
			loadingUpt: true
		});
		this.props.ajaxViewHandler('get', configClass.apiUrl.upt + "?kabupaten=" + kab_id + "&limit=100")
			.then(() => {
				const data = this.props.responseMsg;
				// console.log('data_profile', data);
				if (data != undefined) {
					this.setState({
						listUpt: data.results
					})
				}
				this.setState({
					loadingUpt: false
				});
			}).catch((response) => {
				console.log('gagal_upt', response);
				message.error({ content: 'gagal request data upt, coba lagi', key: 'toast' });
				this.setState({
					loadingUpt: false
				});
			});
	}

	pilihUpt(item) {
		Cookies.set(this.config.get_cookie_name() + "_upt", item, { expires: 1 })
		this.setState({
			isModalUpt: false
		})
		this.props.setConfig({
			uptDipilih: JSON.stringify(item)
		});
		this.getProfile();
		message.success({ content: 'berhasil memilih upt', key: 'toast' });

		if (this.state.isNotice) {
			this.setState({
				modalNotice: true
			}, () => {
				this.getNotice()
			})
		}
	}

	async handlePilihUpt(upt) {
		this.setState({
			loadingPilihUpt: true,
			errPilihUpt: null,
		});
		this.props.ajaxViewHandler('post', configClass.apiUrl.pegawai, JSON.stringify({ upt: upt.id }), false, true)
			.then(() => {
				const data = this.props.responseMsg;
				if (this.props.isSuccess) {
					this.pilihUpt(upt)
				} else {
					this.setState({
						errPilihUpt: JSON.stringify(data)
					});

				}

				this.setState({
					loadingPilihUpt: false
				})

			}).catch((response) => {
				console.log('gagal_plhupt', response);
				message.error({ content: 'gagal pilih data upt, coba lagi', key: 'toast' });
				this.setState({
					loadingPilihUpt: false
				})
			});
	}

	render() {
		if (!this.props.isLogin) {
			return <Redirect to="/auth" />
		}
		if (this.state.isForbidden) {
			return <Redirect to="/admin/forbidden" />
		}
		return (
			<Layout>
				{this.props.isMobile ? (
					<Drawer
						closable={false}
						onClose={this.handleCloseDrawer}
						//visible={!this.props.collapsed}
						visible={!this.props.collapsed}
						placement="top"
						width={200}

					>
						<Sider />
					</Drawer>
				) : (
					<Sider />
				)}
				<div className="container" style={{ maxWidth: '4000px' }}>
					<Header />
					{this.props.akses == null
						?
						<Content
							className="content"

						>
							<Skeleton />
						</Content>
						:
						<Content
							className="content"

						>
							<Switch>
								<Route path="/admin" component={Home} exact />
								<Route path="/admin/forbidden" component={Forbidden} exact />
								<Route path="/admin/index" component={Home} />

								<LayananRoute isAkses={this.props.akses.master_negara} path="/admin/negara" exact component={DataNegara} />
								<LayananRoute isAkses={this.props.akses.master_negara} path="/admin/negara/form/:id?" exact component={FormNegara} />

								<LayananRoute isAkses={this.props.akses.master_Tipe} path="/admin/tipe" exact component={DataTipe} />
								<LayananRoute isAkses={this.props.akses.master_Tipe} path="/admin/tipe/form/:id?" exact component={FormTipe} />

								<LayananRoute isAkses={this.props.akses.master_merek} path="/admin/merek" exact component={DataMerek} />
								<LayananRoute isAkses={this.props.akses.master_merek} path="/admin/merek/form/:id?" exact component={FormMerek} />

								<LayananRoute isAkses={this.props.akses.master_jenis_kendaraan} path="/admin/jenis-kendaraan" exact component={DataJenisKendaraan} />
								<LayananRoute isAkses={this.props.akses.master_jenis_kendaraan} path="/admin/jenis-kendaraan/form/:id?" exact component={FormJenisKendaraan} />

								<LayananRoute isAkses={this.props.akses.master_bahan_bakar} path="/admin/bahan-bakar" exact component={DataBahanBakar} />
								<LayananRoute isAkses={this.props.akses.master_bahan_bakar} path="/admin/bahan-bakar/form/:id?" exact component={FormBahanBakar} />

								<LayananRoute isAkses={this.props.akses.master_warna} path="/admin/warna" exact component={DataWarna} />
								<LayananRoute isAkses={this.props.akses.master_warna} path="/admin/warna/form/:id?" exact component={FormWarna} />

								<LayananRoute isAkses={this.props.akses.master_pekerjaan} path="/admin/pekerjaan" exact component={DataPekerjaan} />
								<LayananRoute isAkses={this.props.akses.master_pekerjaan} path="/admin/pekerjaan/form/:id?" exact component={FormPekerjaan} />

								<LayananRoute isAkses={this.props.akses.master_kepemilikan} path="/admin/kepemilikan" exact component={DataKepemilikan} />
								<LayananRoute isAkses={this.props.akses.master_kepemilikan} path="/admin/kepemilikan/form/:id?" exact component={FormKepemilikan} />

								<LayananRoute isAkses={this.props.akses.master_layanan} path="/admin/layanan" exact component={DataLayanan} />
								<LayananRoute isAkses={this.props.akses.master_layanan} path="/admin/layanan/form/:id?" exact component={FormLayanan} />

								<LayananRoute isAkses={this.props.akses.master_dealer} path="/admin/dealer" exact component={DataDealer} />
								<LayananRoute isAkses={this.props.akses.master_dealer} path="/admin/dealer/form/:id?" exact component={FormDealer} />

								<LayananRoute isAkses={this.props.akses.master_payment} path="/admin/payment" exact component={DataPayment} />
								<LayananRoute isAkses={this.props.akses.master_payment} path="/admin/payment/form/:id?" exact component={FormPayment} />

								<LayananRoute isAkses={this.props.akses.master_golongan} path="/admin/golongan" exact component={DataGolongan} />
								<LayananRoute isAkses={this.props.akses.master_golongan} path="/admin/golongan/form/:id?" exact component={FormGolongan} />

								<LayananRoute isAkses={this.props.akses.master_warna_plat} path="/admin/warna-plat" exact component={DataWarnaPlat} />
								<LayananRoute isAkses={this.props.akses.master_warna_plat} path="/admin/warna-plat/form/:id?" exact component={FormWarnaPlat} />

								<LayananRoute isAkses={this.props.akses.master_fungsi} path="/admin/fungsi" exact component={DataFungsi} />
								<LayananRoute isAkses={this.props.akses.master_fungsi} path="/admin/fungsi/form/:id?" exact component={FormFungsi} />

								{/* <LayananRoute isAkses={this.props.akses.transaksi_pendaftaran} path="/admin/transaksi/pendaftaran" exact render={(props) => <FormPendaftaran {...props} />} /> */}
								<LayananRoute isAkses={this.props.akses.transaksi_pendaftaran} path="/admin/transaksi/pendaftaran" exact component={FormPendaftaran} />
								{/* <LayananRoute isAkses={this.props.akses.transaksi_penetapan} path="/admin/transaksi/penetapan-biaya" exact render={(props) => <FormPenetapanBiaya {...props} />} /> */}
								<LayananRoute isAkses={this.props.akses.transaksi_penetapan} path="/admin/transaksi/penetapan-biaya" exact component={FormPenetapanBiaya} />
								{/* <LayananRoute isAkses={this.props.akses.iwkbu} path="/admin/transaksi/iwkbu" exact render={(props) => <FormIwkbu {...props} />} /> */}
								<LayananRoute isAkses={this.props.akses.iwkbu} path="/admin/transaksi/iwkbu" exact component={FormIwkbu} />
								<LayananRoute isAkses={this.props.akses.blokir} path="/admin/transaksi/blokir" exact component={FormBlokir} />

								{/* <LayananRoute isAkses={this.props.akses.informasi_informasi_kendaraan} path="/admin/informasi/informasi-kendaraan" exact render={(props) => <FormInformasiKendaraan {...props} />} /> */}
								<LayananRoute isAkses={this.props.akses.informasi_informasi_kendaraan} path="/admin/informasi/informasi-kendaraan" exact component={FormInformasiKendaraan} />
								{/* <LayananRoute isAkses={this.props.akses.informasi_cari_data_kbm} path="/admin/informasi/cari-data-kbm" exact render={(props) => <CariDataKbm {...props} />} /> */}
								<LayananRoute isAkses={this.props.akses.informasi_cari_data_kbm} path="/admin/informasi/cari-data-kbm" exact component={CariDataKbm} />

								<LayananRoute isAkses={this.props.akses.pengaturan_umum} path="/admin/pengaturan/umum" exact component={Umum} />

								<LayananRoute isAkses={this.props.akses.pengaturan_dikon} path="/admin/pengaturan/diskon" exact component={DataDiskon} />
								<LayananRoute isAkses={this.props.akses.pengaturan_dikon} path="/admin/pengaturan/diskon/form/:id?" exact component={FormDiskon} />

								<LayananRoute isAkses={this.props.akses.pengaturan_njkb} path="/admin/pengaturan/njkb" exact component={DataNjkb} />
								<LayananRoute isAkses={this.props.akses.pengaturan_njkb} path="/admin/pengaturan/njkb/form/:id?" exact component={FormNjkb} />
								<LayananRoute isAkses={this.props.akses.pengaturan_njkb} path="/admin/pengaturan/njkb-import" exact component={DataImportNjkb} />

								<LayananRoute isAkses={this.props.akses.pengaturan_swdkllj} path="/admin/pengaturan/swdkllj" exact component={DataSwdkllj} />
								<LayananRoute isAkses={this.props.akses.pengaturan_swdkllj} path="/admin/pengaturan/swdkllj/form/:id?" exact component={FormSwdkllj} />

								<LayananRoute isAkses={this.props.akses.pengaturan_adm_stnk} path="/admin/pengaturan/admstnk" exact component={DataAdmstnk} />
								<LayananRoute isAkses={this.props.akses.pengaturan_adm_stnk} path="/admin/pengaturan/admstnk/form/:id?" exact component={FormAdmstnk} />

								<LayananRoute isAkses={this.props.akses.pengaturan_adm_tnkb} path="/admin/pengaturan/admtnkb" exact component={DataAdmtnkb} />
								<LayananRoute isAkses={this.props.akses.pengaturan_adm_tnkb} path="/admin/pengaturan/admtnkb/form/:id?" exact component={FormAdmtnkb} />

								<LayananRoute isAkses={this.props.akses.pengaturan_denda_pkb} path="/admin/pengaturan/denda-pkb" exact component={DataDendaPkb} />
								<LayananRoute isAkses={this.props.akses.pengaturan_denda_pkb} path="/admin/pengaturan/denda-pkb/form/:id?" exact component={FormDendaPkb} />

								<LayananRoute isAkses={this.props.akses.pengaturan_denda_swdkllj} path="/admin/pengaturan/denda-swdkllj" exact component={DataDendaSwdkllj} />
								<LayananRoute isAkses={this.props.akses.pengaturan_denda_swdkllj} path="/admin/pengaturan/denda-swdkllj/form/:id?" exact component={FormDendaSwdkllj} />

								<LayananRoute isAkses={this.props.akses.pengaturan_notice} path="/admin/pengaturan/notice" exact component={Notice} />

								<LayananRoute isAkses={this.props.akses.transaksi_pembayaran} path="/admin/transaksi/pembayaran" exact component={FormPembayaran} />
								<LayananRoute isAkses={this.props.akses.transaksi_surat_keterangan_fiskal} path="/admin/transaksi/surat-keterangan-fiskal" exact component={SuratKeteranganFiskal} />

								<LayananRoute isAkses={this.props.akses.master_tanda_tangan} path="/admin/tanda_tangan" exact component={DataTandaTangan} />
								<LayananRoute isAkses={this.props.akses.master_tanda_tangan} exact path="/admin/tanda_tangan/form/:id?" component={FormTandaTangan} />

								<LayananRoute isAkses={this.props.akses.pengaturan_tanda_tangan} path="/admin/pengaturan/tanda_tangan_laporan" exact component={DataTandaTanganLaporan} />
								<LayananRoute isAkses={this.props.akses.pengaturan_tanda_tangan} path="/admin/pengaturan/tanda_tangan_laporan/form/:id?" exact component={FormTandaTanganLaporan} />

								<LayananRoute isAkses={this.props.akses.laporan} path="/admin/laporan" exact component={Laporan} />

								<LayananRoute isAkses={this.props.akses.laporan_pnbp} path="/admin/laporan/pnbp" exact component={LaporanPnbp} />
								<LayananRoute isAkses={this.props.akses.laporan_penerimaan_bbnkb_pkb} path="/admin/laporan/bbnkb-pkb" exact component={BbnkbDanPkb} />
								<Route path="/admin/laporan/rekapitulasi-penerimaan" exact component={RekapitulasiPenerimaan} />
								<LayananRoute isAkses={this.props.akses.laporan_notice} path="/admin/laporan/notice" exact component={LapNotice} />
								<LayananRoute isAkses={this.props.akses.laporan_notice_rusak} path="/admin/laporan/notice-rusak" exact component={LapNoticeRusak} />

								<LayananRoute isAkses={this.props.akses.laporan_swdkllj} path="/admin/laporan/swdkllj" exact component={LapSwdkllj} />

								<LayananRoute isAkses={this.props.akses.laporan_kasir} path="/admin/laporan/kasir" exact component={LapKasir} />
								<LayananRoute isAkses={this.props.akses.laporan_perekening} path="/admin/laporan/perekening" exact component={LapPerRekening} />

								<LayananRoute isAkses={this.props.akses.laporan_cetak_stnk} path="/admin/laporan/cetak-stnk" exact component={LapCetakStnk} />

								<LayananRoute isAkses={this.props.akses.laporan_bendahara_penerimaan} path="/admin/laporan/bendahara-penerimaan" exact component={LapBendaharaPenerimaan} />

								<LayananRoute isAkses={this.props.akses.laporan_penetapan_pkb_bbnkb} path="/admin/laporan/harian-pkb" exact component={LapPenetapanPkb} />

								<LayananRoute isAkses={this.props.akses.laporan_penerimaan_pkb_plat} path="/admin/laporan/penerimaan-pkb-warna" exact component={LapPenerimaanPkbWarnaPlat} />

								<LayananRoute isAkses={this.props.akses.laporan_bendaraha_pkb_bbnkb} path="/admin/laporan/bendahara-pkb" exact component={LapRealisasiPenerimaan} />

								<LayananRoute isAkses={this.props.akses.laporan_sts} path="/admin/laporan/stss" exact component={LapStss} />

								<LayananRoute isAkses={this.props.akses.laporan_sts} path="/admin/laporan/stss-harian" exact component={StssHarian} />

								<LayananRoute isAkses={this.props.akses.laporan_pemutihan} path="/admin/laporan/pemutihan" exact component={Pemutihan} />

								<LayananRoute isAkses={this.props.akses.transaksi_cancle_notice} path="/admin/transaksi/cancel-notice" exact component={CancelNotice} />

								<LayananRoute isAkses={this.props.akses.pegawai_jabatan} path="/admin/jabatan" exact component={DataJabatan} />
								<LayananRoute isAkses={this.props.akses.pegawai_jabatan} path="/admin/jabatan/form/:id?" exact component={FormJabatan} />

								<LayananRoute isAkses={this.props.akses.pegawai_upt} path="/admin/upt" exact component={DataUpt} />
								<LayananRoute isAkses={this.props.akses.pegawai_upt} path="/admin/upt/form/:id?" exact component={FormUpt} />

								<LayananRoute isAkses={this.props.akses.pegawai_bidang} path="/admin/bidang" exact component={DataBidang} />
								<LayananRoute isAkses={this.props.akses.pegawai_bidang} path="/admin/bidang/form/:id?" exact component={FormBidang} />

								<LayananRoute isAkses={this.props.akses.pegawai_pegawai} path="/admin/pegawai" exact component={DataPegawai} />
								<LayananRoute isAkses={this.props.akses.pegawai_pegawai} path="/admin/pegawai/form/:id?" exact component={FormPegawai} />

								<LayananRoute isAkses={this.props.akses.pengaturan_printer} path="/admin/pengaturan/printer" exact component={DataPengaturanPrinter} />
								<LayananRoute isAkses={this.props.akses.pengaturan_printer} path="/admin/pengaturan/printer/form/:id?" exact component={FormPengaturanPrinter} />

								<LayananRoute isAkses={this.props.akses.informasi_migrasi} path="/admin/informasi/migrasi" exact component={DataMigrasi} />

								<LayananRoute isAkses={this.props.akses.transaksi_jasa_raharja} path="/admin/transaksi/jasaraharja" exact component={DataJasaraharja} />

								<LayananRoute isAkses={this.props.akses.waktu_operasional} path="/admin/waktu-operasional" exact component={DataWaktuOperasional} />
								<LayananRoute isAkses={this.props.akses.waktu_operasional} path="/admin/waktu-operasional/form/:id?" exact component={FormWaktuOperasional} />

								<Route path="" render={
									() =>
										<Result
											status="404"
											title="404"
											subTitle="Maaf, halaman tidak ditemukan."
										/>
								} />
							</Switch>
						</Content>
					}

					{/*<Footer />*/}
				</div>
				<Modal
					title="Akses Terbatas"
					visible={this.state.isModalForbidden}
					footer={[]}
					closable={false}
					width={"50%"}
				>
					<div
						style={{
							textAlign: "center",
							fontSize: 24
						}}
					>
						Akses Terbatas, Halaman ini akan ditutup dalam
						<br />
						<div style={{ color: "red" }}>
							{this.state.counter}
						</div>
					</div>
				</Modal>
				<Modal
					title="Pilih UPT"
					visible={this.state.isModalUpt}
					footer={[]}
					closable={false}
					width={"80%"}
				>
					{this.state.loadingUpt &&
						<div style={{ textAlign: "center" }}>
							<Spin size="large" /> <br />
							Menampilkan data UPT
						</div>
					}
					{this.state.loadingPilihUpt &&
						<div style={{ textAlign: "center" }}>
							<Spin size="large" /> <br />
							Memproses, mohon menunggu...
						</div>
					}
					{this.state.loadingUpt == false && this.state.listUpt.length == 0 &&
						<div style={{ textAlign: "center" }}>
							<div style={{ fontSize: 24 }}>
								<WarningOutlined />
							</div>
							Belum ada data UPT di kabupaten anda
						</div>
					}
					{this.state.errPilihUpt != null &&
						<div style={{ textAlign: "center" }}>
							<div style={{ fontSize: 24 }}>
								<WarningOutlined />
							</div>
							gagal memilih Upt<br />
							{this.state.errPilihUpt}
						</div>
					}
					<Row
						gutter={[16, 16]}
						justify="center"
					>
						{this.state.listUpt.map((item, idx) => (
							<Col span={6} key={"upt" + idx}>
								<Button
									htmlType="button"
									type="primary"
									style={{
										width: "100%",
										height: "auto"
									}}
									onClick={() => {
										this.handlePilihUpt(item)
									}}
									shape="round"
								>
									{item.nama}
									<br />
									<div style={{ fontSize: 10, fontStyle: "italic", wordWrap: "break-word" }}>
										{item.alamat}
									</div>
								</Button>
							</Col>
						))}
					</Row>
				</Modal>

				<Modal
					title="PENGATURAN NOTICE"
					visible={this.state.modalNotice}
					footer={[]}
					closable={false}
					width={"80%"}
				>
					<Row className="isi">
						<Col lg={24} sm={24} xs={24}>
							<Card
								className="konten"
							>
								<Form
									layout="vertical"
									name="fdata"
									onFinish={this.handleSubmitNotice}
									size="small"
									ref={this.formRef}
								>
									{/*data dasar*/}
									<Row className="isi">
										<Col lg={24} sm={24} xs={24}>

											{(this.state.errMsg != '' &&
												<Alert
													message={this.state.errTitle}
													description={JSON.stringify(this.state.errMsg)}
													type="error"
													showIcon
													style={{
														marginBottom: 10
													}}
												/>
											)}


											<Row justify="space-between">
												<Col lg={24} sm={24} xs={24}>
													<Form.Item
														label="Nomor"
														name="nomor"
														rules={[
															{
																required: true,
																message: 'harus diisi'
															}
														]}
													>
														<Input
															style={{ textTransform: 'none' }}
															placeholder="nomor"
														/>
													</Form.Item>
												</Col>
											</Row>
											<Row justify="space-between">
												<Col lg={24} sm={24} xs={24}>
													<Form.Item
														label="Kode"
														name="kode"
														rules={[
															{
																required: true,
																message: 'harus diisi'
															}
														]}
													>
														<Input
															style={{ textTransform: 'none' }}
															placeholder="kode"
														/>
													</Form.Item>
												</Col>
											</Row>

											<Row justify="end">
												<Col style={{ padding: 5 }}>
													<Button
														key="btn-simpan"
														loading={this.state.loadingButton}
														type="primary"
														htmlType="submit"
														disabled={this.state.errComponent ? true : false}
														size="small"
													>
														{(!this.state.loadingButton && <CheckCircleOutlined />)}
														Simpan
													</Button>
												</Col>
											</Row>


										</Col>
									</Row>
								</Form>
							</Card>
						</Col>
					</Row>
				</Modal>
			</Layout>
		);
	}
}

const mapStateToProps = function (state) {
	return state.configReducers
}

export default connect(mapStateToProps, {
	setConfig,
	ajaxViewHandler,
	ajaxHandler
})(withRouter(AdminLayout));
