import React from 'react';
import { Link, Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import {
    Breadcrumb,
    Card,
    Row,
    Col,
    Avatar,
    Form,
    Input,
    Button,
    Space,
    Select,
    DatePicker,
    message,
    Alert,
    Spin,
    Tabs,
    Switch
} from 'antd';
import {
    DiffOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    ClusterOutlined,
    SettingOutlined
} from '@ant-design/icons';
import moment from 'moment';

import axios from 'axios';
import Config from '../../Config';

import { connect } from 'react-redux';
import {
    initHandler,
    ajaxViewHandler,
    ajaxHandler,
    setConfig,
} from "../../store/actions";
import { Helmet } from "react-helmet";

const FormItem = Form.Item

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 19,
            offset: 5,
        },
        lg: {
            span: 19,
            offset: 5,
        }
    },
};

const configClass = {
    title: 'pengaturan diskon',
    apiUrl: {
        preview: "master/api/discount/",
        fungsi: "master/api/fungsi/"
    },
    pageUrl: {
        data: '/admin/pengaturan/diskon/'
    },
    breadCrumb: [
        (
            <span>
                <SettingOutlined /> Pengaturan
            </span>
        ),
        'diskon',
        'form'
    ]
}

const config = new Config();

class FormDiskon extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            isRedirect: false, 
            errComponent: false,
            errTitle: '',
            errMsg: '',
            loadingButton: false,

            loadingFungsi: null,
            listFungsi: [],

            aksi: 'tambah',
            method: 'post',
            idLama: '',

            progressif: false,
        }
    }

    UNSAFE_componentWillMount() {
        this.props.initHandler();
    }

    componentDidMount() {
        //this.preview();
        this.loadMasterList();
    }

    loadMasterList() {
        const rule = [
            {
                destination: {
                    stateProgressName: "loadingFungsi",
                    endPoint: configClass.apiUrl.fungsi,
                    stateDestination: "listFungsi",
                    next: null,
                },
                nextDestination: null
            },
        ];

        this.setState({
            errComponent: false,
        });

        rule.map((item, index) => {
            if (index == rule.length - 1) {
                this.getMasterList(item, true);
            } else {
                this.getMasterList(item);
            }
        });
    }

    async getMasterList(rule, isFinal = false) {
        var destination = rule.destination;
        var nextDestination = rule.nextDestination;
        this.setState({ [destination.stateProgressName]: true });
        this.props
            .ajaxViewHandler("get", destination.endPoint)
            .then(() => {
                const data = this.props.responseMsg;
                if (this.props.isSuccess) {
                    if (data.count != undefined) {
                        let newState = null;
                        if (this.state[destination.stateDestination].length == 0) {
                            newState = data;
                        } else {
                            if (destination.stateProgressName.includes("_searching")) {
                                newState = data;
                            } else {
                                const oldState = this.state[destination.stateDestination];
                                newState = {
                                    count: data.count,
                                    next: data.next,
                                    previous: data.previous,
                                    results: [...oldState.results, ...data.results],
                                };
                            }
                        }
                        this.setState({
                            [destination.stateDestination]: newState,
                        });
                    } else if (data.results != undefined) {
                        this.setState({
                            [destination.stateDestination]: data.results,
                        });
                    } else {
                        if (Array.isArray(data)) {
                            this.setState({
                                [destination.stateDestination]: data,
                            });
                        } else {
                            this.setState({
                                [destination.stateDestination]: [data],
                            });
                        }
                    }
                    if (isFinal) {
                        if (this.props.match.params.id != undefined) {
                            this.preview(this.props.match.params.id);
                        }
                    }
                } else {
                    this.setState({
                        errComponent: true,
                    });
                }
                this.setState({ [destination.stateProgressName]: false });
            })
            .catch((response) => {
                //console.log('gagal_get_master', response);
                message.error({
                    content: "gagal request data, coba lagi",
                    duration: 2,
                    key: "toast",
                });
                this.setState({
                    errTitle: "gagal request data master",
                    errMsg: response,
                });
                this.setState({ [destination.stateProgressName]: false });
            });
        //next function
        if (nextDestination != null) {
            const next = {
                destination: {
                    stateProgressName: nextDestination.stateProgressName,
                    endPoint: nextDestination.endPoint,
                    stateDestination: nextDestination.stateDestination,
                },
                nextDestination: nextDestination.next,
            };
            this.getMasterList(next);
        }
    }

    async preview(id) {
        message.loading({ content: 'Menampilkan data sebelumnya...', duration: 0, key: 'toast' });

        this.props.ajaxViewHandler('get', configClass.apiUrl.preview + id)
            .then(() => {
                const data = this.props.responseMsg;
                if (this.props.isSuccess) {
                    this.formRef.current.setFieldsValue(data);
                    for (var key in data) {
                        const dateRegex = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
                        const dateTimeRegex = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
                        if (dateRegex.test(data[key])) {//jika format tanggal			    	
                            this.formRef.current.setFieldsValue({
                                [key + '_picker']: moment(data[key]),
                                [key]: moment(data[key]).format('DD-MM-YYYY')
                            });
                        }else{
                            if(key == "progressif"){
                                this.setState({
                                    progressif: data[key]
                                })
                            }
                        }

                    }
                    message.destroy();
                    this.setState({
                        aksi: 'edit',
                        method: 'put',
                        idLama: id + '/'
                    });
                } else {
                    const errTitle = 'error preview data lama';
                    this.setState({
                        errTitle: errTitle,
                        errMsg: this.props.responseMsg
                    });
                    message.error({ content: errTitle, duration: 2, key: 'toast' });
                }
            }).catch((response) => {
                console.log('gagal_get_preview', response);
                message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
            });
    }

    // async preview(id) {
    //     message.loading({ content: 'Menampilkan data sebelumnya...', duration: 0, key: 'toast' });

    //     this.props.ajaxViewHandler('get', configClass.apiUrl.preview + id)
    //         .then(() => {
    //             const data = this.props.responseMsg;
    //             if (this.props.isSuccess) {
    //                 if (data.results.length > 0) {
    //                     this.formRef.current.setFieldsValue(data.results[0]);
    //                     this.setState({
    //                         aksi: 'edit',
    //                         method: 'put',
    //                         idLama: data.results[0].id + '/',
    //                         progressif: data.results[0].progressif
    //                     });
    //                     this.formRef.current.setFieldsValue({
    //                         mulai_dari_picker: moment(data.results[0].mulai_dari),
    //                         mulai_dari: moment(data.results[0].mulai_dari).format("DD-MM-YYYY"),
    //                         sampai_dengan_picker: moment(data.results[0].sampai_dengan),
    //                         sampai_dengan: moment(data.results[0].sampai_dengan).format("DD-MM-YYYY"),
    //                     })

    //                 } else {
    //                     this.setState({
    //                         aksi: 'tambah',
    //                         method: 'post',
    //                         idLama: ""
    //                     });
    //                 }
    //                 message.destroy();
    //             } else {
    //                 const errTitle = 'error preview data lama';
    //                 this.setState({
    //                     errTitle: errTitle,
    //                     errMsg: this.props.responseMsg
    //                 });
    //                 message.error({ content: errTitle, duration: 2, key: 'toast' });
    //             }
    //         }).catch((response) => {
    //             console.log('gagal_get_preview', response);
    //             message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
    //         });
    // }

    handleSubmit = (values) => {
        // var params = new FormData();
        // for (var key in values) {
        //     if (key == 'gudang') {
        //         params.append(key, (values[key]).join());
        //     } else {
        //         params.append(key, (values[key]).toString());
        //     }
        // }
        this.postData(values);
    }

    async postData(datas) {
        message.loading({ content: 'Memproses...', key: 'toast' });

        this.props.ajaxHandler(this.state.method, configClass.apiUrl.preview + this.state.idLama, datas, false, true)
            .then(() => {
                this.setState({
                    loadingButton: false
                });
                if (this.props.isSuccess) {
                    message.success({ content: 'berhasil menyimpan data', duration: 2, key: 'toast' });
                    // this.setState({
                    //     aksi: 'edit',
                    //     method: 'put',
                    //     idLama: this.props.responseMsg.id + '/'
                    // });
                    this.setState({
                        isRedirect: true
                    })
                } else {
                    const errTitle = 'gagal menyimpan data'
                    message.error({ content: errTitle, duration: 2, key: 'toast' });
                    this.setState({
                        errTitle: errTitle,
                        errMsg: this.props.responseMsg
                    });
                }
            }).catch((response) => {
                console.log('gagal_post', response);
                this.setState({
                    loadingButton: false
                });
                message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
            });
    }

    generateSelect(stateName, url, key, value, keyDisplay = "") {
        return this.state["loading" + stateName] ? (
            <img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
        ) : (
            <Select
                // autoFocus
                showSearch
                placeholder={"Cari / Pilih " + stateName}
                optionFilterProp="children"
                onSearch={(val) => {
                    if (stateName == "Tipe") {
                        const rule = {
                            destination: {
                                stateProgressName: "loading" + stateName + "_searching",
                                endPoint: url + "?search=" + val + "&merek=" + this.state.merek_dipilih,
                                stateDestination: "list" + stateName,
                            },
                            nextDestination: null,
                        };
                        this.getMasterList(rule);
                    } else {
                        const rule = {
                            destination: {
                                stateProgressName: "loading" + stateName + "_searching",
                                endPoint: url + "?search=" + val,
                                stateDestination: "list" + stateName,
                            },
                            nextDestination: null,
                        };
                        this.getMasterList(rule);
                    }
                }}
                onPopupScroll={(e) => {
                    e.persist();
                    let target = e.target;
                    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
                        if (
                            this.state["list" + stateName].next != undefined &&
                            this.state["list" + stateName].next != null
                        ) {
                            const rule = {
                                destination: {
                                    stateProgressName: "loading" + stateName + "Paging",
                                    endPoint: this.state["list" + stateName].next,
                                    stateDestination: "list" + stateName,
                                },
                                nextDestination: null,
                            };
                            this.getMasterList(rule);
                        }
                    }
                }}
                allowClear
                style={{
                    width: "100%",
                }}
                onChange={(val) => {

                }}
                size={"small"}
            >
                {this.state["loading" + stateName + "_searching"] == true && (
                    <Select.Option value="">
                        <Spin size="small" />
                    </Select.Option>
                )}
                {this.state["list" + stateName].count != undefined
                    ? this.state["list" + stateName].results.map((item) => (
                        <Select.Option value={item[key]}>
                            {keyDisplay == ""
                                ? item[key] + " - " + item[value]
                                : item[keyDisplay] + " - " + item[value]}
                        </Select.Option>
                    ))
                    : this.state["list" + stateName].map((item) => (
                        <Select.Option value={item[key]}>
                            {keyDisplay == ""
                                ? item[key] + " - " + item[value]
                                : item[keyDisplay] + " - " + item[value]}
                        </Select.Option>
                    ))}
                {this.state["list" + stateName] != undefined &&
                    this.state["list" + stateName].next != undefined && (
                        <Select.Option value="">
                            <Spin size="small" />
                        </Select.Option>
                    )}
            </Select>
        );
    }

    render() {
        // if(this.props.isSuperadmin == false){
        // 	return <Redirect to="/admin/forbidden" />
        // }
        // if(this.props.isRedirect){
        // 	return <Redirect to={configClass.pageUrl.data} />
        // }
        if(this.state.isRedirect){
            return <Redirect to="/admin/pengaturan/diskon" />
        }

        return (
            <div>
                <Helmet>
                    <title>{configClass.title + " | " + config.get_site_info().name}</title>
                </Helmet>
                <Breadcrumb>
                    {configClass.breadCrumb.map(item => (
                        <Breadcrumb.Item>
                            {item}
                        </Breadcrumb.Item>
                    ))}
                </Breadcrumb>
                <div className="card">
                    <Row className="isi">
                        <Col lg={24} sm={24} xs={24}>
                            <Card
                                className="konten"
                            >
                                <Form
                                    layout="vertical"
                                    name="fdata"
                                    onFinish={this.handleSubmit}
                                    size="small"
                                    ref={this.formRef}
                                    initialValues={{
                                        progressif_picker: this.state.progressif,
                                        progressif: this.state.progressif,
                                    }}
                                >
                                    {/*data dasar*/}
                                    <Row className="isi">
                                        <Col lg={24} sm={24} xs={24}>

                                            {(this.state.errMsg != '' &&
                                                <Alert
                                                    message={this.state.errTitle}
                                                    description={JSON.stringify(this.state.errMsg)}
                                                    type="error"
                                                    showIcon
                                                    style={{
                                                        marginBottom: 10
                                                    }}
                                                />
                                            )}



                                            <Row justify="space-between">
                                                <Col lg={24} sm={24} xs={24}>
                                                    <Form.Item
                                                        label="KODE FUNGSI"
                                                        name="fungsi"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'harus dipilih'
                                                            }
                                                        ]}
                                                    >
                                                        {this.generateSelect(
                                                            "Fungsi",
                                                            configClass.apiUrl.fungsi,
                                                            "id",
                                                            "nama"
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={24} sm={24} xs={24}>
                                                    <Form.Item
                                                        label="BBNKB I %"
                                                        name="bbnkb"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'harus diisi'
                                                            }
                                                        ]}
                                                    >
                                                        <Input
                                                            style={{ textTransform: 'none' }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={24} sm={24} xs={24}>
                                                    <Form.Item
                                                        label="BBNKB II %"
                                                        name="bbnkb_ii"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'harus diisi'
                                                            }
                                                        ]}
                                                    >
                                                        <Input
                                                            style={{ textTransform: 'none' }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={24} sm={24} xs={24}>
                                                    <Form.Item
                                                        label="PKB %"
                                                        name="pkb"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'harus diisi'
                                                            }
                                                        ]}
                                                    >
                                                        <Input
                                                            style={{ textTransform: 'none' }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={24} sm={24} xs={24}>
                                                    <Form.Item
                                                        label="SWDKLLJ %"
                                                        name="swdkllj"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'harus diisi'
                                                            }
                                                        ]}
                                                    >
                                                        <Input
                                                            style={{ textTransform: 'none' }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={24} sm={24} xs={24}>
                                                    <Form.Item
                                                        label="PROGRESSIF"
                                                        name="progressif_picker"
                                                    >
                                                        <Switch
                                                            checked={this.state.progressif}
                                                            onChange={(isChecked) => {
                                                                this.setState({
                                                                    progressif: isChecked
                                                                })
                                                                this.formRef.current.setFieldsValue({
                                                                    progressif: isChecked
                                                                })
                                                            }}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="progressif"
                                                        value={this.state.progressif}
                                                        style={{ display: "none" }}
                                                    >
                                                        <Input type="hidden" />
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={24} sm={24} xs={24}>
                                                    <Form.Item
                                                        label="Mulai Dari"
                                                        name="mulai_dari_picker"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'harus diisi'
                                                            }
                                                        ]}
                                                    >
                                                        <DatePicker
                                                            placeholder="dd-mm-yyyy"
                                                            format="DD-MM-YYYY"
                                                            style={{ width: "100%" }}
                                                            onChange={(date, dateString) => {
                                                                this.formRef.current.setFieldsValue({
                                                                    mulai_dari: dateString,
                                                                    // stnk_akhir: futureMonthString
                                                                });
                                                            }}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="mulai_dari"
                                                        style={{
                                                            display: "none",
                                                        }}
                                                    >
                                                        <Input type="hidden" />
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={24} sm={24} xs={24}>
                                                    <Form.Item
                                                        label="Sampai Dengan"
                                                        name="sampai_dengan_picker"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'harus diisi'
                                                            }
                                                        ]}
                                                    >
                                                        <DatePicker
                                                            placeholder="dd-mm-yyyy"
                                                            format="DD-MM-YYYY"
                                                            style={{ width: "100%" }}
                                                            onChange={(date, dateString) => {
                                                                this.formRef.current.setFieldsValue({
                                                                    sampai_dengan: dateString,
                                                                    // stnk_akhir: futureMonthString
                                                                });
                                                            }}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="sampai_dengan"
                                                        style={{
                                                            display: "none",
                                                        }}
                                                    >
                                                        <Input type="hidden" />
                                                    </Form.Item>
                                                </Col>

                                            </Row>

                                            <Row justify="end">
                                                <Col style={{ padding: 5 }}>
                                                    <Button
                                                        key="btn-simpan"
                                                        loading={this.state.loadingButton}
                                                        type="primary"
                                                        htmlType="submit"
                                                        disabled={this.state.errComponent ? true : false}
                                                        size="small"
                                                    >
                                                        {(!this.state.loadingButton && <CheckCircleOutlined />)}
                                                        Simpan
                                                    </Button>
                                                </Col>
                                                <Col style={{ padding: 5 }}>
                                                    <Link to={configClass.pageUrl.data}>
                                                        <Button
                                                            type="danger"
                                                            key="btn-cancel"
                                                            disabled={this.state.loadingButton ? true : false}
                                                            size="small"
                                                        >
                                                            <CloseCircleOutlined /> Cancel
                                                        </Button>
                                                    </Link>
                                                </Col>
                                            </Row>


                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return state.configReducers
}

export default connect(
    mapStateToProps,
    {
        initHandler,
        setConfig,
        ajaxViewHandler,
        ajaxHandler
    }
)(FormDiskon);