import React from 'react';
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import Highlighter from 'react-highlight-words';
import { Link } from "react-router-dom";
import {
    Breadcrumb,
    Card,
    Row,
    Col,
    Input,
    Button,
    Table,
    Modal,
    message,
    Alert,
    Select,
    Spin,
    Form,
    DatePicker,
} from 'antd';
import {
    TeamOutlined,
    SearchOutlined,
    PlusOutlined,
    FormOutlined,
    CloseSquareOutlined,
    ExclamationCircleOutlined,
    DiffOutlined,
    RedoOutlined,
    CopyOutlined,
    PrinterOutlined
} from '@ant-design/icons';

import axios from 'axios';

import { connect } from 'react-redux';
import {
    initHandler,
    ajaxViewHandler,
    ajaxHandler,
    setConfig,
} from "../../store/actions";

import Config from '../../Config';
import { Helmet } from "react-helmet";

import moment from "moment";

import '../../assets/laporan.css';

const configClass = {
    title: 'LAPORAN PNBP',
    apiUrl: {
        data: 'laporan/api/pnbp/',
        warna_plat: "master/api/plat",
        jenis_kendaraan: "master/api/jenis/kendaraan/",
        payment: "master/api/payment/",
        upt: "pegawai/api/upt/",
        kabupaten: "master/api/kabupaten/",
    },
    pageUrl: {
        cetak: '/laporan/pnbp'
    },
    breadCrumb: [
        (
            <span>
                <CopyOutlined /> laporan
            </span>
        ),
        'pnbp'
    ]

}

const config = new Config();

class LaporanPnbp extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: null,
            err: false,

            loadingWarnaPlat: false,
            listWarnaPlat: [],

            loadingJenisKendaraan: false,
            listJenisKendaraan: [],

            loadingPayment: false,
            listPayment: [],

            namauptppd: 'SAMSAT PALANGKARAYA',

            filter: "",

            loadingUpt: false,
            listUpt: [],

            loadingKabupaten: null,
            listKabupaten: [],

            kabupaten_id: "",

            tgl_dari: "",


        }
    }

    UNSAFE_componentWillMount() {
        this.props.initHandler();
    }

    componentDidMount() {
        // this.getData();
    }

    handleSubmit = (values) => {
        var filter = "";
        for (var key in values) {
            if (values[key] != undefined) {
                if (filter == "") {
                    filter = "?" + key + "=" + values[key];
                } else {
                    filter += "&" + key + "=" + values[key];
                }
            }
        }
        this.setState({
            filter: filter
        });
        this.getData(filter)
    };

    async getData(filter) {
        message.loading({ content: 'Menampilkan laporan...', duration: 0, key: 'toast' });
        this.setState({
            loading: true,
            err: false,
            data: null,
        });

        // this.props.ajaxViewHandler('get', configClass.apiUrl.data+"?warna_plat=1&jenis_kendaraan&roda=2&mulai_dari=23-10-2020&sampai_dengan=23-10-2021")
        this.props.ajaxViewHandler('get', configClass.apiUrl.data + filter)
            .then(() => {
                const data = this.props.responseMsg;
                if (this.props.isSuccess) {
                    message.destroy();
                    this.setState({
                        loading: false,
                        data: data,
                        err: false
                    });
                    setTimeout(function () {
                        // window.print();

                    }, 1000);
                } else {
                    const errTitle = 'error get laporan';
                    this.setState({
                        loading: false,
                        data: null,
                        err: true,
                        errMsg: this.props.responseMsg
                    });
                    message.error({ content: errTitle, duration: 2, key: 'toast' });
                }
            }).catch((response) => {
                this.setState({
                    loading: false,
                    data: null,
                    err: true,
                    errMsg: 'unknown response'
                });
                console.log('gagal_get_laporan', response);
                message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
            });
    }

    render() {
        // if(this.props.isSuperadmin == false){
        // 	return <Redirect to="/admin/forbidden" />
        // }
        return (
            <div>
                <Helmet>
                    <title>{configClass.title + " | " + config.get_site_info().name}</title>
                </Helmet>
                <Breadcrumb>
                    {configClass.breadCrumb.map(item => (
                        <Breadcrumb.Item>
                            {item}
                        </Breadcrumb.Item>
                    ))}
                </Breadcrumb>
                <div className="card">
                    <Row className="isi">
                        <Col span={24}>
                            <Card
                                className="konten"
                                style={{ overflow: "auto" }}
                            >
                                <Form
                                    layout="vertical"
                                    name="fdata"
                                    onFinish={this.handleSubmit}
                                    size="small"
                                    ref={this.formRef}
                                >
                                    <Row>
                                        <Col span={4} className="titik-dua">Pilih Tanggal</Col>
                                        <Col span={8}>
                                            <Form.Item name="mulai_dari_picker">
                                                <DatePicker
                                                    placeholder="dd-mm-yyyy"
                                                    format="DD-MM-YYYY"
                                                    style={{ width: "100%" }}
                                                    onChange={(date, dateString) => {
                                                        this.formRef.current.setFieldsValue({
                                                            mulai_dari: dateString,
                                                        });
                                                        this.setState({
                                                            tgl_dari: dateString
                                                        })
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="mulai_dari"
                                                style={{
                                                    display: "none",
                                                }}
                                            >
                                                <Input type="hidden" />
                                            </Form.Item>
                                        </Col>
                                        <Col style={{ paddingLeft: 5 }}>
                                            <Button
                                                key="btn-simpan"
                                                loading={this.state.loading}
                                                type="primary"
                                                htmlType="submit"
                                                disabled={this.state.loading ? true : false}
                                                size="small"
                                            >
                                                {(!this.state.loading && <SearchOutlined />)}
                                                Proses
                                            </Button>
                                        </Col>
                                        {(this.state.data != null &&
                                            <Col style={{ paddingLeft: 5 }}>
                                                <a href={configClass.pageUrl.cetak + this.state.filter} target="_blank">
                                                    <Button
                                                        key="btn-cetak"
                                                        loading={this.state.loading}
                                                        type="default"
                                                        htmlType="button"
                                                        disabled={this.state.loading ? true : false}
                                                        size="small"
                                                    >
                                                        {(!this.state.loading && <PrinterOutlined />)}
                                                        Cetak
                                                    </Button>
                                                </a>
                                            </Col>
                                        )}
                                    </Row>
                                </Form>
                            </Card>
                        </Col>

                        <Col span={24}>
                            <Card
                                className="konten"
                                style={{ overflow: "auto" }}
                            >
                                {(this.state.loading == true &&
                                    <div style={{ color: '#72BBFF', fontSize: 17 }}>
                                        <Spin style={{ marginRight: 10 }} /> memproses laporan....
                                    </div>
                                )}
                                {(this.state.data != null &&
                                    <div className="kertas-laporan">
                                        <div className="judul-laporan teks-tengah">
                                            LAPORAN PENERIMAAN PNBP
                                        </div>
                                        <Row>
                                            <Col span={3}>Samsat</Col>
                                            <Col span={3}>{this.state.data.upt}</Col>
                                        </Row>
                                        <Row>
                                            <Col span={3}>Tanggal</Col>
                                            <Col span={3}>{this.state.tgl_dari}</Col>
                                        </Row>
                                        <table className="laporan-table" border="1" cellPadding="5">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>NOPOL</th>
                                                    <th>SD STNK</th>
                                                    <th>JENIS PENDAFTARAN</th>
                                                    <th>STNK</th>
                                                    <th>TNKB</th>
                                                    <th>jumlah</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={7}>Jenis Kendaraan : RODA 4</td>
                                                </tr>
                                                {this.state.data.roda_empat.data.map((item, idx) =>(
                                                    <tr>
                                                        <td>{idx+1}</td>
                                                        <td>{item.nomor_polisi}</td>
                                                        <td>{item.sd_stnk != undefined ? item.sd_stnk : "undefined sd_stnk"}</td>
                                                        <td>{item.layanan}</td>
                                                        <td>{item.adm_stnk.toLocaleString("id")}</td>
                                                        <td>{item.adm_tnkb.toLocaleString("id")}</td>
                                                        <td>{(item.adm_stnk + item.adm_tnkb).toLocaleString("id")}</td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td colSpan={4}>JUMLAH RODA 4 : {this.state.data.roda_empat.jumlah_kendaraan}</td>
                                                    <td>{this.state.data.roda_empat.jumlah_stnk != undefined ? this.state.data.roda_empat.jumlah_stnk : "undefined jumlah_stnk"}</td>
                                                    <td>{this.state.data.roda_empat.jumlah_tnkb != undefined ? this.state.data.roda_empat.jumlah_tnkb : "undefined jumlah_tnkb"}</td>
                                                    <td>{this.state.data.roda_empat.jumlah_jumlah != undefined ? this.state.data.roda_empat.jumlah_jumlah : "undefined jumlah_jumlah"}</td>
                                                </tr>

                                                <tr>
                                                    <td colSpan={7}>Jenis Kendaraan : RODA 2</td>
                                                </tr>
                                                {this.state.data.roda_dua.data.map((item, idx) =>(
                                                    <tr>
                                                        <td>{idx+1}</td>
                                                        <td>{item.nomor_polisi}</td>
                                                        <td>{item.sd_stnk != undefined ? item.sd_stnk : "undefined sd_stnk"}</td>
                                                        <td>{item.layanan}</td>
                                                        <td>{item.adm_stnk.toLocaleString("id")}</td>
                                                        <td>{item.adm_tnkb.toLocaleString("id")}</td>
                                                        <td>{(item.adm_stnk + item.adm_tnkb).toLocaleString("id")}</td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td colSpan={4}>JUMLAH RODA 2 : {this.state.data.roda_dua.jumlah_kendaraan}</td>
                                                    <td>{this.state.data.roda_dua.jumlah_stnk != undefined ? this.state.data.roda_dua.jumlah_stnk : "undefined jumlah_stnk"}</td>
                                                    <td>{this.state.data.roda_dua.jumlah_tnkb != undefined ? this.state.data.roda_dua.jumlah_tnkb : "undefined jumlah_tnkb"}</td>
                                                    <td>{this.state.data.roda_dua.jumlah_jumlah != undefined ? this.state.data.roda_dua.jumlah_jumlah : "undefined jumlah_jumlah"}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {(this.state.data != null && this.state.data.ttd != undefined &&
                                            <Row justify="space-between" style={{ marginTop: 10 }}>
                                                {(this.state.data.ttd.kiri != undefined &&
                                                    <Col span={10} className="teks-tengah">
                                                        MENGETAHUI<br />
                                                        {this.state.data.ttd.kiri.jabatan}
                                                        <div style={{ marginTop: 50 }}>
                                                            <u>{this.state.data.ttd.kiri.nama}</u><br />
                                                            NIP : {this.state.data.ttd.kiri.nip}
                                                        </div>
                                                    </Col>
                                                )}
                                                {(this.state.data.ttd.kanan != undefined &&
                                                    <Col span={10} className="teks-tengah">
                                                        {this.state.data.upt}, {moment().format('DD-MM-YYYY')}<br />
                                                        {this.state.data.ttd.kanan.jabatan}
                                                        <div style={{ marginTop: 50 }}>
                                                            <u>{this.state.data.ttd.kanan.nama}</u><br />
                                                            NIP : {this.state.data.ttd.kanan.nip}
                                                        </div>
                                                    </Col>
                                                )}
                                            </Row>
                                        )}
                                    </div>
                                )}
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return state.configReducers
}

export default connect(
    mapStateToProps,
    {
        initHandler,
        setConfig,
        ajaxViewHandler,
        ajaxHandler
    }
)(LaporanPnbp);