import React from 'react';
import { Link } from "react-router-dom";
import {
  Layout,
  Menu,
  Avatar,
  Popover,
  Badge,
  List
} from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  BellOutlined,
  RightOutlined,
  LogoutOutlined,
  SettingOutlined
} from '@ant-design/icons';
import Cookies from 'js-cookie';
import moment from 'moment';

import { connect } from 'react-redux';
import {
  setConfig,
  initHandler,
  ajaxViewHandler,
} from "../store/actions";

import Config from '../Config';

// import { logoutFirebase } from '../push-notification';

const { SubMenu } = Menu;
const { Header } = Layout;

class AdminHeader extends React.Component {
  config;

  constructor(props) {
    super(props);
    this.config = new Config();
  }

  // UNSAFE_componentWillMount() {
  //   this.props.initHandler();
  //   // navigator.serviceWorker.removeEventListener("message", (message) => {});
  // }

  componentDidMount() {
    // navigator.serviceWorker.addEventListener("message", (message) => {
    //     console.log('refreshing_new_data');
    // });
  }

  hide = () => {
    this.setState({
      visiblePopMenu: false,
    });
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  toggle = () => {
    this.props.setConfig({
      collapsed: !this.props.collapsed
    });
  }

  handleMenuAtas = (e) => {
    if (e.key == 'Logout') {
      Cookies.remove(this.config.get_cookie_name());
      Cookies.remove(this.config.get_cookie_name() + "_upt");
      //Cookies.remove('aBdi_d3s4uth_temp');
      this.props.setConfig({
        uptDipilih: "",
        isLogin: false,
        username: '',
        nama: '',
        id: '',
        pp: '',
        alamat: '',
        no_hp: '',
        layanan_id: [],
        layanan_str: [],
      });
      // logoutFirebase(this.props.desa);
    }
  }

  render() {
    return (
      <Header className={this.props.collapsed ? 'header collapsed' : 'header'}>
        {React.createElement(this.props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: 'trigger',
          onClick: this.toggle,
        })}
        {this.props.uptDipilih != "" &&
          <div className='upt-aktif'>
            {"UPT: " + JSON.parse(this.props.uptDipilih).nama}
            {this.props.tutup_countdown != null && this.props.tutup_countdown >= -10 && this.props.tutup_countdown <= 0
              ?
              <div className='timer'>
                {"( " + this.props.jam_countdown + " )"}
              </div>
              :
              <span className='timer'>
                {"( " + this.props.jam_countdown + " )"}
              </span>
            }
          </div>
        }
        <div className="rightContainer">
          {(this.props.jmlNotif > 0 &&
            <div className="notifications">
              <Link to="#">
                <Badge
                  title="surat mandiri"
                  count={this.props.jmlNotif}
                  offset={[8, 0]}
                  className="notifIconButton"
                >
                  <BellOutlined className="iconFont" />
                </Badge>
              </Link>
            </div>
          )}
          <Menu key="user" mode="horizontal" onClick={this.handleMenuAtas} className="menu-atas">
            <SubMenu
              title={
                <div>
                  <span className="menu-atas">{this.props.nama != null && this.props.nama != '' ? this.props.nama : this.props.username}</span>
                  <Avatar style={{ marginLeft: 8 }} src={this.props.pp} />
                </div>
              }
            >
              {/*
                    <Menu.Item key="Pengaturan">
                      <Link to="/admin/pengaturan">
                        <SettingOutlined /> Pengaturan
                      </Link>
                    </Menu.Item>
                  */}
              <Menu.Item key="Logout">
                <LogoutOutlined /> Logout
              </Menu.Item>
            </SubMenu>
          </Menu>
        </div>
      </Header>
    )
  }
}

const mapStateToProps = function (state) {
  return state.configReducers
}

export default connect(mapStateToProps,
  {
    initHandler,
    setConfig,
    ajaxViewHandler,
  })(AdminHeader);