import React from 'react';
import { Link, Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Avatar,
	Form, 
	Input,
	Button,
	Space,
	Select,
	DatePicker,
	message,
	Alert,
	Spin,
	Switch
} from 'antd';
import {
  DiffOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
  SettingOutlined
} from '@ant-design/icons';
import moment from 'moment';

import axios from 'axios';
import Config from '../../Config';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";
import {Helmet} from "react-helmet";

import LocationPicker from 'react-location-picker';

const FormItem = Form.Item

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 19,
      offset: 5,
    },
    lg: {
      span: 19,
      offset: 5,
    }
  },
};

const configClass = {
	title: 'form tanda tangan laporan',
	apiUrl: {
		preview: "kendaraan/api/tanda/tangan/",
		pegawai: 'pegawai/api/pegawai/'
	},
	pageUrl: {
		data: '/admin/pengaturan/tanda_tangan_laporan'
	},
	breadCrumb: [
		(
			<span>
				<SettingOutlined /> pengaturan
			</span>
		),
		'tanda tangan laporan',
		'form'
	]

}

const config = new Config();

class FormTandaTanganLaporan extends React.Component {
	formRef = React.createRef();

	constructor(props){
		super(props);

		this.state = {
			errComponent: false,
			errTitle: '',
			errMsg: '',
			loadingButton: false,

			aksi: 'tambah',
			method: 'post',
			idLama: '',

			listPegawai: [],
			loadingPegawai: false,

			listPegawai2: [],
			loadingPegawai2: false,
		}
	}

	UNSAFE_componentWillMount(){
		this.props.initHandler();
	}

	componentDidMount(){
		this.loadMasterList();
		// if(this.props.match.params.id != undefined){
		// 	this.preview(this.props.match.params.id);
		// }
	}

	async preview(id) {
		message.loading({ content: 'Menampilkan data sebelumnya...', duration: 0, key: 'toast' });

		this.props.ajaxViewHandler('get', configClass.apiUrl.preview+id)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				this.formRef.current.setFieldsValue(data);
				for ( var key in data ) {
				    const dateRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4}$/;
				    const dateTimeRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
				    if(dateRegex.test(data[key])){//jika format tanggal			    	
				    	this.formRef.current.setFieldsValue({
				    		[key+'_picker']: moment(data[key], 'DD-MM-YYYY'),
							//[key]: moment(data[key], 'DD-MM-YYYY')
						});
				    }else if(dateTimeRegex.test(data[key])){//jika format tanggal dan jam	    	
				    	this.formRef.current.setFieldsValue({
				    		[key+'_picker']: moment(data[key], 'DD-MM-YYYY HH:mm:ss'),
						});
				    }/*else{
				    	this.formRef.current.setFieldsValue({
				    		[key]: data[key]
				    	});
				    }*/
				    
	        	}
	        	message.destroy();
	        	this.setState({
	        		aksi: 'edit',
	        		method: 'put',
	        		idLama: id+'/'
	        	});
	        }else{
	        	const errTitle = 'error preview data lama';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	handleSubmit = (values) =>{
		var datas = values;
		this.postData(datas);
		// console.log('values', values);
	}

	async postData(datas) {
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler(this.state.method, configClass.apiUrl.preview+this.state.idLama, datas, false, true)
		.then(() =>{
			this.setState({
				loadingButton: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil menyimpan data', duration: 2, key: 'toast' });
			}else{
				const errTitle = 'gagal menyimpan data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_post', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	loadMasterList(){
		const rule = [
			{
				destination: {
					stateProgressName: 'loadingPegawai',
					endPoint: configClass.apiUrl.pegawai,
					stateDestination: 'listPegawai',
				},
				nextDestination: {
					stateProgressName: 'loadingPegawai2',
					endPoint: configClass.apiUrl.pegawai,
					stateDestination: 'listPegawai2',
				}
			}
		];

		this.setState({
			errComponent: false
		});

		rule.map((item, index) => {
			if(index == rule.length-1){
				this.getMasterList(item, true);
			}else{
				this.getMasterList(item);
			}
		});
	}

	async getMasterList(rule, isFinal=false) {
		var destination = rule.destination;
		var nextDestination = rule.nextDestination;
		this.setState({[destination.stateProgressName]: true});
		this.props.ajaxViewHandler('get', destination.endPoint)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				if(data.count != undefined){
					let newState = null;
					if(this.state[destination.stateDestination].length == 0){
						newState = data;
					}else{
						if(destination.stateProgressName.includes('_searching')){
							newState = data
						}else{
							const oldState = this.state[destination.stateDestination];
							newState = {
								count: data.count,
								next: data.next,
								previous: data.previous,
								results: [...oldState.results, ...data.results]
							};
						}
					}
					this.setState({
		            	[destination.stateDestination]: newState
		            });
				}else if(data.results != undefined){
	        		this.setState({
		            	[destination.stateDestination]: data.results
		            });
	        	}else{
	        		if(Array.isArray(data)){
			            this.setState({
			            	[destination.stateDestination]: data
			            })
			        }else{
			        	this.setState({
			            	[destination.stateDestination]: [data]
			            })
			        }
		        }
		        if(isFinal){
		        	if(this.props.match.params.id != undefined){
						this.preview(this.props.match.params.id);
					}
		        }
		    }else{
		    	this.setState({
	        		errComponent: true
	        	});
		    }
		    this.setState({[destination.stateProgressName]: false});
		}).catch((response) => {
			//console.log('gagal_get_master', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({
				errTitle: 'gagal request data master',
				errMsg: response
			});
			this.setState({[destination.stateProgressName]: false});
		});
        //next function
        if(nextDestination != null){
        	const next = {
        		destination: {
	        		stateProgressName: nextDestination.stateProgressName,
	        		endPoint: nextDestination.endPoint,
	        		stateDestination: nextDestination.stateDestination,
	        	},
        		nextDestination: nextDestination.next

        	}
        	this.getMasterList(next);
        }
	}

	

	generateSelect(stateName, url, key, value, keyDisplay = "") {
    return this.state["loading" + stateName] ? (
      <img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
    ) : (
      <Select
        showSearch
        placeholder={"Cari / Pilih " + stateName}
        optionFilterProp="children"
        onSearch={(val) => {
          const rule = {
            destination: {
              stateProgressName: "loading" + stateName + "_searching",
              endPoint: url + "?search=" + val,
              stateDestination: "list" + stateName,
            },
            nextDestination: null,
          };
          this.getMasterList(rule);
        }}
        onPopupScroll={(e) => {
          e.persist();
          let target = e.target;
          if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (
              this.state["list" + stateName].next != undefined &&
              this.state["list" + stateName].next != null
            ) {
              const rule = {
                destination: {
                  stateProgressName: "loading" + stateName + "Paging",
                  endPoint: this.state["list" + stateName].next,
                  stateDestination: "list" + stateName,
                },
                nextDestination: null,
              };
              this.getMasterList(rule);
            }
          }
        }}
        allowClear
        style={{
          width: "100%",
        }}
        onChange={(val) => {
          if (stateName == "Kabupaten") {
            const rule = {
              destination: {
                stateProgressName: "loadingKecamatan",
                endPoint: configClass.apiUrl.kecamatan + "?regencies=" + val,
                stateDestination: "listKecamatan",
              },
              nextDestination: null,
            };
            this.getMasterList(rule);
          }
        }}
        size={"small"}
      >
        {this.state["loading" + stateName + "_searching"] == true && (
          <Select.Option value="">
            <Spin size="small" />
          </Select.Option>
        )}
        {this.state["list" + stateName].count != undefined
          ? this.state["list" + stateName].results.map((item) => (
              <Select.Option value={item[key]}>
                {keyDisplay == ""
                  ? item[key] + " - " + item[value]
                  : item[keyDisplay] + " - " + item[value]}
              </Select.Option>
            ))
          : this.state["list" + stateName].map((item) => (
              <Select.Option value={item[key]}>
                {keyDisplay == ""
                  ? item[key] + " - " + item[value]
                  : item[keyDisplay] + " - " + item[value]}
              </Select.Option>
            ))}
        {this.state["list" + stateName] != undefined &&
          this.state["list" + stateName].next != undefined && (
            <Select.Option value="">
              <Spin size="small" />
            </Select.Option>
          )}
      </Select>
    );
  }

	render() {
		// if(this.props.isSuperadmin == false){
		// 	return <Redirect to="/admin/forbidden" />
		// }
		if(this.props.isRedirect){
			return <Redirect to={configClass.pageUrl.data} />
		}

		return(
			<div>
				<Helmet>
					<title>{configClass.title+" | "+config.get_site_info().name}</title>
				</Helmet>
				<Breadcrumb>
					{configClass.breadCrumb.map(item => (
						<Breadcrumb.Item>
							{item}
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
				<div className="card">
					<Form 
						layout="vertical"
						name="fdata"
					    onFinish={this.handleSubmit}
					    size="small"
					    ref={this.formRef}
					>
						{/*data dasar*/}
						<Row className="isi">
					       	<Col lg={24} sm={24} xs={24}>
					        	<Card
					        		className="konten"
						            title={
						            	<span className="konten-judul">
						            		{configClass.title}
						            		{(this.state.loadingButton &&
								        		<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
								        	)}
						            	</span>
						            }
						        >
						        		{(this.state.errMsg!='' &&
								        	<Alert
										      message={this.state.errTitle}
										      description={JSON.stringify(this.state.errMsg)}
										      type="error"
										      showIcon
										      style={{
										      	marginBottom: 10
										      }}
										    />
										)}
						        
								        
										<Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="JENIS LAPORAN"
										            name="laporan"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										        	<Select
												        autoFocus
												        showSearch
												        placeholder={"Pilih"}
												        optionFilterProp="children"
												        allowClear
												        style={{
												          width: "100%",
												        }}
												        size={"small"}
												      >
										            <Select.Option value={1}>Kasir</Select.Option>
										            <Select.Option value={2}>Perekening</Select.Option>
										            <Select.Option value={3}>Cetak Stnk</Select.Option>
										            <Select.Option value={4}>Bendahara Penerimaan</Select.Option>
										            <Select.Option value={5}>Bendahara PKB & BBNKB</Select.Option>
										            <Select.Option value={6}>Penetapan PKB & BBNKB</Select.Option>
										            <Select.Option value={7}>Penerimaan PKB & BBNKB</Select.Option>
										            <Select.Option value={8}>Notice</Select.Option>
										            <Select.Option value={9}>Notice Rusak</Select.Option>
										          </Select>
										        </Form.Item>
										    </Col>
										</Row>
										<Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="TTD KIRI"
										            name="pegawai_ttd_kiri"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										        	{this.generateSelect(
                                "Pegawai",
                                configClass.apiUrl.pegawai,
                                "id",
                                "nama"
                              )}
										        </Form.Item>
										    </Col>
										</Row>
										<Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="TTD KANAN"
										            name="pegawai_ttd_kanan"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										        	{this.generateSelect(
                                "Pegawai2",
                                configClass.apiUrl.pegawai,
                                "id",
                                "nama"
                              )}
										        </Form.Item>
										    </Col>
										</Row>
										

										<Row justify="end">
									        <Col style={{padding: 5}}>
									        	<Button 
													key="btn-simpan"
													loading={this.state.loadingButton}
													type="primary" 
													htmlType="submit"
													disabled={this.state.errComponent?true:false}
													size="small"
												>
													{(!this.state.loadingButton && <CheckCircleOutlined />)}
													Simpan
												</Button>
											</Col>
											<Col style={{padding: 5}}>
												<Link to={configClass.pageUrl.data}>
													<Button 
														type="danger"
														key="btn-cancel"
														disabled={this.state.loadingButton?true:false}
													    size="small"
													>
														<CloseCircleOutlined /> Cancel
													</Button>
												</Link>
										    </Col>
										</Row>

						        </Card>
					        </Col>
					    </Row>
					</Form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler
		}
	)(FormTandaTanganLaporan);