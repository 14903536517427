import React from 'react';
import { Link, Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import {
    Breadcrumb,
    Card,
    Row,
    Col,
    Avatar,
    Form,
    Input,
    Button,
    Space,
    Select,
    DatePicker,
    message,
    Alert,
    Spin,
    TimePicker,
    Switch
} from 'antd';
import {
    DiffOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    UserOutlined
} from '@ant-design/icons';
import moment from 'moment';

import axios from 'axios';
import Config from '../../Config';

import { connect } from 'react-redux';
import {
    initHandler,
    ajaxViewHandler,
    ajaxHandler,
    setConfig,
} from "../../store/actions";
import { Helmet } from "react-helmet";

import LocationPicker from 'react-location-picker';

const FormItem = Form.Item

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 19,
            offset: 5,
        },
        lg: {
            span: 19,
            offset: 5,
        }
    },
};

const configClass = {
    title: 'form waktu operasional',
    apiUrl: {
        preview: "pegawai/api/waktu/operasional/",
    },
    pageUrl: {
        data: '/admin/waktu-operasional/'
    },
    breadCrumb: [
        (
            <span>
                <UserOutlined /> pegawai
            </span>
        ),
        'waktu operasional',
        'form'
    ]

}

const config = new Config();

class FormWaktuOperasional extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            errComponent: false,
            errTitle: '',
            errMsg: '',
            loadingButton: false,

            aksi: 'tambah',
            method: 'post',
            idLama: '',

            is_baypass: false,
        }
    }

    UNSAFE_componentWillMount() {
        this.props.initHandler();
    }

    componentDidMount() {
        if (this.props.match.params.id != undefined) {
            this.preview(this.props.match.params.id);
        }
    }

    async preview(id) {
        message.loading({ content: 'Menampilkan data sebelumnya...', duration: 0, key: 'toast' });

        this.props.ajaxViewHandler('get', configClass.apiUrl.preview + id)
            .then(() => {
                const data = this.props.responseMsg;
                if (this.props.isSuccess) {
                    this.formRef.current.setFieldsValue(data);
                    for (var key in data) {
                        const dateRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4}$/;
                        const dateTimeRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
                        const timeRegex = /^[0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
                        if (dateRegex.test(data[key])) {//jika format tanggal			    	
                            this.formRef.current.setFieldsValue({
                                [key + '_picker']: moment(data[key], 'DD-MM-YYYY'),
                                //[key]: moment(data[key], 'DD-MM-YYYY')
                            });
                        } else if (dateTimeRegex.test(data[key])) {//jika format tanggal dan jam	    	
                            this.formRef.current.setFieldsValue({
                                [key + '_picker']: moment(data[key], 'DD-MM-YYYY HH:mm:ss'),
                            });
                        } else if (timeRegex.test(data[key])) {//jika format jam	    	
                            this.formRef.current.setFieldsValue({
                                [key + '_picker']: moment(data[key], 'HH:mm'),
                                //[key]: moment(data[key]).format('HH:mm'),
                            });
                        } else if (key == "is_bypass") {
                            this.setState({
                                is_baypass: data[key]
                            });
                        }/*
				    	this.formRef.current.setFieldsValue({
				    		[key]: data[key]
				    	});
				    }*/

                    }
                    message.destroy();
                    this.setState({
                        aksi: 'edit',
                        method: 'put',
                        idLama: id + '/'
                    });
                } else {
                    const errTitle = 'error preview data lama';
                    this.setState({
                        errTitle: errTitle,
                        errMsg: this.props.responseMsg
                    });
                    message.error({ content: errTitle, duration: 2, key: 'toast' });
                }
            }).catch((response) => {
                console.log('gagal_get_preview', response);
                message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
            });
    }

    handleSubmit = (values) => {
        var datas = values;
        this.postData(datas);
    }

    async postData(datas) {
        message.loading({ content: 'Memproses...', key: 'toast' });

        this.props.ajaxHandler(this.state.method, configClass.apiUrl.preview + this.state.idLama, datas, false, true)
            .then(() => {
                this.setState({
                    loadingButton: false
                });
                if (this.props.isSuccess) {
                    message.success({ content: 'berhasil menyimpan data', duration: 2, key: 'toast' });
                } else {
                    const errTitle = 'gagal menyimpan data'
                    message.error({ content: errTitle, duration: 2, key: 'toast' });
                    this.setState({
                        errTitle: errTitle,
                        errMsg: this.props.responseMsg
                    });
                }
            }).catch((response) => {
                console.log('gagal_post', response);
                this.setState({
                    loadingButton: false
                });
                message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
            });
    }

    generateSelectHari() {
        return (
            <Select
                // autoFocus
                showSearch
                placeholder={"Pilih Hari"}
                optionFilterProp="children"
                allowClear
                style={{
                    width: "100%",
                }}
                onChange={(val) => {

                }}
                size={"small"}
            >
                <Select.Option value={0}>Senin</Select.Option>
                <Select.Option value={1}>Selasa</Select.Option>
                <Select.Option value={2}>Rabu</Select.Option>
                <Select.Option value={3}>Kamis</Select.Option>
                <Select.Option value={4}>Jumat</Select.Option>
                <Select.Option value={5}>Sabtu</Select.Option>
                <Select.Option value={6}>Minggu</Select.Option>
            </Select>
        );
    }

    render() {
        // if(this.props.isSuperadmin == false){
        // 	return <Redirect to="/admin/forbidden" />
        // }
        if (this.props.isRedirect) {
            return <Redirect to={configClass.pageUrl.data} />
        }

        return (
            <div>
                <Helmet>
                    <title>{configClass.title + " | " + config.get_site_info().name}</title>
                </Helmet>
                <Breadcrumb>
                    {configClass.breadCrumb.map(item => (
                        <Breadcrumb.Item>
                            {item}
                        </Breadcrumb.Item>
                    ))}
                </Breadcrumb>
                <div className="card">
                    <Form
                        layout="vertical"
                        name="fdata"
                        onFinish={this.handleSubmit}
                        size="small"
                        ref={this.formRef}
                    >
                        {/*data dasar*/}
                        <Row className="isi">

                            <Col lg={24} sm={24} xs={24}>
                                <Card
                                    className="konten"
                                    title={
                                        <span className="konten-judul">
                                            {configClass.title}
                                            {(this.state.loadingButton &&
                                                <img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
                                            )}
                                        </span>
                                    }
                                >
                                    {(this.state.errMsg != '' &&
                                        <Alert
                                            message={this.state.errTitle}
                                            description={JSON.stringify(this.state.errMsg)}
                                            type="error"
                                            showIcon
                                            style={{
                                                marginBottom: 10
                                            }}
                                        />
                                    )}
                                    <Row justify="space-between">
                                        <Col lg={24} sm={24} xs={24}>
                                            <Form.Item
                                                label="HARI"
                                                name="hari"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'harus dipilih'
                                                    }
                                                ]}
                                            >
                                                {this.generateSelectHari()}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify="left">
                                        <Col lg={4} sm={4} xs={24}>
                                            <Form.Item
                                                label="MULAI DARI"
                                                name="mulai_dari_picker"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'harus dipilih'
                                                    }
                                                ]}
                                            >
                                                <TimePicker
                                                    onChange={(dateMoment, dateString) => {
                                                        this.formRef.current.setFieldsValue({
                                                            mulai_dari: dateString
                                                        })
                                                    }}
                                                    format={"HH:mm"}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                style={{ display: "none" }}
                                                name="mulai_dari"
                                            >
                                                <Input type="hidden" />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={4} sm={4} xs={24}>
                                            <Form.Item
                                                label="SAMPAI DENGAN"
                                                name="sampai_dengan_picker"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'harus dipilih'
                                                    }
                                                ]}
                                            >
                                                <TimePicker
                                                    onChange={(dateMoment, dateString) => {
                                                        this.formRef.current.setFieldsValue({
                                                            sampai_dengan: dateString
                                                        })
                                                    }}
                                                    format={"HH:mm"}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                style={{ display: "none" }}
                                                name="sampai_dengan"
                                            >
                                                <Input type="hidden" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify="end">
                                        <Col style={{ padding: 5 }}>
                                            <Button
                                                key="btn-simpan"
                                                loading={this.state.loadingButton}
                                                type="primary"
                                                htmlType="submit"
                                                disabled={this.state.errComponent ? true : false}
                                                size="small"
                                            >
                                                {(!this.state.loadingButton && <CheckCircleOutlined />)}
                                                Simpan
                                            </Button>
                                        </Col>
                                        <Col style={{ padding: 5 }}>
                                            <Link to={configClass.pageUrl.data}>
                                                <Button
                                                    type="danger"
                                                    key="btn-cancel"
                                                    disabled={this.state.loadingButton ? true : false}
                                                    size="small"
                                                >
                                                    <CloseCircleOutlined /> Cancel
                                                </Button>
                                            </Link>
                                        </Col>
                                    </Row>

                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return state.configReducers
}

export default connect(
    mapStateToProps,
    {
        initHandler,
        setConfig,
        ajaxViewHandler,
        ajaxHandler
    }
)(FormWaktuOperasional);