import React from "react";
import { Link, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Breadcrumb,
  Card,
  Row,
  Col,
  Avatar,
  Form,
  Input,
  Button,
  Space,
  Select,
  DatePicker,
  message,
  Alert,
  Spin,
  Modal,
  Skeleton,
  Table,
  Tag,
  Divider,
} from "antd";
import {
  DiffOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DollarOutlined,
  BarcodeOutlined,
  UserAddOutlined,
  PrinterOutlined,
  UserOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  CarOutlined,
  UserSwitchOutlined,
  EyeOutlined
} from "@ant-design/icons";
import moment from "moment";
import NumberFormat from "react-number-format";

import axios from "axios";
import Config from "../../Config";

import { connect } from "react-redux";
import {
  initHandler,
  ajaxViewHandler,
  ajaxHandler,
  setConfig,
} from "../../store/actions";
import { Helmet } from "react-helmet";

const FormItem = Form.Item;

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 19,
      offset: 5,
    },
    lg: {
      span: 19,
      offset: 5,
    },
  },
};

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const configClass = {
  title: "form pendaftaran",
  apiUrl: {
    kabupaten: "master/api/kabupaten/",
    kecamatan: "master/api/kecamatan/",
    desa: "master/api/desa/",
    kepemilikan: "master/api/kepemilikan/",
    fungsi: "master/api/fungsi/",
    jenis_kendaraan: "master/api/jenis/kendaraan/",
    merek: "master/api/merek/",
    tipe: "master/api/tipe/",
    bahan_bakar: "master/api/bahan/bakar/",
    warna_plat: "master/api/plat",
    pemilik: "pemilik/api/wajib/pajak/",
    kendaraan: "kendaraan/api/kendaraan/",
    jenis_layanan: "master/api/layanan/",
    history: "kendaraan/api/history/",
    golongan: "master/api/golongan/",
  },
  pageUrl: {
    cetak_no: "/addon/cetak_antrian/",
    redirect_url: "/admin/transaksi/penetapan-biaya/"
  },
  breadCrumb: [
    <span>
      <CarOutlined /> transaksi
    </span>,
    "pendaftaran",
  ],
};

const config = new Config();

class FormPendaftaran extends React.Component {
  formRef = React.createRef();
  formRefScroll = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      errComponent: false,
      errTitle: "",
      errMsg: "",

      isSukses: false,
      redirect_nopol: "",

      loadingButton: false,

      isModalRiwayat: false,

      loadingKabupaten: null,
      listKabupaten: [],

      kabupatenDipilih: null,

      loadingKecamatan: null,
      listKecamatan: [],

      loadingDesa: null,
      listDesa: [],

      loadingKepemilikan: null,
      listKepemilikan: [],

      loadingFungsi: null,
      listFungsi: [],

      loadingJenisKendaraan: false,
      listJenisKendaraan: [],

      loadingMerek: false,
      listMerek: [],

      loadingTipe: false,
      listTipe: [],

      loadingGolongan: false,
      listGolongan: [],

      loadingBahanBakar: false,
      listBahanBakar: [],

      loadingWarnaPlat: false,
      listWarnaPlat: [],

      loadingJenisLayanan: false,
      listJenisLayanan: [],
      listJenisLayananRequirement: [],

      cari_no_pol: undefined,

      isOpenForm: false,

      loadingDataKendaraan: false,
      dataKendaraan: null,

      pemilik_lama: null,

      merek_dipilih: "",
      jenis_kendaraan_dipilih: "",

      jenis: "Kendaraan Lama",

      aksi: "tambah",
      method: "post",
      idLama: "",

      modalBaru: false,

      field_requirements: {},

      kabupaten_dipilih: "",
      kecamatan_dipilih: "",

      modalKonfirmasi: false,

      modalBlock: false,

      errMsgRiwayat: '',
      searchText: "",
      loadingDataRiwayat: false,
      paginationRiwayat: { pageSize: 25 },
      dataSourceRiwayat: [],
      filterDataRiwayat: '',
      columnsRiwayat: [
        {
          title: 'NIK',
          width: 150,
          dataIndex: 'pemilik_nik',
          key: 'pemilik_nik',
        },
        {
          title: 'NAMA WP',
          width: 150,
          dataIndex: 'nama_wp',
          key: 'nama_wp',
        },
        {
          title: 'ALAMAT WP',
          width: 150,
          dataIndex: 'alamat_wp',
          key: 'alamat_wp'
        },
        {
          title: 'AKSI',
          width: 100,
          dataIndex: 'id',
          key: 'aksi',
          fixed: 'right',
          render: (value, row, index) => {
            return (
              <Row key={"space-aksi-" + index} justify="center">	<Col style={{ padding: 5 }}>
                <Button title="detail" className="btn-log" size="small" onClick={() => this.showDetail("DETAIL RIWAYAT PEMILIK", row, true)} shape="circle">
                  <EyeOutlined />
                </Button>
              </Col>
              </Row>
            )
          },
        }

      ],
    };
  }

  UNSAFE_componentWillMount() {
    this.props.initHandler();
  }

  componentDidMount() {
    this.loadMasterList();
    // this.getDataRiwayat();
  }

  setGambar = (e) => {
    this.setState({
      gambar: e.target.files[0],
    });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  handleTableChange = async (pagination, filters, sorter) => {
    const newPagination = { ...this.state.paginationRiwayat };
    newPagination.pageSize = pagination.pageSize;
    this.setState({
      loadingData: true,
      paginationRiwayat: newPagination
    });
    if (newPagination.current == 1) {
      this.getDataRiwayat(newPagination.pageSize);
    } else {
      this.getDataRiwayat(newPagination.pageSize, (newPagination.current - 1) * newPagination.pageSize);
    }


  }

  async getDataRiwayat(
    limit = this.state.paginationRiwayat.pageSize,
    offset = 0,
    search = this.state.searchText
  ) {
    this.props
      .ajaxViewHandler(
        "get",
        configClass.apiUrl.history +
        "?kendaraan=" + this.state.dataKendaraan.id +
        "&limit=" +
        limit +
        "&offset=" +
        offset +
        "&search=" +
        search
      )
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          const pagination = { ...this.state.paginationRiwayat };
          pagination.total = data.count;
          this.setState({
            dataSourceRiwayat: data.results,
            paginationRiwayat: pagination,
          });
        } else {
          const errTitle = "error get data";
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
          message.error({ content: errTitle, duration: 2, key: "toast" });
        }
        this.setState({ loadingData: false });
      })
      .catch((response) => {
        console.log("gagal_get_preview", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({ loadingData: false });
      });
  }

  searchKendaraan = (no_polisi) => {
    this.setState({
      errComponent: false,
      errTitle: "",
    })
    this.previewData(no_polisi);
  };

  cekPreview(stateName, compare_id, compare_nama, compareKeyDisplay = "") {
    // var chat_dipilih = this.props.chat_dipilih;
    // if(chat_dipilih[compare_id] != null && chat_dipilih[compare_nama] != null && this.state[stateName] != undefined && this.state[stateName].results != undefined){
    var tempList = this.state[stateName];
    var temp = this.state[stateName].results;
    var res = false;
    // console.log('tempList', tempList);
    // console.log('temp', temp);
    // console.log('compare_id', compare_id);
    // console.log('data_kendaraan', this.state.dataKendaraan);
    // console.log('compare_id_val', this.state.dataKendaraan[compare_id]);

    temp.map(item => {
      // console.log(item.id, chat_dipilih.company);
      if (item.id == this.state.dataKendaraan[compare_id]) {
        // console.log(stateName);
        // console.log('ada');
        res = true;
      }
    });
    if (res == false) {
      // console.log(stateName)
      // console.log('sini')
      if (compareKeyDisplay == "") {
        // console.log('sini1')
        temp = [...temp, {
          id: this.state.dataKendaraan[compare_id],
          nama: this.state.dataKendaraan[compare_nama]
        }];
      } else {
        // console.log('sini2', compare_id + "#" + compare_nama + "#" + compareKeyDisplay)
        // console.log('temp_bf', temp)
        temp = [...temp, {
          id: this.state.dataKendaraan[compare_id],
          nama: this.state.dataKendaraan[compare_nama],
          kode: this.state.dataKendaraan[compareKeyDisplay],
        }];
        // console.log('temp_af', temp)
      }
      // console.log('temp_list', tempList.results)
      // console.log('temp_list2', temp)
      // console.log('temp_list3', temp)
      // console.log('temp', temp);
      this.setState({
        [stateName]: {
          count: tempList.count,
          previous: tempList.previous,
          next: tempList.next,
          results: temp
        }
      }, () => {
        // console.log('temp_listf', this.state[stateName])
        // console.log([compare_nama], this.state.dataKendaraan[compare_id])
        if (stateName == "listTipessssssssssss") {
          this.formRef.current.setFieldsValue({
            [compare_id]: {
              // label: this.state.dataKendaraan[compare_id]+" - "+this.state.dataKendaraan[compare_nama],
              value: this.state.dataKendaraan[compare_id],
            },
          })
          this.formRef.current.setFieldsValue({
            koding: this.state.dataKendaraan.koding != undefined && this.state.dataKendaraan.koding != null && this.state.dataKendaraan.koding != "" ? this.state.dataKendaraan.koding : this.state.dataKendaraan.tipe_kode.replace(/ /g, ''),
          })
        } else {
          this.formRef.current.setFieldsValue({
            [compare_id]: this.state.dataKendaraan[compare_id],
          })
        }
      });
      // console.log('temp2', this.state.[stateName]);
    } else {
      // console.log(stateName);
      // console.log('sana')
      if (stateName == "listTipesssssssssssssss") {
        this.formRef.current.setFieldsValue({
          [compare_id]: {
            // label: this.state.dataKendaraan[compare_id]+" - "+this.state.dataKendaraan[compare_nama],
            value: this.state.dataKendaraan[compare_id],
          },
        })
        this.formRef.current.setFieldsValue({
          koding: this.state.dataKendaraan.koding != undefined && this.state.dataKendaraan.koding != null && this.state.dataKendaraan.koding != "" ? this.state.dataKendaraan.koding : this.state.dataKendaraan.tipe_kode.replace(/ /g, ''),
        })
      } else {
        this.formRef.current.setFieldsValue({
          [compare_id]: this.state.dataKendaraan[compare_id],
        })
      }
    }
    // }else{
    //   if(chat_dipilih[compare_id]==null){
    //     this.formRefAgen.current.setFieldsValue({
    //       [compare_id]: undefined,
    //     });
    //   }
    // }
  }

  async previewData(no_polisi) {
    message.loading({
      content: "Proses pencarian...",
      duration: 0,
      key: "toast",
    });

    this.props
      .ajaxViewHandler(
        "get",
        configClass.apiUrl.kendaraan + "?nomor_polisi=KH" + no_polisi
      )
      .then(() => {
        const datas = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (datas.results.length == 0) {
            this.setState({
              isOpenForm: false,
            });
            message.error({ content: "nomor kendaraan tidak ditemukan", duration: 2, key: "toast" });
          } else {
            this.setState({
              isOpenForm: true,
            }, () => {
              this.formRefScroll.current.scrollTo(0, 0);
            });
            const data = datas.results[0];
            //this.formRef.current.setFieldsValue(data);
            this.setState({
              pemilik_lama: data.pemilik,
              dataKendaraan: data,
              merek_dipilih: data.merek,
              jenis_kendaraan_dipilih: data.jenis_kendaraan,
              kabupaten_dipilih: data.kabupaten,
              kecamatan_dipilih: data.kecamatan,
              modalKonfirmasi: data.tunggakan.is_popup != undefined && data.tunggakan.is_popup ? true : false
            }, () => {
              const rule = {
                destination: {
                  stateProgressName: "loadingTipe",
                  endPoint: configClass.apiUrl.tipe + "?merek=" + this.state.merek_dipilih + "&jenis=" + this.state.jenis_kendaraan_dipilih,
                  stateDestination: "listTipe",
                },
                nextDestination: null,
              };
              this.getMasterList(rule);

              const ruleKec = {
                destination: {
                  stateProgressName: "loadingKecamatan",
                  endPoint: configClass.apiUrl.kecamatan + "?regencies=" + this.state.kabupaten_dipilih,
                  stateDestination: "listKecamatan",
                },
                nextDestination: null,
              };
              this.getMasterList(ruleKec);

              const ruleDesa = {
                destination: {
                  stateProgressName: "loadingDesa",
                  endPoint: configClass.apiUrl.desa + "?district=" + this.state.kecamatan_dipilih,
                  stateDestination: "listDesa",
                },
                nextDestination: null,
              };
              this.getMasterList(ruleKec);
            });
            if (data.layanan_list != undefined) {
              data.layanan_list.map((item, idx) => {
                if (item.field_requirements != undefined && Object.keys(item.field_requirements).length > 0) {
                  let tempReq = this.state.field_requirements;
                  tempReq = { ...item.field_requirements };
                  this.setState({
                    field_requirements: tempReq
                  })
                }
              });
            }
            for (var key in data) {
              const dateRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4}$/;
              const dateTimeRegex =
                /^[0-9]{2}\-[0-9]{2}\-[0-9]{4} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;

              const dateRegex2 = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
              const dateTimeRegex2 =
                /^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
              if (dateRegex.test(data[key])) {
                //jika format tanggal
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key]),//.format("DD-MM-YYYY"),
                  [key]: data[key]
                  //[key]: moment(data[key], 'DD-MM-YYYY')
                });
              } else if (dateTimeRegex.test(data[key])) {
                //jika format tanggal dan jam
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key]),//.format("DD-MM-YYYY HH:mm:ss"),
                  [key]: data[key]
                });
              } else if (dateRegex2.test(data[key])) {
                //jika format tanggal
                // console.log(key, data[key]);
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key]),
                  [key]: moment(data[key]).format("DD-MM-YYYY")
                  //[key]: moment(data[key], 'DD-MM-YYYY')
                });
              } else if (dateTimeRegex2.test(data[key])) {
                //jika format tanggal dan jam
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key]),
                  [key]: moment(data[key]).format("DD-MM-YYYY")
                });
              } else {
                if (key == "pemilik_nama") {
                  this.formRef.current.setFieldsValue({
                    nama: data[key],
                  });
                } else if (key == "pemilik_alamat") {
                  this.formRef.current.setFieldsValue({
                    alamat: data[key],
                  });
                } else if (key == "pemilik_no_hp") {
                  this.formRef.current.setFieldsValue({
                    no_hp: data[key],
                  });
                } else if (key == "pemilik_nik") {
                  this.formRef.current.setFieldsValue({
                    nik: data[key],
                  });
                } else if (key == "nomor_polisi_tengah") {
                  this.formRef.current.setFieldsValue({
                    no_polisi_tengah: data[key],
                  });
                } else if (key == "nomor_polisi_akhir") {
                  this.formRef.current.setFieldsValue({
                    no_polisi_belakang: data[key],
                  });
                } else if (key == "tanggal_pembayaran" && data[key] != null) {
                  this.formRef.current.setFieldsValue({
                    [key + "_picker"]: moment(data[key]),// "DD-MM-YYYY"),
                    [key]: moment(data[key]).format("DD-MM-YYYY"),
                  });
                } else if (key == "sd_swdkllj" && data[key] != null) {
                  this.formRef.current.setFieldsValue({
                    [key + "_picker"]: moment(data[key]),// "DD-MM-YYYY"),
                    [key]: moment(data[key]).format("DD-MM-YYYY"),
                  });
                } else if (key == "sd_notice" && data[key] != null) {
                  this.formRef.current.setFieldsValue({
                    [key + "_picker"]: moment(data[key]),// "DD-MM-YYYY"),
                    [key]: moment(data[key]).format("DD-MM-YYYY"),
                  });
                }
                else if (key == "stnk_akhir" && data[key] != null) {
                  this.formRef.current.setFieldsValue({
                    [key + "_picker"]: moment(data[key]),// "DD-MM-YYYY"),
                    [key]: moment(data[key]).format("DD-MM-YYYY")
                  });
                } else if (key == "stnk_mulai" && data[key] != null) {
                  this.formRef.current.setFieldsValue({
                    [key + "_picker"]: moment(data[key]),// "DD-MM-YYYY"),
                    [key]: moment(data[key]).format("DD-MM-YYYY")
                  });
                } else if ((key == "tanggal_faktur" && data[key] != null) || (key == "tanggal_kwitansi" && data[key] != null)) {
                  const temps = data[key].split("-");//2024-12-31
                  this.formRef.current.setFieldsValue({
                    [key + "_picker"]: moment(data[key]),// "DD-MM-YYYY"),
                    [key]: temps[2] + "-" + temps[1] + "-" + temps[0]
                  });
                } else if (key == "layanan" && data[key] != null) {
                  let temp = data[key];
                  if (Object.keys(temp).length > 0) {
                    temp.map((itemLayanan, idxLayanan) => {
                      // console.log("lstr", data["layanan_list"][idxLayanan]["field_requirements"])
                      temp[idxLayanan] = itemLayanan + "#" + JSON.stringify(data["layanan_list"][idxLayanan]["field_requirements"])
                    });
                    // console.log('temp', temp)
                    this.formRef.current.setFieldsValue({
                      [key]: temp
                    });
                  }
                } else {
                  this.formRef.current.setFieldsValue({
                    [key]: data[key],
                  });
                }
              }
              // if(key == 'gambar'){
              // 	this.setState({
              // 		preview_gambar: data[key]
              // 	});
              // }else{
              // 	this.formRef.current.setFieldsValue({
              // 		[key]: data[key]
              // 	});
              // }
            }

            if (data.kabupaten != null) {
              const rule = {
                destination: {
                  stateProgressName: "loadingKecamatan",
                  endPoint: configClass.apiUrl.kecamatan + "?regencies=" + data.kabupaten,
                  stateDestination: "listKecamatan",
                },
                nextDestination: null,
              };
              this.getMasterList(rule);
            }
            if (data.kecamatan != null) {
              const rule = {
                destination: {
                  stateProgressName: "loadingDesa",
                  endPoint: configClass.apiUrl.desa + "?district=" + data.kecamatan,
                  stateDestination: "listDesa",
                },
                nextDestination: null,
              };
              this.getMasterList(rule);
            }

            this.formRef.current.setFieldsValue({
              is_next: false,
            });



            this.cekPreview('listKabupaten', 'kabupaten', 'kabupaten_nama', 'kabupaten');
            this.cekPreview('listJenisKendaraan', 'jenis_kendaraan', 'jenis_kendaraan_nama', 'jenis_kendaraan_kode');
            this.cekPreview('listKepemilikan', 'kepemilikan', 'kepemilikan_nama', 'kepemilikan_kode');
            this.cekPreview('listFungsi', 'fungsi', 'fungsi_nama', 'fungsi_kode');
            this.cekPreview('listMerek', 'merek', 'merek_nama', 'merek_kode');
            this.cekPreview('listTipe', 'tipe', 'tipe_nama', 'tipe_kode');

            message.destroy();
            this.setState({
              aksi: "edit",
              method: "put",
              idLama: data.id + "/",
            });

            if (data.is_block == true) {//cek blokir kendaraan
              this.setState({
                modalBlock: true
              })
            }
          }
        } else {
          const errTitle = "error preview data lama";
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
          message.error({ content: errTitle, duration: 2, key: "toast" });
        }
      })
      .catch((response) => {
        console.log("gagal_get_preview", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  handleSubmit = (values) => {
    this.postDataPemilik(values);
  };

  reset() {
    this.formRef.current.setFieldsValue({
      pasien: undefined,
      jenis: "barcode",
    });
    this.resetPasien();
    this.setState({
      dataPasien: null,
      jenis: "barcode",
    });
    this.getDataRiwayat();
  }

  resetPasien() {
    // if (
    //   this.formRefPasien != undefined &&
    //   this.formRefPasien.current != undefined
    // ) {
    //   this.formRefPasien.current.resetFields();
    // }
  }

  async getPasienById(val) {
    message.loading({
      content: "Display data pasien...",
      duration: 0,
      key: "toast",
    });
    this.setState({
      errTitle: "",
      errMsg: "",
      dataPasien: null,
      skeletonPasien: true,
    });
    this.props
      .ajaxViewHandler("get", configClass.apiUrl.pasien + "/" + val)
      .then(() => {
        this.setState({
          skeletonPasien: false,
        });
        if (this.props.isSuccess) {
          message.destroy();

          var response = this.props.responseMsg;
          this.setState({
            dataPasien: response,
          });
        } else {
          const errTitle = "gagal menampilkan data pasien";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
        }
      })
      .catch((response) => {
        console.log("gagal_preview_pasien", response);
        this.setState({
          skeletonPasien: false,
        });
        message.error({
          content: "kesalahan pengiriman data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  async getDataPemilik(nik) {
    message.loading({
      content: "Pengecekan kepemilikan...",
      duration: 0,
      key: "toast",
    });
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler(
        "get",
        configClass.apiUrl.pemilik +
        "?nik=" +
        nik
      )
      .then(() => {
        const datas = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (datas.results != undefined) {
            const data = datas.results;
            if (data.length == 0) {
              message.warning({ content: "data kepemilikan tidak ditemukan", duration: 2, key: "toast" });
            } else {
              message.destroy();
            }
            for (var key in data) {
              const dateRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4}$/;
              const dateTimeRegex =
                /^[0-9]{2}\-[0-9]{2}\-[0-9]{4} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;

              const dateRegex2 = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
              const dateTimeRegex2 =
                /^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
              if (dateRegex.test(data[key])) {
                //jika format tanggal
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key], "DD-MM-YYYY"),
                  [key]: moment(data[key]).format('DD-MM-YYYY')
                });
              } else if (dateTimeRegex.test(data[key])) {
                //jika format tanggal dan jam
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key], "DD-MM-YYYY HH:mm:ss"),
                  [key]: moment(data[key]).format('DD-MM-YYYY HH:mm:ss')
                });
              } else if (dateRegex2.test(data[key])) {
                //jika format tanggal
                // console.log(key, data[key]);
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key], "DD-MM-YYYY"),
                  [key]: moment(data[key]).format("DD-MM-YYYY"),
                  //[key]: moment(data[key], 'DD-MM-YYYY')
                });
              } else if (dateTimeRegex2.test(data[key])) {
                //jika format tanggal dan jam
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key], "DD-MM-YYYY HH:mm:ss"),
                  [key]: moment(data[key]).format("DD-MM-YYYY HH:mm:ss"),
                });
              } else {
                this.formRef.current.setFieldsValue({
                  [key]: data[key],
                });
              }
            }
          }
        } else {
          const errTitle = "error get data";
          message.error({ content: errTitle, duration: 2, key: "toast" });
        }
      })
      .catch((response) => {
        console.log("gagal_get_wp", response);
        message.error({
          content: "gagal cek wp, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  async postDataPemilik(datas) {
    message.loading({ content: "Memproses...", duration: 0, key: "toast" });
    this.setState({
      errTitle: "",
      errMsg: "",
      loadingButton: true,
    });
    this.props
      .ajaxHandler("post", configClass.apiUrl.pemilik, datas, false, true)
      .then(() => {
        if (this.props.isSuccess) {
          // message.success({ content: 'berhasil melakukan registrasi', duration: 2, key: 'toast' });

          var response = this.props.responseMsg;
          this.postDataKendaraan(datas, response.id);
        } else {
          const errTitle = "gagal menyimpan data pemilik";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            loadingButton: false,
            errTitle: errTitle,
            errMsg: JSON.stringify(this.props.responseMsg),
          });
        }
      })
      .catch((response) => {
        console.log("gagal_post_pemilik", response);
        this.setState({
          loadingButton: false,
        });
        message.error({
          content: "kesalahan pengiriman data pemilik, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  async postDataKendaraan(datas, idPemilik) {
    var dataKendaraan = datas;
    // if (this.state.pemilik_lama == null) {
    //   dataKendaraan.pemilik = idPemilik;
    // } else {
    //   dataKendaraan.pemilik = this.state.pemilik_lama;
    // }
    dataKendaraan.pemilik = idPemilik;

    dataKendaraan.nomor_polisi =
      datas.no_polisi_depan + datas.no_polisi_tengah + datas.no_polisi_belakang;
    dataKendaraan.nomor_polisi_lama =
      datas.no_polisi_depan_lama +
      datas.no_polisi_tengah_lama +
      datas.no_polisi_belakang_lama;

    message.loading({ content: "Memproses...", duration: 0, key: "toast" });
    this.props
      .ajaxHandler(
        this.state.method,
        configClass.apiUrl.kendaraan + this.state.idLama,
        dataKendaraan,
        false,
        true
      )
      .then(() => {
        if (this.props.isSuccess) {
          message.success({
            content: "berhasil menyimpan pendaftaran",
            duration: 2,
            key: "toast",
          });
          this.setState({
            pemilik_lama: null,
            loadingButton: false,
            isOpenForm: false,

            isSukses: true,
            redirect_nopol: datas.no_polisi_tengah + datas.no_polisi_belakang,
          });
        } else {
          const errTitle = "gagal menyimpan data kendaraan";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            loadingButton: false,
            errTitle: errTitle,
            errMsg: JSON.stringify(this.props.responseMsg),
          });
        }
      })
      .catch((response) => {
        console.log("gagal_post_kendaraan", response);
        this.setState({
          loadingButton: false,
        });
        message.error({
          content: "kesalahan pengiriman data kendaraan, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  handleReset() {
    this.setState({
      isOpenForm: false,
    });
  }

  handleSubmitPasien = (values) => {
    var datas = new FormData();
    for (var key in values) {
      if (values[key] == undefined) {
        datas.append(key, "");
      } else {
        if (key == "nama" || key == "tempat_lahir") {
          datas.append(key, values[key].toString().toUpperCase());
        } else if (key == "email") {
          datas.append(key, values[key].toString().toLowerCase());
        } else {
          datas.append(key, values[key].toString());
        }
      }
    }
    // datas.append('provider', this.props.id);
    this.postDataPasien(datas);
  };

  async postDataPasien(datas) {
    message.loading({ content: "Memproses...", duration: 0, key: "toast" });
    this.setState({
      errTitle: "",
      errMsg: "",
    });
    this.props
      .ajaxHandler("post", configClass.apiUrl.pasien + "/", datas, true, true)
      .then(() => {
        this.setState({
          loadingButton: false,
        });
        if (this.props.isSuccess) {
          message.success({
            content: "berhasil menyimpan data",
            duration: 2,
            key: "toast",
          });
          var response = this.props.responseMsg;
          this.formRef.current.setFieldsValue({
            pasien: response.id + "",
          });
          //preview yang berbentuk list
          const rule = [
            {
              destination: {
                stateProgressName: "loadingPasien_searching",
                endPoint: configClass.apiUrl.pasien + "/" + response.id,
                stateDestination: "listPasien",
              },
              nextDestination: null,
            },
          ];

          this.setState({
            errComponent: false,
            modalBaru: false,
          });
          rule.map((item, index) => {
            this.getMasterList(item);
          });
          this.resetPasien();
          this.getPasienById(response.id);
        } else {
          const errTitle = "gagal menyimpan data";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
        }
      })
      .catch((response) => {
        console.log("gagal_post", response);
        this.setState({
          loadingButton: false,
        });
        message.error({
          content: "kesalahan pengiriman data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  loadMasterList() {
    const rule = [
      {
        destination: {
          stateProgressName: "loadingKabupaten",
          endPoint: configClass.apiUrl.kabupaten,
          stateDestination: "listKabupaten",
        },
        nextDestination: {
          stateProgressName: "loadingGolongan",
          endPoint: configClass.apiUrl.golongan,
          stateDestination: "listGolongan",
          next: null,
        },
      },
      {
        destination: {
          stateProgressName: "loadingKepemilikan",
          endPoint: configClass.apiUrl.kepemilikan,
          stateDestination: "listKepemilikan",
        },
        nextDestination: {
          stateProgressName: "loadingFungsi",
          endPoint: configClass.apiUrl.fungsi,
          stateDestination: "listFungsi",
          next: null,
        },
      },
      {
        destination: {
          stateProgressName: "loadingJenisKendaraan",
          endPoint: configClass.apiUrl.jenis_kendaraan,
          stateDestination: "listJenisKendaraan",
        },
        nextDestination: {
          stateProgressName: "loadingMerek",
          endPoint: configClass.apiUrl.merek,
          stateDestination: "listMerek",
          next: null,
        },
      },
      {
        destination: {
          stateProgressName: "loadingTipe",
          endPoint: configClass.apiUrl.tipe,
          stateDestination: "listTipe",
        },
        nextDestination: {
          stateProgressName: "loadingBahanBakar",
          endPoint: configClass.apiUrl.bahan_bakar,
          stateDestination: "listBahanBakar",
          next: null,
        },
      },
      {
        destination: {
          stateProgressName: "loadingWarnaPlat",
          endPoint: configClass.apiUrl.warna_plat,
          stateDestination: "listWarnaPlat",
        },
        nextDestination: {
          stateProgressName: "loadingJenisLayanan",
          endPoint: configClass.apiUrl.jenis_layanan,
          stateDestination: "listJenisLayanan",
          next: null,
        },
      },
    ];

    this.setState({
      errComponent: false,
    });

    rule.map((item, index) => {
      if (index == rule.length - 1) {
        this.getMasterList(item, true);
      } else {
        this.getMasterList(item);
      }
    });
  }

  async getMasterList(rule, isFinal = false) {
    var destination = rule.destination;
    var nextDestination = rule.nextDestination;
    this.setState({ [destination.stateProgressName]: true });
    this.props
      .ajaxViewHandler("get", destination.endPoint)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (data.count != undefined) {
            let newState = null;
            if (this.state[destination.stateDestination].length == 0) {
              newState = data;
              // if(destination.stateProgressName.toLowerCase().includes("JenisLayanan")){
              //   let templistJenisLayananRequirement = data.results
              // }
            } else {
              if (destination.stateProgressName.includes("_searching")) {
                newState = data;
              } else {
                const oldState = this.state[destination.stateDestination];
                newState = {
                  count: data.count,
                  next: data.next,
                  previous: data.previous,
                  results: [...oldState.results, ...data.results],
                };
              }
            }
            this.setState({
              [destination.stateDestination]: newState,
            });
          } else if (data.results != undefined) {
            this.setState({
              [destination.stateDestination]: data.results,
            });
          } else {
            if (Array.isArray(data)) {
              this.setState({
                [destination.stateDestination]: data,
              });
            } else {
              this.setState({
                [destination.stateDestination]: [data],
              });
            }
          }
          if (isFinal) {
            if (this.props.match.params.id != undefined) {
              // this.preview(this.props.match.params.id);
            }
          }
        } else {
          this.setState({
            errComponent: true,
          });
        }
        this.setState({ [destination.stateProgressName]: false });
      })
      .catch((response) => {
        //console.log('gagal_get_master', response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({
          errTitle: "gagal request data master",
          errMsg: response,
        });
        this.setState({ [destination.stateProgressName]: false });
      });
    //next function
    if (nextDestination != null) {
      const next = {
        destination: {
          stateProgressName: nextDestination.stateProgressName,
          endPoint: nextDestination.endPoint,
          stateDestination: nextDestination.stateDestination,
        },
        nextDestination: nextDestination.next,
      };
      this.getMasterList(next);
    }
  }

  generateSelect(stateName, url, key, value, keyDisplay = "") {
    let convertName = stateName.toLowerCase();
    if (stateName == "jeniskendaraan") {
      convertName = "jenis_kendaraan";
    } else if (stateName == "bahanbakar") {
      convertName = "bahan_bakar";
    } else if (stateName == "warnaplat") {
      convertName = "warna_plat";
    }

    return this.state["loading" + stateName] ? (
      <img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
    ) : (
      <Select
        // autoFocus
        // labelInValue={stateName == "Tipe" ? true : false}
        showSearch
        placeholder={"Cari / Pilih " + stateName}
        optionFilterProp="children"
        onSearch={(val) => {
          if (stateName == "Tipe") {
            const rule = {
              destination: {
                stateProgressName: "loading" + stateName + "_searching",
                endPoint: configClass.apiUrl.tipe + "?merek=" + this.state.merek_dipilih + "&jenis=" + this.state.jenis_kendaraan_dipilih + "&search=" + val,
                stateDestination: "list" + stateName,
              },
              nextDestination: null,
            };
            this.getMasterList(rule);
          } else if (stateName == "Kecamatan") {
            const rule = {
              destination: {
                stateProgressName: "loading" + stateName + "_searching",
                endPoint: configClass.apiUrl.kecamatan + "?regencies=" + this.state.kabupaten_dipilih + "&search=" + val,
                stateDestination: "list" + stateName,
              },
              nextDestination: null,
            };
            this.getMasterList(rule);
          } else if (stateName == "Desa") {
            const rule = {
              destination: {
                stateProgressName: "loading" + stateName + "_searching",
                endPoint: configClass.apiUrl.desa + "?district=" + this.state.kecamatan_dipilih + "&search=" + val,
                stateDestination: "list" + stateName,
              },
              nextDestination: null,
            };
            this.getMasterList(rule);
          } else {
            const rule = {
              destination: {
                stateProgressName: "loading" + stateName + "_searching",
                endPoint: url + "?search=" + val,
                stateDestination: "list" + stateName,
              },
              nextDestination: null,
            };
            this.getMasterList(rule);
          }
        }}
        onPopupScroll={(e) => {
          e.persist();
          let target = e.target;
          if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (
              this.state["list" + stateName].next != undefined &&
              this.state["list" + stateName].next != null
            ) {
              const rule = {
                destination: {
                  stateProgressName: "loading" + stateName + "Paging",
                  endPoint: this.state["list" + stateName].next,
                  stateDestination: "list" + stateName,
                },
                nextDestination: null,
              };
              this.getMasterList(rule);
            }
          }
        }}
        allowClear
        style={{
          width: "100%",
        }}
        onChange={(val) => {
          if (stateName == "Kabupaten") {
            const rule = {
              destination: {
                stateProgressName: "loadingKecamatan",
                endPoint: configClass.apiUrl.kecamatan + "?regencies=" + val,
                stateDestination: "listKecamatan",
              },
              nextDestination: null,
            };
            this.getMasterList(rule);
            this.setState({
              kabupaten_dipilih: val
            });
          } else if (stateName == "Kecamatan") {
            const rule = {
              destination: {
                stateProgressName: "loadingDesa",
                endPoint: configClass.apiUrl.desa + "?district=" + val,
                stateDestination: "listDesa",
              },
              nextDestination: null,
            };
            this.getMasterList(rule);
            this.setState({
              kecamatan_dipilih: val
            });
          } else if (stateName == "Merek") {
            this.setState({
              merek_dipilih: val
            });
            const rule = {
              destination: {
                stateProgressName: "loadingTipe",
                endPoint: configClass.apiUrl.tipe + "?merek=" + this.state.merek_dipilih + "&jenis=" + this.state.jenis_kendaraan_dipilih,
                stateDestination: "listTipe",
              },
              nextDestination: null,
            };
            this.getMasterList(rule);
          } else if (stateName == "JenisKendaraan") {
            this.setState({
              jenis_kendaraan_dipilih: val
            });
            const rule = {
              destination: {
                stateProgressName: "loadingTipe",
                endPoint: configClass.apiUrl.tipe + "?merek=" + this.state.merek_dipilih + "&jenis=" + this.state.jenis_kendaraan_dipilih,
                stateDestination: "listTipe",
              },
              nextDestination: null,
            };
            this.getMasterList(rule);
          } else if (stateName == "Tipe") {
            // this.formRef.current.setFieldsValue({
            //   koding: val.label.replace(/ /g, '').split("-")[0]
            // })
          }
        }}
        size={"small"}
        disabled={!this.cekIsRequired(convertName)}
      >
        {this.state["loading" + stateName + "_searching"] == true && (
          <Select.Option value="">
            <Spin size="small" />
          </Select.Option>
        )}
        {this.state["list" + stateName].count != undefined
          ? this.state["list" + stateName].results.map((item) => (
            <Select.Option
              value={item[key]}
              title={keyDisplay == "" ? item[key] + " - " + item[value] : item[keyDisplay] + " - " + item[value]}
            >
              {keyDisplay == ""
                ? item[key] + " - " + item[value]
                : item[keyDisplay] + " - " + item[value]}
            </Select.Option>
          ))
          : this.state["list" + stateName].map((item) => (
            <Select.Option
              value={item[key]}
              title={keyDisplay == "" ? item[key] + " - " + item[value] : item[keyDisplay] + " - " + item[value]}
            >
              {keyDisplay == ""
                ? item[key] + " - " + item[value]
                : item[keyDisplay] + " - " + item[value]}
            </Select.Option>
          ))}
        {this.state["list" + stateName] != undefined &&
          this.state["list" + stateName].next != undefined && (
            <Select.Option value="">
              <Spin size="small" />
            </Select.Option>
          )}
      </Select>
    );
  }

  generateSelectMultiple(stateName, url, key, value, keyDisplay = "") {
    return this.state["loading" + stateName] ? (
      <img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
    ) : (
      <Select
        mode="multiple"
        autoFocus
        showSearch
        placeholder={"Cari / Pilih " + stateName}
        optionFilterProp="children"
        onSearch={(val) => {
          const rule = {
            destination: {
              stateProgressName: "loading" + stateName + "_searching",
              endPoint: url + "?search=" + val,
              stateDestination: "list" + stateName,
            },
            nextDestination: null,
          };
          this.getMasterList(rule);
        }}
        onPopupScroll={(e) => {
          e.persist();
          let target = e.target;
          if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (
              this.state["list" + stateName].next != undefined &&
              this.state["list" + stateName].next != null
            ) {
              const rule = {
                destination: {
                  stateProgressName: "loading" + stateName + "Paging",
                  endPoint: this.state["list" + stateName].next,
                  stateDestination: "list" + stateName,
                },
                nextDestination: null,
              };
              this.getMasterList(rule);
            }
          }
        }}
        allowClear
        style={{
          width: "100%",
        }}
        onChange={(val) => {
          // if (stateName == "Kabupaten") {
          //   const rule = {
          //     destination: {
          //       stateProgressName: "loadingKecamatan",
          //       endPoint: configClass.apiUrl.kecamatan + "?regencies=" + val,
          //       stateDestination: "listKecamatan",
          //     },
          //     nextDestination: null,
          //   };
          //   this.getMasterList(rule);
          // }
          if (stateName == "JenisLayanan") {
            if (this.state.jenis == "Kendaraan Baru") {
              this.setState({
                field_requirements: {}
              });
            } else {
              this.setState({
                field_requirements: {}
              }, () => {
                let tempReq = {};
                val.map((items, idx) => {
                  const item = items.split("#")[1];
                  if (Object.keys(item).length > 0) {
                    // tempReq = { ...tempReq, ...item };
                    Object.assign(tempReq, JSON.parse(item));
                  }
                });
                this.setState({
                  field_requirements: tempReq
                })
              });
            }
          }
        }}
        size={"small"}
      >
        {this.state["loading" + stateName + "_searching"] == true && (
          <Select.Option value="">
            <Spin size="small" />
          </Select.Option>
        )}
        {this.state["list" + stateName].count != undefined
          ? this.state["list" + stateName].results.map((item) => (
            <Select.Option value={item[key] + "#" + JSON.stringify(item.field_requirements)}>
              {keyDisplay == ""
                ? item[key] + " - " + item[value]
                : item[keyDisplay] + " - " + item[value]}
            </Select.Option>
          ))
          : this.state["list" + stateName].map((item) => (
            <Select.Option value={item[key]}>
              {keyDisplay == ""
                ? item[key] + " - " + item[value]
                : item[keyDisplay] + " - " + item[value]}
            </Select.Option>
          ))}
        {this.state["list" + stateName] != undefined &&
          this.state["list" + stateName].next != undefined && (
            <Select.Option value="">
              <Spin size="small" />
            </Select.Option>
          )}
      </Select>
    );
  }

  showmodalBaru() {
    this.setState({
      modalBaru: true,
    });
  }

  cekIsRequired(fieldName) {
    let result = true;
    const data = this.state.field_requirements;
    for (var key in data) {
      if (fieldName == key) {
        result = data[key]
      }
    }
    // console.log("field_name", fieldName)
    // console.log("result", result)
    return result;
  }

  showDetail = (judul, dataModal, is_flat = false) => {
    // alert(JSON.stringify(dataModal))
    this.setState({
      visibleModal: true,
      dataModal: {
        is_flat: is_flat,
        judul: judul,
        konten: {
          kolom: judul,
          value: dataModal
        },
      },
    });
  };
  /*
    async getDataRiwayat(limit = this.state.paginationRiwayat.pageSize, offset = 0, search = this.state.searchText) {
      this.setState({ loadingData: true });
      //console.log('master/api/desa/?limit='+limit+'&offset='+offset);
  
      this.props.ajaxViewHandler('get', configClass.apiUrl.history + '?limit=' + limit + '&offset=' + offset + '&search=' + search + '&' + this.state.filterData)
        .then(() => {
          const data = this.props.responseMsg;
          if (this.props.isSuccess) {
            const pagination = { ...this.state.pagination };
            pagination.total = data.count;
            this.setState({
              dataSource: data.results,
              pagination
            });
          } else {
            const errTitle = 'error get data';
            this.setState({
              errTitle: errTitle,
              errMsg: this.props.responseMsg
            });
            message.error({ content: errTitle, duration: 2, key: 'toast' });
          }
          this.setState({ loadingData: false });
        }).catch((response) => {
          console.log('gagal_get_preview', response);
          message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
          this.setState({ loadingData: false });
        });
    }
  */
  render() {
    // if(this.props.layanan_str.length>0 && this.props.layanan_str.includes('Pembelian / Pemesanan Obat') == false){
    // 	return <Redirect to="/admin/forbidden" />
    // }
    if (this.state.isSukses) {
      return <Redirect to={configClass.pageUrl.redirect_url + "?no_pol=" + this.state.redirect_nopol} />
    }

    return (
      <>
        <div>
          <Helmet>
            <title>
              {configClass.title + " | " + config.get_site_info().name}
            </title>
          </Helmet>
          <Breadcrumb>
            {configClass.breadCrumb.map((item) => (
              <Breadcrumb.Item>{item}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
          <div className="card" id="panel-transaksi">
            <Card
              className="konten"
              title={null}
            >
              <Form
                layout="horizontal"
                name="fdata"
                onFinish={this.handleSubmit}
                size="small"
                ref={this.formRef}
                initialValues={{
                  jenis: this.state.jenis,
                  no_polisi_depan: "KH",
                  is_next: false,
                }}
              >
                <Form.Item
                  name="is_next"
                  style={{
                    display: "none",
                  }}
                >
                  <Input type="hidden" />
                </Form.Item>

                <Row className="isi">
                  {this.state.errTitle != null && this.state.errTitle != "" && (
                    <Alert
                      closable
                      message={this.state.errTitle}
                      description={this.state.errMsg}
                      type="error"
                      showIcon
                      style={{
                        marginBottom: 10,
                        width: "100%"
                      }}
                    />
                  )}

                  <Col span={24}>
                    <Row justify="start">
                      <Col span={24}>
                        <Input.Group compact>
                          <Form.Item name="jenis" style={{ width: "20%" }}>
                            <Select
                              size="large"
                              showSearch
                              placeholder="pilih jenis"
                              optionFilterProp="children"
                              style={{
                                width: "100%",
                              }}
                              onChange={(val) => {
                                this.setState(
                                  {
                                    jenis: val,
                                    isOpenForm: val == "Kendaraan Lama" ? false : true
                                    // modalBaru: val == 'pasien baru' ? true : false
                                  },
                                  () => {
                                    // this.formRef.pasien.current.focus({cursor: 'start'});
                                    this.formRef.current.setFieldsValue({
                                      // pasien: undefined
                                    });
                                    if (val == "Kendaraan Baru") {
                                      this.formRefScroll.current.scrollTo(0, 0);
                                    }
                                  }
                                );
                              }}
                            >
                              <Select.Option value={"Kendaraan Lama"}>
                                <BarcodeOutlined /> Kendaraan Lama
                              </Select.Option>
                              <Select.Option value={"Kendaraan Baru"}>
                                <CarOutlined /> Kendaraan Baru
                              </Select.Option>
                            </Select>
                          </Form.Item>
                          {this.state.jenis == "Kendaraan Lama"
                            ?
                            <Input
                              allowClear
                              style={{ width: "80%" }}
                              placeholder="input nomer polisi, cth : KH1234XY"
                              onChange={(e) => {
                                const regex = /^\d+\.?\d*$/;
                                let startIdx = 0;
                                if (regex.test(e.target.value.charAt(0))) {
                                  startIdx = 0;
                                } else {
                                  if (regex.test(e.target.value.charAt(1))) {
                                    startIdx = 1;
                                  } else {
                                    startIdx = 2;
                                  }
                                }

                                const noKendaraan = e.target.value.substring(startIdx);

                                this.setState({
                                  // cari_no_pol: e.target.value,
                                  cari_no_pol: noKendaraan,
                                });
                              }}
                              prefix="KH"
                              value={this.state.cari_no_pol}
                              name="cari_no_pol"
                              size="large"
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  this.searchKendaraan(this.state.cari_no_pol);
                                }
                              }}
                            />
                            :
                            <Input
                              size="large"
                              style={{ width: "80%" }}
                              disabled
                            />
                          }

                        </Input.Group>
                      </Col>
                    </Row>
                  </Col>

                  {this.state.isOpenForm == true && (
                    <Col
                      span={24}
                      style={{
                        overflowY: "auto",
                        maxHeight: "calc(100vh - 205px)",
                        // background: "rgb(11,93,37, 0.2)", 
                        padding: 10,
                        // boxShadow: "5px 10px"
                      }}
                      ref={this.formRefScroll}>
                      <Row className="form-penetapan-biaya">
                        <Col span={24}>
                          <Row justify="space-between">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Jenis Layanan
                            </Col>
                            <Col span={15} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="layanan"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                {this.generateSelectMultiple(
                                  "JenisLayanan",
                                  configClass.apiUrl.jenis_layanan,
                                  "id",
                                  "nama",
                                  "kode"
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={6} style={{ paddingLeft: 3 }}>
                              <Button
                                style={{ width: "100%" }}
                                key="btn-simpan"
                                loading={this.state.loadingButton}
                                type="primary"
                                htmlType="button"
                                disabled={this.state.jenis == "Kendaraan Baru" ? true : false}
                                onClick={() => {
                                  this.setState({ isModalRiwayat: true }, () => {
                                    this.getDataRiwayat();
                                  })
                                }}
                              >
                                {!this.state.loadingButton && (
                                  <UserSwitchOutlined />
                                )}
                                Riwayat Pemilik
                              </Button>
                            </Col>

                          </Row>
                        </Col>
                        {/*
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Jenis Layanan
                          </Col>
                          <Col span={21} style={{ paddingLeft: 5 }}>
                            <Form.Item name="layanan">
                              {this.generateSelect(
                                "JenisLayanan",
                                configClass.apiUrl.jenis_layanan,
                                "id",
                                "nama",
                                "kode"
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      */}
                        <Col span={24}>
                          <Row justify="start">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Nomor Polisi
                            </Col>
                            <Col span={1} style={{ paddingLeft: 5 }}>
                              <Form.Item name="no_polisi_depan">
                                <Input placeholder="KH" />
                              </Form.Item>
                            </Col>
                            <Col span={3} style={{ paddingLeft: 5 }}>
                              <Form.Item name="no_polisi_tengah">
                                <Input placeholder="1234" />
                              </Form.Item>
                            </Col>
                            <Col span={1} style={{ paddingLeft: 5 }}>
                              <Form.Item name="no_polisi_belakang">
                                <Input placeholder="YZ" />
                              </Form.Item>
                            </Col>
                            <Col span={1} style={{ paddingLeft: 5 }}>
                              SKUM
                            </Col>
                            <Col span={2} style={{ paddingLeft: 5 }}>
                              <Form.Item name="skum1">
                                <Input disabled={!this.cekIsRequired("skum1")} />
                              </Form.Item>
                            </Col>
                            <Col span={2} style={{ paddingLeft: 5 }}>
                              <Form.Item name="skum2">
                                <Input disabled={!this.cekIsRequired("skum2")} />
                              </Form.Item>
                            </Col>
                            <Col span={2} style={{ paddingLeft: 5 }}>
                              No. Urut
                            </Col>
                            <Col span={2} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="no_urut"
                              >
                                <Input disabled={!this.cekIsRequired("skuno_urutm2")} />
                              </Form.Item>
                            </Col>

                            <Col span={2} style={{ paddingLeft: 5 }}>
                              Kohir
                            </Col>
                            <Col span={3} style={{ paddingLeft: 5 }}>
                              <Form.Item name="kohir1">
                                <Input disabled={!this.cekIsRequired("kohir1")} />
                              </Form.Item>
                            </Col>
                            <Col span={2} style={{ paddingLeft: 5 }}>
                              <Form.Item name="kohir2">
                                <Input disabled={!this.cekIsRequired("kohir2")} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="start">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Nomor Polisi Lama
                            </Col>
                            <Col span={1} style={{ paddingLeft: 5 }}>
                              <Form.Item name="no_polisi_depan_lama">
                                <Input placeholder="KH" disabled={!this.cekIsRequired("nomor_polisi_lama")} />
                              </Form.Item>
                            </Col>
                            <Col span={3} style={{ paddingLeft: 5 }}>
                              <Form.Item name="no_polisi_tengah_lama">
                                <Input placeholder="1234" disabled={!this.cekIsRequired("nomor_polisi_lama")} />
                              </Form.Item>
                            </Col>
                            <Col span={1} style={{ paddingLeft: 5 }}>
                              <Form.Item name="no_polisi_belakang_lama">
                                <Input placeholder="YZ" disabled={!this.cekIsRequired("nomor_polisi_lama")} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        {/* <Col span={24}>
                        <Row justify="start">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Nomor Polisi Lama
                          </Col>
                          <Col span={1} style={{ paddingLeft: 5 }}>
                            <Form.Item name="no_polisi_depan_lama">
                              <Input placeholder="KH" />
                            </Form.Item>
                          </Col>
                          <Col span={3} style={{ paddingLeft: 5 }}>
                            <Form.Item name="no_polisi_tengah_lama">
                              <Input placeholder="1234" />
                            </Form.Item>
                          </Col>
                          <Col span={1} style={{ paddingLeft: 5 }}>
                            <Form.Item name="no_polisi_belakang_lama">
                              <Input placeholder="YZ" />
                            </Form.Item>
                          </Col>
                          <Col span={1} style={{ paddingLeft: 5 }}>
                            Kohir
                          </Col>
                          <Col span={2} style={{ paddingLeft: 5 }}>
                            <Form.Item name="kohir1">
                              <Input disabled={!this.cekIsRequired("kohir1")} />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ paddingLeft: 5 }}>
                            <Form.Item name="kohir2">
                              <Input disabled={!this.cekIsRequired("kohir2")} />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ paddingLeft: 5 }}>
                            Tgl. Daftar
                          </Col>
                          <Col span={9} style={{ paddingLeft: 5 }}>
                            <Form.Item name="tgl_daftar_picker">
                              <DatePicker
                                placeholder="dd-mm-yyyy"
                                format="DD-MM-YYYY"
                                style={{ width: "100%" }}
                                onChange={(date, dateString) => {
                                  this.formRef.current.setFieldsValue({
                                    created_at: dateString,
                                  });
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              name="created_at"
                              style={{
                                display: "none",
                              }}
                            >
                              <Input type="hidden" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col> */}
                        <Col span={24}>
                          <Row justify="start">
                            <Col span={3} style={{ textAlign: "right" }}>
                              No Identitas
                            </Col>
                            <Col span={21} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="nik"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input
                                  disabled={!this.cekIsRequired("nik")}
                                  placeholder="cth: 1234567890123456"
                                  onChange={(e) => {
                                    this.getDataPemilik(e.target.value);
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={3} style={{ textAlign: "right" }}>
                              Nama WP/Pemilik
                            </Col>
                            <Col span={21} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="nama"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("nama")} placeholder="cth: Budi Raharjo" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="start">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Alamat WP/Pemilik
                            </Col>
                            <Col span={21} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="alamat"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("alamat")} placeholder="cth: Jl. Raya Indonesia No 75, Bumi Insan Pertiwi" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="start">
                            <Col span={3} style={{ textAlign: "right" }}>
                              No Hp
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="no_hp"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("no_hp")} placeholder="cth: 081234567890" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="space-between">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Kabupaten
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="kabupaten"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                {this.generateSelect(
                                  "Kabupaten",
                                  configClass.apiUrl.kabupaten,
                                  "id",
                                  "name"
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={2} style={{ textAlign: "right" }}>
                              Kecamatan
                            </Col>
                            <Col span={5} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="kecamatan"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                {this.generateSelect(
                                  "Kecamatan",
                                  configClass.apiUrl.kecamatan,
                                  "id",
                                  "name"
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={2} style={{ textAlign: "right" }}>
                              Desa
                            </Col>
                            <Col span={5} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="desa"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                {this.generateSelect(
                                  "Desa",
                                  configClass.apiUrl.desa,
                                  "id",
                                  "name"
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="start">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Kepemilikan Ke
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="kepemilikan_ke"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("kepemilikan_ke")} placeholder="cth: 1" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="start">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Kode Kepemilikan
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="kepemilikan"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                {this.generateSelect(
                                  "Kepemilikan",
                                  configClass.apiUrl.kepemilikan,
                                  "id",
                                  "nama",
                                  "kode"
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={3} style={{ textAlign: "right" }}>
                              Tanggal Kwitansi
                            </Col>
                            <Col span={11} style={{ paddingLeft: 5 }}>
                              <Form.Item name="tanggal_kwitansi_picker">
                                <DatePicker
                                  placeholder="dd-mm-yyyy"
                                  format="DD-MM-YYYY"
                                  style={{ width: "100%" }}
                                  onChange={(date, dateString) => {
                                    this.formRef.current.setFieldsValue({
                                      tanggal_kwitansi: dateString,
                                    });
                                  }}
                                  disabled={!this.cekIsRequired("tanggal_kwitansi")}
                                />
                              </Form.Item>
                              <Form.Item
                                name="tanggal_kwitansi"
                                style={{
                                  display: "none",
                                }}
                              >
                                <Input type="hidden" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="start">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Kode Fungsi
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="fungsi"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                {this.generateSelect(
                                  "Fungsi",
                                  configClass.apiUrl.fungsi,
                                  "id",
                                  "nama",
                                  "kode"
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={3} style={{ textAlign: "right" }}>
                              Tanggal Fiskal
                            </Col>
                            <Col span={11} style={{ paddingLeft: 5 }}>
                              <Form.Item name="tanggal_fiskal_picker">
                                <DatePicker
                                  placeholder="dd-mm-yyyy"
                                  format="DD-MM-YYYY"
                                  style={{ width: "100%" }}
                                  onChange={(date, dateString) => {
                                    this.formRef.current.setFieldsValue({
                                      tanggal_fiskal: dateString,
                                    });
                                  }}
                                  disabled={!this.cekIsRequired("tanggal_fiskal")}
                                />
                              </Form.Item>
                              <Form.Item
                                name="tanggal_fiskal"
                                style={{
                                  display: "none",
                                }}
                              >
                                <Input type="hidden" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="left">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Tanggal Faktur
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item name="tanggal_faktur_picker">
                                <DatePicker
                                  placeholder="dd-mm-yyyy"
                                  format="DD-MM-YYYY"
                                  style={{ width: "100%" }}
                                  onChange={(date, dateString) => {
                                    this.formRef.current.setFieldsValue({
                                      tanggal_faktur: dateString,
                                    });
                                  }}
                                  disabled={!this.cekIsRequired("tanggal_faktur")}
                                />
                              </Form.Item>
                              <Form.Item
                                name="tanggal_faktur"
                                style={{
                                  display: "none",
                                }}
                              >
                                <Input type="hidden" />
                              </Form.Item>
                            </Col>
                            {/* <Col span={3} style={{ textAlign: "right" }}>
                            Koding
                          </Col>
                          <Col span={11} style={{ paddingLeft: 5 }}>
                            <Form.Item name="koding">
                              <Input disabled={!this.cekIsRequired("koding")} />
                            </Form.Item>
                          </Col> */}
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="space-between">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Jenis
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="jenis_kendaraan"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                {this.generateSelect(
                                  "JenisKendaraan",
                                  configClass.apiUrl.jenis_kendaraan,
                                  "id",
                                  "nama",
                                  "kode"
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={3} style={{ textAlign: "right" }}>
                              Merk
                            </Col>
                            <Col span={11} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="merek"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                {this.generateSelect(
                                  "Merek",
                                  configClass.apiUrl.merek,
                                  "id",
                                  "nama",
                                  "kode"
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="start">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Tipe
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="tipe"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                {this.generateSelect(
                                  "Tipe",
                                  configClass.apiUrl.tipe,
                                  "id",
                                  "nama",
                                  "kode"
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={3} style={{ textAlign: "right" }}>
                              Golongan
                            </Col>
                            <Col span={11} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="golongan"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                {this.generateSelect(
                                  "Golongan",
                                  configClass.apiUrl.golongan,
                                  "id",
                                  "nama",
                                  "kode"
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="space-between">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Tahun Buat
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="tahun_buat"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("tahun_buat")} placeholder="cth: 2021" />
                              </Form.Item>
                            </Col>
                            <Col span={3} style={{ textAlign: "right" }}>
                              CC
                            </Col>
                            <Col span={11} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="silinder"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("silinder")} placeholder="cth: 150" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="space-between">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Warna
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="warna"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("warna")} placeholder="cth: merah" />
                              </Form.Item>
                            </Col>
                            <Col span={3} style={{ textAlign: "right" }}>
                              No. Rangka
                            </Col>
                            <Col span={11} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="nomor_rangka"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("nomor_rangka")} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="space-between">
                            <Col span={3} style={{ textAlign: "right" }}>
                              No Mesin
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="nomor_mesin"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("nomor_mesin")} />
                              </Form.Item>
                            </Col>
                            <Col span={3} style={{ textAlign: "right" }}>
                              Kode BBM
                            </Col>
                            <Col span={11} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="bahan_bakar"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                {this.generateSelect(
                                  "BahanBakar",
                                  configClass.apiUrl.bahan_bakar,
                                  "id",
                                  "nama",
                                  "kode",
                                  true
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="start">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Jumlah Roda
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item name="roda">
                                <Input disabled={!this.cekIsRequired("roda")} placeholder="cth: 2" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="space-between">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Warna Plat
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="warna_plat"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                {this.generateSelect(
                                  "WarnaPlat",
                                  configClass.apiUrl.warna_plat,
                                  "id",
                                  "nama",
                                  "kode"
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={3} style={{ textAlign: "right" }}>
                              No. BPKB
                            </Col>
                            <Col span={11} style={{ paddingLeft: 5 }}>
                              <Form.Item
                                name="no_bpkb"
                                rules={[
                                  {
                                    required: true,
                                    message: 'harus dipilih'
                                  }
                                ]}
                              >
                                <Input disabled={!this.cekIsRequired("no_bpkb")} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="space-between">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Tgl STNK
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item name="stnk_akhir_picker">
                                <DatePicker
                                  placeholder="dd-mm-yyyy"
                                  format="DD-MM-YYYY"
                                  style={{ width: "100%" }}
                                  onChange={(date, dateString) => {
                                    var currentDate = moment(date);
                                    var futureMonth = moment(currentDate).add(
                                      1,
                                      "Y"
                                    );
                                    var futureMonthEnd =
                                      moment(futureMonth).endOf("month");
                                    // var futureMonthString = moment(futureMonthEnd).format("DD-MM-YYYY");

                                    if (
                                      currentDate.date() != futureMonth.date() &&
                                      futureMonth.isSame(
                                        futureMonthEnd.format("YYYY-MM-DD")
                                      )
                                    ) {
                                      futureMonth = futureMonth.add(1, "d");
                                      // futureMonthString = moment(futureMonth).format("DD-MM-YYYY");
                                    }
                                    this.formRef.current.setFieldsValue({
                                      stnk_akhir: dateString,
                                      // tanggal_pembayaran_picker: futureMonth,
                                      // tanggal_pembayaran: futureMonthString
                                    });
                                  }}
                                  disabled={!this.cekIsRequired("stnk_akhir")}
                                />
                              </Form.Item>
                              <Form.Item
                                name="stnk_akhir"
                                style={{
                                  display: "none",
                                }}
                              >
                                <Input type="hidden" />
                              </Form.Item>
                            </Col>
                            <Col span={4} style={{ textAlign: "right" }}>
                              Tgl Pembayaran Terakhir
                            </Col>
                            <Col span={10} style={{ paddingLeft: 5 }}>
                              <Form.Item name="tanggal_pembayaran_picker">
                                <DatePicker
                                  placeholder="dd-mm-yyyy"
                                  format="DD-MM-YYYY"
                                  style={{ width: "100%" }}
                                  onChange={(date, dateString) => {
                                    this.formRef.current.setFieldsValue({
                                      tanggal_pembayaran: dateString,
                                    });
                                  }}
                                  disabled={!this.cekIsRequired("tanggal_pembayaran")}
                                />
                              </Form.Item>
                              <Form.Item
                                name="tanggal_pembayaran"
                                style={{
                                  display: "none",
                                }}
                              >
                                <Input type="hidden" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="space-between">
                            <Col span={3} style={{ textAlign: "right" }}>
                              Tgl PKB
                            </Col>
                            <Col span={7} style={{ paddingLeft: 5 }}>
                              <Form.Item name="sd_notice_picker">
                                <DatePicker
                                  placeholder="dd-mm-yyyy"
                                  format="DD-MM-YYYY"
                                  style={{ width: "100%" }}
                                  onChange={(date, dateString) => {
                                    this.formRef.current.setFieldsValue({
                                      sd_notice: dateString,
                                    });
                                  }}
                                  disabled={!this.cekIsRequired("sd_notice")}
                                />
                              </Form.Item>
                              <Form.Item
                                name="sd_notice"
                                style={{
                                  display: "none",
                                }}
                              >
                                <Input type="hidden" />
                              </Form.Item>
                            </Col>
                            <Col span={4} style={{ textAlign: "right" }}>
                              Tgl Akhir SWDKLLJ
                            </Col>
                            <Col span={10} style={{ paddingLeft: 5 }}>
                              <Form.Item name="sd_swdkllj_picker">
                                <DatePicker
                                  placeholder="dd-mm-yyyy"
                                  format="DD-MM-YYYY"
                                  style={{ width: "100%" }}
                                  onChange={(date, dateString) => {
                                    this.formRef.current.setFieldsValue({
                                      sd_swdkllj: dateString,
                                    });
                                  }}
                                  disabled={!this.cekIsRequired("sd_swdkllj")}
                                />
                              </Form.Item>
                              <Form.Item
                                name="sd_swdkllj"
                                style={{
                                  display: "none",
                                }}
                              >
                                <Input type="hidden" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="end">
                            <Col span={3} style={{ padding: 3 }}>
                              <Button
                                style={{ width: "100%" }}
                                key="btn-simpan"
                                loading={this.state.loadingButton}
                                type="primary"
                                htmlType="submit"
                              //disabled={this.state.errComponent ? true : false}
                              >
                                {!this.state.loadingButton && (
                                  <CheckCircleOutlined />
                                )}
                                Simpan
                              </Button>
                            </Col>
                            <Col span={3} style={{ padding: 3 }}>
                              <Button
                                type="danger"
                                key="btn-cancel"
                                disabled={this.state.loadingButton ? true : false}
                                style={{ width: "100%" }}
                                onClick={() => this.handleReset()}
                              >
                                <CloseCircleOutlined /> Reset
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Form>
            </Card>
          </div>
        </div>
        <Modal
          title="Riwayat Pemilik Kendaraan"
          visible={this.state.isModalRiwayat}
          footer={[]}
          closable={true}
          width={"80%"}
          onCancel={() => this.setState({ isModalRiwayat: false })}
        >
          <Table
            title={() =>
              <Row>
                <Col lg={8} sm={24} xs={24}><span className="konten-judul">Total {this.state.paginationRiwayat.total} record</span></Col>
                <Col lg={{ span: 8, offset: 8 }} sm={24} xs={24}>
                  <Input.Search
                    size="small"
                    placeholder="ketikkan pencarian"
                    onSearch={value => {
                      this.setState({
                        searchText: value
                      }, () => {
                        this.getDataRiwayat();
                      });
                    }}
                    allowClear
                  />
                </Col>
              </Row>
            }
            bordered
            columns={this.state.columnsRiwayat}
            dataSource={this.state.dataSourceRiwayat}
            loading={this.state.loadingDataRiwayat}
            scroll={{ x: 240, y: 450 }}
            size="small"
            pagination={this.state.paginationRiwayat}
            onChange={this.handleTableChangeRiwayat}
          />

        </Modal>

        <Modal
          title={this.state.dataModal != null ? this.state.dataModal.judul : ""}
          centered
          visible={this.state.visibleModal}
          // onOk={() => this.setVisible(false)}
          onCancel={() => this.setState({ visibleModal: false })}
          width={700}
          footer={[]}
        >

          <Row>
            <Col span={24}>

              {(this.state.dataModal != null && this.state.dataModal.is_flat == true && Object.entries(this.state.dataModal.konten.value).map(([key, value]) => (
                <Row style={{ borderBottom: "1px solid #F0F2F6" }}>
                  <Col span={12}>{config.toSpace(key).toUpperCase()}</Col>
                  <Col span={12}>
                    {Array.isArray(value) ? ": " + JSON.stringify(value) : ": " + (value == null ? "-" : value)}
                  </Col>
                </Row>
              )))}
            </Col>
          </Row>
          <Row justify="end">
            <Col style={{ padding: 5 }}>
              <Button
                type="danger"
                key="btn-cancel"
                size="small"
                onClick={() => this.setState({ visibleModal: false })}
              >
                <CloseCircleOutlined /> Tutup
              </Button>
            </Col>
          </Row>


        </Modal>

        <Modal
          title={"Konfirmasi"}
          centered
          visible={this.state.modalKonfirmasi}
          // onOk={() => this.setVisible(false)}
          onCancel={() => this.setState({ modalKonfirmasi: false })}
          width={"40%"}
          footer={[]}
        >

          <Row>
            <Col span={24}>
              {(this.state.dataKendaraan != null && this.state.dataKendaraan.tunggakan.is_popup != undefined && this.state.dataKendaraan.tunggakan.is_popup &&
                <Row>
                  <Col span={24}>
                    {this.state.dataKendaraan.tunggakan.message}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Row justify="end" style={{ paddingTop: 30 }}>
            <Col style={{ padding: 5 }}>
              <Button
                type="primary"
                key="btn-cancel"
                size="small"
                onClick={() => {
                  this.setState({
                    modalKonfirmasi: false
                  }, () => {
                    this.formRef.current.setFieldsValue({
                      is_next: true,
                    });
                  })
                }}
              >
                <CheckCircleOutlined /> Lanjut
              </Button>
            </Col>
            <Col style={{ padding: 5 }}>
              <Button
                type="danger"
                key="btn-cancel"
                size="small"
                onClick={() => this.setState({ modalKonfirmasi: false })}
              >
                <CloseCircleOutlined /> Tidak
              </Button>
            </Col>
          </Row>


        </Modal >

        <Modal
          title={"Kendaraan Diblokir"}
          centered
          visible={this.state.modalBlock}
          // onOk={() => this.setVisible(false)}
          onCancel={() => this.setState({ modalBlock: false, isOpenForm: false })}
          width={"40%"}
          footer={[]}
        >

          <Row>
            <Col span={24}>
              {(this.state.dataKendaraan != null &&
                <Row>
                  <Col span={24}>
                    <Alert
                      message="Kendaraan Diblokir"
                      description={this.state.dataKendaraan.alasan_blokir != undefined ? this.state.dataKendaraan.alasan_blokir : "Kendaraan dalam status Diblokir"}
                      type="error"
                      showIcon
                    />

                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Row justify="end" style={{ paddingTop: 30 }}>
            <Col style={{ padding: 5 }}>
              <Button
                type="primary"
                key="btn-cancel"
                size="small"
                onClick={() => {
                  this.setState({
                    modalBlock: false,
                    isOpenForm: false
                  })
                }}
              >
                <CheckCircleOutlined /> Tutup
              </Button>
            </Col>
          </Row>
        </Modal >
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  initHandler,
  setConfig,
  ajaxViewHandler,
  ajaxHandler,
})(FormPendaftaran);
