import React from 'react';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Input,
	Button,
	Table,
	Modal,
	message,
	Alert,
	Select,
	Tooltip,
	Spin,
	Form,
	Skeleton
} from 'antd';

import moment from 'moment';

import Config from '../../Config';
import { Helmet } from "react-helmet";
import queryString from 'query-string';

import '../../assets/laporan.css';


const configClass = {
	title: 'LAPORAN KASIR',
	apiUrl: {
		preview: 'laporan/api/kasir/',
		userProfile: "auth/api/user/info"
	},

}

class LapKasirCetak extends React.Component {
	config;

	constructor(props) {
		super(props);
		this.config = new Config();
		this.state = {
			loading: false,
			data: null,
			err: false,

			namauptppd: 'SAMSAT PALANGKARAYA',

			mulai_dari: "",
			sampai_dengan: "",
		}

		// this.preview(this.props.match.params.id);

	}

	componentDidMount() {
		var uri_params = "";
		const uri_string = queryString.parse(this.props.location.search);
		var uri_params = "";
		for (var key in uri_string) {
			if (uri_params == "") {
				uri_params = "?" + key + "=" + uri_string[key];
			} else {
				uri_params += "&" + key + "=" + uri_string[key];
			}
			if (key == "mulai_dari") {
				this.setState({
					mulai_dari: uri_string[key]
				})
			} else if (key == "sampai_dengan") {
				this.setState({
					sampai_dengan: uri_string[key]
				})
			}
		}
		this.preview(uri_params);
	}

	async preview(uri_params) {
		message.loading({ content: 'Menampilkan laporan...', duration: 0, key: 'toast' });
		this.setState({
			loading: true,
			err: false,
			data: null,
		});

		this.props.ajaxViewHandler('get', configClass.apiUrl.preview + uri_params)
			.then(() => {
				const data = this.props.responseMsg;
				if (this.props.isSuccess) {
					message.destroy();
					this.setState({
						loading: false,
						data: data,
						err: false
					});
					setTimeout(function () {
						window.print();

					}, 1300);
				} else {
					const errTitle = 'error get laporan';
					this.setState({
						loading: false,
						data: null,
						err: true,
						errMsg: this.props.responseMsg
					});
					message.error({ content: errTitle, duration: 2, key: 'toast' });
				}
			}).catch((response) => {
				this.setState({
					loading: false,
					data: null,
					err: true,
					errMsg: 'unknown response'
				});
				console.log('gagal_get_laporan', response);
				message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			});
	}

	render() {
		return (
			<>
				<Helmet>
					<title>{configClass.title}</title>
				</Helmet>
				<div className="kertas-laporan">
					<div className="judul-laporan" style={{ borderBottom: "double 5px #000000" }}>
						REKAPITULASI PENERIMAAN<br />
						TANGGAL : {this.state.mulai_dari == this.state.sampai_dengan ? this.state.mulai_dari : this.state.mulai_dari + " s/d " + this.state.sampai_dengan}
						<span style={{marginLeft: 10}}>PEMERINTAH PROPINSI KALIMANTAN TENGAH</span>
						<div className="teks-kanan">
							{this.state.data != null && this.state.data.ttd.kanan.nama}
						</div>
					</div>

					<table className="laporan-table" cellPadding="5" style={{ border: "1px dotted #000000" }}>
						<tr>
							<td>1. POKOK PKB</td>
							<td>Rp.</td>
							<td className="teks-kanan">{this.state.data != null && this.state.data.data.pkb.pokok.toLocaleString('id')}</td>
						</tr>
						<tr>
							<td>2. TUNGGAKAN PKB</td>
							<td>Rp.</td>
							<td className="teks-kanan">{this.state.data != null && this.state.data.data.pkb.tunggakan.toLocaleString('id')}</td>
						</tr>
						<tr>
							<td>3. DENDA PKB</td>
							<td>Rp.</td>
							<td className="teks-kanan">{this.state.data != null && this.state.data.data.pkb.denda.toLocaleString('id')}</td>
						</tr>
						<tr>
							<td>4. DENDA TUNGGAKAN PKB</td>
							<td>Rp.</td>
							<td className="teks-kanan">{this.state.data != null && this.state.data.data.pkb.denda_tunggakan.toLocaleString('id')}</td>
						</tr>
						<tr>
							<td className="teks-kanan">TOTAL PKB</td>
							<td>Rp.</td>
							<td className="teks-kanan">{this.state.data != null && this.state.data.data.pkb.jumlah.toLocaleString('id')}</td>
						</tr>

						<tr>
							<td>5. POKOK BBN I</td>
							<td>Rp.</td>
							<td className="teks-kanan">{this.state.data != null && this.state.data.data.bbnkbi.pokok.toLocaleString('id')}</td>
						</tr>
						<tr>
							<td>6. TUNGGAKAN BBN I</td>
							<td>Rp.</td>
							<td className="teks-kanan">{this.state.data != null && this.state.data.data.bbnkbi.tunggakan.toLocaleString('id')}</td>
						</tr>
						<tr>
							<td>7. DENDA BBN I</td>
							<td>Rp.</td>
							<td className="teks-kanan">{this.state.data != null && this.state.data.data.bbnkbi.denda.toLocaleString('id')}</td>
						</tr>
						<tr>
							<td>8. DENDA TUNGGAKAN BBN I</td>
							<td>Rp.</td>
							<td className="teks-kanan">{this.state.data != null && this.state.data.data.bbnkbi.denda_tunggakan.toLocaleString('id')}</td>
						</tr>
						<tr>
							<td className="teks-kanan">TOTAL BBN I</td>
							<td>Rp.</td>
							<td className="teks-kanan">{this.state.data != null && this.state.data.data.bbnkbi.jumlah.toLocaleString('id')}</td>
						</tr>


						<tr>
							<td>9. POKOK BBN II</td>
							<td>Rp.</td>
							<td className="teks-kanan">{this.state.data != null && this.state.data.data.bbnkbii.pokok.toLocaleString('id')}</td>
						</tr>
						<tr>
							<td>10. TUNGGAKAN BBN II</td>
							<td>Rp.</td>
							<td className="teks-kanan">{this.state.data != null && this.state.data.data.bbnkbii.tunggakan.toLocaleString('id')}</td>
						</tr>
						<tr>
							<td>11. DENDA BBN II</td>
							<td>Rp.</td>
							<td className="teks-kanan">{this.state.data != null && this.state.data.data.bbnkbii.denda.toLocaleString('id')}</td>
						</tr>
						<tr>
							<td>12. DENDA TUNGGAKAN BBN II</td>
							<td>Rp.</td>
							<td className="teks-kanan">{this.state.data != null && this.state.data.data.bbnkbii.denda_tunggakan.toLocaleString('id')}</td>
						</tr>
						<tr>
							<td className="teks-kanan">TOTAL BBN II</td>
							<td>Rp.</td>
							<td className="teks-kanan">{this.state.data != null && this.state.data.data.bbnkbii.jumlah.toLocaleString('id')}</td>
						</tr>


						<tr>
							<td>13. POKOK SWDKLLJ</td>
							<td>Rp.</td>
							<td className="teks-kanan">{this.state.data != null && this.state.data.data.swdkllj.pokok.toLocaleString('id')}</td>
						</tr>
						<tr>
							<td>14. DENDA SWDKLLJ</td>
							<td>Rp.</td>
							<td className="teks-kanan">{this.state.data != null && this.state.data.data.swdkllj.denda.toLocaleString('id')}</td>
						</tr>
						<tr>
							<td className="teks-kanan">TOTAL SWDKLLJ</td>
							<td>Rp.</td>
							<td className="teks-kanan">{this.state.data != null && this.state.data.data.swdkllj.jumlah.toLocaleString('id')}</td>
						</tr>

						<tr>
							<td>15. ADMINISTRASI STNK</td>
							<td>Rp.</td>
							<td className="teks-kanan">{this.state.data != null && this.state.data.data.adm_stnk.jumlah.toLocaleString('id')}</td>
						</tr>
						<tr>
							<td>16. ADMINISTRASI TNKB</td>
							<td>Rp.</td>
							<td className="teks-kanan">{this.state.data != null && this.state.data.data.adm_tnkb.jumlah.toLocaleString('id')}</td>
						</tr>
						<tr>
							<td className="teks-kanan">TOTAL PNBP</td>
							<td>Rp.</td>
							<td className="teks-kanan">{this.state.data != null && (this.state.data.data.adm_stnk.jumlah + this.state.data.data.adm_tnkb.jumlah).toLocaleString('id')}</td>
						</tr>

					</table>
					<Row>
						<Col span={3}>posting</Col>
						<Col span={9}>{this.state.data != null && moment(this.state.data.footer.posting).format("DD-MM-YYYY HH:mm:ss")}</Col>

						<Col span={6}>jumlah data transaksi online</Col>
						<Col span={6}>{this.state.data != null && this.state.data.footer.online.toLocaleString("ID")}</Col>

						<Col span={3}>printed</Col>
						<Col span={9}>{this.state.data != null && moment(this.state.data.footer.printed).format("DD-MM-YYYY HH:mm:ss")}</Col>

						<Col span={6}>total pajak kendaraan online</Col>
						<Col span={6}>{this.state.data != null && this.state.data.footer.total.toLocaleString("ID")}</Col>

					</Row>
				</div>
			</>
		)
	}

}

const mapStateToProps = function (state) {
	return state.configReducers
}

export default connect(
	mapStateToProps,
	{
		initHandler,
		setConfig,
		ajaxViewHandler,
		ajaxHandler,
		setConfig
	}
)(LapKasirCetak);