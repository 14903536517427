import React from 'react';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Input,
	Button,
	Table,
	Modal,
	message,
	Alert,
	Select,
	Tooltip,
	Spin,
	Form,
	Skeleton
} from 'antd';

import moment from 'moment';

import Config from '../../Config';
import { Helmet } from "react-helmet";
import queryString from 'query-string';

import '../../assets/laporan.css';


const configClass = {
	title: 'LAPORAN PER REKENING',
	apiUrl: {
		preview: 'laporan/api/sts_harian/',
		userProfile: "auth/api/user/info"
	},

}

class StssHarianCetak extends React.Component {
	config;

	constructor(props) {
		super(props);
		this.config = new Config();
		this.state = {
			loading: false,
			data: null,
			err: false,

			namauptppd: 'SAMSAT PALANGKARAYA',

			tgl_dari: ""
		}

		// this.preview(this.props.match.params.id);

	}

	componentDidMount() {
		var uri_params = "";
		const uri_string = queryString.parse(this.props.location.search);
		var uri_params = "";
		
		for (var key in uri_string) {
			if (uri_params == "") {
				uri_params = "?" + key + "=" + uri_string[key];
			} else {
				uri_params += "&" + key + "=" + uri_string[key];
			}
			if(key == "mulai_dari"){
				this.setState({
					tgl_dari: uri_string[key]
				})
			}
		}
		this.preview(uri_params);
	}

	async preview(uri_params) {
		message.loading({ content: 'Menampilkan laporan...', duration: 0, key: 'toast' });
		this.setState({
			loading: true,
			err: false,
			data: null,
		});

		this.props.ajaxViewHandler('get', configClass.apiUrl.preview + uri_params)
			.then(() => {
				const data = this.props.responseMsg;
				if (this.props.isSuccess) {
					message.destroy();
					this.setState({
						loading: false,
						data: data,
						err: false
					});
					setTimeout(function () {
						window.print();

					}, 1300);
				} else {
					const errTitle = 'error get laporan';
					this.setState({
						loading: false,
						data: null,
						err: true,
						errMsg: this.props.responseMsg
					});
					message.error({ content: errTitle, duration: 2, key: 'toast' });
				}
			}).catch((response) => {
				this.setState({
					loading: false,
					data: null,
					err: true,
					errMsg: 'unknown response'
				});
				console.log('gagal_get_laporan', response);
				message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			});
	}

	render() {
		return (
			<>
				<Helmet>
					<title>{configClass.title}</title>
				</Helmet>
				{(this.state.data != null && this.state.data.map((item, idx) => (
					<div className="kertas-laporan-a4 pagebreak">
						<div className="judul-laporan-sts" style={{ borderBottom: "double 5px #000000" }}>
							<img src={this.config.get_site_info().logo_bw} />
							PEMERINTAH PROPINSI KALIMANTAN TENGAH<br />
							SURAT TANDA SETORAN<br />
							(STS)
						</div>
						<Row>
							<Col span={13} className='setoran-ke'>
								Setoran yang ke : {item.nomor}
							</Col>
							<Col span={11} className='kepada'>
								<span className='samsat'>{item.kabupaten}</span><br />
								Kas Daerah Propinsi Kalimantan Tengah<br />
								di. - <br />
								PALANGKARAYA

							</Col>
						</Row>
						<div className='deskripsi'>
							Harap Menerima Uang Sebesar: {item.jumlah.toLocaleString("ID")}<br />
							{"# " + this.config.pembilang(item.jumlah) + " #"}<br />
							Yaitu Penerima: KAS UMUM DAERAH PROPINSI KALIMANTAN TENGAH NO. REK 1000101005004
						</div>

						{item.data.map((subItem, subIdx) => (
							<Row>
								<Col span={6}>{subItem.nomor_rekening}</Col>
								<Col span={6}>{subItem.nama}</Col>
								<Col span={6}>{subItem.jumlah + " Unit"}</Col>
								<Col span={6} className='samsat'>{subItem.total.toLocaleString("id")}</Col>
							</Row>
						))}

						<Row className='total-sts bold'>
							<Col span={6} offset={18}>
								{item.jumlah.toLocaleString("ID")}
							</Col>
						</Row>

						{(item.ttd != undefined &&
							<Row justify="space-between" style={{ marginTop: 10 }}>
								<Col span={24} className='text-center'>
									{item.kabupaten_asal}, TGL {this.state.tgl_dari}
								</Col>
								<Col span={8} className="teks-tengah">
									{item.ttd.ttd.kiri.jabatan}<br />
									<div style={{ marginTop: 50 }}>
										<u>{item.ttd.ttd.kiri.nama}</u><br />
										NIP : {item.ttd.ttd.kiri.nip}
									</div>
								</Col>
								<Col span={8} className="teks-tengah">
									{item.ttd.ttd.kanan.jabatan}<br />
									<div style={{ marginTop: 50 }}>
										<u>{item.ttd.ttd.kanan.nama}</u><br />
										NIP : {item.ttd.ttd.kanan.nip}
									</div>
								</Col>
								<Col span={8} className="teks-tengah">
									{item.kabupaten_asal},<br />
									{"TGL " + this.state.tgl_dari}
								</Col>

							</Row>
						)}

					</div>
				)))}
			</>
		)
	}

}

const mapStateToProps = function (state) {
	return state.configReducers
}

export default connect(
	mapStateToProps,
	{
		initHandler,
		setConfig,
		ajaxViewHandler,
		ajaxHandler,
		setConfig
	}
)(StssHarianCetak);