import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import store from './store/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";
import AuthLayout from "./layouts/AuthLayout.jsx";
import AdminLayout from "./layouts/AdminLayout.jsx";
import {PrivateRoute} from './PrivateRoute';

import CetakKutipanSuratKetetapanPajak from "./layouts/CetakKutipanSuratKetetapanPajak.jsx";
import CetakStnk from "./layouts/CetakStnk.jsx";
import SuratFiskal from "./layouts/SuratFiskal.jsx";

import NotaPajak from "./views/laporan/NotaPajak.jsx";
import BendaharawanKhususPenerimaanPajakBbnkb from "./views/laporan/BendaharawanKhususPenerimaanPajakBbnkb.jsx";
import LapNoticePrint from "./views/laporan/LapNoticePrint.jsx";
import LapNoticeRusakPrint from "./views/laporan/LapNoticeRusakPrint.jsx";

import LapKasirCetak from "./views/laporan/LapKasirCetak.jsx";
import LapPerRekeningCetak from "./views/laporan/LapPerRekeningCetak.jsx";

import LapCetakStnkCetak from "./views/laporan/LapCetakStnkCetak.jsx";
import LapBendaharaPenerimaanCetak from "./views/laporan/LapBendaharaPenerimaanCetak.jsx";
import LapPenetapanPkbCetak from "./views/laporan/LapPenetapanPkbCetak.jsx";
import LapPenerimaanPkbWarnaPlatCetak from "./views/laporan/LapPenerimaanPkbWarnaPlatCetak.jsx";

import LapStssCetak from "./views/laporan/LapStssCetak.jsx";
import StssHarianCetak from "./views/laporan/StssHarianCetak.jsx";

import LaporanPnbpCetak from "./views/laporan/LaporanPnbpCetak.jsx";

import PemutihanCetak from "./views/laporan/PemutihanCetak.jsx";


import { 
  Result,
  Button
} from 'antd';

import LapSwdklljCetak from './views/laporan/LapSwdklljCetak';

// import { initializeFirebase } from './push-notification';

ReactDOM.render(
  <Provider store={store}>
  	<BrowserRouter>
    	<Switch>
    		<Route path="/auth" component={AuthLayout} />

        <PrivateRoute path="/admin" component={AdminLayout} />
        
        <PrivateRoute exact path="/addon/cetak_kutipan_skp/:id" component={CetakKutipanSuratKetetapanPajak} />
        <PrivateRoute exact path="/addon/cetak_stnk/:id" component={CetakStnk} />
        <PrivateRoute exact path="/addon/surat_fiskal/:id" component={SuratFiskal} />

        <PrivateRoute exact path="/laporan/harian-nota-pajak" component={NotaPajak} />
        <PrivateRoute exact path="/laporan/bbnkb-pkb" component={BendaharawanKhususPenerimaanPajakBbnkb} />
        <PrivateRoute exact path="/laporan/notice" component={LapNoticePrint} />
        <PrivateRoute exact path="/laporan/notice-rusak" component={LapNoticeRusakPrint} />
        <PrivateRoute exact path="/laporan/kasir" component={LapKasirCetak} />
        <PrivateRoute exact path="/laporan/swdkllj" component={LapSwdklljCetak} />
        <PrivateRoute exact path="/laporan/perekening" component={LapPerRekeningCetak} />
        <PrivateRoute exact path="/laporan/cetak-stnk" component={LapCetakStnkCetak} />
        <PrivateRoute exact path="/laporan/bendahara-penerimaan" component={LapBendaharaPenerimaanCetak} />
        <PrivateRoute exact path="/laporan/harian-pkb" component={LapPenetapanPkbCetak} />
        <PrivateRoute exact path="/laporan/penerimaan-pkb-warna" component={LapPenerimaanPkbWarnaPlatCetak} />
        <PrivateRoute exact path="/laporan/stss" component={LapStssCetak} />

        <PrivateRoute exact path="/laporan/stss-harian" component={StssHarianCetak} />

        <PrivateRoute exact path="/laporan/pnbp" component={LaporanPnbpCetak} />

        <PrivateRoute exact path="/laporan/pemutihan" component={PemutihanCetak} />

    		<Route from="/" render={() => <Redirect to="/admin/index"/>}/>{/*live server must off*/}
        <Route path="" render={
            () => 
                <Result
                    status="404"
                    title={
                      <div>
                      404
                      </div>
                    }
                    subTitle={
                      <div>
                        <h1>Maaf, halaman tidak ditemukan</h1><br />
                        <img src={process.env.PUBLIC_URL + "/icon.svg"} height="50" /><br /><br />
                        <Link to="/">
                          <Button type="primary">
                            kembali ke halaman utama
                          </Button>
                        </Link>
                      </div>
                    }
                />
        } />
    	</Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// initializeFirebase();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
