import React from "react";
import { Link, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Breadcrumb,
  Card,
  Row,
  Col,
  Avatar,
  Form,
  Input,
  Button,
  Space,
  Select,
  DatePicker,
  message,
  Alert,
  Spin,
  Modal,
  Skeleton,
  Table,
  Tag,
  Divider
} from "antd";
import {
  DiffOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DollarOutlined,
  BarcodeOutlined,
  UserAddOutlined,
  PrinterOutlined,
  UserOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  CarOutlined,
  InfoCircleOutlined,
  SearchOutlined
} from "@ant-design/icons";
import moment from "moment";
import NumberFormat from "react-number-format";

import '../../assets/fiskal.css';

import axios from "axios";
import Config from "../../Config";

import { connect } from "react-redux";
import {
  initHandler,
  ajaxViewHandler,
  ajaxHandler,
  setConfig,
} from "../../store/actions";
import { Helmet } from "react-helmet";

const FormItem = Form.Item;



const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 20 },
};

const configClass = {
  title: "surat keterangan fiskal",
  apiUrl: {
    provinsi: "master/api/provinsi/",
    kabupaten: "master/api/kabupaten/",
    fiskal: "transaksi/api/tagihan/",
  },
  pageUrl: {
    cetak_no: "/addon/surat_fiskal/",
  },
  breadCrumb: [
    <span>
      <InfoCircleOutlined /> informasi
    </span>,
    "surat keterangan fiskal",
  ],
};

const config = new Config();

class SuratKeteranganFiskal extends React.Component {
  formRef = React.createRef();
  formRefScroll = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      errComponent: false,
      errTitle: "",
      errMsg: "",
      loadingButton: false,

      cari_no_pol: undefined,

      loadingData: false,

      loadingProvinsi: null,
      listProvinsi: [],

      loadingKabupaten: null,
      listKabupaten: [],

      aksi: "tambah",
      method: "post",

      dataKendaraan: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.initHandler();
  }

  componentDidMount() {
    this.loadMasterList();
  }

  searchKendaraan = (no_polisi) => {
    this.previewData(no_polisi);
  };

  loadMasterList() {
    const rule = [
      {
        destination: {
          stateProgressName: "loadingProvinsi",
          endPoint: configClass.apiUrl.provinsi,
          stateDestination: "listProvinsi",
        },
        nextDestination: null
        // nextDestination: {
        //   stateProgressName: "loadingKabupaten",
        //   endPoint: configClass.apiUrl.kabupaten,
        //   stateDestination: "listKabupaten",
        //   next: null,
        // },
      },
    ];

    this.setState({
      errComponent: false,
    });

    rule.map((item, index) => {
      if (index == rule.length - 1) {
        this.getMasterList(item, true);
      } else {
        this.getMasterList(item);
      }
    });
  }

  async getMasterList(rule, isFinal = false) {
    var destination = rule.destination;
    var nextDestination = rule.nextDestination;
    this.setState({
      [destination.stateProgressName]: true
    });
    this.props
      .ajaxViewHandler("get", destination.endPoint)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (data.count != undefined) {
            let newState = null;
            if (this.state[destination.stateDestination].length == 0) {
              newState = data;
            } else {
              if (destination.stateProgressName.includes("_searching")) {
                newState = data;
              } else {
                const oldState = this.state[destination.stateDestination];
                newState = {
                  count: data.count,
                  next: data.next,
                  previous: data.previous,
                  results: [...oldState.results, ...data.results],
                };
              }
            }
            this.setState({
              [destination.stateDestination]: newState,
            });
          } else if (data.results != undefined) {
            this.setState({
              [destination.stateDestination]: data.results,
            });
          } else {
            if (Array.isArray(data)) {
              this.setState({
                [destination.stateDestination]: data,
              });
            } else {
              this.setState({
                [destination.stateDestination]: [data],
              });
            }
          }
          if (isFinal) {
            if (this.props.match.params.id != undefined) {
              // this.preview(this.props.match.params.id);
            }
          }
        } else {
          this.setState({
            errComponent: true,
          });
        }
        this.setState({ [destination.stateProgressName]: false });
      })
      .catch((response) => {
        //console.log('gagal_get_master', response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({
          errTitle: "gagal request data master",
          errMsg: response,
        });
        this.setState({ [destination.stateProgressName]: false });
      });
    //next function
    if (nextDestination != null) {
      const next = {
        destination: {
          stateProgressName: nextDestination.stateProgressName,
          endPoint: nextDestination.endPoint,
          stateDestination: nextDestination.stateDestination,
        },
        nextDestination: nextDestination.next,
      };
      this.getMasterList(next);
    }
  }

  generateSelect(stateName, url, key, value, keyDisplay = "") {
    return this.state["loading" + stateName] ? (
      <img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
    ) : (
      <Select
        showSearch
        placeholder={"Cari / Pilih " + stateName}
        optionFilterProp="children"
        onSearch={(val) => {
          const rule = {
            destination: {
              stateProgressName: "loading" + stateName + "_searching",
              endPoint: url + "?search=" + val,
              stateDestination: "list" + stateName,
            },
            nextDestination: null,
          };
          this.getMasterList(rule);
        }}
        onPopupScroll={(e) => {
          e.persist();
          let target = e.target;
          if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (
              this.state["list" + stateName].next != undefined &&
              this.state["list" + stateName].next != null
            ) {
              const rule = {
                destination: {
                  stateProgressName: "loading" + stateName + "Paging",
                  endPoint: this.state["list" + stateName].next,
                  stateDestination: "list" + stateName,
                },
                nextDestination: null,
              };
              this.getMasterList(rule);
            }
          }
        }}
        allowClear
        style={{
          width: "100%",
        }}
        onChange={(val) => {
          if (stateName == "Provinsi") {
            const rule = {
              destination: {
                stateProgressName: "loadingKabupaten",
                endPoint: configClass.apiUrl.kabupaten + "?province=" + val,
                stateDestination: "listKabupaten",
              },
              nextDestination: null,
            };
            this.getMasterList(rule);
          }
        }}
      >
        {(this.state["loading" + stateName + "_searching"] == true &&
          <Select.Option value="">
            <Spin size="small" /> cari
          </Select.Option>
        )}
        {this.state["list" + stateName].count != undefined
          ? this.state["list" + stateName].results.map((item) => (
            <Select.Option value={item[key]}>
              {keyDisplay == ""
                ? item[key] + " - " + item[value]
                : item[keyDisplay] + " - " + item[value]}
            </Select.Option>
          ))
          : this.state["list" + stateName].map((item) => (
            <Select.Option value={item[key]}>
              {keyDisplay == ""
                ? item[key] + " - " + item[value]
                : item[keyDisplay] + " - " + item[value]}
            </Select.Option>
          ))}
        {this.state["list" + stateName] != undefined &&
          this.state["list" + stateName].next != undefined && (
            <Select.Option value="">
              <Spin size="small" /> si
            </Select.Option>
          )}
      </Select>
    );
  }

  async previewData(no_polisi = "") {
    message.loading({
      content: "Proses pencarian...",
      duration: 0,
      key: "toast",
    });

    this.setState({
      errTitle: '',
      errMsg: '',
      dataKendaraan: null,
      loadingData: true,
    });

    var url = no_polisi == "" ? configClass.apiUrl.penetapan : configClass.apiUrl.fiskal + "?is_paid=true&nomor_polisi=KH" + no_polisi

    this.props
      .ajaxViewHandler(
        "get",
        url
      )
      .then(() => {
        const datas = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (datas.results.length == 0) {
            message.error({ content: "nomor kendaraan tidak ditemukan", duration: 2, key: "toast" });
          } else {
            message.destroy();
            this.setState({
              dataKendaraan: datas.results[0]
            });
            const data = datas.results[0];
            this.formRefScroll.current.scrollTo(0, 0);
            for (var key in data) {
              const dateRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4}$/;
              const dateTimeRegex =
                /^[0-9]{2}\-[0-9]{2}\-[0-9]{4} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;

              const dateRegex2 = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
              const dateTimeRegex2 =
                /^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
              if (dateRegex.test(data[key])) {
                //jika format tanggal
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key], "DD-MM-YYYY"),
                  //[key]: moment(data[key], 'DD-MM-YYYY')
                });
              } else if (dateTimeRegex.test(data[key])) {
                //jika format tanggal dan jam
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key], "DD-MM-YYYY HH:mm:ss"),
                });
              } else if (dateRegex2.test(data[key])) {
                //jika format tanggal
                console.log(key, data[key]);
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key], "DD-MM-YYYY"),
                  [key]: moment(data[key]).format('DD-MM-YYYY')
                });
              } else if (dateTimeRegex2.test(data[key])) {
                //jika format tanggal dan jam
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key], "DD-MM-YYYY HH:mm:ss"),
                });
              } else {
                if (key == "pemilik_nama") {
                  this.formRef.current.setFieldsValue({
                    nama: data[key],
                  });
                } else if (key == "alamat_wp") {
                  this.formRef.current.setFieldsValue({
                    alamat: data[key],
                  });
                } else if (key == "merk_kendaraan") {
                  this.formRef.current.setFieldsValue({
                    merek: data[key],
                  });
                } else if (key == "tahun_buat") {
                  this.formRef.current.setFieldsValue({
                    tahun: data[key],
                  });
                }
                else if (key == "cc") {
                  this.formRef.current.setFieldsValue({
                    silinder: data[key],
                  });
                }
                else if (key == "bahan_bakar_nama") {
                  this.formRef.current.setFieldsValue({
                    bahan_bakar: data[key],
                  });
                }
                else if (key == "tipe_nama") {
                  this.formRef.current.setFieldsValue({
                    tipe: data[key],
                  });
                }
                else if (key == "pemilik_no_hp") {
                  this.formRef.current.setFieldsValue({
                    no_hp: data[key],
                  });
                } else if (key == "pemilik_nik") {
                  this.formRef.current.setFieldsValue({
                    nik: data[key],
                  });
                } else if (key == "nomor_polisi_tengah") {
                  this.formRef.current.setFieldsValue({
                    no_polisi_tengah: data[key],
                  });
                } else if (key == "nomor_polisi_akhir") {
                  this.formRef.current.setFieldsValue({
                    no_polisi_belakang: data[key],
                  });
                } else if (key == "stnk_akhir" && data[key] != null) {
                  this.formRef.current.setFieldsValue({
                    [key + "_picker"]: moment(data[key]),// "DD-MM-YYYY"),
                    [key]: moment(data[key]).format("DD-MM-YYYY"),

                    tgl_akhir_swdkllj_picker: moment(data[key]),// "DD-MM-YYYY"),
                    tgl_akhir_swdkllj: moment(data[key]),// "DD-MM-YYYY"),
                  });
                } else if (key == "stnk_mulai" && data[key] != null) {
                  this.formRef.current.setFieldsValue({
                    [key + "_picker"]: moment(data[key]),// "DD-MM-YYYY"),
                    [key]: moment(data[key]).format("DD-MM-YYYY")
                  });
                } else if ((key == "tanggal_faktur" && data[key] != null) || (key == "tanggal_kwitansi" && data[key] != null)) {
                  this.formRef.current.setFieldsValue({
                    [key + "_picker"]: moment(data[key]),// "DD-MM-YYYY"),
                    [key]: moment(data[key]).format("DD-MM-YYYY")
                  });
                } else if (key == "kode_tujuan") {
                  this.formRef.current.setFieldsValue({
                    [key]: undefined
                  });
                } else {
                  this.formRef.current.setFieldsValue({
                    [key]: data[key],
                  });
                }
              }
            }
            //end	          
          }
        } else {
          const errTitle = "error preview data lama";
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
          message.error({ content: errTitle, duration: 2, key: "toast" });
        }
        this.setState({
          loadingData: false
        });
      })
      .catch((response) => {
        console.log("gagal_get_preview", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({
          loadingData: false
        });
      });
  }

  handleSubmit = (values) => {
    this.postDataPenetapan(values);
  };

  reset = () => {
    this.setState({
      cari_no_pol: undefined,
      isOpenForm: false,
    });
  };

  async postDataPenetapan(datas) {
    message.loading({ content: "Memproses...", duration: 0, key: "toast" });
    this.setState({
      errTitle: "",
      errMsg: "",
      loadingButton: true,
    });
    this.props
      .ajaxHandler("post", configClass.apiUrl.fiskal + this.state.dataKendaraan.id + "/fiskal/", datas, false, true)
      .then(() => {
        if (this.props.isSuccess) {
          var response = this.props.responseMsg;
          var that = this;

          message.success({ content: 'berhasil menyimpan data fiskal', duration: 2, key: 'toast' });
          this.setState({
            loadingButton: false,
          });

          Modal.confirm({
            title: 'Berhasil Menyimpan Fiskal',
            icon: <CheckCircleOutlined style={{ color: 'green' }} />,
            content: 'tekan cetak untuk mencetak, tekan selesai untuk melanjutkan',
            okText: <a href={configClass.pageUrl.cetak_no + this.state.dataKendaraan.id} target="_blank"><PrinterOutlined /> Cetak</a>,
            cancelText: 'Selesai',
            onOk() {
              that.reset()
            },
            onCancel() {
              that.reset()
            }
          });

        } else {
          const errTitle = "gagal menyimpan data fiskal";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            loadingButton: false,
            errTitle: errTitle,
            errMsg: JSON.stringify(this.props.responseMsg),
          });
        }
      })
      .catch((response) => {
        console.log("gagal_post_fiskal", response);
        this.setState({
          loadingButton: false,
        });
        message.error({
          content: "kesalahan pengiriman data fiskal, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  onReset = () => {
    // this.formRef.current!.resetFields();
  };

  // async postDataKendaraan(datas, idPemilik) {
  //   var dataKendaraan = datas;
  //   dataKendaraan.pemilik = idPemilik;
  //   dataKendaraan.nomor_polisi =
  //     datas.no_polisi_depan + datas.no_polisi_tengah + datas.no_polisi_belakang;
  //   dataKendaraan.nomor_polisi_lama =
  //     datas.no_polisi_depan_lama +
  //     datas.no_polisi_tengah_lama +
  //     datas.no_polisi_belakang_lama;

  //   message.loading({ content: "Memproses...", duration: 0, key: "toast" });
  //   this.props
  //     .ajaxHandler(
  //       this.state.method,
  //       configClass.apiUrl.kendaraan + this.state.idLama,
  //       dataKendaraan,
  //       true,
  //       true
  //     )
  //     .then(() => {
  //       if (this.props.isSuccess) {
  //         message.success({
  //           content: "berhasil menyimpan pendaftaran",
  //           duration: 2,
  //           key: "toast",
  //         });
  //         this.setState({
  //           loadingButton: false,
  //         });
  //       } else {
  //         const errTitle = "gagal menyimpan data kendaraan";
  //         message.error({ content: errTitle, duration: 2, key: "toast" });
  //         this.setState({
  //           loadingButton: false,
  //           errTitle: errTitle,
  //           errMsg: JSON.stringify(this.props.responseMsg),
  //         });
  //       }
  //     })
  //     .catch((response) => {
  //       console.log("gagal_post_kendaraan", response);
  //       this.setState({
  //         loadingButton: false,
  //       });
  //       message.error({
  //         content: "kesalahan pengiriman data kendaraan, coba lagi",
  //         duration: 2,
  //         key: "toast",
  //       });
  //     });
  // }

  render() {
    // if(this.props.layanan_str.length>0 && this.props.layanan_str.includes('Pembelian / Pemesanan Obat') == false){
    // 	return <Redirect to="/admin/forbidden" />
    // }
    // if(this.props.isRedirect){
    // 	return <Redirect to={configClass.pageUrl.data} />
    // }

    return (
      <div>
        <Helmet>
          <title>
            {configClass.title + " | " + config.get_site_info().name}
          </title>
        </Helmet>
        <Breadcrumb>
          {configClass.breadCrumb.map((item) => (
            <Breadcrumb.Item>{item}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <div className="card" id="panel-transaksi">
          <Card
            className="konten"
            title={null}
          >

            <Row className="isi">
              {this.state.errTitle != null && this.state.errTitle != "" && (
                <Alert
                  message={this.state.errTitle}
                  description={this.state.errMsg}
                  type="error"
                  showIcon
                  style={{
                    marginBottom: 10,
                    width: "100%"
                  }}
                />
              )}

              <Col span={24}>
                <Row justify="start">
                  <Col span={24}>
                    <Input.Group compact>
                      <Input
                        allowClear
                        style={{ width: "95%" }}
                        placeholder="input nomer polisi, cth : KH1234XY"
                        prefix="KH"
                        onChange={(e) => {
                          this.setState({
                            cari_no_pol: e.target.value,
                          });
                        }}
                        value={this.state.cari_no_pol}
                        name="cari_no_pol"
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            this.searchKendaraan(this.state.cari_no_pol);
                          }
                        }}
                      />
                      <Form.Item name="cari" style={{ width: "5%" }}>
                        <Button
                          style={{ width: "100%" }}
                          key="btn-cari-kendaraan"
                          loading={this.state.loadingData}
                          type="default"
                          htmlType="button"
                          size="normal"
                          onClick={() => this.searchKendaraan(this.state.cari_no_pol)}
                        >
                          {!this.state.loadingData && <SearchOutlined />}
                        </Button>
                      </Form.Item>


                    </Input.Group>
                    <Divider />
                  </Col>
                  <Col span={24}>
                    {(this.state.dataKendaraan != null &&
                      <Form
                        layout="horizontal"
                        name="fdata"
                        onFinish={this.handleSubmit}
                        size="small"
                        ref={this.formRef}
                        initialValues={{
                          kode_tujuan: "1",
                          no_polisi_depan: "KH",
                        }}
                      >
                        <Col
                          span={24}
                          style={{
                            overflowY: "auto",
                            maxHeight: "calc(100vh - 290px)",
                            // background: "rgb(11,93,37, 0.2)", 
                            padding: 10,
                            // boxShadow: "5px 10px"
                          }}
                          ref={this.formRefScroll}
                        >
                          <Row className="form-penetapan-biaya">
                            <Col span={24}>
                              <Row justify="start">
                                <Col span={3} style={{ textAlign: "right" }}>
                                  Nomor Polisi
                                </Col>
                                <Col span={1} style={{ paddingLeft: 5 }}>
                                  <Form.Item name="no_polisi_depan">
                                    <Input disabled />
                                  </Form.Item>
                                </Col>
                                <Col span={3} style={{ paddingLeft: 5 }}>
                                  <Form.Item name="no_polisi_tengah">
                                    <Input disabled />
                                  </Form.Item>
                                </Col>
                                <Col span={1} style={{ paddingLeft: 5 }}>
                                  <Form.Item name="no_polisi_belakang">
                                    <Input disabled />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={24}>
                              <Row justify="start">
                                <Col span={3} style={{ textAlign: "right" }}>
                                  Nama Pemilik
                                </Col>
                                <Col span={21} style={{ paddingLeft: 5 }}>
                                  <Form.Item
                                    name="nama"
                                  >
                                    <Input disabled />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={24}>
                              <Row justify="start">
                                <Col span={3} style={{ textAlign: "right" }}>
                                  Alamat
                                </Col>
                                <Col span={21} style={{ paddingLeft: 5 }}>
                                  <Form.Item
                                    name="alamat"
                                  >
                                    <Input disabled />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={24}>
                              <Row justify="start">
                                <Col span={3} style={{ textAlign: "right" }}>
                                  Jenis
                                </Col>
                                <Col span={21} style={{ paddingLeft: 5 }}>
                                  <Form.Item
                                    name="jenis_kendaraan"
                                  >
                                    <Input disabled />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={24}>
                              <Row justify="start">
                                <Col span={3} style={{ textAlign: "right" }}>
                                  Tahun
                                </Col>
                                <Col span={10} style={{ paddingLeft: 5 }}>
                                  <Form.Item
                                    name="tahun"
                                  >
                                    <Input disabled />
                                  </Form.Item>
                                </Col>
                                <Col span={3} style={{ textAlign: "right" }}>
                                  Isi Cyclinder
                                </Col>
                                <Col span={8} style={{ paddingLeft: 5 }}>
                                  <Form.Item
                                    name="silinder"
                                  >
                                    <Input disabled />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={24}>
                              <Row justify="start">
                                <Col span={3} style={{ textAlign: "right" }}>
                                  Merek
                                </Col>
                                <Col span={10} style={{ paddingLeft: 5 }}>
                                  <Form.Item
                                    name="merek"
                                  >
                                    <Input disabled />
                                  </Form.Item>
                                </Col>
                                <Col span={3} style={{ textAlign: "right" }}>
                                  Type
                                </Col>
                                <Col span={8} style={{ paddingLeft: 5 }}>
                                  <Form.Item
                                    name="tipe"
                                  >
                                    <Input disabled />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={24}>
                              <Row justify="start">
                                <Col span={3} style={{ textAlign: "right" }}>
                                  No Rangka
                                </Col>
                                <Col span={10} style={{ paddingLeft: 5 }}>
                                  <Form.Item
                                    name="nomor_rangka"
                                  >
                                    <Input disabled />
                                  </Form.Item>
                                </Col>
                                <Col span={3} style={{ textAlign: "right" }}>
                                  Plat Dasar
                                </Col>
                                <Col span={8} style={{ paddingLeft: 5 }}>
                                  <Form.Item
                                    name="warna_plat_nama"
                                  >
                                    <Input disabled />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={24}>
                              <Row justify="start">
                                <Col span={3} style={{ textAlign: "right" }}>
                                  No Mesin
                                </Col>
                                <Col span={10} style={{ paddingLeft: 5 }}>
                                  <Form.Item
                                    name="nomor_mesin"
                                  >
                                    <Input disabled />
                                  </Form.Item>
                                </Col>
                                <Col span={3} style={{ textAlign: "right" }}>
                                  Warna Kendaraan
                                </Col>
                                <Col span={8} style={{ paddingLeft: 5 }}>
                                  <Form.Item
                                    name="warna"
                                  >
                                    <Input disabled />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={24}>
                              <Row justify="start">
                                <Col span={3} style={{ textAlign: "right" }}>
                                  Bahan Bakar
                                </Col>
                                <Col span={10} style={{ paddingLeft: 5 }}>
                                  <Form.Item
                                    name="bahan_bakar"
                                  >
                                    <Input disabled />
                                  </Form.Item>
                                </Col>
                                {/* <Col span={3} style={{ textAlign: "right" }}>
                                  Nomor STNK
                                </Col>
                                <Col span={8} style={{ paddingLeft: 5 }}>
                                  <Form.Item
                                    name="nomor_stnk" 
                                  > 
                                    <Input disabled />
                                  </Form.Item>
                                </Col> */}
                              </Row>
                            </Col>
                            <Col span={24}>
                              <Row justify="start">
                                <Col span={3}></Col>
                                <Col span={21} style={{ paddingLeft: 5, paddingBottom: 5 }}>
                                  <Alert message="Keterangan ini diberikan berhubung kendaraan tersebut akan dipindahkan ke" type="info" />
                                </Col>
                              </Row>
                            </Col>
                            <Col span={24}>
                              <Col span={24}>
                                <Row justify="start">
                                  <Col span={3} style={{ textAlign: "right" }}>
                                    No Surat
                                  </Col>
                                  <Col span={21} style={{ paddingLeft: 5 }}>
                                    <Form.Item
                                      name="nomor_surat"
                                      rules={[
                                        {
                                          required: true,
                                          message: 'harus diisi'
                                        }
                                      ]}
                                    >
                                      <Input autoFocus placeholder="input nomor surat" style={{ textTransform: "none" }} />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Col>
                              <Row justify="start">
                                <Col span={3} style={{ textAlign: "right" }}>
                                  Kode Tujuan
                                </Col>
                                <Col span={21} style={{ paddingLeft: 5 }}>
                                  <Form.Item
                                    name="kode_tujuan"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'harus diisi'
                                      }
                                    ]}
                                  >
                                    <Select
                                      placeholder="Pilih"
                                      optionFilterProp="children"
                                      allowClear
                                    >
                                      <Select.Option value={"1"}>1 - Antar Provinsi</Select.Option>
                                      <Select.Option value={"2"}>2 - Dalam Provinsi</Select.Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={24}>
                              <Row justify="start">
                                <Col span={3} style={{ textAlign: "right" }}>
                                  Provinsi
                                </Col>
                                <Col span={21} style={{ paddingLeft: 5 }}>
                                  <Form.Item
                                    name="provinsi"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'harus diisi'
                                      }
                                    ]}
                                  >
                                    {this.generateSelect(
                                      "Provinsi",
                                      configClass.apiUrl.provinsi,
                                      "id",
                                      "name"
                                    )}
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={24}>
                              <Row justify="start">
                                <Col span={3} style={{ textAlign: "right" }}>
                                  Kabupaten
                                </Col>
                                <Col span={21} style={{ paddingLeft: 5 }}>
                                  <Form.Item
                                    name="kabupaten"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'harus diisi'
                                      }
                                    ]}
                                  >
                                    {this.generateSelect(
                                      "Kabupaten",
                                      configClass.apiUrl.kabupaten,
                                      "id",
                                      "name"
                                    )}
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={24}>
                              <Row justify="start">
                                <Col span={3} style={{ textAlign: "right" }}>
                                  Nama
                                </Col>
                                <Col span={21} style={{ paddingLeft: 5 }}>
                                  <Form.Item
                                    name="nama_tujuan"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'harus diisi'
                                      }
                                    ]}
                                  >
                                    <Input placeholder="input nama" style={{ textTransform: "none" }} />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={24}>
                              <Row justify="start">
                                <Col span={3} style={{ textAlign: "right" }}>
                                  Alamat Tujuan
                                </Col>
                                <Col span={21} style={{ paddingLeft: 5 }}>
                                  <Form.Item
                                    name="alamat_tujuan"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'harus diisi'
                                      }
                                    ]}
                                  >
                                    <Input placeholder="input alamat tujuan" style={{ textTransform: "none" }} />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={24}>
                              <Row justify="start">
                                <Col span={3} style={{ textAlign: "right" }}>
                                  Tanggal Fiskal
                                </Col>
                                <Col span={21} style={{ paddingLeft: 5 }}>
                                  <Form.Item
                                    name="tanggal_fiskal_picker"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'harus diisi'
                                      }
                                    ]}
                                  >
                                    <DatePicker
                                      placeholder="dd-mm-yyyy"
                                      format="DD-MM-YYYY"
                                      style={{ width: "100%" }}
                                      onChange={(date, dateString) => {
                                        this.formRef.current.setFieldsValue({
                                          tanggal_fiskal: dateString,
                                        });
                                      }}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    name="tanggal_fiskal"
                                    style={{
                                      display: "none",
                                    }}
                                  >
                                    <Input type="hidden" />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={24}>
                              <Row justify="start">
                                <Col span={3} style={{ textAlign: "right" }}></Col>
                                <Col span={21} style={{ paddingLeft: 5 }}>
                                  <Button type="primary" htmlType="submit">
                                    Simpan
                                  </Button>

                                </Col>
                              </Row>
                            </Col>

                          </Row>
                        </Col>
                      </Form>
                    )}
                  </Col>
                </Row>
              </Col>


            </Row>

          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  initHandler,
  setConfig,
  ajaxViewHandler,
  ajaxHandler,
})(SuratKeteranganFiskal);
