import React from 'react';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Input,
	Button,
	Table,
	Modal,
	message,
	Alert,
	Select,
	Tooltip,
	Spin,
	Form,
	Skeleton
} from 'antd';

import moment from 'moment';

import Config from '../../Config';
import {Helmet} from "react-helmet";
import queryString from 'query-string';

import '../../assets/laporan.css';


const configClass = {
	title: 'LAPORAN PER REKENING',
	apiUrl: {
		preview: 'laporan/api/sts/',
		userProfile: "auth/api/user/info"
	},

}

class LapStssCetak extends React.Component {
	config;
	
	constructor(props){
	    super(props);
	    this.config = new Config();
	    this.state = {
	    	loading: false,
	    	data: null,
	    	err: false,

	    	namauptppd: 'SAMSAT PALANGKARAYA',
	    }

	    // this.preview(this.props.match.params.id);
	    
	}

	componentDidMount(){
		var uri_params = "";
		const uri_string = queryString.parse(this.props.location.search);
		var uri_params = "";
		for (var key in uri_string) {
			if(uri_params == ""){
				uri_params = "?"+key+"="+uri_string[key];
			}else{
				uri_params += "&"+key+"="+uri_string[key];
			}
		}
		this.preview(uri_params);
	}

	async preview(uri_params) {
		message.loading({ content: 'Menampilkan laporan...', duration: 0, key: 'toast' });
		this.setState({
			loading: true,
			err: false,
			data: null,
		});

		this.props.ajaxViewHandler('get', configClass.apiUrl.preview+uri_params)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				message.destroy();
				this.setState({
					loading: false,
					data: data,
					err: false
				});
				setTimeout(function(){
					window.print();

				}, 1300);
	        }else{
	        	const errTitle = 'error get laporan';
	        	this.setState({
	        		loading: false,
	        		data: null,
	        		err: true,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
		}).catch((response) => {
			this.setState({
	        		loading: false,
	        		data: null,
	        		err: true,
	        		errMsg: 'unknown response'
	        });
			console.log('gagal_get_laporan', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	render() {		
    	return (
			<>
			  	<Helmet>
			  		<title>{configClass.title}</title>
			  	</Helmet>
			  	<div className="kertas-laporan">
			  		{(this.state.data != null &&
					        		<div className="kertas-laporan">
									  		<div className="judul-laporan" style={{borderBottom: "double 5px #000000"}}>
									  			REKAPITULASI SETORAN PENERIMAAN<br />
									  			BADAN PENDAPATAN DAERAH PROPINSI KALIMANTAN TENGAH<br />
									  			UPTTPD PALANGKARAYA
									  		</div>

									  		<table className="laporan-table" cellPadding="5" style={{border: "1px solid #000000"}}>
									  			<tr style={{borderBottom: "1px solid #000000"}}>
									  				<th style={{borderRight: "1px solid #000000"}}>KODE REKENING</th>
									  				<th style={{borderRight: "1px solid #000000"}}>URAIAN</th>
									  				<th style={{borderRight: "1px solid #000000"}}>JUMLAH PENERIMAAN</th>
									  				<th>KET</th>
									  			</tr>
									  			{this.state.data.map((item, idx) => (
									  				item.parent_rekening != undefined
									  				?
										  				<>
										  					<tr>
										  						<td style={{borderRight: "1px solid #000000"}}>{item.parent_rekening}</td>
										  						<td style={{borderRight: "1px solid #000000", fontWeight: 'bold'}}>{item.parent_nama}</td>
										  						<td style={{borderRight: "1px solid #000000"}}></td>
										  						<td></td>
										  					</tr>
										  					{item.parent_child != undefined && item.parent_child.map((itemChild, idxChild) => (
										  						<>
											  						<tr>
											  							<td style={{borderRight: "1px solid #000000"}}>{itemChild.sub_parent_rekening}</td>
											  							<td style={{borderRight: "1px solid #000000", fontWeight: 'bold'}}>{itemChild.sub_parent_nama}</td>
											  							<td style={{borderRight: "1px solid #000000"}}></td>
											  							<td></td>
											  						</tr>
											  						{itemChild.sub_parent_child != undefined && itemChild.sub_parent_child.map((itemSubChild, idxSubChild) => (
											  							<>
													  						{(itemSubChild.nomor_rekening != undefined &&
														  						<tr>
														  							<td style={{borderRight: "1px solid #000000"}}>{itemSubChild.nomor_rekening}</td>
														  							<td style={{borderRight: "1px solid #000000"}}>{itemSubChild.nama}</td>
														  							<td style={{borderRight: "1px solid #000000"}}>{itemSubChild.total.toLocaleString('id')}</td>
														  							<td style={{borderRight: "1px solid #000000"}}>{itemSubChild.jumlah+" Unit"}</td>
														  						</tr>
														  					)}
														  					{(itemSubChild.label_jumlah != undefined &&
														  						<tr>
														  							<td style={{borderRight: "1px solid #000000"}}></td>
														  							<td style={{borderRight: "1px solid #000000", fontWeight: 'bold'}}>{itemSubChild.label_jumlah}</td>
														  							<td style={{borderRight: "1px solid #000000", borderTop: "1px dotted #000000", borderBottom: "1px dotted #000000", fontWeight:'bold'}}>{itemSubChild.jumlah.toLocaleString('id')}</td>
														  							<td style={{borderRight: "1px solid #000000"}}></td>
														  						</tr>
														  					)}
													  					</>
												  					))}
											  					</>
										  					))}
									  					</>
									  				: 
									  					<>
									  						{(item.total != undefined &&
									  							<>
										  							<tr>
												  						<td style={{borderRight: "1px solid #000000", paddingTop: 30}}></td>
												  						<td style={{borderRight: "1px solid #000000", fontWeight: 'bold', paddingTop: 30}}>JUMLAH SETORAN SELURUHNYA</td>
												  						<td style={{borderRight: "1px solid #000000", fontWeight: 'bold', paddingTop: 30}}>{item.total.toLocaleString('id')}</td>
												  						<td></td>
												  					</tr>
												  					<tr>
												  						<td style={{borderRight: "1px solid #000000"}}></td>
												  						<td style={{borderRight: "1px solid #000000", fontWeight: 'bold'}}>JUMLAH DATA TRANSAKSI ONLINE</td>
												  						<td style={{borderRight: "1px solid #000000", fontWeight: 'bold'}}>{item.jumlah}</td>
												  						<td></td>
												  					</tr>
												  				</>
									  						)}
									  					</>
									  			))}	
									  			</table>
									  	</div>
					        	)}								  		
				</div>
			</>
    	)
    }

}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler,
			setConfig
		}
	)(LapStssCetak);