import React from 'react';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Input,
	Button,
	Table,
	Modal,
	message,
	Alert,
	Select,
	Tooltip,
	Spin,
	Form,
	Skeleton
} from 'antd';

import moment from 'moment';

import Config from '../../Config';
import { Helmet } from "react-helmet";
import queryString from 'query-string';

import '../../assets/laporan.css';


const configClass = {
	title: 'LAPORAN PNBP',
	apiUrl: {
		preview: 'laporan/api/penetapan/',
		userProfile: "auth/api/user/info"
	},

}

class LapPenetapanPkbCetak extends React.Component {
	config;

	constructor(props) {
		super(props);
		this.config = new Config();
		this.state = {
			loading: false,
			data: null,
			err: false,

			namauptppd: 'SAMSAT PALANGKARAYA',
		}

		// this.preview(this.props.match.params.id);

	}

	componentDidMount() {
		var uri_params = "";
		const uri_string = queryString.parse(this.props.location.search);
		var uri_params = "";
		for (var key in uri_string) {
			if (uri_params == "") {
				uri_params = "?" + key + "=" + uri_string[key];
			} else {
				uri_params += "&" + key + "=" + uri_string[key];
			}
		}
		this.preview(uri_params);
	}

	async preview(uri_params) {
		message.loading({ content: 'Menampilkan laporan...', duration: 0, key: 'toast' });
		this.setState({
			loading: true,
			err: false,
			data: null,
		});

		this.props.ajaxViewHandler('get', configClass.apiUrl.preview + uri_params)
			.then(() => {
				const data = this.props.responseMsg;
				if (this.props.isSuccess) {
					message.destroy();
					this.setState({
						loading: false,
						data: data,
						err: false
					});
					setTimeout(function () {
						window.print();

					}, 1300);
				} else {
					const errTitle = 'error get laporan';
					this.setState({
						loading: false,
						data: null,
						err: true,
						errMsg: this.props.responseMsg
					});
					message.error({ content: errTitle, duration: 2, key: 'toast' });
				}
			}).catch((response) => {
				this.setState({
					loading: false,
					data: null,
					err: true,
					errMsg: 'unknown response'
				});
				console.log('gagal_get_laporan', response);
				message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			});
	}

	render() {
		var total_pokok_bbnkb = 0;
		var total_denda_bbnkb = 0;
		var total_total_bbnkb = 0;

		var total_pokok_pkb = 0;
		var total_denda_pkb = 0;
		var total_total_pkb = 0;

		var total_pokok_swdkllj = 0;
		var total_denda_swdkllj = 0;
		var total_total_swdkllj = 0;

		var no = 0;
		return (
			<>
				<Helmet>
					<title>{configClass.title}</title>
				</Helmet>
				{(this.state.data != null &&
					<div className="kertas-laporan-besar">
						<div className="judul-laporan teks-tengah">
							LAPORAN HARIAN PENETAPAN PKB DAN BBNKB<br />
							KEPOLISIAN NEGARA REPUBLIK INDONESIA<br />
							DIREKTORAT LALU LINTAS<br />
							DAERAH KALIMANTAN TENGAH
						</div>
						<table className="laporan-table" border="1" cellPadding="5">
							<thead>
								<tr>
									<th rowspan="2">No</th>
									<th rowspan="2">NAMA DAN ALAMAT PEMILIK</th>
									<th rowspan="2">JATUH<br />TEMPO</th>
									<th rowspan="2">NO POLISI</th>
									<th rowspan="2">JENIS</th>
									<th rowspan="2">MERK DAN TIPE</th>
									<th rowspan="2">THN</th>
									<th colspan="3">BBN KB</th>
									<th colspan="3">PKB</th>
									<th colspan="3">SWDKLLJ</th>
								</tr>
								<tr>
									<th>POKOK</th>
									<th>DENDA</th>
									<th>TOTAL</th>
									<th>POKOK</th>
									<th>DENDA</th>
									<th>TOTAL</th>
									<th>POKOK</th>
									<th>DENDA</th>
									<th>TOTAL</th>
								</tr>
							</thead>
							<tbody>
								{(this.state.data != null && this.state.data.data.map((item, id) => (
									// (total_pokok_bbnkb += item.pokok_bbnkb) &&
									// (total_denda_bbnkb += item.denda_bbnkb) &&
									// (total_total_bbnkb += (item.pokok_bbnkb+item.denda_bbnkb)) &&

									// (total_pokok_pkb += item.pokok_pkb) &&
									// (total_denda_pkb += item.denda_pkb) &&
									// (total_total_pkb += (item.pokok_pkb+item.denda_pkb)) &&

									// (total_pokok_swdkllj += item.pokok_swdkllj) &&
									// (total_denda_swdkllj += item.denda_swdkllj) &&
									// (total_total_swdkllj += (item.pokok_swdkllj+item.denda_swdkllj)) &&
									<>
										<tr>
											<td colSpan={16} style={{ fontWeight: "bold" }}>{item.nama != null ? item.nama : "LAINNYA"}</td>
										</tr>
										{item.items.map((detail, idxDetail) => (
											<tr>
												<td className="teks-kanan">{no += 1}</td>
												<td>{detail.alamat_wp == null ? detail.nama_wp : detail.nama_wp + ", " + detail.alamat_wp}</td>
												<td width={100}>{moment(detail.masa_pajak_mulai).format('DD-MM-YYYY')}</td>
												<td className="teks-tengah">{detail.nomor_polisi.toUpperCase()}</td>
												<td>{detail.jenis_kendaraan}</td>
												<td>{detail.merk_kendaraan + " " + detail.tipe_nama}</td>
												<td className="teks-tengah">{detail.thn_buat}</td>

												<td>{(detail.pokok_bbnkb + detail.tunggakan_pokok_bbnkb).toLocaleString('id')}</td>
												<td>{(detail.denda_bbnkb + detail.tunggakan_denda_bbnkb).toLocaleString('id')}</td>
												<td>{(detail.pokok_bbnkb + detail.tunggakan_pokok_bbnkb + detail.denda_bbnkb + detail.tunggakan_denda_bbnkb).toLocaleString('id')}</td>

												<td>{detail.pokok_pkb.toLocaleString('id')}</td>
												<td>{detail.denda_pkb.toLocaleString('id')}</td>
												<td>{(detail.pokok_pkb + detail.denda_pkb).toLocaleString('id')}</td>

												<td>{detail.pokok_swdkllj.toLocaleString('id')}</td>
												<td>{detail.denda_swdkllj.toLocaleString('id')}</td>
												<td>{(detail.pokok_swdkllj + detail.denda_swdkllj).toLocaleString('id')}</td>
											</tr>
										))}
										<tr>
											<td colSpan={6} style={{ textAlign: "right", fontWeight: "bold" }}>{item.nama != null ? "Jumlah (" + item.nama + ")" : "Jumlah (LAINNYA)"}</td>
											<td></td>
											<td>{item.total_pokok_bbnkb.toLocaleString('id')}</td>
											<td>{item.total_denda_bbnkb.toLocaleString('id')}</td>
											<td>{(item.total_pokok_bbnkb + item.total_denda_bbnkb).toLocaleString('id')}</td>

											<td>{item.total_pokok_pkb.toLocaleString('id')}</td>
											<td>{item.total_denda_pkb.toLocaleString('id')}</td>
											<td>{(item.total_pokok_pkb + item.total_denda_pkb).toLocaleString('id')}</td>

											<td>{item.total_pokok_swdkllj.toLocaleString('id')}</td>
											<td>{item.total_denda_swdkllj.toLocaleString('id')}</td>
											<td>{(item.total_pokok_swdkllj + item.total_denda_swdkllj).toLocaleString('id')}</td>
										</tr>
									</>


								)))}
							</tbody>
							{(this.state.data != null &&
								<tfoot>
									<tr>
										<td colSpan={16}></td>
									</tr>
									<tr>
										<td colSpan={7}>Total</td>
										<td>{this.state.data.summary.bbnkb_pokok.toLocaleString('id')}</td>
										<td>{this.state.data.summary.bbnkb_denda.toLocaleString('id')}</td>
										<td>{this.state.data.summary.bbnkb_jumlah.toLocaleString('id')}</td>

										<td>{this.state.data.summary.pkb_pokok.toLocaleString('id')}</td>
										<td>{this.state.data.summary.pkb_denda.toLocaleString('id')}</td>
										<td>{this.state.data.summary.pkb_jumlah.toLocaleString('id')}</td>

										<td>{this.state.data.summary.swdkllj_pokok.toLocaleString('id')}</td>
										<td>{this.state.data.summary.swdkllj_denda.toLocaleString('id')}</td>
										<td>{this.state.data.summary.swdkllj_jumlah.toLocaleString('id')}</td>
									</tr>
								</tfoot>
							)}
						</table>
						{/*(this.state.data != null && this.state.data.ttd != undefined &&
									  				<Row justify="space-between" style={{marginTop: 10}}>
									  					{(this.state.data.ttd.kiri != undefined &&
									  							<Col span={10} className="teks-tengah">
									  								MENGETAHUI<br />
													  				{this.state.data.ttd.kiri.jabatan}
													  				<div style={{marginTop: 50}}>
													  					<u>{this.state.data.ttd.kiri.nama}</u><br />
													  					NIP : {this.state.data.ttd.kiri.nip}
													  				</div>
													  			</Col>
									  					)}
									  					{(this.state.data.ttd.kanan != undefined &&
												  			<Col span={10} className="teks-tengah">
												  				{this.state.namauptppd}, {moment().format('DD-MM-YYYY')}<br />
												  				{this.state.data.ttd.kanan.jabatan}
												  				<div style={{marginTop: 50}}>
												  					<u>{this.state.data.ttd.kanan.nama}</u><br />
												  					NIP : {this.state.data.ttd.kanan.nip}
												  				</div>
												  			</Col>
											  			)}
											  		</Row>
									  		)*/}
					</div>
				)}
			</>
		)
	}

}

const mapStateToProps = function (state) {
	return state.configReducers
}

export default connect(
	mapStateToProps,
	{
		initHandler,
		setConfig,
		ajaxViewHandler,
		ajaxHandler,
		setConfig
	}
)(LapPenetapanPkbCetak);