import React from 'react';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import {
    initHandler,
    ajaxViewHandler,
    ajaxHandler,
    setConfig,
} from "../../store/actions";
import {
    Breadcrumb,
    Card,
    Row,
    Col,
    Input,
    Button,
    Table,
    Modal,
    message,
    Alert,
    Select,
    Tooltip,
    Spin,
    Form,
    Skeleton
} from 'antd';

import moment from 'moment';

import Config from '../../Config';
import { Helmet } from "react-helmet";
import queryString from 'query-string';

import '../../assets/laporan.css';


const configClass = {
    title: 'LAPORAN PNBP',
    apiUrl: {
        preview: 'laporan/api/pnbp/',
        userProfile: "auth/api/user/info"
    },

}

class LaporanPnbpCetak extends React.Component {
    config;

    constructor(props) {
        super(props);
        this.config = new Config();
        this.state = {
            loading: false,
            data: null,
            err: false,

            namauptppd: 'SAMSAT PALANGKARAYA',

            tgl_dari: "",
        }

        // this.preview(this.props.match.params.id);

    }

    componentDidMount() {
        var uri_params = "";
        const uri_string = queryString.parse(this.props.location.search);
        var uri_params = "";
        for (var key in uri_string) {
            if (uri_params == "") {
                uri_params = "?" + key + "=" + uri_string[key];
            } else {
                uri_params += "&" + key + "=" + uri_string[key];
            }
            if(key == "mulai_dari"){
				this.setState({
					tgl_dari: uri_string[key]
				})
			}
        }
        this.preview(uri_params);
    }

    async preview(uri_params) {
        message.loading({ content: 'Menampilkan laporan...', duration: 0, key: 'toast' });
        this.setState({
            loading: true,
            err: false,
            data: null,
        });

        this.props.ajaxViewHandler('get', configClass.apiUrl.preview + uri_params)
            .then(() => {
                const data = this.props.responseMsg;
                if (this.props.isSuccess) {
                    message.destroy();
                    this.setState({
                        loading: false,
                        data: data,
                        err: false
                    });
                    setTimeout(function () {
                        window.print();

                    }, 1300);
                } else {
                    const errTitle = 'error get laporan';
                    this.setState({
                        loading: false,
                        data: null,
                        err: true,
                        errMsg: this.props.responseMsg
                    });
                    message.error({ content: errTitle, duration: 2, key: 'toast' });
                }
            }).catch((response) => {
                this.setState({
                    loading: false,
                    data: null,
                    err: true,
                    errMsg: 'unknown response'
                });
                console.log('gagal_get_laporan', response);
                message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
            });
    }

    render() {
        var total_pokok_bbnkb = 0;
        var total_denda_bbnkb = 0;
        var total_total_bbnkb = 0;

        var total_pokok_pkb = 0;
        var total_denda_pkb = 0;
        var total_total_pkb = 0;

        var total_pokok_swdkllj = 0;
        var total_denda_swdkllj = 0;
        var total_total_swdkllj = 0;

        var no = 0;
        return (
            <>
                <Helmet>
                    <title>{configClass.title}</title>
                </Helmet>
                {(this.state.data != null &&
                    <div className="kertas-laporan">
                        <div className="judul-laporan teks-tengah">
                            LAPORAN PENERIMAAN PNBP
                        </div>
                        <Row>
                            <Col span={3}>Samsat</Col>
                            <Col span={3}>{this.state.data.upt}</Col>
                        </Row>
                        <Row>
                            <Col span={3}>Tanggal</Col>
                            <Col span={3}>{this.state.tgl_dari}</Col>
                        </Row>
                        <table className="laporan-table" border="1" cellPadding="5">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>NOPOL</th>
                                    <th>SD STNK</th>
                                    <th>JENIS PENDAFTARAN</th>
                                    <th>STNK</th>
                                    <th>TNKB</th>
                                    <th>jumlah</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={7}>Jenis Kendaraan : RODA 4</td>
                                </tr>
                                {this.state.data.roda_empat.data.map((item, idx) => (
                                    <tr>
                                        <td>{idx + 1}</td>
                                        <td>{item.nomor_polisi}</td>
                                        <td>{item.sd_stnk != undefined ? item.sd_stnk : "undefined sd_stnk"}</td>
                                        <td>{item.layanan}</td>
                                        <td>{item.adm_stnk.toLocaleString("id")}</td>
                                        <td>{item.adm_tnkb.toLocaleString("id")}</td>
                                        <td>{(item.adm_stnk + item.adm_tnkb).toLocaleString("id")}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan={4}>JUMLAH RODA 4 : {this.state.data.roda_empat.jumlah_kendaraan}</td>
                                    <td>{this.state.data.roda_empat.jumlah_stnk != undefined ? this.state.data.roda_empat.jumlah_stnk : "undefined jumlah_stnk"}</td>
                                    <td>{this.state.data.roda_empat.jumlah_tnkb != undefined ? this.state.data.roda_empat.jumlah_tnkb : "undefined jumlah_tnkb"}</td>
                                    <td>{this.state.data.roda_empat.jumlah_jumlah != undefined ? this.state.data.roda_empat.jumlah_jumlah : "undefined jumlah_jumlah"}</td>
                                </tr>

                                <tr>
                                    <td colSpan={7}>Jenis Kendaraan : RODA 2</td>
                                </tr>
                                {this.state.data.roda_dua.data.map((item, idx) => (
                                    <tr>
                                        <td>{idx + 1}</td>
                                        <td>{item.nomor_polisi}</td>
                                        <td>{item.sd_stnk != undefined ? item.sd_stnk : "undefined sd_stnk"}</td>
                                        <td>{item.layanan}</td>
                                        <td>{item.adm_stnk.toLocaleString("id")}</td>
                                        <td>{item.adm_tnkb.toLocaleString("id")}</td>
                                        <td>{(item.adm_stnk + item.adm_tnkb).toLocaleString("id")}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan={4}>JUMLAH RODA 2 : {this.state.data.roda_dua.jumlah_kendaraan}</td>
                                    <td>{this.state.data.roda_dua.jumlah_stnk != undefined ? this.state.data.roda_dua.jumlah_stnk : "undefined jumlah_stnk"}</td>
                                    <td>{this.state.data.roda_dua.jumlah_tnkb != undefined ? this.state.data.roda_dua.jumlah_tnkb : "undefined jumlah_tnkb"}</td>
                                    <td>{this.state.data.roda_dua.jumlah_jumlah != undefined ? this.state.data.roda_dua.jumlah_jumlah : "undefined jumlah_jumlah"}</td>
                                </tr>
                            </tbody>
                        </table>
                        {(this.state.data != null && this.state.data.ttd != undefined &&
                            <Row justify="space-between" style={{ marginTop: 10 }}>
                                {(this.state.data.ttd.kiri != undefined &&
                                    <Col span={10} className="teks-tengah">
                                        MENGETAHUI<br />
                                        {this.state.data.ttd.kiri.jabatan}
                                        <div style={{ marginTop: 50 }}>
                                            <u>{this.state.data.ttd.kiri.nama}</u><br />
                                            NIP : {this.state.data.ttd.kiri.nip}
                                        </div>
                                    </Col>
                                )}
                                {(this.state.data.ttd.kanan != undefined &&
                                    <Col span={10} className="teks-tengah">
                                        {this.state.data.upt}, {moment().format('DD-MM-YYYY')}<br />
                                        {this.state.data.ttd.kanan.jabatan}
                                        <div style={{ marginTop: 50 }}>
                                            <u>{this.state.data.ttd.kanan.nama}</u><br />
                                            NIP : {this.state.data.ttd.kanan.nip}
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        )}
                    </div>
                )}
            </>
        )
    }

}

const mapStateToProps = function (state) {
    return state.configReducers
}

export default connect(
    mapStateToProps,
    {
        initHandler,
        setConfig,
        ajaxViewHandler,
        ajaxHandler,
        setConfig
    }
)(LaporanPnbpCetak);