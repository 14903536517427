import React from 'react';
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import Highlighter from 'react-highlight-words';
import { Link } from "react-router-dom";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Input,
	Button,
	Table,
	Modal,
	message,
	Alert,
	Tag,
	Select,
	Spin,
	Form
} from 'antd';
import {
	TeamOutlined,
	SearchOutlined,
	PlusOutlined,
	FormOutlined,
	CloseSquareOutlined,
	ExclamationCircleOutlined,
	DiffOutlined,
	RedoOutlined,
	SettingOutlined,
	SyncOutlined,
	ClockCircleOutlined,
	CheckCircleOutlined,
	CloseCircleOutlined
} from '@ant-design/icons';

import axios from 'axios';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";


import moment from "moment";

import Config from '../../Config';
import { Helmet } from "react-helmet";

const configClass = {
	title: 'import data njkb',
	apiUrl: {
		data: 'kendaraan/api/upload/njkb/',
		jenis_kendaraan: "master/api/jenis/kendaraan/"
	},

	breadCrumb: [
		(
			<span>
				<SettingOutlined /> pengaturan
			</span>
		),
		'import njkb'
	]

}

const config = new Config();

class DataImportNjkb extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loadingJenisKendaraan: false,
			listJenisKendaraan: [],

			document: null,

			errMsg: '',
			loadingData: false,
			pagination: { pageSize: 25 },
			searchText: '',
			searchColumn: '',
			dataSource: [],
			columns: [
				{
					title: 'Status',
					width: 150,
					dataIndex: 'status',
					key: 'status',
					render: (value, row, index) => {
						return (
							<>
								{value == "1" &&
									<Tag icon={<ClockCircleOutlined />} color="warning">
										{row.status_nama}
									</Tag>
								}
								{value == "2" &&
									<Tag icon={<SyncOutlined spin />} color="processing">
										{row.status_nama}
									</Tag>
								}
								{value == "3" &&
									<Tag icon={<CheckCircleOutlined />} color="success">
										{row.status_nama}
									</Tag>
								}
							</>
						)
					}
				},
				{
					title: 'Lampiran',
					width: 150,
					dataIndex: 'lampiran',
					key: 'lampiran',
					render: (value, row, index) => {
						return value != null && <a href={value} target='_blank'>download</a>
					}
				},
				{
					title: 'Koreksi',
					width: 150,
					dataIndex: 'koreksi',
					key: 'koreksi',
					render: (value, row, index) => {
						return value != null && <a href={value} target='_blank'>download</a>
					}
				},
				{
					title: 'Success',
					width: 150,
					dataIndex: 'success',
					key: 'success'
				},
				{
					title: 'Gagal',
					width: 150,
					dataIndex: 'success',
					key: 'success'
				},
				{
					title: 'Jenis Kendaraan',
					width: 150,
					dataIndex: 'jenis_kendaraan_nama',
					key: 'jenis_kendaraan_nama'
				},
				{
					title: 'CREATED',
					width: 150,
					dataIndex: 'created_at',
					key: 'created_at',
					render: (value, row, index) => {
						return moment(value).format("DD-MM-YYYY");
					}
				},


			]
		}
	}

	UNSAFE_componentWillMount() {
		this.props.initHandler();
	}

	componentDidMount() {
		// this.loadMasterList();
		this.getData();
	}

	setDocument = (e) => {
		this.setState({
			document: e.target.files[0]
		});
	}


	loadMasterList() {
		const rule = [
			{
				destination: {
					stateProgressName: "loadingJenisKendaraan",
					endPoint: configClass.apiUrl.jenis_kendaraan,
					stateDestination: "listJenisKendaraan",
				},
				nextDestination: null
			}
		];

		this.setState({
			errComponent: false,
		});

		rule.map((item, index) => {
			if (index == rule.length - 1) {
				this.getMasterList(item, true);
			} else {
				this.getMasterList(item);
			}
		});
	}

	async getMasterList(rule, isFinal = false) {
		var destination = rule.destination;
		var nextDestination = rule.nextDestination;
		this.setState({ [destination.stateProgressName]: true });
		this.props
			.ajaxViewHandler("get", destination.endPoint)
			.then(() => {
				const data = this.props.responseMsg;
				if (this.props.isSuccess) {
					if (data.count != undefined) {
						let newState = null;
						if (this.state[destination.stateDestination].length == 0) {
							newState = data;
						} else {
							if (destination.stateProgressName.includes("_searching")) {
								newState = data;
							} else {
								const oldState = this.state[destination.stateDestination];
								newState = {
									count: data.count,
									next: data.next,
									previous: data.previous,
									results: [...oldState.results, ...data.results],
								};
							}
						}
						this.setState({
							[destination.stateDestination]: newState,
						});
					} else if (data.results != undefined) {
						this.setState({
							[destination.stateDestination]: data.results,
						});
					} else {
						if (Array.isArray(data)) {
							this.setState({
								[destination.stateDestination]: data,
							});
						} else {
							this.setState({
								[destination.stateDestination]: [data],
							});
						}
					}
					if (isFinal) {
						if (this.props.match.params.id != undefined) {
							// this.preview(this.props.match.params.id);
						}
					}
				} else {
					this.setState({
						errComponent: true,
					});
				}
				this.setState({ [destination.stateProgressName]: false });
			})
			.catch((response) => {
				console.log('gagal_get_master', response);
				message.error({
					content: "gagal request data, coba lagi",
					duration: 2,
					key: "toast",
				});
				this.setState({
					errTitle: "gagal request data master",
					errMsg: response,
				});
				this.setState({ [destination.stateProgressName]: false });
			});
		//next function
		if (nextDestination != null) {
			const next = {
				destination: {
					stateProgressName: nextDestination.stateProgressName,
					endPoint: nextDestination.endPoint,
					stateDestination: nextDestination.stateDestination,
				},
				nextDestination: nextDestination.next,
			};
			this.getMasterList(next);
		}
	}

	generateSelect(stateName, url, key, value, keyDisplay = "") {
		return this.state["loading" + stateName] ? (
			<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
		) : (
			<Select
				showSearch
				placeholder={"Cari / Pilih " + stateName}
				optionFilterProp="children"
				onSearch={(val) => {
					let urls = url + "?search=" + val;
					if (stateName == "Upt") {
						urls = `${url}?kabupaten=${this.state.kabupaten_id}&search=${val}`;
					}
					const rule = {
						destination: {
							stateProgressName: "loading" + stateName + "_searching",
							endPoint: urls,
							stateDestination: "list" + stateName,
						},
						nextDestination: null,
					};
					this.getMasterList(rule);
				}}
				onPopupScroll={(e) => {
					e.persist();
					let target = e.target;
					if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
						if (
							this.state["list" + stateName].next != undefined &&
							this.state["list" + stateName].next != null
						) {
							const rule = {
								destination: {
									stateProgressName: "loading" + stateName + "Paging",
									endPoint: this.state["list" + stateName].next,
									stateDestination: "list" + stateName,
								},
								nextDestination: null,
							};
							this.getMasterList(rule);
						}
					}
				}}
				allowClear
				style={{
					width: "100%",
				}}
				onChange={(val) => {
					if (stateName == "Kabupaten") {
						this.setState({
							kabupaten_id: val
						}, () => {
							const rule = {
								destination: {
									stateProgressName: "loadingUpt",
									endPoint: configClass.apiUrl.upt + "?kabupaten=" + val,
									stateDestination: "listUpt",
								},
								nextDestination: null,
							};
							this.getMasterList(rule);
						});
					}
				}}
				size={"small"}
			>
				{this.state["loading" + stateName + "_searching"] == true && (
					<Select.Option value="">
						<Spin size="small" />
					</Select.Option>
				)}
				{this.state["list" + stateName].count != undefined
					? this.state["list" + stateName].results.map((item) => (
						<Select.Option value={item[key]}>
							{keyDisplay == ""
								? item[key] + " - " + item[value]
								: item[keyDisplay] + " - " + item[value]}
						</Select.Option>
					))
					: this.state["list" + stateName].map((item) => (
						<Select.Option value={item[key]}>
							{keyDisplay == ""
								? item[key] + " - " + item[value]
								: item[keyDisplay] + " - " + item[value]}
						</Select.Option>
					))}
				{this.state["list" + stateName] != undefined &&
					this.state["list" + stateName].next != undefined && (
						<Select.Option value="">
							<Spin size="small" />
						</Select.Option>
					)}
			</Select>
		);
	}

	showDeleteConfirm(id) {
		Modal.confirm({
			title: 'Konfirmasi Hapus?',
			icon: <ExclamationCircleOutlined />,
			content: 'yakin menghapus data?',
			okText: 'Ya',
			okType: 'danger',
			cancelText: 'Tidak',
			onOk: () => this.prosesHapus(id),
			onCancel() {
				//console.log('Cancel');
			},
		});
	}

	prosesHapus(id) {
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler('delete', configClass.apiUrl.data + id + '/')
			.then(() => {
				this.setState({
					loadingButton: false
				});
				if (this.props.isSuccess) {
					message.success({ content: 'berhasil menghapus data', duration: 2, key: 'toast' });
					this.getData();
				} else {
					const errTitle = 'gagal menghapus data'
					message.error({ content: errTitle, duration: 2, key: 'toast' });
					this.setState({
						errTitle: errTitle,
						errMsg: this.props.responseMsg
					});
				}
			}).catch((response) => {
				console.log('gagal_delete', response);
				this.setState({
					loadingButton: false
				});
				message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
			});
	}

	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={node => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Button
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90, marginRight: 8 }}
				>
					Search
				</Button>
				<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
					Reset
				</Button>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex]
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
		render: text =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text.toString()}
				/>
			) : (
				text
			),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	handleReset = clearFilters => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	async getData(limit = this.state.pagination.pageSize, offset = 0, search = this.state.searchText) {
		this.setState({ loadingData: true });
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.data + '?limit=' + limit + '&offset=' + offset + '&search=' + search)
			.then(() => {
				const data = this.props.responseMsg;
				if (this.props.isSuccess) {
					const pagination = { ...this.state.pagination };
					pagination.total = data.count;
					this.setState({
						dataSource: data.results,
						pagination
					});
				} else {
					const errTitle = 'error get data';
					this.setState({
						errTitle: errTitle,
						errMsg: this.props.responseMsg
					});
					message.error({ content: errTitle, duration: 2, key: 'toast' });
				}
				this.setState({ loadingData: false });
			}).catch((response) => {
				console.log('gagal_get_preview', response);
				message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
				this.setState({ loadingData: false });
			});
	}

	handleTableChange = async (pagination, filters, sorter) => {
		const newPagination = { ...this.state.pagination };
		newPagination.pageSize = pagination.pageSize;
		this.setState({
			loadingData: true,
			pagination
		});
		if (pagination.current == 1) {
			this.getData(pagination.pageSize);
		} else {
			this.getData(pagination.pageSize, (pagination.current - 1) * pagination.pageSize);
		}


	}

	handleSubmit = (values) => {
		var datas = new FormData();
		for (var key in values) {
			if (values[key] == undefined) {
				datas.append(key, '');
			} else {
				datas.append(key, (values[key]).toString());
			}
		}

		if (this.state.document != null) {
			datas.append('lampiran', this.state.document, this.state.document.name);
		} else {
			datas.append('lampiran', '');
		}
		//datas.append('created_at', '01-01-2020');
		this.postData(datas);
	}

	async postData(datas) {
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler("post", configClass.apiUrl.data, datas, true, false, '', true)
			.then(() => {
				this.setState({
					loadingButton: false
				});
				if (this.props.isSuccess) {
					message.success({ content: 'berhasil menyimpan data', key: 'toast' });
					this.setState({
						modalImport: false
					})
				} else {
					const errTitle = 'gagal menyimpan data'
					message.error({ content: errTitle, key: 'toast' });
					this.setState({
						errTitle: errTitle,
						errMsg: this.props.responseMsg
					});
				}
			}).catch((response) => {
				console.log('gagal_post', response);
				this.setState({
					loadingButton: false
				});
				message.error({ content: 'kesalahan pengiriman data, coba lagi', key: 'toast' });
			});
	}

	render() {
		// if(this.props.isSuperadmin == false){
		// 	return <Redirect to="/admin/forbidden" />
		// }
		return (
			<div>
				<Helmet>
					<title>{configClass.title + " | " + config.get_site_info().name}</title>
				</Helmet>
				<Breadcrumb>
					{configClass.breadCrumb.map(item => (
						<Breadcrumb.Item>
							{item}
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
				<div className="card">
					<Row className="isi">
						<Col span={24}>
							<Card
								className="konten"
								title={
									<Row>
										<Col lg={{ span: 20 }} xs={{ span: 24 }}>
											<span className="konten-judul">
												{configClass.title}
												{(this.state.loadingData &&
													<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="30" alt="loading" />
												)}
											</span>
										</Col>
										<Col lg={{ span: 4 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ textAlign: 'right' }}>
											<Button
												loading={this.state.loadingButton}
												className="btn-refresh"
												size="small"
												onClick={() => this.getData()}
											>
												<RedoOutlined />
											</Button>
											<Button
												loading={this.state.loadingButton}
												className="btn-primary"
												size="small"
												onClick={() => {
													this.setState({
														modalImport: true
													}, () => {
														this.loadMasterList();
													})
												}}
											>
												<PlusOutlined /> Upload
											</Button>

										</Col>
									</Row>
								}
							>
								{(this.state.errMsg != '' &&
									<Alert
										message={this.state.errTitle}
										description={JSON.stringify(this.state.errMsg)}
										type="error"
										showIcon
										style={{
											marginBottom: 10
										}}
									/>
								)}
								<Table
									title={() =>
										<Row>
											<Col lg={8} sm={24} xs={24}><span className="konten-judul">Total {this.state.pagination.total} record</span></Col>
											<Col lg={{ span: 8, offset: 8 }} sm={24} xs={24}>
												<Input.Search
													size="small"
													placeholder="ketikkan pencarian"
													onSearch={value => {
														this.setState({
															searchText: value
														}, () => {
															this.getData();
														});
													}}
													allowClear
												/>
											</Col>
										</Row>
									}
									bordered
									columns={this.state.columns}
									dataSource={this.state.dataSource}
									loading={this.state.loadingData}
									scroll={{ x: 240, y: 450 }}
									size="small"
									pagination={this.state.pagination}
									onChange={this.handleTableChange}
								/>
							</Card>
						</Col>
					</Row>
				</div>
				<Modal
					title={"Import NJKB"}
					centered
					visible={this.state.modalImport}
					// onOk={() => this.setVisible(false)}
					onCancel={() => this.setState({ modalImport: false })}
					width={"40%"}
					footer={[]}
				>

					<Row>
						<Col span={24}>
							{this.state.errTitle != null && this.state.errTitle != "" && (
								<Alert
									closable
									message={this.state.errTitle}
									description={this.state.errMsg}
									type="error"
									showIcon
									style={{
										marginBottom: 10,
										width: "100%"
									}}
								/>
							)}
						</Col>
					</Row>

					<Form
						layout="vertical"
						name="fdata"
						onFinish={this.handleSubmit}
						size="small"
						ref={this.formRef}
					>
						<Row justify="space-between">
							<Col lg={24} sm={24} xs={24}>
								<Form.Item
									label="LAMPIRAN"
									name="lampiran"
									rules={[
										{
											required: true,
											message: 'harus diisi'
										}
									]}
								>
									<Input
										type="file"
										style={{ textTransform: 'none' }}
										placeholder="file njkb"
										onChange={this.setDocument}
									/>
								</Form.Item>
							</Col>
							<Col lg={24} sm={24} xs={24}>
								<Form.Item
									label="JENIS KENDARAAN"
									name="jenis_kendaraan"
									rules={[
										{
											required: true,
											message: 'harus diisi'
										}
									]}
								>
									{this.generateSelect(
										"JenisKendaraan",
										configClass.apiUrl.jenis_kendaraan,
										"id",
										"nama",
										"kode"
									)}
								</Form.Item>
							</Col>
						</Row>
						<Row justify="end" style={{ paddingTop: 30 }}>
							<Col style={{ padding: 5 }}>
								<Button
									type="primary"
									key="btn-cancel"
									size="small"
									htmlType="submit"
								>
									<CheckCircleOutlined /> Proses
								</Button>
							</Col>
							<Col style={{ padding: 5 }}>
								<Button
									type="danger"
									key="btn-cancel"
									size="small"
									htmlType="button"
									onClick={() => this.setState({ modalImport: false })}
								>
									<CloseCircleOutlined /> Tidak
								</Button>
							</Col>
						</Row>
					</Form>


				</Modal >
			</div>
		);
	}
}

const mapStateToProps = function (state) {
	return state.configReducers
}

export default connect(
	mapStateToProps,
	{
		initHandler,
		setConfig,
		ajaxViewHandler,
		ajaxHandler
	}
)(DataImportNjkb);