import React from 'react';
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import Highlighter from 'react-highlight-words';
import { Link } from "react-router-dom";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Input,
	Button,
	Table,
	Modal,
	message,
	Alert,
	Tag,
	DatePicker,
	Form
} from 'antd';
import {
  TeamOutlined,
  SearchOutlined,
  PlusOutlined,
  FormOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  DiffOutlined,
  RedoOutlined,
  CarOutlined,
  CloseCircleOutlined,
  FileOutlined,
  HistoryOutlined,
  SendOutlined,
  EyeOutlined,
  FileExcelOutlined
} from '@ant-design/icons';

import axios from 'axios';
import moment from "moment";

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
	ajaxDownloadHandler
} from "../../store/actions";

import Config from '../../Config';
import {Helmet} from "react-helmet";

const configClass = {
	title: 'data jasaraharja',
	apiUrl: {
		data: 'transaksi/api/jasa/raharja/'
	},
	pageUrl: {
		form: '/admin/jasaraharja/form/'
	},
	breadCrumb: [
		(
			<span>
				<CarOutlined /> transaksi
			</span>
		),
		'jasaraharja'
	]

}

const config = new Config();
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 23 },
  },
};

class DataJasaraharja extends React.Component {
	formRef = React.createRef();

	constructor(props){
		super(props);
		this.state = {
			errMsg: '',
			loadingData: false,
			pagination: {pageSize: 25},
			searchText: '',
			searchColumn: '',
			dataSource: [],
			filterData: '',
			columns: [
				{
					title: 'STATUS',
					width: 120,
					dataIndex: 'status',
					key: 'status',
					fixed: 'left',
					render: (value, row, index) => {
						return(
							<div>
								{(value == 1 &&
									<Tag color="warning">Pending</Tag>
								)}
								{(value == 2 &&
									<Tag color="success">Terkirim</Tag>
								)}
								{(value == 3 &&
									<Tag color="error">Gagal</Tag>
								)}
							</div>
						)
					}
				},
				{
					title: 'NOMOR',
					width: 150,
					dataIndex: 'nomor',
					key: 'nomor'
				},
				{
					title: 'NOMOR POLISI',
					width: 150,
					dataIndex: 'nomor_polisi',
					key: 'nomor_polisi'
				},
				{
					title: 'NAMA PEMILIK',
					width: 150,
					dataIndex: 'nama_pemilik',
					key: 'nama_pemilik'
				},
				{
					title: 'POKOK SWDKLLJ',
					width: 150,
					dataIndex: 'pokok_swdkllj',
					key: 'pokok_swdkllj',
					render: (value, row, index) => {
						return value.toLocaleString('ID')
					}
				},
				{
					title: 'POKOK SAMSAT',
					width: 150,
					dataIndex: 'pokok_samsat',
					key: 'pokok_samsat',
					render: (value, row, index) => {
						return value.toLocaleString('ID')
					}
				},
				{
					title: 'DENDA SWDKLLJ',
					width: 150,
					dataIndex: 'denda_swdkllj',
					key: 'denda_swdkllj',
					render: (value, row, index) => {
						return value.toLocaleString('ID')
					}
				},
				{
					title: 'DENDA SAMSAT',
					width: 150,
					dataIndex: 'denda_samsat',
					key: 'denda_samsat',
					render: (value, row, index) => {
						return value.toLocaleString('ID')
					}
				},
				{
					title: 'CREATED',
					width: 150,
					dataIndex: 'created',
					key: 'created',
					render: (value, row, index) => {
						return moment(value).format("DD-MM-YYYY HH:mm:ss")
					}
				},
				{
					title: 'AKSI',
					width: 120,
					dataIndex: 'id',
					key: 'aksi',
					fixed: 'right',
					render: (value, row, index) => {
						return (
							<Row key={"space-aksi-"+index} justify="center">
								{(row.data != undefined &&
									<Col style={{padding: 5}}>
										<Button title="data" className="btn-data" size="small" onClick={() => this.showDetail("DATA", row.data)} shape="circle">
											<FileOutlined />
										</Button>
									</Col>
								)}
								{(row.log != undefined &&
									<Col style={{padding: 5}}>
										<Button title="log" className="btn-log" size="small" onClick={() => this.showDetail("LOG", row.log, true)} shape="circle">
											<HistoryOutlined />
										</Button>
									</Col>
								)}
								{(row.is_deleted == true &&
									<Col style={{padding: 5}}>
										<Button title="resend" className="btn-resend" size="small" onClick={() => this.doRequest(value, "resend")} shape="circle">
											<SendOutlined />
										</Button>
									</Col>
								)}
								<Col style={{padding: 5}}>
										<Button title="monitor" className="btn-monitor" size="small" onClick={() => this.doRequest(value, "monitor")} shape="circle">
											<EyeOutlined />
										</Button>
								</Col>
								
								<Col style={{padding: 5}}>
									<Button title="hapus" className="btn-delete" size="small" onClick={() => this.showDeleteConfirm(value)} shape="circle">
										<CloseSquareOutlined />
									</Button>
								</Col>
							</Row>
						)
					},
				}

			],

			visibleModal: false,
			dataModal: null
		}
	}

	UNSAFE_componentWillMount(){
		this.props.initHandler();
	}

	componentDidMount(){
		this.getData();
	}

	showDetail = (judul, dataModal, is_flat=false) =>{
		// alert(JSON.stringify(dataModal))
		if(is_flat == false){
			this.setState({
				visibleModal: true,
				dataModal: {
					is_flat: is_flat,
					judul: judul,
					konten: dataModal.Params[0],
				},
			});
		}else{
			this.setState({
				visibleModal: true,
				dataModal: {
					is_flat: is_flat,
					judul: judul,
					konten: {
						kolom: judul,
						value: JSON.stringify(dataModal)
					},
				},
			});
		}
	};

	showDeleteConfirm(id) {
	  Modal.confirm({
	    title: 'Konfirmasi Hapus?',
	    icon: <ExclamationCircleOutlined />,
	    content: 'yakin menghapus data?',
	    okText: 'Ya',
	    okType: 'danger',
	    cancelText: 'Tidak',
	    onOk: () => this.prosesHapus(id),
	    onCancel() {
	      //console.log('Cancel');
	    },
	  });
	}

	prosesHapus(id) {
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler('delete', configClass.apiUrl.data+id+'/')
		.then(() =>{
			this.setState({
				loadingButton: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil menghapus data', duration: 2, key: 'toast' });
				this.getData();
			}else{
				const errTitle = 'gagal menghapus data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_delete', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	getColumnSearchProps = dataIndex => ({
	    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
	      <div style={{ padding: 8 }}>
	        <Input
	          ref={node => {
	            this.searchInput = node;
	          }}
	          placeholder={`Search ${dataIndex}`}
	          value={selectedKeys[0]}
	          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
	          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
	          style={{ width: 188, marginBottom: 8, display: 'block' }}
	        />
	        <Button
	          type="primary"
	          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
	          icon={<SearchOutlined />}
	          size="small"
	          style={{ width: 90, marginRight: 8 }}
	        >
	          Search
	        </Button>
	        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
	          Reset
	        </Button>
	      </div>
	    ),
	    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	    onFilter: (value, record) =>
	      record[dataIndex]
	        .toString()
	        .toLowerCase()
	        .includes(value.toLowerCase()),
	    onFilterDropdownVisibleChange: visible => {
	      if (visible) {
	        setTimeout(() => this.searchInput.select());
	      }
	    },
	    render: text =>
	      this.state.searchedColumn === dataIndex ? (
	        <Highlighter
	          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
	          searchWords={[this.state.searchText]}
	          autoEscape
	          textToHighlight={text.toString()}
	        />
	      ) : (
	        text
	      ),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
	    confirm();
	    this.setState({
	      searchText: selectedKeys[0],
	      searchedColumn: dataIndex,
	    });
	};

	handleReset = clearFilters => {
	    clearFilters();
	    this.setState({ searchText: '' });
	};

	async getData(limit=this.state.pagination.pageSize, offset=0, search=this.state.searchText){
		this.setState({loadingData: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.data+'?limit='+limit+'&offset='+offset+'&search='+search+'&'+this.state.filterData)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				const pagination = { ...this.state.pagination };
				pagination.total = data.count;
				this.setState({
					dataSource: data.results,
					pagination
				});
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
	        this.setState({loadingData: false});
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingData: false});
		});
	}

	async doRequest(id, code){
		this.setState({loadingData: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.data+id+'/'+code)
		.then(() =>{
			// if(this.props.isSuccess){
			// 	message.success({ content: code+' berhasil', duration: 2, key: 'toast' });
			// 	this.getData();
			// }else{
			// 	// alert('sini')
			// 	// message.error({ content: JSON.stringify(this.props.responseMsg), duration: 2, key: 'toast' });
			// }
			if(this.props.isSuccess){
				message.success({ content: code+' berhasil', duration: 2, key: 'toast' });
				this.getData();
			}else{
				const errTitle = 'gagal '+code+' data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
			this.setState({loadingData: false});
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: code+' gagal', duration: 2, key: 'toast' });
			this.setState({loadingData: false});
		});
	}

	async postDataWithDownload() {
	    message.loading({ content: 'Memproses...', duration: 0, key: 'toast' });
	    this.setState({
	        loadingData: true,
	        errTitle: '',
	        errMsg: '',
	        successMsg: '',
	    });


	    this.props.ajaxDownloadHandler('get', configClass.apiUrl.data+'?'+this.state.filterData+'&export=true')
	    .then(() =>{
	      if(this.props.isSuccess){
	        this.setState({
	            loadingData: false,
	            errTitle: '',
	            errMsg: '',
	            successMsg: 'proses berhasil',
	        });
	      //   var a = document.createElement('a');
	      // var url = window.URL.createObjectURL(this.props.responseMsg);
	      // a.href = url;
	      // a.download = this.state.title;//'myfile.pdf';
	      // document.body.append(a);
	      // a.click();
	      // a.remove();
	      // window.URL.revokeObjectURL(url);
	         const url = window.URL.createObjectURL(new Blob([this.props.responseMsg]));
	         const link = document.createElement('a');
	         link.href = url;
	         // link.setAttribute(this.state.title); //or any other extension
	         // link.download = this.state.title;
	         // link.download = this.getFileNameFromUrl(this.state.media);
	         link.download = 'sam_jsr_'+moment().format("DD-MM-YYYY_HH-mm-ss")+'.bin';
	         document.body.appendChild(link);
	         link.click();
	      	 message.success({ content: 'File berhasil diunduh', duration: 2, key: 'toast' });
	      }else{
	        this.setState({
	          msg: this.props.responseMsg.detail != undefined ? this.props.responseMsg.detail : this.props.responseMsg,
	          loadingData: false,
	          errTitle: 'error',
	          errMsg: 'gagal export',
	          successMsg: '',
	        });
	      }
	    }).catch((response) => {
	      console.log('gagal_post', response);
	      this.setState({
	          loadingData: false
	      });
	      message.error({ content: 'kesalahan pengiriman data, code: '+response.responseCode+'coba lagi', duration: 2, key: 'toast' });
	    });
	 }


	handleTableChange = async (pagination, filters, sorter) =>{
    	const newPagination = { ...this.state.pagination };
    	newPagination.pageSize = pagination.pageSize;
		this.setState({
			loadingData: true,
			pagination
		});
		if(pagination.current==1){
			this.getData(pagination.pageSize);
		}else{
			this.getData(pagination.pageSize, (pagination.current - 1)*pagination.pageSize);
		}

		
	}

	handleSubmit = (values) =>{
		var datas = '';
		for ( var key in values ) {
			if(values[key] != undefined){
				if(datas == ''){
					datas = key+'='+values[key];
				}else{
					datas += '&'+key+'='+values[key];
				}
			}
		}
		this.setState({
			filterData: datas
		});

		this.getData();
	}

	render() {
		// if(this.props.isSuperadmin == false){
		// 	return <Redirect to="/admin/forbidden" />
		// }
		return(
			<div>
				<Helmet>
					<title>{configClass.title+" | "+config.get_site_info().name}</title>
				</Helmet>
				<Breadcrumb>
					{configClass.breadCrumb.map(item => (
						<Breadcrumb.Item>
							{item}
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
				<div className="card">
					<Row className="isi">
				       	<Col span={24}>
				        	<Card
				        		className="konten"
					            title={
					            	<Row>
					            		<Col lg={{ span: 20 }} xs={{ span:24 }}>
							            	<span className="konten-judul">
							            		{configClass.title}
							            		{(this.state.loadingData &&
									        		<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="30" alt="loading" />
									        	)}
							            	</span>
							            </Col>
						            	<Col lg={{ span: 4 }} sm={{ span: 24 }} xs={{ span:24 }} style={{textAlign: 'right'}}>
						            		<Button 
										        	loading={this.state.loadingButton}
										        	className="btn-refresh"
										        	size="small"
										        	onClick={() => this.getData()} 
										        >
										          <RedoOutlined />
										    </Button>
										    {/*
						            		<Link to={configClass.pageUrl.form}>
							            		<Button 
										        	loading={this.state.loadingButton}
										        	className="btn-primary"
										        	size="small" 
										        >
										          <PlusOutlined /> Tambah
										        </Button>
										    </Link>
										  */}
						            	</Col>
						            </Row>
					            }
					        >
					        	{(this.state.errMsg!='' &&
						        	<Alert
								      message={this.state.errTitle}
								      description={JSON.stringify(this.state.errMsg)}
								      type="error"
								      showIcon
								      style={{
								      	marginBottom: 10
								      }}
								    />
								)}
					        	<Form 
											layout="horizontal"
											name="fdata"
										    onFinish={this.handleSubmit}
										    size="small"
										    ref={this.formRef}
										    {...formItemLayout}
										>
											<Row>
												<Col md={8} sm={8} xs={24}>
													<Form.Item name="tanggal_mulai_picker">
												        <DatePicker 
														    placeholder="Dari Tgl (dd-mm-yyyy)" 
														    format="DD-MM-YYYY"
														    style={{width: '100%'}}
														    onChange={(date, dateString) =>{
														        this.formRef.current.setFieldsValue({
																	tanggal_mulai: dateString,
																});
														    }}
														/>
													</Form.Item>
													<Form.Item
														name="tanggal_mulai"
														style={{
															display: 'none'
														}}
													>
														<Input type="hidden" />
												    </Form.Item>
												</Col>
												<Col  md={8} sm={8} xs={24}>
												    <Form.Item name="tanggal_akhir_picker">
												        <DatePicker 
														    placeholder="Sampai Tgl (dd-mm-yyyy)" 
														    format="DD-MM-YYYY"
														    style={{width: '100%'}}
														    onChange={(date, dateString) =>{
														        this.formRef.current.setFieldsValue({
																	tanggal_akhir: dateString,
																});
														    }}
														/>
													</Form.Item>
													<Form.Item
														name="tanggal_akhir"
														style={{
															display: 'none'
														}}
													>
														<Input type="hidden" />
												    </Form.Item>
												</Col>
												<Col md={8} sm={8} xs={24}>
													<Row justify="space-between">
																<Col span={10}>
																	<Button
																		style={{width: '100%'}}
																		key="btn-view"
																		loading={this.state.loadingData}
																		type="primary"
																		htmlType="submit"
																		size="small"
																	>
																		{(!this.state.loadingData && <EyeOutlined />)}
																			Filter
																	</Button>
																</Col>
																<Col span={14} style={{paddingLeft: 10}}>
																	<Button
																		style={{width: '100%'}}
																		key="btn-view"
																		loading={this.state.loadingData}
																		type="danger"
																		htmlType="button"
																		size="small"
																		onClick={() => this.postDataWithDownload()}
																	>
																		{(!this.state.loadingData && <FileExcelOutlined />)}
																			Download Splitzing
																	</Button>
																</Col>
													</Row>
												</Col>
											</Row>
										</Form>

					        	<Table 
					        		title={() => 
					        			<Row>
					        				<Col lg={8} sm={24} xs={24}><span className="konten-judul">Total {this.state.pagination.total} record</span></Col>
											<Col lg={{span: 8, offset: 8}} sm={24} xs={24}>
												<Input.Search 
													size="small"
													placeholder="ketikkan pencarian" 
													onSearch={value => {
														this.setState({
															searchText: value
														}, () =>{
																this.getData();
														});
													}} 
													allowClear
												/>										
											</Col>
										</Row>					        			
					        		}
					        		bordered
					        		columns={this.state.columns} 
					        		dataSource={this.state.dataSource} 
					        		loading={this.state.loadingData} 
					        		scroll={{ x: 240, y: 450 }}
					        		size="small"
					        		pagination={this.state.pagination}
					        		onChange={this.handleTableChange}
					        	/>
					        </Card>
				        </Col>
				    </Row>
				</div>

				<Modal
	        title={this.state.dataModal != null ? this.state.dataModal.judul : ""}
	        centered
	        visible={this.state.visibleModal}
	        // onOk={() => this.setVisible(false)}
	        onCancel={() => this.setState({visibleModal: false})}
	        width={700}
	        footer={[]}
	      >
	      	
					<Row>
						<Col span={24}>
							{this.state.dataModal != null && this.state.dataModal.is_flat == false && Object.entries(this.state.dataModal.konten).map(([key,value]) =>(
									typeof value != 'object' &&
				          	<Row style={{borderBottom: "1px solid #F0F2F6"}}>
				          		<Col span={12}>{key}</Col>
				          		<Col span={12}>
				          			{": "+config.toSpace(value)}
				          		</Col>
				          	</Row>
			          ))}

							{(this.state.dataModal != null && this.state.dataModal.is_flat == true &&
									<Row style={{borderBottom: "1px solid #F0F2F6"}}>
				          		<Col span={12}>{this.state.dataModal.konten.kolom}</Col>
				          		<Col span={12}>
				          			{this.state.dataModal.konten.value}
				          		</Col>
				          </Row>
			         )}
						</Col>
					</Row>
					<Row justify="end">
						<Col style={{padding: 5}}>
							<Button 
								type="danger"
								key="btn-cancel"
								size="small"
								onClick={() => this.setState({visibleModal: false})}
							>
								<CloseCircleOutlined /> Tutup
							</Button>
						</Col>
					</Row>

	      	
	      </Modal>


			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler,
			ajaxDownloadHandler
		}
	)(DataJasaraharja);