import React, { PureComponent } from 'react'
import { Redirect, Link } from "react-router-dom";
import {
  Breadcrumb,
  Card,
  Row,
  Col,
  Avatar,
  Form,
  Input,
  Button,
  Space,
  Select,
  DatePicker,
  message,
  Alert,
  Spin,
  Skeleton
} from 'antd';
import { connect } from 'react-redux';
import {
  initHandler,
  ajaxViewHandler,
  ajaxHandler,
  setConfig,
} from "../store/actions";
import { Helmet } from "react-helmet";
import '../assets/fiskal.css';
import Config from '../Config';
import moment from 'moment';

import QRCode from 'qrcode.react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from './Logo';
import 'jspdf-autotable'


const configClass = {
  title: 'cetak kutipan surat ketetapan pajak',
  apiUrl: {
    // preview: "transaksi/api/transaksi/",
    preview: "transaksi/api/tagihan/",
    userProfile: "auth/api/user/info"
  }
}

class SuratFiskal extends PureComponent {
  config;

  constructor(props) {
    super(props);
    this.config = new Config();
    this.state = {
      loading: true,
      dataKendaraan: null,
      dataResult: null,
    }

    // this.preview(this.props.match.params.id);

  }

  componentDidMount() {
    this.preview(this.props.match.params.id);
  }

  async preview(id) {
    message.loading({ content: 'Menampilkan data...', duration: 0, key: 'toast' });

    this.setState({
      errTitle: '',
      errMsg: '',
      loading: true
    });

    this.props.ajaxViewHandler('get', configClass.apiUrl.preview + id)
      .then(() => {
        const data = this.props.responseMsg;
        var that = this;
        if (this.props.isSuccess) {
          message.destroy();
          this.setState({
            loading: false,
            dataKendaraan: data
          }, () => {
            setTimeout(function () {
              // window.print();
              window.print();

            }, 1000);
          });
        } else {
          const errTitle = 'error preview data registrasi';
          this.setState({
            loading: false,
            errTitle: errTitle,
            errMsg: this.props.responseMsg
          });
          message.error({ content: errTitle, duration: 2, key: 'toast' });
        }
      }).catch((response) => {
        console.log('gagal_get_preview', response);
        message.error({ content: 'gagal request data registrasi, coba lagi', duration: 2, key: 'toast' });
        this.setState({
          loading: false
        });
      });
  }



  render() {
    return (
      <div>
        <Helmet>
          <title>surat keterangan fiskal</title>
        </Helmet>
        {(this.state.dataKendaraan != null &&
          <div style={{ padding: 20 }}>
            <div className="header-surat" style={{position: "relative"}}>
              <div className="kop-logo">
                <img src={this.config.get_site_info().logo_bw} height="90" />
              </div>
              <div className="kop">
                PEMERINTAH PROPINSI KALIMANTAN TENGAH<br />
                BADAN PENDAPATAN DAERAH PROV. KATENG
              </div>
              <div className="sub-kop">
                UNIT PELAKSANA TKNIS PELAYANAN PENDAPATAN DAERAH<br />
                DI PALANGKARAYA<br />
              </div>
              <div className="sub-kop2">
                Jalan R.T.A. Milino Km. 5,5 Telp 05536-32275523
              </div>
            </div>
            <div className="judul-surat">
              SURAT KETERANGAN FISKAL ANTAR DAERAH
            </div>
            <div className="nomor-surat">
              NOMOR: {this.state.dataKendaraan.nomor_surat}
            </div>
            <div className="isi">
              Kepala BADAN PENDAPATAN DAERAH PROV. KALTENG Provinsi Kalimantan Tengah Menerangkan, bahwa kendaraan berikut ini :
              <ol>
                <li>
                  <Row>
                    <Col span={4}>Nomor Polisi</Col>
                    <Col span={20}>: {this.state.dataKendaraan.nomor_polisi}</Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col span={4}>Nama Pemilik</Col>
                    <Col span={20}>: {this.state.dataKendaraan.pemilik_nama}</Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col span={4}>Alamat</Col>
                    <Col span={20}>: {this.state.dataKendaraan.alamat_wp}</Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col span={4}>Jenis</Col>
                    <Col span={20}>: {this.state.dataKendaraan.jenis_kendaraan}</Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col span={4}>Tahun / CC</Col>
                    <Col span={20}>: {this.state.dataKendaraan.tahun_buat + " / " + this.state.dataKendaraan.cc}</Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col span={4}>Merk / Tipe</Col>
                    <Col span={20}>: {this.state.dataKendaraan.merk_kendaraan + " / " + this.state.dataKendaraan.tipe_nama}</Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col span={4}>Nomor Rangka</Col>
                    <Col span={20}>: {this.state.dataKendaraan.nomor_rangka}</Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col span={4}>Nomor Mesin</Col>
                    <Col span={20}>: {this.state.dataKendaraan.nomor_mesin}</Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col span={4}>Plat Dasar</Col>
                    <Col span={20}>: {this.state.dataKendaraan.warna_plat_nama}</Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col span={4}>Warna</Col>
                    <Col span={20}>: {this.state.dataKendaraan.warna}</Col>
                  </Row>
                </li>
              </ol>
            </div>
            <div className="berdasarkan">
              Berdasarkan catatan kami telah melunasi :
            </div>
            <table border={1}>
              <tr>
                <td>
                  <Row>
                    <Col span={4}>
                      - PKB sebesar
                    </Col>
                    <Col span={4}>
                      Rp. {this.state.dataKendaraan.jumlah_pkb.toLocaleString('in')}
                    </Col>
                    <Col span={4}>
                      Tgl. {moment(this.state.dataKendaraan.tgl_penetapan).format('DD-MM-YYYY')}
                    </Col>
                    <Col span={4}>
                      No. Kohir
                    </Col>
                    <Col span={8}>
                      {this.state.dataKendaraan.no_kohir}
                    </Col>
                  </Row>
                </td>
              </tr>
              <tr>
                <td>
                  <Row>
                    <Col span={4}>
                      - BBNKB sebesar
                    </Col>
                    <Col span={4}>
                      Rp. {this.state.dataKendaraan.jumlah_bbnkb.toLocaleString('in')}
                    </Col>
                    <Col span={4}>
                      Tgl. {moment(this.state.dataKendaraan.tgl_penetapan).format('DD-MM-YYYY')}
                    </Col>
                    <Col span={4}>
                      No. Skum
                    </Col>
                    <Col span={8}>
                      {this.state.dataKendaraan.no_skum}
                    </Col>
                  </Row>
                </td>
              </tr>
              <tr>
                <td>
                  <Row>
                    <Col span={8}>
                      Masa Pajak berlaku sampai dengan tanggal
                    </Col>
                    <Col span={16}>
                      Tgl. {moment(this.state.dataKendaraan.masa_pajak_sampai).format('DD-MM-YYYY')}
                    </Col>
                  </Row>
                </td>
              </tr>
            </table>
            Keterangan ini di berikan berhubungan kendaraan tersebut akan dipindahkan ke Daerah tujuan : KABUPATEN {this.state.dataKendaraan.kabupaten_tujuan_nama}
            <div className="biodata-fiskal">
              <Row>
                <Col span={4} className="titik-dua">Untuk Atas Nama</Col>
                <Col span={20}>{this.state.dataKendaraan.nama_tujuan}</Col>
                <Col span={4} className="titik-dua">Alamat Pemilik</Col>
                <Col span={20}>{this.state.dataKendaraan.alamat_tujuan}</Col>
              </Row>
            </div>
            Demikian untuk dipergunakan sebagaimana mestinya
            <Row justify="end pagebreak">
              <Col span={10} className="ttd-fiskal">
                PALANGKARAYA, {this.state.dataKendaraan.tanggal_fiskal}<br />
                <b>
                  An. KEPALA BADAN PENDAPATAN DAERAH<br />
                  PROPINSI KALIMANTAN TENGAH<br />
                  KASI PENETAPAN<br />
                  DI PALANGKARAYA
                </b>
                <div className="nama-ttd-fiskal">
                  {this.state.dataKendaraan.korektor_nama}
                </div>
                <div className="jabatan-ttd-fiskal">
                  PENATA
                </div>
                <div className="nip-ttd-fiskal">
                  NIP. {this.state.dataKendaraan.korektor_nip}
                </div>
              </Col>
            </Row>
            
            <div className='pagebreak'>
              <div className="header-surat" style={{position: "relative"}}>
                <div className="kop-logo">
                  <img src={this.config.get_site_info().logo_bw} height="90" />
                </div>
                <div className="kop">
                  PEMERINTAH PROPINSI KALIMANTAN TENGAH<br />
                  BADAN PENDAPATAN DAERAH PROV. KATENG
                </div>
                <div className="sub-kop">
                  UNIT PELAKSANA TKNIS PELAYANAN PENDAPATAN DAERAH<br />
                  DI PALANGKARAYA<br />
                </div>
                <div className="sub-kop2">
                  Jalan R.T.A. Milino Km. 5,5 Telp 05536-32275523
                </div>
              </div>
              <Row justify="space-between">
                <Col span={11}>
                  {"Nomor : " + this.state.dataKendaraan.nomor_surat}
                </Col>
                <Col span={2}>
                  Kepada<br />
                  Yth. Sdr.
                </Col>
                <Col span={11}>
                  Kepala Badan / Kepala Unit Pelaksana Teknis Pendapatan<br />
                  Proinsi KALIMANTAN TENGAH<br />
                  di KABUPATEN KATINGAN
                </Col>
                <Col span={24} style={{ textAlign: "center", marginTop: 10, marginBottom: 5, fontWeight: 'bold' }}>
                  DAFTAR PENGANTAR
                </Col>
                <Col span={24}>
                  <table border={1} cellpadding="5">
                    <tr>
                      <td>No</td>
                      <td>Jenis Yang Dikirim</td>
                      <td>Banyaknya</td>
                      <td>Keterangan</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td valign="top">
                        Bersama ini kami kirimkan :
                        <br />
                        <br />
                        Tindasan Surat Keterangan Fiskal bagi Kendaraan yang dimutasikan ke Unit Pelaksana Teknis Dinas Pendapatan Provinsi{this.state.dataKendaraan.provinsi_tujuan_nama} di KABUPATEN {this.state.dataKendaraan.kabupaten_tujuan_nama}
                      </td>
                      <td valign="top">
                        berkas
                      </td>
                      <td valign="top">
                        Dikirim dengan hormat untuk dipergunakan sebagaimana mestinya
                      </td>
                    </tr>
                  </table>
                </Col>
                <Col span={5}>
                  Diterima tanggal, .......<br />
                  Yang Menerima
                </Col>
                <Col span={19} style={{ textAlign: 'right' }}>
                  <div style={{ textAlign: 'center' }}>
                    PALANGKARAYA, {this.state.dataKendaraan.tanggal_fiskal}<br />
                    <b>
                      KEPALA SEKSI PENAGIHAN, PEMBUKUAN DAN PELAPORAN<br />
                      BADAN PENDAPATAN DAERAH PROP. KALIMANTAN TENGAH<br />
                      DI PALANGKARAYA
                    </b>
                    <div className="nama-ttd-fiskal">
                      {this.state.dataKendaraan.korektor_nama}
                    </div>
                    <div className="jabatan-ttd-fiskal">
                      PENATA
                    </div>
                    <div className="nip-ttd-fiskal">
                      NIP. {this.state.dataKendaraan.korektor_nip}
                    </div>
                  </div>
                </Col>
              </Row>

            </div>



          </div>
        )}
      </div>

    )
  }


}

const mapStateToProps = function (state) {
  return state.configReducers
}

export default connect(
  mapStateToProps,
  {
    initHandler,
    setConfig,
    ajaxViewHandler,
    ajaxHandler
  }
)(SuratFiskal);