import React from 'react';
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import Highlighter from 'react-highlight-words';
import { Link } from "react-router-dom";
import {
    Breadcrumb,
    Card,
    Row,
    Col,
    Input,
    Button,
    Table,
    Modal,
    message,
    Alert,
    Select,
    Spin,
    Form,
    DatePicker,
} from 'antd';
import {
    TeamOutlined,
    SearchOutlined,
    PlusOutlined,
    FormOutlined,
    CloseSquareOutlined,
    ExclamationCircleOutlined,
    DiffOutlined,
    RedoOutlined,
    CopyOutlined,
    PrinterOutlined
} from '@ant-design/icons';

import axios from 'axios';

import { connect } from 'react-redux';
import {
    initHandler,
    ajaxViewHandler,
    ajaxHandler,
    setConfig,
} from "../../store/actions";

import Config from '../../Config';
import { Helmet } from "react-helmet";

import moment from "moment";

import '../../assets/laporan.css';

const configClass = {
    title: 'LAPORAN SWDKLLJ',
    apiUrl: {
        data: 'laporan/api/kasir/',
        warna_plat: "master/api/plat",
        jenis_kendaraan: "master/api/jenis/kendaraan/",
        payment: "master/api/payment/",
        upt: "pegawai/api/upt/",
        kabupaten: "master/api/kabupaten/",
    },
    pageUrl: {
        cetak: '/laporan/swdkllj'
    },
    breadCrumb: [
        (
            <span>
                <CopyOutlined /> laporan
            </span>
        ),
        'swdkllj'
    ]

}

const config = new Config();

class LapSwdkllj extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: null,
            err: false,

            loadingWarnaPlat: false,
            listWarnaPlat: [],

            loadingJenisKendaraan: false,
            listJenisKendaraan: [],

            loadingPayment: false,
            listPayment: [],

            namauptppd: 'SAMSAT PALANGKARAYA',

            filter: "",

            loadingUpt: false,
            listUpt: [],

            loadingKabupaten: null,
            listKabupaten: [],

            mulai_dari: "",
            sampai_dengan: "",



        }
    }

    UNSAFE_componentWillMount() {
        this.props.initHandler();
    }

    componentDidMount() {
        this.loadMasterList();
        // this.getData();
    }

    loadMasterList() {
        const rule = [
            {
                destination: {
                    stateProgressName: "loadingJenisKendaraan",
                    endPoint: configClass.apiUrl.jenis_kendaraan,
                    stateDestination: "listJenisKendaraan",
                },
                nextDestination: {
                    stateProgressName: "loadingWarnaPlat",
                    endPoint: configClass.apiUrl.warna_plat,
                    stateDestination: "listWarnaPlat",
                    next: null,
                },
            },
            {
                destination: {
                    stateProgressName: "loadingPayment",
                    endPoint: configClass.apiUrl.payment,
                    stateDestination: "listPayment",
                },
                nextDestination: {
                    stateProgressName: "loadingUpt",
                    endPoint: configClass.apiUrl.upt,
                    stateDestination: "listUpt",
                    next: null,
                },
            },
            {
                destination: {
                    stateProgressName: "loadingKabupaten",
                    endPoint: configClass.apiUrl.kabupaten,
                    stateDestination: "listKabupaten",
                },
                nextDestination: null,
            }
        ];

        this.setState({
            errComponent: false,
        });

        rule.map((item, index) => {
            if (index == rule.length - 1) {
                this.getMasterList(item, true);
            } else {
                this.getMasterList(item);
            }
        });
    }

    async getMasterList(rule, isFinal = false) {
        var destination = rule.destination;
        var nextDestination = rule.nextDestination;
        this.setState({ [destination.stateProgressName]: true });
        this.props
            .ajaxViewHandler("get", destination.endPoint)
            .then(() => {
                const data = this.props.responseMsg;
                if (this.props.isSuccess) {
                    if (data.count != undefined) {
                        let newState = null;
                        if (this.state[destination.stateDestination].length == 0) {
                            newState = data;
                        } else {
                            if (destination.stateProgressName.includes("_searching")) {
                                newState = data;
                            } else {
                                const oldState = this.state[destination.stateDestination];
                                newState = {
                                    count: data.count,
                                    next: data.next,
                                    previous: data.previous,
                                    results: [...oldState.results, ...data.results],
                                };
                            }
                        }
                        this.setState({
                            [destination.stateDestination]: newState,
                        });
                    } else if (data.results != undefined) {
                        this.setState({
                            [destination.stateDestination]: data.results,
                        });
                    } else {
                        if (Array.isArray(data)) {
                            this.setState({
                                [destination.stateDestination]: data,
                            });
                        } else {
                            this.setState({
                                [destination.stateDestination]: [data],
                            });
                        }
                    }
                    if (isFinal) {
                        if (this.props.match.params.id != undefined) {
                            // this.preview(this.props.match.params.id);
                        }
                    }
                } else {
                    this.setState({
                        errComponent: true,
                    });
                }
                this.setState({ [destination.stateProgressName]: false });
            })
            .catch((response) => {
                //console.log('gagal_get_master', response);
                message.error({
                    content: "gagal request data, coba lagi",
                    duration: 2,
                    key: "toast",
                });
                this.setState({
                    errTitle: "gagal request data master",
                    errMsg: response,
                });
                this.setState({ [destination.stateProgressName]: false });
            });
        //next function
        if (nextDestination != null) {
            const next = {
                destination: {
                    stateProgressName: nextDestination.stateProgressName,
                    endPoint: nextDestination.endPoint,
                    stateDestination: nextDestination.stateDestination,
                },
                nextDestination: nextDestination.next,
            };
            this.getMasterList(next);
        }
    }

    generateSelect(stateName, url, key, value, keyDisplay = "") {
        return this.state["loading" + stateName] ? (
            <img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
        ) : (
            <Select
                showSearch
                placeholder={"Cari / Pilih " + stateName}
                optionFilterProp="children"
                onSearch={(val) => {
                    const rule = {
                        destination: {
                            stateProgressName: "loading" + stateName + "_searching",
                            endPoint: url + "?search=" + val,
                            stateDestination: "list" + stateName,
                        },
                        nextDestination: null,
                    };
                    this.getMasterList(rule);
                }}
                onPopupScroll={(e) => {
                    e.persist();
                    let target = e.target;
                    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
                        if (
                            this.state["list" + stateName].next != undefined &&
                            this.state["list" + stateName].next != null
                        ) {
                            const rule = {
                                destination: {
                                    stateProgressName: "loading" + stateName + "Paging",
                                    endPoint: this.state["list" + stateName].next,
                                    stateDestination: "list" + stateName,
                                },
                                nextDestination: null,
                            };
                            this.getMasterList(rule);
                        }
                    }
                }}
                allowClear
                style={{
                    width: "100%",
                }}
                onChange={(val) => {
                    if (stateName == "Kabupaten") {
                        const rule = {
                            destination: {
                                stateProgressName: "loadingKecamatan",
                                endPoint: configClass.apiUrl.kecamatan + "?regencies=" + val,
                                stateDestination: "listKecamatan",
                            },
                            nextDestination: null,
                        };
                        this.getMasterList(rule);
                    }
                }}
                size={"small"}
            >
                {this.state["loading" + stateName + "_searching"] == true && (
                    <Select.Option value="">
                        <Spin size="small" />
                    </Select.Option>
                )}
                {this.state["list" + stateName].count != undefined
                    ? this.state["list" + stateName].results.map((item) => (
                        <Select.Option value={item[key]}>
                            {keyDisplay == ""
                                ? item[key] + " - " + item[value]
                                : item[keyDisplay] + " - " + item[value]}
                        </Select.Option>
                    ))
                    : this.state["list" + stateName].map((item) => (
                        <Select.Option value={item[key]}>
                            {keyDisplay == ""
                                ? item[key] + " - " + item[value]
                                : item[keyDisplay] + " - " + item[value]}
                        </Select.Option>
                    ))}
                {this.state["list" + stateName] != undefined &&
                    this.state["list" + stateName].next != undefined && (
                        <Select.Option value="">
                            <Spin size="small" />
                        </Select.Option>
                    )}
            </Select>
        );
    }

    handleSubmit = (values) => {
        var filter = "";
        for (var key in values) {
            if (values[key] != undefined) {
                if (filter == "") {
                    filter = "?" + key + "=" + values[key];
                } else {
                    filter += "&" + key + "=" + values[key];
                }
            }
        }
        this.setState({
            filter: filter
        });
        this.getData(filter)
    };

    async getData(filter) {
        message.loading({ content: 'Menampilkan laporan...', duration: 0, key: 'toast' });
        this.setState({
            loading: true,
            err: false,
            data: null,
        });

        // this.props.ajaxViewHandler('get', configClass.apiUrl.data+"?warna_plat=1&jenis_kendaraan&roda=2&mulai_dari=23-10-2020&sampai_dengan=23-10-2021")
        this.props.ajaxViewHandler('get', configClass.apiUrl.data + filter)
            .then(() => {
                const data = this.props.responseMsg;
                if (this.props.isSuccess) {
                    message.destroy();
                    this.setState({
                        loading: false,
                        data: data,
                        err: false
                    });
                    setTimeout(function () {
                        // window.print();

                    }, 1000);
                } else {
                    const errTitle = 'error get laporan';
                    this.setState({
                        loading: false,
                        data: null,
                        err: true,
                        errMsg: this.props.responseMsg
                    });
                    message.error({ content: errTitle, duration: 2, key: 'toast' });
                }
            }).catch((response) => {
                this.setState({
                    loading: false,
                    data: null,
                    err: true,
                    errMsg: 'unknown response'
                });
                console.log('gagal_get_laporan', response);
                message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
            });
    }

    render() {
        // if(this.props.isSuperadmin == false){
        // 	return <Redirect to="/admin/forbidden" />
        // }
        return (
            <div>
                <Helmet>
                    <title>{configClass.title + " | " + config.get_site_info().name}</title>
                </Helmet>
                <Breadcrumb>
                    {configClass.breadCrumb.map(item => (
                        <Breadcrumb.Item>
                            {item}
                        </Breadcrumb.Item>
                    ))}
                </Breadcrumb>
                <div className="card">
                    <Row className="isi">
                        <Col span={24}>
                            <Card
                                className="konten"
                                style={{ overflow: "auto" }}
                            >
                                <Form
                                    layout="vertical"
                                    name="fdata"
                                    onFinish={this.handleSubmit}
                                    size="small"
                                    ref={this.formRef}
                                >
                                    
                                    <Row>
                                        <Col span={4} className="titik-dua">Tanggal</Col>
                                        <Col span={8}>
                                            <Form.Item name="mulai_dari_picker">
                                                <DatePicker
                                                    placeholder="dd-mm-yyyy"
                                                    format="DD-MM-YYYY"
                                                    style={{ width: "100%" }}
                                                    onChange={(date, dateString) => {
                                                        this.formRef.current.setFieldsValue({
                                                            mulai_dari: dateString,
                                                        });
                                                        this.setState({
                                                            mulai_dari: dateString
                                                        })
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="mulai_dari"
                                                style={{
                                                    display: "none",
                                                }}
                                            >
                                                <Input type="hidden" />
                                            </Form.Item>
                                        </Col>
                                        <Col style={{ paddingLeft: 5 }}>
                                            <Button
                                                key="btn-simpan"
                                                loading={this.state.loading}
                                                type="primary"
                                                htmlType="submit"
                                                disabled={this.state.loading ? true : false}
                                                size="small"
                                            >
                                                {(!this.state.loading && <SearchOutlined />)}
                                                Proses
                                            </Button>
                                        </Col>
                                        {(this.state.data != null &&
                                            <Col style={{ paddingLeft: 5 }}>
                                                <a href={configClass.pageUrl.cetak + this.state.filter} target="_blank">
                                                    <Button
                                                        key="btn-cetak"
                                                        loading={this.state.loading}
                                                        type="default"
                                                        htmlType="button"
                                                        disabled={this.state.loading ? true : false}
                                                        size="small"
                                                    >
                                                        {(!this.state.loading && <PrinterOutlined />)}
                                                        Cetak
                                                    </Button>
                                                </a>
                                            </Col>
                                        )}
                                    </Row>
                                </Form>
                            </Card>
                        </Col>

                        <Col span={24}>
                            <Card
                                className="konten"
                                style={{ overflow: "auto" }}
                            >
                                {(this.state.loading == true &&
                                    <div style={{ color: '#72BBFF', fontSize: 17 }}>
                                        <Spin style={{ marginRight: 10 }} /> memproses laporan....
                                    </div>
                                )}
                                {(this.state.data != null &&
                                    <div className="kertas-laporan">
                                        <div className="judul-laporan" style={{ borderBottom: "double 5px #000000", textAlign:"center"}}>
                                           <span style={{fontWeight:"bolder"}}> PENGANTAR PENYETORAN UANG </span>
                                            <br />
                                            TANGGAL : {this.state.mulai_dari == this.state.sampai_dengan ? this.state.mulai_dari : this.state.mulai_dari + " s/d " + this.state.sampai_dengan}
                                        </div>

                                        <table className="laporan-table" cellPadding="5" style={{ border: "1px dotted #000000" }}>
                                            <tr>
                                                <td>1. POKOK SWDKLLJ</td>
                                                <td>Rp.</td>
                                                <td className="teks-kanan">{this.state.data != null && this.state.data.data.swdkllj.pokok.toLocaleString('id')}</td>
                                            </tr>
                                            <tr>
                                                <td>2. DENDA SWDKLLJ</td>
                                                <td>Rp.</td>
                                                <td className="teks-kanan">{this.state.data != null && this.state.data.data.swdkllj.denda.toLocaleString('id')}</td>
                                            </tr>
                                            <tr>
                                                <td className="teks-kanan">TOTAL SWDKLLJ</td>
                                                <td>Rp.</td>
                                                <td className="teks-kanan">{this.state.data != null && this.state.data.data.swdkllj.jumlah.toLocaleString('id')}</td>
                                            </tr>

                                        </table>
                                        {(this.state.data.ttd != undefined &&
											<Row justify="space-between" style={{ marginTop: 10 }}>
												<Col span={24} className='text-center'>
													{this.state.data.footer.upt}, TGL {this.state.mulai_dari}
												</Col>
												<Col span={8} className="teks-tengah">
													{this.state.data.ttd.kiri.jabatan}<br />
													<div style={{ marginTop: 50 }}>
														<u>{this.state.data.ttd.kiri.nama}</u><br />
														NIP : {this.state.data.ttd.kiri.nip}
													</div>
												</Col>
                                                <Col span={8} className="teks-tengah">
													{this.state.data.ttd.tengah.jabatan}<br />
													<div style={{ marginTop: 50 }}>
														<u>{this.state.data.ttd.tengah.nama}</u><br />
														NIP : {this.state.data.ttd.tengah.nip}
													</div>
												</Col>
												<Col span={8} className="teks-tengah">
													{this.state.data.ttd.kanan.jabatan}<br />
													<div style={{ marginTop: 50 }}>
														<u>{this.state.data.ttd.kanan.nama}</u><br />
														NIP : {this.state.data.ttd.kanan.nip}
													</div>
												</Col>

											</Row>
										)}
                                    </div>
                                )}
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return state.configReducers
}

export default connect(
    mapStateToProps,
    {
        initHandler,
        setConfig,
        ajaxViewHandler,
        ajaxHandler
    }
)(LapSwdkllj);