import React from "react";
import { Link, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Breadcrumb,
  Card,
  Row,
  Col,
  Avatar,
  Form,
  Input,
  Button,
  Space,
  Select,
  DatePicker,
  message,
  Alert,
  Spin,
  Modal,
  Skeleton,
  Table,
  Tag,
  Divider
} from "antd";
import {
  DiffOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DollarOutlined,
  BarcodeOutlined,
  UserAddOutlined,
  PrinterOutlined,
  UserOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  CarOutlined,
} from "@ant-design/icons";
import moment from "moment";
import NumberFormat from "react-number-format";

import axios from "axios";
import Config from "../../Config";

import { connect } from "react-redux";
import {
  initHandler,
  ajaxViewHandler,
  ajaxHandler,
  setConfig,
} from "../../store/actions";
import { Helmet } from "react-helmet";

const FormItem = Form.Item;

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 19,
      offset: 5,
    },
    lg: {
      span: 19,
      offset: 5,
    },
  },
};

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const configClass = {
  title: "form pembayaran",
  apiUrl: {
    penetapan: "transaksi/api/tagihan/",
    pembayaran: "transaksi/api/tagihan/",
    payment: "master/api/payment/"
  },
  pageUrl: {
    cetak_no: "/addon/cetak_kutipan_skp/",
  },
  breadCrumb: [
    <span>
      <CarOutlined /> transaksi
    </span>,
    "pembayaran",
  ],
};

const config = new Config();

class FormPembayaran extends React.Component {
  formRef = React.createRef();
  formRefScroll = React.createRef();
  formRefPembayaran = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      errComponent: false,
      errTitle: "",
      errMsg: "",
      loadingButton: false,

      cari_no_pol: undefined,

      isOpenForm: false,

      aksi: "tambah",
      method: "post",
      idLama: "",

      pokok_bbnkb: 0,
      denda_bbnkb: 0,
      tunggakan_pokok_bbnkb: 0,
      tunggakan_denda_bbnkb: 0,

      pokok_pkb: 0,
      denda_pkb: 0,
      tunggakan_pkb: 0,
      tunggakan_pkb: 0,

      pokok_swdkllj: 0,
      denda_swdkllj: 0,
      tunggakan_swdkllj: 0,
      tunggakan_swdkllj: 0,

      pokok_admstnk: 0,
      denda_admstnk: 0,
      tunggakan_admstnk: 0,
      tunggakan_admstnk: 0,

      pokok_admtnk: 0,
      denda_admtnk: 0,
      tunggakan_admtnk: 0,
      tunggakan_admtnk: 0,

      pokok_sp3: 0,
      denda_sp3: 0,
      tunggakan_sp3: 0,
      tunggakan_sp3: 0,

      loadingPayment: false,
      listPayment: [],

      status: "1",

      isModalVisible: false,

    };
  }

  UNSAFE_componentWillMount() {
    this.props.initHandler();
  }

  componentDidMount() {
    this.loadMasterList();
  }

  showModalPembayaran(status) {
    this.setState({
      isModalVisible: status
    });
  }

  loadMasterList() {
    const rule = [
      {
        destination: {
          stateProgressName: "loadingPayment",
          endPoint: configClass.apiUrl.payment,
          stateDestination: "listPayment",
        },
        nextDestination: null,
      }
    ];

    this.setState({
      errComponent: false,
    });

    rule.map((item, index) => {
      if (index == rule.length - 1) {
        this.getMasterList(item, true);
      } else {
        this.getMasterList(item);
      }
    });
  }

  async getMasterList(rule, isFinal = false) {
    var destination = rule.destination;
    var nextDestination = rule.nextDestination;
    this.setState({ [destination.stateProgressName]: true });
    this.props
      .ajaxViewHandler("get", destination.endPoint)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (data.count != undefined) {
            let newState = null;
            if (this.state[destination.stateDestination].length == 0) {
              newState = data;
            } else {
              if (destination.stateProgressName.includes("_searching")) {
                newState = data;
              } else {
                const oldState = this.state[destination.stateDestination];
                newState = {
                  count: data.count,
                  next: data.next,
                  previous: data.previous,
                  results: [...oldState.results, ...data.results],
                };
              }
            }
            this.setState({
              [destination.stateDestination]: newState,
            });
          } else if (data.results != undefined) {
            this.setState({
              [destination.stateDestination]: data.results,
            });
          } else {
            if (Array.isArray(data)) {
              this.setState({
                [destination.stateDestination]: data,
              });
            } else {
              this.setState({
                [destination.stateDestination]: [data],
              });
            }
          }
          if (isFinal) {
            if (this.props.match.params.id != undefined) {
              // this.preview(this.props.match.params.id);
            }
          }
        } else {
          this.setState({
            errComponent: true,
          });
        }
        this.setState({ [destination.stateProgressName]: false });
      })
      .catch((response) => {
        //console.log('gagal_get_master', response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({
          errTitle: "gagal request data master",
          errMsg: response,
        });
        this.setState({ [destination.stateProgressName]: false });
      });
    //next function
    if (nextDestination != null) {
      const next = {
        destination: {
          stateProgressName: nextDestination.stateProgressName,
          endPoint: nextDestination.endPoint,
          stateDestination: nextDestination.stateDestination,
        },
        nextDestination: nextDestination.next,
      };
      this.getMasterList(next);
    }
  }

  generateSelect(stateName, url, key, value, keyDisplay = "") {
    return this.state["loading" + stateName] ? (
      <img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
    ) : (
      <Select
        autoFocus
        showSearch
        placeholder={"Cari / Pilih " + stateName}
        optionFilterProp="children"
        onSearch={(val) => {
          const rule = {
            destination: {
              stateProgressName: "loading" + stateName + "_searching",
              endPoint: url + "?search=" + val,
              stateDestination: "list" + stateName,
            },
            nextDestination: null,
          };
          this.getMasterList(rule);
        }}
        onPopupScroll={(e) => {
          e.persist();
          let target = e.target;
          if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (
              this.state["list" + stateName].next != undefined &&
              this.state["list" + stateName].next != null
            ) {
              const rule = {
                destination: {
                  stateProgressName: "loading" + stateName + "Paging",
                  endPoint: this.state["list" + stateName].next,
                  stateDestination: "list" + stateName,
                },
                nextDestination: null,
              };
              this.getMasterList(rule);
            }
          }
        }}
        allowClear
        style={{
          width: "100%",
        }}
        onChange={(val) => {
          if (stateName == "Kabupaten") {
            const rule = {
              destination: {
                stateProgressName: "loadingKecamatan",
                endPoint: configClass.apiUrl.kecamatan + "?regencies=" + val,
                stateDestination: "listKecamatan",
              },
              nextDestination: null,
            };
            this.getMasterList(rule);
          }
        }}
        size={"small"}
      >
        {this.state["loading" + stateName + "_searching"] == true && (
          <Select.Option value="">
            <Spin size="small" />
          </Select.Option>
        )}
        {this.state["list" + stateName].count != undefined
          ? this.state["list" + stateName].results.map((item) => (
            <Select.Option value={item[key]}>
              {keyDisplay == ""
                ? item[key] + " - " + item[value]
                : item[keyDisplay] + " - " + item[value]}
            </Select.Option>
          ))
          : this.state["list" + stateName].map((item) => (
            <Select.Option value={item[key]}>
              {keyDisplay == ""
                ? item[key] + " - " + item[value]
                : item[keyDisplay] + " - " + item[value]}
            </Select.Option>
          ))}
        {this.state["list" + stateName] != undefined &&
          this.state["list" + stateName].next != undefined && (
            <Select.Option value="">
              <Spin size="small" />
            </Select.Option>
          )}
      </Select>
    );
  }

  searchKendaraan = (no_polisi) => {
    this.previewData(no_polisi);
  };

  async previewData(no_polisi) {
    message.loading({
      content: "Proses pencarian...",
      duration: 0,
      key: "toast",
    });

    this.setState({
      errTitle: '',
      errMsg: '',
    });

    this.props
      .ajaxViewHandler(
        "get",
        configClass.apiUrl.penetapan + "?nomor_polisi=KH" + no_polisi
      )
      .then(() => {
        const datas = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (datas.results.length == 0) {
            this.setState({
              isOpenForm: false,
            });
            message.error({ content: "nomor kendaraan tidak ditemukan", duration: 2, key: "toast" });
          } else {
            this.setState({
              isOpenForm: true,
            }, () => {
              this.formRefScroll.current.scrollTo(0, 0);
            });
            const data = datas.results[0];
            this.formRef.current.setFieldsValue(data);
            const total_pokok_berjalan = data.pokok_bbnkb + data.pokok_pkb + data.pokok_swdkllj + data.pokok_admstnk + data.pokok_admtnk;
            const total_denda_berjalan = data.denda_bbnkb + data.denda_pkb + data.denda_swdkllj + data.denda_admstnk + data.denda_admtnk;
            const total_jumlah_berjalan = data.jumlah_bbnkb + data.jumlah_pkb + data.jumlah_swdkllj + data.jumlah_admstnk + data.jumlah_admtnk;
            const total_pokok_tunggakan = data.tunggakan_pokok_bbnkb + data.tunggakan_pokok_pkb + data.tunggakan_pokok_swdkllj + data.tunggakan_pokok_admstnk + data.tunggakan_pokok_admtnk;
            const total_denda_tunggakan = data.tunggakan_denda_bbnkb + data.tunggakan_denda_pkb + data.tunggakan_denda_swdkllj + data.tunggakan_denda_admstnk + data.tunggakan_denda_admtnk;
            const total_jumlah_tunggakan = data.tunggakan_jumlah_bbnkb + data.tunggakan_jumlah_pkb + data.tunggakan_jumlah_swdkllj + data.tunggakan_jumlah_admstnk + data.tunggakan_jumlah_admtnk;

            this.formRef.current.setFieldsValue({
              total_pokok_berjalan: total_pokok_berjalan, //data.pokok_bbnkb+data.pokok_pkb+data.pokok_swdkllj+data.pokok_admstnk+data.pokok_admtnk,
              total_denda_berjalan: total_denda_berjalan, //data.denda_bbnkb+data.denda_pkb+data.denda_swdkllj+data.denda_admstnk+data.denda_admtnk,
              total_jumlah_berjalan: total_jumlah_berjalan, //data.jumlah_bbnkb+data.jumlah_pkb+data.jumlah_swdkllj+data.jumlah_admstnk+data.jumlah_admtnk,

              total_pokok_tunggakan: total_pokok_tunggakan, //data.tunggakan_pokok_bbnkb+data.tunggakan_pokok_pkb+data.tunggakan_pokok_swdkllj+data.tunggakan_pokok_admstnk+data.tunggakan_pokok_admtnk,
              total_denda_tunggakan: total_denda_tunggakan, //data.tunggakan_denda_bbnkb+data.tunggakan_denda_pkb+data.tunggakan_denda_swdkllj+data.tunggakan_denda_admstnk+data.tunggakan_denda_admtnk,
              total_jumlah_tunggakan: total_jumlah_tunggakan, //data.tunggakan_jumlah_bbnkb+data.tunggakan_jumlah_pkb+data.tunggakan_jumlah_swdkllj+data.tunggakan_jumlah_admstnk+data.tunggakan_jumlah_admtnk,

              grand_total: total_jumlah_berjalan + total_jumlah_tunggakan,
            });
            for (var key in data) {
              const dateRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4}$/;
              const dateTimeRegex =
                /^[0-9]{2}\-[0-9]{2}\-[0-9]{4} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;

              const dateRegex2 = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
              const dateTimeRegex2 =
                /^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
              if (dateRegex.test(data[key])) {
                //jika format tanggal
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key], "DD-MM-YYYY"),
                  //[key]: moment(data[key], 'DD-MM-YYYY')
                });
              } else if (dateTimeRegex.test(data[key])) {
                //jika format tanggal dan jam
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key], "DD-MM-YYYY HH:mm:ss"),
                });
              } else if (dateRegex2.test(data[key])) {
                //jika format tanggal
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key], "DD-MM-YYYY"),
                  //[key]: moment(data[key], 'DD-MM-YYYY')
                });
              } else if (dateTimeRegex2.test(data[key])) {
                //jika format tanggal dan jam
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key], "DD-MM-YYYY HH:mm:ss"),
                });
              }

              if (key == "tgl_daftar" || key == "tgl_penetapan") {
                this.formRef.current.setFieldsValue({
                  // [key + "_picker"]: moment(data[key]),// "DD-MM-YYYY"),
                  [key]: moment(data[key]).format("DD-MM-YYYY"),
                });
              } else if (key == "pokok_bbnkb" || key == "denda_bbnkb" || key == "jumlah_bbnkb" || key == "tunggakan_pokok_bbnkb" || key == "tunggakan_denda_bbnkb" || key == "tunggakan_jumlah_bbnkb") {
                this.formRef.current.setFieldsValue({
                  [key + "_preview"]: data[key]
                });
              } else if (key == "pokok_pkb" || key == "denda_pkb" || key == "jumlah_pkb" || key == "tunggakan_pokok_pkb" || key == "tunggakan_denda_pkb" || key == "tunggakan_jumlah_pkb") {
                this.formRef.current.setFieldsValue({
                  [key + "_preview"]: data[key]
                });
              } else if (key == "pokok_swdkllj" || key == "denda_swdkllj" || key == "jumlah_swdkllj" || key == "tunggakan_pokok_swdkllj" || key == "tunggakan_denda_swdkllj" || key == "tunggakan_jumlah_swdkllj") {
                this.formRef.current.setFieldsValue({
                  [key + "_preview"]: data[key]
                });
              } else if (key == "pokok_admstnk" || key == "denda_admstnk" || key == "jumlah_admstnk" || key == "tunggakan_pokok_admstnk" || key == "tunggakan_denda_admstnk" || key == "tunggakan_jumlah_admstnk") {
                this.formRef.current.setFieldsValue({
                  [key + "_preview"]: data[key]
                });
              } else if (key == "pokok_admtnk" || key == "denda_admtnk" || key == "jumlah_admtnk" || key == "tunggakan_pokok_admtnk" || key == "tunggakan_denda_admtnk" || key == "tunggakan_jumlah_admtnk") {
                this.formRef.current.setFieldsValue({
                  [key + "_preview"]: data[key]
                });
              } else if (key == "pokok_sp3" || key == "denda_sp3" || key == "jumlah_sp3" || key == "tunggakan_pokok_sp3" || key == "tunggakan_denda_sp3" || key == "tunggakan_jumlah_sp3") {
                this.formRef.current.setFieldsValue({
                  [key + "_preview"]: data[key]
                });
              } else if (key == "layanan_list") {
                this.formRef.current.setFieldsValue({
                  layanan_nama: data[key][0] != undefined ? data[key][0].nama : '',
                  layanan_nama2: data[key][1] != undefined ? data[key][1].nama : '',
                  layanan_nama3: data[key][3] != undefined ? data[key][3].nama : '',
                  layanan_nama4: data[key][4] != undefined ? data[key][4].nama : ''
                });
              } else if (key == 'status') {
                this.setState({
                  status: data[key]
                });
              }

              /*else {
               if (key == "pemilik_nama") {
                 this.formRef.current.setFieldsValue({
                   nama: data[key],
                 });
               } else if (key == "pemilik_alamat") {
                 this.formRef.current.setFieldsValue({
                   alamat: data[key],
                 });
               } else if (key == "pemilik_no_hp") {
                 this.formRef.current.setFieldsValue({
                   no_hp: data[key],
                 });
               } else if (key == "pemilik_nik") {
                 this.formRef.current.setFieldsValue({
                   nik: data[key],
                 });
               }else if (key == "nomor_polisi_tengah") {
                 this.formRef.current.setFieldsValue({
                   no_polisi_tengah: data[key],
                 });
               }else if (key == "nomor_polisi_akhir") {
                 this.formRef.current.setFieldsValue({
                   no_polisi_belakang: data[key],
                 });
               } else {
                 this.formRef.current.setFieldsValue({
                   [key]: data[key],
                 });
               }
             }*/
              // if(key == 'gambar'){
              //  this.setState({
              //    preview_gambar: data[key]
              //  });
              // }else{
              //  this.formRef.current.setFieldsValue({
              //    [key]: data[key]
              //  });
              // }
            }
            message.destroy();
            this.setState({
              aksi: "edit",
              method: "put",
              idLama: data.id + "/",
            });
          }
        } else {
          const errTitle = "error preview data lama";
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
          message.error({ content: errTitle, duration: 2, key: "toast" });
        }
      })
      .catch((response) => {
        console.log("gagal_get_preview", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  handleSubmit = (values) => {
    this.postDataPembayaran(values);
  };

  async getPasienById(val) {
    message.loading({
      content: "Display data pasien...",
      duration: 0,
      key: "toast",
    });
    this.setState({
      errTitle: "",
      errMsg: "",
      dataPasien: null,
      skeletonPasien: true,
    });
    this.props
      .ajaxViewHandler("get", configClass.apiUrl.pasien + "/" + val)
      .then(() => {
        this.setState({
          skeletonPasien: false,
        });
        if (this.props.isSuccess) {
          message.destroy();

          var response = this.props.responseMsg;
          this.setState({
            dataPasien: response,
          });
        } else {
          const errTitle = "gagal menampilkan data pasien";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
        }
      })
      .catch((response) => {
        console.log("gagal_preview_pasien", response);
        this.setState({
          skeletonPasien: false,
        });
        message.error({
          content: "kesalahan pengiriman data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  reset = () => {
    this.setState({
      cari_no_pol: undefined,
      isOpenForm: false,
    });
  };

  async postDataPembayaran(datas) {
    message.loading({ content: "Memproses...", duration: 0, key: "toast" });
    this.setState({
      errTitle: "",
      errMsg: "",
      loadingButton: true,
    });
    this.props
      .ajaxHandler("post", configClass.apiUrl.pembayaran + this.state.idLama + 'pembayaran/', datas, false, true)
      .then(() => {
        if (this.props.isSuccess) {
          var response = this.props.responseMsg;
          var that = this;

          message.success({ content: 'berhasil menyimpan data pembayaran', duration: 2, key: 'toast' });
          this.setState({
            loadingButton: false,
            isModalVisible: false,
            isOpenForm: false,
          });

          // Modal.confirm({
          //     title: 'Penetapan Biaya Berhasil',
          //     icon: <CheckCircleOutlined style={{color: 'green'}} />,
          //     content: 'tekan cetak untuk mencetak, tekan selesai untuk melanjutkan',
          //     okText: <a href={configClass.pageUrl.cetak_no+response.id} target="_blank"><PrinterOutlined /> Cetak</a>,
          //     cancelText: 'Selesai',
          //     onOk() {
          //       that.reset()
          //     },
          //     onCancel() {
          //       that.reset()
          //     }
          // });

        } else {
          const errTitle = "gagal menyimpan data penetapan";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            loadingButton: false,
            errTitle: errTitle,
            errMsg: JSON.stringify(this.props.responseMsg),
          });
        }
      })
      .catch((response) => {
        console.log("gagal_post_penetapan", response);
        this.setState({
          loadingButton: false,
        });
        message.error({
          content: "kesalahan pengiriman data penetapan, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  // async postDataKendaraan(datas, idPemilik) {
  //   var dataKendaraan = datas;
  //   dataKendaraan.pemilik = idPemilik;
  //   dataKendaraan.nomor_polisi =
  //     datas.no_polisi_depan + datas.no_polisi_tengah + datas.no_polisi_belakang;
  //   dataKendaraan.nomor_polisi_lama =
  //     datas.no_polisi_depan_lama +
  //     datas.no_polisi_tengah_lama +
  //     datas.no_polisi_belakang_lama;

  //   message.loading({ content: "Memproses...", duration: 0, key: "toast" });
  //   this.props
  //     .ajaxHandler(
  //       this.state.method,
  //       configClass.apiUrl.kendaraan + this.state.idLama,
  //       dataKendaraan,
  //       true,
  //       true
  //     )
  //     .then(() => {
  //       if (this.props.isSuccess) {
  //         message.success({
  //           content: "berhasil menyimpan pendaftaran",
  //           duration: 2,
  //           key: "toast",
  //         });
  //         this.setState({
  //           loadingButton: false,
  //         });
  //       } else {
  //         const errTitle = "gagal menyimpan data kendaraan";
  //         message.error({ content: errTitle, duration: 2, key: "toast" });
  //         this.setState({
  //           loadingButton: false,
  //           errTitle: errTitle,
  //           errMsg: JSON.stringify(this.props.responseMsg),
  //         });
  //       }
  //     })
  //     .catch((response) => {
  //       console.log("gagal_post_kendaraan", response);
  //       this.setState({
  //         loadingButton: false,
  //       });
  //       message.error({
  //         content: "kesalahan pengiriman data kendaraan, coba lagi",
  //         duration: 2,
  //         key: "toast",
  //       });
  //     });
  // }

  render() {
    // if(this.props.layanan_str.length>0 && this.props.layanan_str.includes('Pembelian / Pemesanan Obat') == false){
    // 	return <Redirect to="/admin/forbidden" />
    // }
    // if(this.props.isRedirect){
    // 	return <Redirect to={configClass.pageUrl.data} />
    // }

    return (
      <div>
        <Helmet>
          <title>
            {configClass.title + " | " + config.get_site_info().name}
          </title>
        </Helmet>
        <Breadcrumb>
          {configClass.breadCrumb.map((item) => (
            <Breadcrumb.Item>{item}</Breadcrumb.Item>
          ))}
          {this.props.printer != undefined && <Tag color="blue">default printer : {this.props.nama_printer}</Tag>}
        </Breadcrumb>
        <div className="card" id="panel-transaksi">
          <Card
            className="konten"
            title={null}
          >
            <Form
              layout="horizontal"
              name="fdata"
              size="small"
              ref={this.formRef}
              initialValues={{
                kode_bbn_kb: "001",
                nama_bbn_kb: "BBN KB",
                kode_pkb: "002",
                nama_pkb: "PKB",
                kode_swdkllj: "003",
                nama_swdkllj: "SWDKLLJ",
                kode_admstnk: "004",
                nama_admstnk: "BIAYA ADM.STNK",
                kode_admtnk: "005",
                nama_admtnk: "BIAYA ADM.TNKB",
                kode_sp3: "006",
                nama_sp3: "SP3",
                label_jumlah_total: "JUMLAH TOTAL",
                label_grand_total: "Total Pajak Berjalan & Tunggakan",
                nomor_polisi_awal: "KH"
              }}
            >
              <Row className="isi">
                {this.state.errTitle != null && this.state.errTitle != "" && (
                  <Alert
                    message={this.state.errTitle}
                    description={this.state.errMsg}
                    type="error"
                    showIcon
                    style={{
                      marginBottom: 10,
                      width: "100%"
                    }}
                  />
                )}

                <Col span={24}>
                  <Row justify="start">
                    <Col span={24}>
                      <Input.Group compact>

                        <Input
                          allowClear
                          style={{ width: "100%" }}
                          prefix="KH"
                          placeholder="input nomer polisi, cth : KH1234XY"
                          onChange={(e) => {
                            const regex = /^\d+\.?\d*$/;
                            let startIdx = 0;
                            if (regex.test(e.target.value.charAt(0))) {
                              startIdx = 0;
                            } else {
                              if (regex.test(e.target.value.charAt(1))) {
                                startIdx = 1;
                              } else {
                                startIdx = 2;
                              }
                            }

                            const noKendaraan = e.target.value.substring(startIdx);

                            this.setState({
                              // cari_no_pol: e.target.value,
                              cari_no_pol: noKendaraan,
                            });
                          }}
                          value={this.state.cari_no_pol}
                          name="cari_no_pol"
                          size="large"
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              this.searchKendaraan(this.state.cari_no_pol);
                            }
                          }}
                        />

                      </Input.Group>
                      <Divider />
                    </Col>
                  </Row>
                </Col>

                {this.state.isOpenForm == true && (
                  <Col
                    span={24}
                    style={{
                      overflowY: "auto",
                      maxHeight: "calc(100vh - 205px)",
                      // background: "rgb(11,93,37, 0.2)", 
                      padding: 10,
                      // boxShadow: "5px 10px"
                    }}
                    ref={this.formRefScroll}>

                    <Form.Item name="is_pembayaran" style={{ display: "none" }}>
                      <Input type="hidden" />
                    </Form.Item>
                    <Form.Item name="pemilik" style={{ display: "none" }}>
                      <Input type="hidden" />
                    </Form.Item>
                    <Form.Item name="kendaraan" style={{ display: "none" }}>
                      <Input type="hidden" />
                    </Form.Item>
                    <Row className="form-penetapan-biaya">
                      <Col span={24}>
                        <Row justify="start">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Status
                          </Col>
                          <Col span={7} style={{ paddingLeft: 5, paddingBottom: 5 }}>
                            {(this.state.status == "1" &&
                              <Tag color="red">pendaftaran</Tag>
                            )}
                            {(this.state.status == "2" &&
                              <Tag color="yellow">penetapan</Tag>
                            )}
                            {(this.state.status == "3" &&
                              <Tag color="green">pembayaran</Tag>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="form-penetapan-biaya">
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Jenis Daftar 1
                          </Col>
                          <Col span={7} style={{ paddingLeft: 5 }}>
                            <Form.Item name="layanan_nama">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={4} style={{ textAlign: "right" }}>
                            Jenis Daftar 2
                          </Col>
                          <Col span={10} style={{ paddingLeft: 5 }}>
                            <Form.Item name="layanan_nama2">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Jenis Daftar 3
                          </Col>
                          <Col span={7} style={{ paddingLeft: 5 }}>
                            <Form.Item name="layanan_nama3">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={4} style={{ textAlign: "right" }}>
                            Jenis Daftar 4
                          </Col>
                          <Col span={10} style={{ paddingLeft: 5 }}>
                            <Form.Item name="layanan_nama4">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Nomor Polisi
                          </Col>
                          <Col span={2} style={{ paddingLeft: 5 }}>
                            <Form.Item name="nomor_polisi_awal">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={3} style={{ paddingLeft: 5 }}>
                            <Form.Item name="nomor_polisi_tengah">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ paddingLeft: 5 }}>
                            <Form.Item name="nomor_polisi_akhir">
                              <Input disabled />
                            </Form.Item>
                          </Col>

                          <Col span={4} style={{ textAlign: "right" }}>
                            No Daftar
                          </Col>
                          <Col span={3} style={{ paddingLeft: 5 }}>
                            <Form.Item name="no_daftar">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={3} style={{ textAlign: "right" }}>
                            Tgl. Daftar
                          </Col>
                          <Col span={4} style={{ paddingLeft: 5 }}>
                            <Form.Item name="tgl_daftar">
                              <Input disabled />
                            </Form.Item>
                            <Form.Item name="tgl_daftar" style={{ display: "none" }}>
                              <Input type="hidden" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            No. SKUM
                          </Col>
                          <Col span={7} style={{ paddingLeft: 5 }}>
                            <Form.Item name="no_skum">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={4} style={{ textAlign: "right" }}>
                            No. Kohir
                          </Col>
                          <Col span={10} style={{ paddingLeft: 5 }}>
                            <Form.Item name="no_kohir">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row>
                          <Col span={24}>
                            <Divider>
                              Wajib Pajak &amp; Obyek Pajak
                            </Divider>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Nama WP/Pemilik
                          </Col>
                          <Col span={7} style={{ paddingLeft: 5 }}>
                            <Form.Item name="nama_wp">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={4} style={{ textAlign: "right" }}>
                            Alamat WP/Pemilik
                          </Col>
                          <Col span={10} style={{ paddingLeft: 5 }}>
                            <Form.Item name="alamat_wp">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Jenis Kendaraan
                          </Col>
                          <Col span={7} style={{ paddingLeft: 5 }}>
                            <Form.Item name="jenis_kendaraan">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={4} style={{ textAlign: "right" }}>
                            Merk Kendaraan
                          </Col>
                          <Col span={10} style={{ paddingLeft: 5 }}>
                            <Form.Item name="merk_kendaraan">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Tipe Kendaraan
                          </Col>
                          <Col span={7} style={{ paddingLeft: 5 }}>
                            <Form.Item name="tipe_kendaraan">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={4} style={{ textAlign: "right" }}>
                            Thn. Buat/Rakit
                          </Col>
                          <Col span={3} style={{ paddingLeft: 5 }}>
                            <Form.Item name="thn_buat">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={1} style={{ textAlign: "left", paddingLeft: 5 }}>
                            Warna
                          </Col>
                          <Col span={6} style={{ paddingLeft: 5 }}>
                            <Form.Item name="warna">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Warna TNK
                          </Col>

                          <Col span={2} style={{ paddingLeft: 5 }}>
                            <Form.Item name="warna_tnk">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={1} style={{ paddingRight: 5, textAlign: "right" }}>
                            Ke
                          </Col>
                          <Col span={1} style={{ paddingLeft: 5 }}>
                            <Form.Item name="ke">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={1} style={{ paddingRight: 5, textAlign: "right" }}>
                            CC
                          </Col>
                          <Col span={2} style={{ paddingLeft: 5 }}>
                            <Form.Item name="cc">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={4} style={{ textAlign: "right" }}>
                            Kend. JR
                          </Col>
                          <Col span={2} style={{ paddingLeft: 5 }}>
                            <Form.Item name="kend_jr">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={8} style={{ paddingLeft: 5 }}>
                            <Form.Item name="no_kohir">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Tgl. Kwitansi
                          </Col>
                          <Col span={2} style={{ paddingLeft: 5 }}>
                            <Form.Item name="tgl_kwitansi">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ paddingRight: 5, textAlign: "right" }}>
                            Tgl. Fiskal
                          </Col>
                          <Col span={3} style={{ paddingLeft: 5 }}>
                            <Form.Item name="tgl_fiskal">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={4} style={{ textAlign: "right" }}>
                            Tgl. SK
                          </Col>
                          <Col span={2} style={{ paddingLeft: 5 }}>
                            <Form.Item name="tgl_sk">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ textAlign: "right" }}>
                            Tgl. JR yl
                          </Col>
                          <Col span={6} style={{ paddingLeft: 5 }}>
                            <Form.Item name="tgl_jr_yl">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Harga Risalah
                          </Col>
                          <Col span={2} style={{ paddingLeft: 5 }}>
                            <Form.Item name="harga_risalah">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ paddingRight: 5, textAlign: "right" }}>
                            Tgl. Faktur
                          </Col>
                          <Col span={3} style={{ paddingLeft: 5 }}>
                            <Form.Item name="tgl_faktur">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={4} style={{ textAlign: "right" }}>
                            Masa Berlaku Notice
                          </Col>
                          <Col span={2} style={{ paddingLeft: 5 }}>
                            <Form.Item name="masa_berlaku_notice">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ textAlign: "right" }}>
                            S.d.
                          </Col>
                          <Col span={6} style={{ paddingLeft: 5 }}>
                            <Form.Item name="sd">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={8} style={{ border: "1px solid #cccccc", borderRight: "none" }}>
                            <Row>
                              <Col span={24}>
                                <Divider>PENETAPAN</Divider>
                              </Col>
                              <Col span={13} style={{ textAlign: "right", paddingRight: 5 }}>
                                Tgl. bayar Thn. Lalu
                              </Col>
                              <Col span={11} style={{ paddingRight: 5 }}>
                                <Form.Item name="tgl_bayar_thn_lalu">
                                  <Input disabled />
                                </Form.Item>
                              </Col>
                              <Col span={13} style={{ textAlign: "right", paddingRight: 5 }}>
                                Tgl. Penetapan
                              </Col>
                              <Col span={11} style={{ paddingRight: 5 }}>
                                <Form.Item name="tgl_penetapan">
                                  <Input disabled />
                                </Form.Item>
                                <Form.Item name="tgl_penetapan" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={24} style={{ background: "#F5F5F5", textAlign: "center", padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", fontWeight: "bold" }}>
                                Kode dan Keterangan
                              </Col>
                              <Col span={4} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="kode_bbn_kb">
                                  <Input disabled />
                                </Form.Item>
                              </Col>
                              <Col span={20} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="nama_bbn_kb">
                                  <Input disabled />
                                </Form.Item>
                              </Col>
                              <Col span={4} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="kode_pkb">
                                  <Input disabled />
                                </Form.Item>
                              </Col>
                              <Col span={20} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="nama_pkb">
                                  <Input disabled />
                                </Form.Item>
                              </Col>
                              <Col span={4} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="kode_swdkllj">
                                  <Input disabled />
                                </Form.Item>
                              </Col>
                              <Col span={20} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="nama_swdkllj">
                                  <Input disabled />
                                </Form.Item>
                              </Col>
                              <Col span={4} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="kode_admstnk">
                                  <Input disabled />
                                </Form.Item>
                              </Col>
                              <Col span={20} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="nama_admstnk">
                                  <Input disabled />
                                </Form.Item>
                              </Col>
                              <Col span={4} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="kode_admtnk">
                                  <Input disabled />
                                </Form.Item>
                              </Col>
                              <Col span={20} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="nama_admtnk">
                                  <Input disabled />
                                </Form.Item>
                              </Col>
                              <Col span={4} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none", borderBottom: "none" }}>
                                <Form.Item name="kode_sp3">
                                  <Input disabled />
                                </Form.Item>
                              </Col>
                              <Col span={20} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                <Form.Item name="nama_sp3">
                                  <Input disabled />
                                </Form.Item>
                              </Col>
                              <Col span={24} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none" }}>
                                <Form.Item name="label_jumlah_total">
                                  <Input value="JUMLAH TOTAL" readonly style={{ border: "none" }} />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={8} style={{ border: "1px solid #cccccc", borderRight: "none" }}>
                            <Row>
                              <Col span={24}>
                                <Divider>BERJALAN</Divider>
                              </Col>
                              <Col span={13} style={{ textAlign: "right", paddingRight: 5 }}>
                                No. SKPD Th. Lalu
                              </Col>
                              <Col span={11} style={{ paddingRight: 5 }}>
                                <Form.Item name="no_skpd_th_lalu">
                                  <Input disabled />
                                </Form.Item>
                              </Col>
                              <Col span={13} style={{ textAlign: "right", paddingRight: 5 }}>
                                No. SKPD
                              </Col>
                              <Col span={11} style={{ paddingRight: 5 }}>
                                <Form.Item name="no_skpd">
                                  <Input
                                    disabled
                                    onChange={(e) => this.formRef.current.setFieldsValue({ no_skpd2: e.target.value })}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ background: "#F5F5F5", textAlign: "center", padding: 5, border: "1px solid #cccccc", borderLeft: "none", fontWeight: "bold" }}>
                                Pokok
                              </Col>
                              <Col span={8} style={{ background: "#F5F5F5", textAlign: "center", padding: 5, border: "1px solid #cccccc", borderLeft: "none", fontWeight: "bold" }}>
                                Denda
                              </Col>
                              <Col span={8} style={{ background: "#F5F5F5", textAlign: "center", padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", fontWeight: "bold" }}>
                                Jumlah
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="pokok_bbnkb_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        pokok_bbnkb: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          pokok_bbnkb: value,
                                          jumlah_bbnkb: this.state.pokok_bbnkb + this.state.denda_bbnkb,
                                          jumlah_bbnkb_preview: parseFloat(this.state.pokok_bbnkb) + parseFloat(this.state.denda_bbnkb)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="pokok_bbnkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="denda_bbnkb_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        denda_bbnkb: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          denda_bbnkb: value,
                                          jumlah_bbnkb: this.state.pokok_bbnkb + this.state.denda_bbnkb,
                                          jumlah_bbnkb_preview: parseFloat(this.state.pokok_bbnkb) + parseFloat(this.state.denda_bbnkb)
                                        });
                                      });
                                      // this.formRef.current.setFieldsValue({
                                      //   denda_bbnkb: value
                                      // });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="denda_bbnkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="jumlah_bbnkb_preview">
                                  <NumberFormat
                                    disabled
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        jumlah_bbnkb: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="jumlah_bbnkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="pokok_pkb_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        pokok_pkb: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          pokok_pkb: value,
                                          jumlah_pkb: this.state.pokok_pkb + this.state.denda_pkb,
                                          jumlah_pkb_preview: parseFloat(this.state.pokok_pkb) + parseFloat(this.state.denda_pkb)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="pokok_pkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="denda_pkb_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        denda_pkb: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          denda_pkb: value,
                                          jumlah_pkb: this.state.pokok_pkb + this.state.denda_pkb,
                                          jumlah_pkb_preview: parseFloat(this.state.pokok_pkb) + parseFloat(this.state.denda_pkb)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="denda_pkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="jumlah_pkb_preview">
                                  <NumberFormat
                                    disabled
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        jumlah_pkb: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="jumlah_pkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="pokok_swdkllj_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        pokok_swdkllj: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          pokok_swdkllj: value,
                                          jumlah_swdkllj: this.state.pokok_swdkllj + this.state.denda_swdkllj,
                                          jumlah_swdkllj_preview: parseFloat(this.state.pokok_swdkllj) + parseFloat(this.state.denda_swdkllj)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="pokok_swdkllj" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="denda_swdkllj_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        denda_swdkllj: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          denda_swdkllj: value,
                                          jumlah_swdkllj: this.state.pokok_swdkllj + this.state.denda_swdkllj,
                                          jumlah_swdkllj_preview: parseFloat(this.state.pokok_swdkllj) + parseFloat(this.state.denda_swdkllj)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="denda_swdkllj" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="jumlah_swdkllj_preview">
                                  <NumberFormat
                                    disabled
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        jumlah_swdkllj: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="jumlah_swdkllj" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="pokok_admstnk_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        pokok_admstnk: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          pokok_admstnk: value,
                                          jumlah_admstnk: this.state.pokok_admstnk + this.state.denda_admstnk,
                                          jumlah_admstnk_preview: parseFloat(this.state.pokok_admstnk) + parseFloat(this.state.denda_admstnk)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="pokok_admstnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="denda_admstnk_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        denda_admstnk: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          denda_admstnk: value,
                                          jumlah_admstnk: this.state.pokok_admstnk + this.state.denda_admstnk,
                                          jumlah_admstnk_preview: parseFloat(this.state.pokok_admstnk) + parseFloat(this.state.denda_admstnk)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="denda_admstnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="jumlah_admstnk_preview">
                                  <NumberFormat
                                    disabled
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        jumlah_admstnk: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="jumlah_admstnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="pokok_admtnk_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        pokok_admtnk: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          pokok_admtnk: value,
                                          jumlah_admtnk: this.state.pokok_admtnk + this.state.denda_admtnk,
                                          jumlah_admtnk_preview: parseFloat(this.state.pokok_admtnk) + parseFloat(this.state.denda_admtnk)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="pokok_admtnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="denda_admtnk_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        denda_admtnk: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          denda_admtnk: value,
                                          jumlah_admtnk: this.state.pokok_admtnk + this.state.denda_admtnk,
                                          jumlah_admtnk_preview: parseFloat(this.state.pokok_admtnk) + parseFloat(this.state.denda_admtnk)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="denda_admtnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="jumlah_admtnk_preview">
                                  <NumberFormat
                                    disabled
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        jumlah_admtnk: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="jumlah_admtnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none", borderBottom: "none" }}>
                                <Form.Item name="pokok_sp3_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        pokok_sp3: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          pokok_sp3: value,
                                          jumlah_sp3: this.state.pokok_sp3 + this.state.denda_sp3,
                                          jumlah_sp3_preview: parseFloat(this.state.pokok_sp3) + parseFloat(this.state.denda_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="pokok_sp3" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none", borderBottom: "none" }}>
                                <Form.Item name="denda_sp3_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        denda_sp3: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          denda_sp3: value,
                                          jumlah_sp3: this.state.pokok_sp3 + this.state.denda_sp3,
                                          jumlah_sp3_preview: parseFloat(this.state.pokok_sp3) + parseFloat(this.state.denda_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="denda_sp3" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                <Form.Item name="jumlah_sp3_preview">
                                  <NumberFormat
                                    disabled
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        jumlah_sp3: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="jumlah_sp3" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none" }}>
                                <Form.Item name="total_pokok_berjalan">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      // this.formRef.current.setFieldsValue({
                                      //   jumlah_sp3: value
                                      // });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none" }}>
                                <Form.Item name="total_denda_berjalan">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      // this.formRef.current.setFieldsValue({
                                      //   jumlah_sp3: value
                                      // });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none" }}>
                                <Form.Item name="total_jumlah_berjalan">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      // this.formRef.current.setFieldsValue({
                                      //   jumlah_sp3: value
                                      // });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={24} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none", borderBottom: "none", fontWeight: 'bold', textAlign: 'right' }}>
                                <Form.Item name="label_grand_total">
                                  <Input readonly style={{ border: "none", fontWeight: 'bold' }} />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={8} style={{ border: "1px solid #cccccc" }}>
                            <Row>
                              <Col span={24}>
                                <Divider>TUNGGAKAN &gt;&gt; ADA &lt;&lt;</Divider>
                              </Col>
                              <Col span={24} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item name="lama_tunggakan">
                                  <Input style={{ width: "100%" }} disabled />
                                </Form.Item>
                              </Col>
                              <Col span={15} style={{ textAlign: "right", paddingRight: 5 }}>
                                No. SKPD
                              </Col>
                              <Col span={9} style={{ paddingRight: 5 }}>
                                <Form.Item name="no_skpd2">
                                  <Input disabled />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ background: "#F5F5F5", textAlign: "center", padding: 5, border: "1px solid #cccccc", borderLeft: "none", fontWeight: "bold", borderTop: "none" }}>
                                Pokok
                              </Col>
                              <Col span={8} style={{ background: "#F5F5F5", textAlign: "center", padding: 5, border: "1px solid #cccccc", borderLeft: "none", fontWeight: "bold", borderTop: "none" }}>
                                Denda
                              </Col>
                              <Col span={8} style={{ background: "#F5F5F5", textAlign: "center", padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", fontWeight: "bold", borderTop: "none" }}>
                                Jumlah
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_pokok_bbnkb_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_pokok_bbnkb: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_pokok_bbnkb: value,
                                          tunggakan_jumlah_bbnkb: this.state.tunggakan_pokok_bbnkb + this.state.tunggakan_denda_bbnkb,
                                          tunggakan_jumlah_bbnkb_preview: parseFloat(this.state.tunggakan_pokok_bbnkb) + parseFloat(this.state.tunggakan_denda_bbnkb)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_pokok_bbnkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_denda_bbnkb_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_denda_bbnkb: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_denda_bbnkb: value,
                                          tunggakan_jumlah_bbnkb: this.state.tunggakan_pokok_bbnkb + this.state.tunggakan_denda_bbnkb,
                                          tunggakan_jumlah_bbnkb_preview: parseFloat(this.state.tunggakan_pokok_bbnkb) + parseFloat(this.state.tunggakan_denda_bbnkb)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_denda_bbnkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_jumlah_bbnkb_preview">
                                  <NumberFormat
                                    disabled
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        tunggakan_jumlah_bbnkb: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_jumlah_bbnkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_pokok_pkb_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_pokok_pkb: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_pokok_pkb: value,
                                          tunggakan_jumlah_pkb: this.state.tunggakan_pokok_pkb + this.state.tunggakan_denda_pkb,
                                          tunggakan_jumlah_pkb_preview: parseFloat(this.state.tunggakan_pokok_pkb) + parseFloat(this.state.tunggakan_denda_pkb)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_pokok_pkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_denda_pkb_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_denda_pkb: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_denda_pkb: value,
                                          tunggakan_jumlah_pkb: this.state.tunggakan_pokok_pkb + this.state.tunggakan_denda_pkb,
                                          tunggakan_jumlah_pkb_preview: parseFloat(this.state.tunggakan_pokok_pkb) + parseFloat(this.state.tunggakan_denda_pkb)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_denda_pkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_jumlah_pkb_preview">
                                  <NumberFormat
                                    disabled
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        tunggakan_jumlah_pkb: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_jumlah_pkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_pokok_swdkllj_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_pokok_swdkllj: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_pokok_swdkllj: value,
                                          tunggakan_jumlah_swdkllj: this.state.tunggakan_pokok_swdkllj + this.state.tunggakan_denda_swdkllj,
                                          tunggakan_jumlah_swdkllj_preview: parseFloat(this.state.tunggakan_pokok_swdkllj) + parseFloat(this.state.tunggakan_denda_swdkllj)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_pokok_swdkllj" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_denda_swdkllj_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_denda_swdkllj: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_denda_swdkllj: value,
                                          tunggakan_jumlah_swdkllj: this.state.tunggakan_pokok_swdkllj + this.state.tunggakan_denda_swdkllj,
                                          tunggakan_jumlah_swdkllj_preview: parseFloat(this.state.tunggakan_pokok_swdkllj) + parseFloat(this.state.tunggakan_denda_swdkllj)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_denda_swdkllj" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_jumlah_swdkllj_preview">
                                  <NumberFormat
                                    disabled
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        tunggakan_jumlah_swdkllj: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_jumlah_swdkllj" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_pokok_admstnk_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_pokok_admstnk: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_pokok_admstnk: value,
                                          tunggakan_jumlah_admstnk: this.state.tunggakan_pokok_admstnk + this.state.tunggakan_denda_admstnk,
                                          tunggakan_jumlah_admstnk_preview: parseFloat(this.state.tunggakan_pokok_admstnk) + parseFloat(this.state.tunggakan_denda_admstnk)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_pokok_admstnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_denda_admstnk_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_denda_admstnk: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_denda_admstnk: value,
                                          tunggakan_jumlah_admstnk: this.state.tunggakan_pokok_admstnk + this.state.tunggakan_denda_admstnk,
                                          tunggakan_jumlah_admstnk_preview: parseFloat(this.state.tunggakan_pokok_admstnk) + parseFloat(this.state.tunggakan_denda_admstnk)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_denda_admstnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_jumlah_admstnk_preview">
                                  <NumberFormat
                                    disabled
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        tunggakan_jumlah_admstnk: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_jumlah_admstnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_pokok_admtnk_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_pokok_admtnk: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_pokok_admtnk: value,
                                          tunggakan_jumlah_admtnk: this.state.tunggakan_pokok_admtnk + this.state.tunggakan_denda_admtnk,
                                          tunggakan_jumlah_admtnk_preview: parseFloat(this.state.tunggakan_pokok_admtnk) + parseFloat(this.state.tunggakan_denda_admtnk)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_pokok_admtnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_denda_admtnk_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_denda_admtnk: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_denda_admtnk: value,
                                          tunggakan_jumlah_admtnk: this.state.tunggakan_pokok_admtnk + this.state.tunggakan_denda_admtnk,
                                          tunggakan_jumlah_admtnk_preview: parseFloat(this.state.tunggakan_pokok_admtnk) + parseFloat(this.state.tunggakan_denda_admtnk)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_denda_admtnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_jumlah_admtnk_preview">
                                  <NumberFormat
                                    disabled
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        tunggakan_jumlah_admtnk: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_jumlah_admtnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none", borderBottom: "none" }}>
                                <Form.Item name="tunggakan_pokok_sp3_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_pokok_sp3: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_pokok_sp3: value,
                                          tunggakan_jumlah_sp3: this.state.tunggakan_pokok_sp3 + this.state.tunggakan_denda_sp3,
                                          tunggakan_jumlah_sp3_preview: parseFloat(this.state.tunggakan_pokok_sp3) + parseFloat(this.state.tunggakan_denda_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_pokok_sp3" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none", borderBottom: "none" }}>
                                <Form.Item name="tunggakan_denda_sp3_preview">
                                  <NumberFormat
                                    disabled
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_denda_sp3: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_denda_sp3: value,
                                          tunggakan_jumlah_sp3: this.state.tunggakan_pokok_sp3 + this.state.tunggakan_denda_sp3,
                                          tunggakan_jumlah_sp3_preview: parseFloat(this.state.tunggakan_pokok_sp3) + parseFloat(this.state.tunggakan_denda_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_pokok_sp3" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                <Form.Item name="tunggakan_jumlah_sp3_preview">
                                  <NumberFormat
                                    disabled
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        tunggakan_jumlah_sp3: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_jumlah_sp3" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>

                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderBottom: "none" }}>
                                <Form.Item name="total_pokok_tunggakan">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      // this.formRef.current.setFieldsValue({
                                      //   jumlah_sp3: value
                                      // });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderBottom: "none" }}>
                                <Form.Item name="total_denda_tunggakan">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      // this.formRef.current.setFieldsValue({
                                      //   jumlah_sp3: value
                                      // });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderBottom: "none" }}>
                                <Form.Item name="total_jumlah_tunggakan">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      // this.formRef.current.setFieldsValue({
                                      //   jumlah_sp3: value
                                      // });
                                    }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={24} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderBottom: "none" }}>
                                <Form.Item name="grand_total">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none', fontWeight: 'bold' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      // this.formRef.current.setFieldsValue({
                                      //   jumlah_sp3: value
                                      // });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={24}>
                            <Row justify="end">
                              <Col span={3} style={{ padding: 3 }}>
                                <Button
                                  onClick={() => this.showModalPembayaran(true)}
                                  style={{ width: "100%" }}
                                  key="btn-simpan"
                                  loading={this.state.loadingButton}
                                  type="primary"
                                  htmlType="Button"
                                  disabled={this.state.errComponent ? true : false}
                                >
                                  {!this.state.loadingButton && (
                                    <CheckCircleOutlined />
                                  )}
                                  Bayar
                                </Button>
                              </Col>
                              <Col span={3} style={{ padding: 3 }}>
                                <Button
                                  type="danger"
                                  key="btn-cancel"
                                  disabled={this.state.loadingButton ? true : false}
                                  style={{ width: "100%" }}
                                  onClick={() => this.reset()}
                                >
                                  <CloseCircleOutlined /> Reset
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>

                      {/*
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Tgl. bayar Thn Lalu
                          </Col>
                          <Col span={5} style={{ paddingLeft: 5 }}>
                            <Form.Item name="tgl_bayar_thn_lalu">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={3} style={{ textAlign: "right" }}>
                            No. SKPD Th. Lalu
                          </Col>
                          <Col span={5} style={{ paddingLeft: 5 }}>
                            <Form.Item name="no_skpd_th_lalu">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={6} style={{ textAlign: "right" }}>
                            8 Tahun 9 BUlan 10 Hari Denda Jr
                          </Col>
                          <Col span={2} style={{ paddingLeft: 5 }}>
                            0 Hari
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Tgl Penetapan
                          </Col>
                          <Col span={5} style={{ paddingLeft: 5 }}>
                            <Form.Item name="tgl_penetapan">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={3} style={{ textAlign: "right" }}>
                            No. SKPD
                          </Col>
                          <Col span={5} style={{ paddingLeft: 5 }}>
                            <Form.Item name="no_skpd">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={4} style={{ textAlign: "right" }}>
                            No. SKPD
                          </Col>
                          <Col span={4} style={{ paddingLeft: 5 }}>
                            <Form.Item name="no_skpd2_th_lalu">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <table style={{width: "100%"}} border={1}>
                          <thead>
                            <tr>
                              <th colspan="2">Kode dan Keterangan</th>
                              <th>Pokok</th>
                              <th>Denda</th>
                              <th>Jumlah</th>
                              <th>Pokok</th>
                              <th>Denda</th>
                              <th>Jumlah</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>001</td>
                              <td>BBN KB</td>
                              <td>
                                <Form.Item
                                  name="bbn_pkb"
                                >
                                  <Input />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  name="denda_bbn_pkb"
                                >
                                  <Input />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  name="tunggakan_bbn_pkb"
                                >
                                  <Input />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  name="tunggakan_denda_bbn_pkb"
                                >
                                  <Input />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  name="tunggakan_jumlah_bbn_pkb"
                                >
                                  <Input />
                                </Form.Item>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                      <Col span={24}>
                        <Row justify="end">
                          <Col span={3} style={{ padding: 3 }}>
                            <Button
                              style={{ width: "100%" }}
                              key="btn-simpan"
                              loading={this.state.loadingButton}
                              type="primary"
                              htmlType="submit"
                              disabled={this.state.errComponent ? true : false}
                            >
                              {!this.state.loadingButton && (
                                <CheckCircleOutlined />
                              )}
                              Simpan
                            </Button>
                          </Col>
                          <Col span={3} style={{ padding: 3 }}>
                            <Button
                              type="danger"
                              key="btn-cancel"
                              disabled={this.state.loadingButton ? true : false}
                              style={{ width: "100%" }}
                            >
                              <CloseCircleOutlined /> Reset
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      */}
                    </Row>
                  </Col>
                )}
              </Row>
            </Form>
          </Card>
        </div>
        <Modal title="Pembayaran" visible={this.state.isModalVisible} onCancel={() => this.showModalPembayaran(false)} footer={null}>
          <Form
            layout="horizontal"
            name="fdata"
            onFinish={this.handleSubmit}
            size="small"
            ref={this.formRefPembayaran}
          >
            <Row justify="space-between">
              <Col span={6} style={{ textAlign: "right" }}>
                Jenis Pembayaran
              </Col>
              <Col span={18} style={{ paddingLeft: 5 }}>
                <Form.Item
                  name="payment_type"
                  rules={[
                    {
                      required: true,
                      message: 'harus diisi'
                    }
                  ]}
                >
                  {this.generateSelect(
                    "Payment",
                    configClass.apiUrl.jenis_layanan,
                    "id",
                    "nama",
                    "kode"
                  )}
                </Form.Item>
              </Col>
              <Col span={6} style={{ textAlign: "right" }}>
                No. Referensi
              </Col>
              <Col span={18} style={{ paddingLeft: 5 }}>
                <Form.Item
                  name="no_referensi"
                >
                  <Input placeholder="nomer referensi pembayaran (opsional)" />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
              <Col span={5} style={{ paddingLeft: 3 }}>
                <Button
                  style={{ width: "100%" }}
                  key="btn-simpan"
                  loading={this.state.loadingButton}
                  type="primary"
                  htmlType="submit"
                  disabled={this.state.errComponent ? true : false}
                >
                  {!this.state.loadingButton && (
                    <CheckCircleOutlined />
                  )}
                  Simpan
                </Button>
              </Col>
              <Col span={5} style={{ paddingLeft: 3 }}>
                <Button
                  onClick={() => this.showModalPembayaran(false)}
                  type="danger"
                  key="btn-cancel"
                  disabled={this.state.loadingButton ? true : false}
                  style={{ width: "100%" }}
                >
                  <CloseCircleOutlined /> Batal
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  initHandler,
  setConfig,
  ajaxViewHandler,
  ajaxHandler,
})(FormPembayaran);
