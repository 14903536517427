import React from "react";
import { Link, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Breadcrumb,
  Card,
  Row,
  Col,
  Avatar,
  Form,
  Input,
  Button,
  Space,
  Select,
  DatePicker,
  message,
  Alert,
  Spin,
  Modal,
  Skeleton,
  Table,
  Tag,
  Divider
} from "antd";
import {
  DiffOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DollarOutlined,
  BarcodeOutlined,
  UserAddOutlined,
  PrinterOutlined,
  UserOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  CarOutlined,
  InfoCircleOutlined,
  FormOutlined
} from "@ant-design/icons";
import moment from "moment";
import NumberFormat from "react-number-format";

import axios from "axios";
import Config from "../../Config";

import { connect } from "react-redux";
import {
  initHandler,
  ajaxViewHandler,
  ajaxHandler,
  setConfig,
} from "../../store/actions";
import { Helmet } from "react-helmet";

const FormItem = Form.Item;

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 19,
      offset: 5,
    },
    lg: {
      span: 19,
      offset: 5,
    },
  },
};

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const configClass = {
  title: "cancel notice",
  apiUrl: {
    penetapan: "transaksi/api/tagihan/",
    cancel_notice: "transaksi/api/notice/",
  },
  pageUrl: {

  },
  breadCrumb: [
    <span>
      <CarOutlined /> transaksi
    </span>,
    "cancel notice",
  ],
};

const config = new Config();

class CancelNotice extends React.Component {
  formRef = React.createRef();
  formRefScroll = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      searchText: '',

      errComponent: false,
      errTitle: "",
      errMsg: "",
      loadingButton: false,

      pagination: { pageSize: 8 },

      loadingData: false,

      aksi: "tambah",
      method: "post",
      idLama: "",

      keterangan: "",

      dataSource: [],
      columns: [
        {
          title: 'NOMOR NOTICE',
          width: 200,
          dataIndex: 'notice_number',
          key: 'notice_number'
        },
        {
          title: 'STATUS',
          width: 200,
          dataIndex: 'is_notice_failed',
          key: 'is_notice_failed',
          render: (value, row, index) => {
            return value == true
              ?
              <Button>
                <CheckCircleOutlined /> Notice Canceled
              </Button>
              : ''
          }
        },
        {
          title: 'NOMOR POLISI',
          width: 200,
          dataIndex: 'nomor_polisi',
          key: 'nomor_polisi'
        },
        {
          title: 'LAYANAN',
          width: 150,
          dataIndex: 'layanan_list',
          key: 'layanan_list',
          render: (value, row, index) => {
            return value.map((item, idx) => (
              idx == 0
                ? item.nama
                : ", " + item.nama
            ))
          }
        },
        {
          title: 'NAMA WP/ PEMILIK',
          width: 200,
          dataIndex: 'nama_wp',
          key: 'nama_wp'
        },
        {
          title: 'ALAMAT WP/ PEMILIK',
          width: 200,
          dataIndex: 'alamat_wp',
          key: 'alamat_wp'
        },
        {
          title: 'JENIS KENDARAAN',
          width: 150,
          dataIndex: 'jenis_kendaraan',
          key: 'jenis_kendaraan'
        },
        {
          title: 'MEREK KENDARAAN',
          width: 200,
          dataIndex: 'merk_kendaraan',
          key: 'merk_kendaraan'
        },
        {
          title: 'TIPE KENDARAAN',
          width: 150,
          dataIndex: 'tipe_kendaraan',
          key: 'tipe_kendaraan'
        },
        {
          title: 'THN. BUAT/ RAKIT',
          width: 150,
          dataIndex: 'thn_buat',
          key: 'thn_buat'
        },
        {
          title: 'CC',
          width: 150,
          dataIndex: 'cc',
          key: 'cc'
        },
        {
          title: 'WARNA TNK',
          width: 150,
          dataIndex: 'warna_tnk',
          key: 'warna_tnk'
        },
        {
          title: 'KENDARAAN KE',
          width: 150,
          dataIndex: 'ke',
          key: 'ke'
        },
        {
          title: 'PENGGUNAAN',
          width: 150,
          dataIndex: 'fungsi_nama',
          key: 'fungsi_nama'
        },
        {
          title: 'MASA BERLAKU STNK',
          width: 200,
          dataIndex: 'masa_pajak',
          key: 'masa_pajak'
        },
        {
          title: 'POKOK BBNKB',
          width: 150,
          dataIndex: 'pokok_bbnkb',
          key: 'pokok_bbnkb'
        },
        {
          title: 'DENDA BBNKB',
          width: 150,
          dataIndex: 'denda_bbnkb',
          key: 'denda_bbnkb'
        },
        {
          title: 'JUMLAH BBNKB',
          width: 150,
          dataIndex: 'jumlah_bbnkb',
          key: 'jumlah_bbnkb'
        },
        {
          title: 'TUNGGAKAN POKOK BBNKB',
          width: 250,
          dataIndex: 'tunggakan_pokok_bbnkb',
          key: 'tunggakan_pokok_bbnkb'
        },
        {
          title: 'TUNGGAKAN DENDA BBNKB',
          width: 250,
          dataIndex: 'tunggakan_denda_bbnkb',
          key: 'tunggakan_denda_bbnkb'
        },
        {
          title: 'TUNGGAKAN JUMLAH BBNKB',
          width: 250,
          dataIndex: 'tunggakan_jumlah_bbnkb',
          key: 'tunggakan_jumlah_bbnkb'
        },
        {
          title: 'POKOK PKB',
          width: 200,
          dataIndex: 'pokok_pkb',
          key: 'pokok_pkb'
        },
        {
          title: 'DENDA PKB',
          width: 200,
          dataIndex: 'denda_pkb',
          key: 'denda_pkb'
        },
        {
          title: 'JUMLAH PKB',
          width: 200,
          dataIndex: 'jumlah_pkb',
          key: 'jumlah_pkb'
        },
        {
          title: 'TUNGGAKAN POKOK PKB',
          width: 200,
          dataIndex: 'tunggakan_pokok_pkb',
          key: 'tunggakan_pokok_pkb'
        },
        {
          title: 'TUNGGAKAN DENDA PKB',
          width: 200,
          dataIndex: 'tunggakan_denda_pkb',
          key: 'tunggakan_denda_pkb'
        },
        {
          title: 'TUNGGAKAN JUMLAH PKB',
          width: 200,
          dataIndex: 'tunggakan_jumlah_pkb',
          key: 'tunggakan_jumlah_pkb'
        },
        {
          title: 'POKOK SWDKLLJ',
          width: 200,
          dataIndex: 'pokok_swdkllj',
          key: 'pokok_swdkllj'
        },
        {
          title: 'DENDA SWDKLLJ',
          width: 200,
          dataIndex: 'denda_swdkllj',
          key: 'denda_swdkllj'
        },
        {
          title: 'JUMLAH SWDKLLJ',
          width: 200,
          dataIndex: 'jumlah_swdkllj',
          key: 'jumlah_swdkllj'
        },
        {
          title: 'TUNGGAKAN POKOK SWDKLLJ',
          width: 200,
          dataIndex: 'tunggakan_pokok_swdkllj',
          key: 'tunggakan_pokok_swdkllj'
        },
        {
          title: 'TUNGGAKAN DENDA SWDKLLJ',
          width: 200,
          dataIndex: 'tunggakan_denda_swdkllj',
          key: 'tunggakan_denda_swdkllj'
        },
        {
          title: 'TUNGGAKAN JUMLAH SWDKLLJ',
          width: 200,
          dataIndex: 'tunggakan_jumlah_swdkllj',
          key: 'tunggakan_jumlah_swdkllj'
        },
        {
          title: 'POKOK ADM. STNK',
          width: 200,
          dataIndex: 'pokok_admstnk',
          key: 'pokok_admstnk'
        },
        {
          title: 'DENDA ADM. STNK',
          width: 200,
          dataIndex: 'denda_admstnk',
          key: 'denda_admstnk'
        },
        {
          title: 'JUMLAH ADM. STNK',
          width: 200,
          dataIndex: 'jumlah_admstnk',
          key: 'jumlah_admstnk'
        },
        {
          title: 'TUNGGAKAN POKOK ADM. STNK',
          width: 250,
          dataIndex: 'tunggakan_pokok_admstnk',
          key: 'tunggakan_pokok_admstnk'
        },
        {
          title: 'TUNGGAKAN DENDA ADM. STNK',
          width: 250,
          dataIndex: 'tunggakan_denda_admstnk',
          key: 'tunggakan_denda_admstnk'
        },
        {
          title: 'TUNGGAKAN JUMLAH ADM. STNK',
          width: 250,
          dataIndex: 'tunggakan_jumlah_admstnk',
          key: 'tunggakan_jumlah_admstnk'
        },
        {
          title: 'POKOK ADM. TNKB',
          width: 200,
          dataIndex: 'pokok_admtnk',
          key: 'pokok_admtnk'
        },
        {
          title: 'DENDA ADM. TNKB',
          width: 200,
          dataIndex: 'denda_admtnk',
          key: 'denda_admtnk'
        },
        {
          title: 'JUMLAH ADM. TNKB',
          width: 200,
          dataIndex: 'jumlah_admtnk',
          key: 'jumlah_admtnk'
        },
        {
          title: 'TUNGGAKAN POKOK ADM. TNKB',
          width: 250,
          dataIndex: 'tunggakan_pokok_admtnk',
          key: 'tunggakan_pokok_admtnk'
        },
        {
          title: 'TUNGGAKAN DENDA ADM. TNKB',
          width: 250,
          dataIndex: 'tunggakan_denda_admtnk',
          key: 'tunggakan_denda_admtnk'
        },
        {
          title: 'TUNGGAKAN JUMLAH ADM. TNKB',
          width: 250,
          dataIndex: 'tunggakan_jumlah_admtnk',
          key: 'tunggakan_jumlah_admtnk'
        },
        {
          title: 'POKOK SP3',
          width: 200,
          dataIndex: 'pokok_sp3',
          key: 'pokok_sp3'
        },
        {
          title: 'DENDA SP3',
          width: 200,
          dataIndex: 'denda_sp3',
          key: 'denda_sp3'
        },
        {
          title: 'JUMLAH SP3',
          width: 200,
          dataIndex: 'jumlah_sp3',
          key: 'jumlah_sp3'
        },
        {
          title: 'TUNGGAKAN POKOK SP3',
          width: 200,
          dataIndex: 'tunggakan_pokok_sp3',
          key: 'tunggakan_pokok_sp3'
        },
        {
          title: 'TUNGGAKAN DENDA SP3',
          width: 200,
          dataIndex: 'tunggakan_denda_sp3',
          key: 'tunggakan_denda_sp3'
        },
        {
          title: 'TUNGGAKAN JUMLAH SP3',
          width: 200,
          dataIndex: 'tunggakan_jumlah_sp3',
          key: 'tunggakan_jumlah_sp3'
        },
        {
          title: 'CANCEL NOTICE',
          width: 150,
          dataIndex: 'id',
          key: 'aksi',
          fixed: 'right',
          render: (value, row, index) => {
            return (
              <Row key={"space-aksi-" + index} justify="center">
                <Col style={{ padding: 5 }}>
                  <Button className="btn-delete" size="small" onClick={() => this.showDeleteConfirm(row)} shape="circle">
                    <CloseSquareOutlined />
                  </Button>
                </Col>
              </Row>
            )
          },
        }
      ]

    };
  }

  UNSAFE_componentWillMount() {
    this.props.initHandler();
  }

  componentDidMount() {
    this.previewData();
  }

  setKeterangan = (e) => {
    this.setState({
      keterangan: e.target.value
    });
  };

  showDeleteConfirm(item) {
    Modal.confirm({
      title: 'Konfirmasi Cancel Notice',
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          yakin melakukan cancel notice : {item.notice_number} ?
          <Input placeholder="tuliskan keterangan disini" onChange={this.setKeterangan} style={{ textTransform: 'none' }} />
        </div>
      ),
      okText: 'Ya',
      okType: 'danger',
      cancelText: 'Tidak',
      onOk: () => this.prosesHapus(item),
      onCancel() {
        //console.log('Cancel');
      },
    });
  }

  prosesHapus(item) {
    var datas = new FormData();
    datas.append('transaksi', item.id);
    datas.append('nomor', item.notice_number);
    datas.append('keterangan', this.state.keterangan);


    message.loading({ content: 'Memproses...', key: 'toast', errTitle: '', errMsg: '' });

    this.props.ajaxHandler('post', configClass.apiUrl.cancel_notice, datas, true, true)
      .then(() => {
        this.setState({
          loadingButton: false,
          keterangan: "",
        });
        if (this.props.isSuccess) {
          message.success({ content: 'berhasil memproses cancel notice', duration: 2, key: 'toast' });
          this.previewData();
        } else {
          const errTitle = 'gagal memproses cancel notice'
          message.error({ content: errTitle, duration: 2, key: 'toast' });
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg != undefined ? this.props.responseMsg : this.props.responseCode
          });
        }
      }).catch((response) => {
        console.log('gagal_delete', response);
        this.setState({
          loadingButton: false
        });
        message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
      });
  }

  async previewData(limit = this.state.pagination.pageSize, offset = 0, search = this.state.searchText) {
    message.loading({
      content: "Proses pencarian...",
      duration: 0,
      key: "toast",
    });

    this.setState({
      errTitle: '',
      errMsg: '',
      dataSource: [],
      loadingData: true,
    });

    // var url = configClass.apiUrl.penetapan
    var url = configClass.apiUrl.penetapan + '?limit=' + limit + '&offset=' + offset + '&search=' + search

    this.props
      .ajaxViewHandler(
        "get",
        url
      )
      .then(() => {
        const datas = this.props.responseMsg;
        if (this.props.isSuccess) {
          var pagination = { ...this.state.pagination };
          pagination.total = datas.count;

          if (datas.results.length == 0) {
            message.error({ content: "nomor kendaraan tidak ditemukan", duration: 2, key: "toast" });
          } else {
            message.destroy();
            this.setState({
              dataSource: datas.results,
              pagination
            });
          }
        } else {
          const errTitle = "error preview data lama";
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
          message.error({ content: errTitle, duration: 2, key: "toast" });
        }
        this.setState({
          loadingData: false
        });
      })
      .catch((response) => {
        console.log("gagal_get_preview", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({
          loadingData: false
        });
      });
  }

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  handleTableChange = async (pagination, filters, sorter) => {
    const newPagination = { ...this.state.pagination };
    newPagination.pageSize = pagination.pageSize;
    this.setState({
      loadingData: true,
      pagination
    });
    if (pagination.current == 1) {
      this.previewData(pagination.pageSize);
    } else {
      this.previewData(pagination.pageSize, (pagination.current - 1) * pagination.pageSize);
    }

  }

  handleSubmit = (values) => {
    this.postDataPenetapan(values);
  };

  async getPasienById(val) {
    message.loading({
      content: "Display data pasien...",
      duration: 0,
      key: "toast",
    });
    this.setState({
      errTitle: "",
      errMsg: "",
      dataPasien: null,
      skeletonPasien: true,
    });
    this.props
      .ajaxViewHandler("get", configClass.apiUrl.pasien + "/" + val)
      .then(() => {
        this.setState({
          skeletonPasien: false,
        });
        if (this.props.isSuccess) {
          message.destroy();

          var response = this.props.responseMsg;
          this.setState({
            dataPasien: response,
          });
        } else {
          const errTitle = "gagal menampilkan data pasien";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
        }
      })
      .catch((response) => {
        console.log("gagal_preview_pasien", response);
        this.setState({
          skeletonPasien: false,
        });
        message.error({
          content: "kesalahan pengiriman data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  reset = () => {
    this.setState({
      isOpenForm: false,
    });
  };

  async postDataPenetapan(datas) {
    message.loading({ content: "Memproses...", duration: 0, key: "toast" });
    this.setState({
      errTitle: "",
      errMsg: "",
      loadingButton: true,
    });
    this.props
      .ajaxHandler("put", configClass.apiUrl.penetapan + this.state.idLama, datas, false, true)
      .then(() => {
        if (this.props.isSuccess) {
          var response = this.props.responseMsg;
          var that = this;

          message.success({ content: 'berhasil menyimpan data penetapan', duration: 2, key: 'toast' });
          this.setState({
            loadingButton: false,
          });

          Modal.confirm({
            title: 'Penetapan Biaya Berhasil',
            icon: <CheckCircleOutlined style={{ color: 'green' }} />,
            content: 'tekan cetak untuk mencetak, tekan selesai untuk melanjutkan',
            okText: <a href={configClass.pageUrl.cetak_no + response.id} target="_blank"><PrinterOutlined /> Cetak</a>,
            cancelText: 'Selesai',
            onOk() {
              that.reset()
            },
            onCancel() {
              that.reset()
            }
          });

        } else {
          const errTitle = "gagal menyimpan data penetapan";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            loadingButton: false,
            errTitle: errTitle,
            errMsg: JSON.stringify(this.props.responseMsg),
          });
        }
      })
      .catch((response) => {
        console.log("gagal_post_penetapan", response);
        this.setState({
          loadingButton: false,
        });
        message.error({
          content: "kesalahan pengiriman data penetapan, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  // async postDataKendaraan(datas, idPemilik) {
  //   var dataKendaraan = datas;
  //   dataKendaraan.pemilik = idPemilik;
  //   dataKendaraan.nomor_polisi =
  //     datas.no_polisi_depan + datas.no_polisi_tengah + datas.no_polisi_belakang;
  //   dataKendaraan.nomor_polisi_lama =
  //     datas.no_polisi_depan_lama +
  //     datas.no_polisi_tengah_lama +
  //     datas.no_polisi_belakang_lama;

  //   message.loading({ content: "Memproses...", duration: 0, key: "toast" });
  //   this.props
  //     .ajaxHandler(
  //       this.state.method,
  //       configClass.apiUrl.kendaraan + this.state.idLama,
  //       dataKendaraan,
  //       true,
  //       true
  //     )
  //     .then(() => {
  //       if (this.props.isSuccess) {
  //         message.success({
  //           content: "berhasil menyimpan pendaftaran",
  //           duration: 2,
  //           key: "toast",
  //         });
  //         this.setState({
  //           loadingButton: false,
  //         });
  //       } else {
  //         const errTitle = "gagal menyimpan data kendaraan";
  //         message.error({ content: errTitle, duration: 2, key: "toast" });
  //         this.setState({
  //           loadingButton: false,
  //           errTitle: errTitle,
  //           errMsg: JSON.stringify(this.props.responseMsg),
  //         });
  //       }
  //     })
  //     .catch((response) => {
  //       console.log("gagal_post_kendaraan", response);
  //       this.setState({
  //         loadingButton: false,
  //       });
  //       message.error({
  //         content: "kesalahan pengiriman data kendaraan, coba lagi",
  //         duration: 2,
  //         key: "toast",
  //       });
  //     });
  // }

  render() {
    // if(this.props.layanan_str.length>0 && this.props.layanan_str.includes('Pembelian / Pemesanan Obat') == false){
    // 	return <Redirect to="/admin/forbidden" />
    // }
    // if(this.props.isRedirect){
    // 	return <Redirect to={configClass.pageUrl.data} />
    // }

    return (
      <div>
        <Helmet>
          <title>
            {configClass.title + " | " + config.get_site_info().name}
          </title>
        </Helmet>
        <Breadcrumb>
          {configClass.breadCrumb.map((item) => (
            <Breadcrumb.Item>{item}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <div className="card" id="panel-transaksi">
          <Card
            className="konten"
            title={null}
          >

            <Row className="isi">
              {this.state.errTitle != null && this.state.errTitle != "" && (
                <Alert
                  message={this.state.errTitle}
                  description={JSON.stringify(this.state.errMsg)}
                  type="error"
                  showIcon
                  style={{
                    marginBottom: 10,
                  }}
                />
              )}

              <Col span={24}>
                <Row justify="start">
                  <Col span={24}>
                    <Table
                      title={() =>
                        <Row>
                          <Col lg={8} sm={24} xs={24}><span className="konten-judul">Total {this.state.pagination.total} record</span></Col>
                          <Col lg={{ span: 8, offset: 8 }} sm={24} xs={24}>
                            <Input.Search
                              size="small"
                              placeholder="ketikkan pencarian"
                              onSearch={value => {
                                this.setState({
                                  searchText: value
                                }, () => {
                                  this.previewData();
                                });
                              }}
                              allowClear
                            />
                          </Col>
                        </Row>
                      }
                      bordered
                      columns={this.state.columns}
                      dataSource={this.state.dataSource}
                      loading={this.state.loadingData}
                      scroll={{ x: 240, y: 450 }}
                      size="small"
                      pagination={this.state.pagination}
                      onChange={this.handleTableChange}
                    />
                  </Col>
                </Row>
              </Col>


            </Row>

          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  initHandler,
  setConfig,
  ajaxViewHandler,
  ajaxHandler,
})(CancelNotice);
