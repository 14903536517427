import React from 'react';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import {
    initHandler,
    ajaxViewHandler,
    ajaxHandler,
    setConfig,
} from "../../store/actions";
import {
    Breadcrumb,
    Card,
    Row,
    Col,
    Input,
    Button,
    Table,
    Modal,
    message,
    Alert,
    Select,
    Tooltip,
    Spin,
    Form,
    Skeleton
} from 'antd';

import moment from 'moment';

import Config from '../../Config';
import { Helmet } from "react-helmet";
import queryString from 'query-string';

import '../../assets/laporan.css';


const configClass = {
    title: 'LAPORAN PER REKENING',
    apiUrl: {
        preview: 'laporan/api/pemutihan/',
        userProfile: "auth/api/user/info"
    },

}

class PemutihanCetak extends React.Component {
    config;

    constructor(props) {
        super(props);
        this.config = new Config();
        this.state = {
            loading: false,
            data: null,
            err: false,

            namauptppd: 'SAMSAT PALANGKARAYA',

            mulai_dari: "",
            sampai_dengan: "",
        }

        // this.preview(this.props.match.params.id);

    }

    componentDidMount() {
        var uri_params = "";
        const uri_string = queryString.parse(this.props.location.search);
        var uri_params = "";

        for (var key in uri_string) {
            if (uri_params == "") {
                uri_params = "?" + key + "=" + uri_string[key];
            } else {
                uri_params += "&" + key + "=" + uri_string[key];
            }
            if (key == "mulai_dari") {
                this.setState({
                    mulai_dari: uri_string[key]
                })
            } else if (key == "sampai_dengan") {
                this.setState({
                    sampai_dengan: uri_string[key]
                })
            }
        }
        this.preview(uri_params);
    }

    async preview(uri_params) {
        message.loading({ content: 'Menampilkan laporan...', duration: 0, key: 'toast' });
        this.setState({
            loading: true,
            err: false,
            data: null,
        });

        this.props.ajaxViewHandler('get', configClass.apiUrl.preview + uri_params)
            .then(() => {
                const data = this.props.responseMsg;
                if (this.props.isSuccess) {
                    message.destroy();
                    this.setState({
                        loading: false,
                        data: data,
                        err: false
                    });
                    setTimeout(function () {
                        window.print();

                    }, 1300);
                } else {
                    const errTitle = 'error get laporan';
                    this.setState({
                        loading: false,
                        data: null,
                        err: true,
                        errMsg: this.props.responseMsg
                    });
                    message.error({ content: errTitle, duration: 2, key: 'toast' });
                }
            }).catch((response) => {
                this.setState({
                    loading: false,
                    data: null,
                    err: true,
                    errMsg: 'unknown response'
                });
                console.log('gagal_get_laporan', response);
                message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
            });
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{configClass.title}</title>
                </Helmet>
                {(this.state.data != null &&
                    <div className="kertas-laporan pagebreak">
                        <div className="judul-laporan-sts" style={{ borderBottom: "double 5px #000000" }}>
                            REKAPITULASI POKOK DAN DENDA BBN2 YANG DIBERIKAN PENGHAPUSAN<br />
                            BERDASARKAN PERATURAN GUBERNUR OKTOBER-DESEMBER 2023
                            <br />
                            uptppd : {this.state.data.ttd.upt != undefined ? this.state.data.ttd.upt : "undefined ttd > upt"}
                            <br />
                            tanggal : {this.state.mulai_dari != this.state.sampai_dengan ? this.state.mulai_dari + " s/d " + this.state.sampai_dengan : this.state.mulai_dari}
                        </div>
                        <table className="laporan-table" cellPadding="5" border="1">
                            <tr >
                                <th >NO</th>
                                <th >NAMA</th>
                                <th >ALAMAT</th>
                                <th >NO HP</th>
                                <th >NOPOL</th>
                                <th >PKB YANG DIBAYAR (Rp)</th>
                                <th >POKOK BBN2 YANG DIHAPUSKAN (Rp)</th>
                                <th >DENDA BBN2 YANG DIHAPUSKAN (Rp)</th>
                                <th>JENIS KENDARAAN BERMOTOR</th>
                            </tr>
                            {this.state.data.data.map((item, idx) => (
                                <tr>
                                    <td >{idx + 1}</td>
                                    <td >{item.nama != undefined ? item.nama : "undefined nama"}</td>
                                    <td >{item.alamat}</td>
                                    <td >{item.no_hp}</td>
                                    <td >{item.nomor_polisi}</td>
                                    <td >{item.pkb != undefined ? item.pkb : "undefined pkb"}</td>
                                    <td >{item.bbn2_pokok != undefined ? item.bbn2_pokok : "undefined bbn2_pokok"}</td>
                                    <td >{item.bbn2_denda != undefined ? item.bbn2_denda : "undefined bbn2_denda"}</td>
                                    <td>{item.jenis_kendaraan}</td>
                                </tr>
                            ))}
                        </table>
                        {(this.state.data.ttd != undefined &&
                            <Row justify="space-between" style={{ marginTop: 10 }}>
                                <Col span={24}>
                                    REKAPITULASI JUMLAH KENDARAAN<br />
                                    RODA 2 : {this.state.data.roda_dua}
                                    <br />
                                    RODA 4 : {this.state.data.roda_dua}
                                </Col>
                                <Col span={24} className='text-center'>
                                    {this.state.data.ttd.upt != undefined ? this.state.data.ttd.upt : "undefined ttd > upt"}, TGL {this.state.mulai_dari}
                                </Col>
                                <Col span={8} className="teks-tengah">
                                    {this.state.data.ttd.kiri.jabatan}<br />
                                    <div style={{ marginTop: 50 }}>
                                        <u>{this.state.data.ttd.kiri.nama}</u><br />
                                        NIP : {this.state.data.ttd.kiri.nip}
                                    </div>
                                </Col>
                                <Col span={8} className="teks-tengah">
                                    {this.state.data.ttd.kanan.jabatan}<br />
                                    <div style={{ marginTop: 50 }}>
                                        <u>{this.state.data.ttd.kanan.nama}</u><br />
                                        NIP : {this.state.data.ttd.kanan.nip}
                                    </div>
                                </Col>

                            </Row>
                        )}

                    </div>
                )}
            </>
        )
    }

}

const mapStateToProps = function (state) {
    return state.configReducers
}

export default connect(
    mapStateToProps,
    {
        initHandler,
        setConfig,
        ajaxViewHandler,
        ajaxHandler,
        setConfig
    }
)(PemutihanCetak);