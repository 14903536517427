import React from 'react';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Input,
	Button,
	Table,
	Modal,
	message,
	Alert,
	Select,
	Tooltip,
	Spin,
	Form,
	Skeleton
} from 'antd';

import moment from 'moment';

import Config from '../../Config';
import { Helmet } from "react-helmet";
import queryString from 'query-string';

import '../../assets/laporan.css';


const configClass = {
	title: 'laporan notice',
	apiUrl: {
		preview: '#',
		userProfile: "auth/api/user/info"
	},

}

class NotaPajak extends React.Component {
	config;

	constructor(props) {
		super(props);
		this.config = new Config();
		this.state = {
			loadingCart: false,
			dataCart: null,
			nama_outlet: this.config.get_site_info().name,
			nama_user: '',
			alamat_outlet: '',
		}

		// this.preview(this.props.match.params.id);

	}

	componentDidMount() {
		// this.preview(this.props.match.params.id);
	}

	async preview(id) {
		message.loading({ content: 'Menampilkan faktur...', duration: 0, key: 'toast' });
		this.setState({
			loadingCart: true
		});

		this.props.ajaxViewHandler('get', configClass.apiUrl.preview + id)
			.then(() => {
				const data = this.props.responseMsg;
				if (this.props.isSuccess) {
					message.destroy();
					this.setState({
						loadingCart: false,
						dataCart: data
					});
					setTimeout(function () {
						// window.print();

					}, 100);
				} else {
					const errTitle = 'error preview data lama';
					this.setState({
						errTitle: errTitle,
						errMsg: this.props.responseMsg
					});
					message.error({ content: errTitle, duration: 2, key: 'toast' });
				}
			}).catch((response) => {
				console.log('gagal_get_preview', response);
				message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			});
	}

	render() {
		return (
			<>
				<Helmet>
					<title>{configClass.title}</title>
				</Helmet>
				<div className="kertas-laporan">
					<div className="judul-laporan teks-tengah">
						BADAN PENDAPATAN DAERAH<br />
						SAMSAT PALANGKARAYA<br />
						LAPORAN : HARIAN PENGGUNAAN NOTA PAJAK
					</div>
					<Row>
						<Col span={5} className="titik-dua">UPTD SAMSAT</Col>
						<Col span={19}>SAMSAT PALANGKARAYA</Col>
						<Col span={5} className="titik-dua">TANGGAL</Col>
						<Col span={19}>07-10-2021</Col>
						<Col span={5} className="titik-dua">TAHUN ANGGARAN</Col>
						<Col span={19}>2021</Col>
					</Row>
					<table className="laporan-table" border="1">
						<thead>
							<tr>
								<th rowspan="2">No.</th>
								<th rowspan="2">Nomor Kendaraan</th>
								<th colspan="2">Nomor Notice</th>
								<th rowspan="2">Masa Pajak</th>
								<th rowspan="2">Keterangan</th>
							</tr>
							<tr>
								<th>Terpakai</th>
								<th>Batal / Rusak</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
							<tr>
								<td className="teks-kanan">1</td>
								<td className="teks-tengah">KH 1234 XY</td>
								<td className="teks-tengah">0183272</td>
								<td className="teks-tengah"></td>
								<td className="teks-tengah">31/10/2022</td>
								<td className="teks-tengah"></td>
							</tr>
						</tbody>
					</table>
					<Row justify="space-between" style={{ marginTop: 10 }}>
						<Col span={10}>
							<Row>
								<Col span={10} className="titik-dua">
									1. TERPAKAI
								</Col>
								<Col span={14} >
									<Row>
										<Col span="4" className="teks-kanan">
											517
										</Col>
										<Col span="20" style={{ paddingLeft: 15 }}>
											Lembar
										</Col>
									</Row>
								</Col>
								<Col span={10} className="titik-dua">
									2. BATAL / RUSAK
								</Col>
								<Col span={14} >
									<Row>
										<Col span="4" className="teks-kanan">
											3
										</Col>
										<Col span="20" style={{ paddingLeft: 15 }}>
											Lembar
										</Col>
									</Row>
								</Col>
								<Col span={10} className="titik-dua">
									JUMLAH
								</Col>
								<Col span={14} >
									<Row>
										<Col span="4" className="teks-kanan">
											520
										</Col>
										<Col span="20" style={{ paddingLeft: 15 }}>
											Lembar
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
						<Col span={10} className="teks-tengah">
							PALANGKARAYA, 7-10-2021<br />
							PETUGAS PENETAPAN KENDARAAN
							<div style={{ marginTop: 50 }}>
								<u>I KETUT WISNU PUTRA</u><br />
								NIP : 198205182014021001
							</div>
						</Col>
					</Row>
				</div>
			</>
		)
	}

}

const mapStateToProps = function (state) {
	return state.configReducers
}

export default connect(
	mapStateToProps,
	{
		initHandler,
		setConfig,
		ajaxViewHandler,
		ajaxHandler,
		setConfig
	}
)(NotaPajak);