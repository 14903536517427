import React from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Breadcrumb,
  Card,
  Row,
  Col,
  Avatar,
  Form,
  Input,
  Button,
  Space,
  Select,
  DatePicker,
  message,
  Alert,
  Spin,
  Modal,
  Skeleton,
  Table,
  Tag,
  Divider,
  Tooltip
} from "antd";
import {
  EditOutlined,
  DiffOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DollarOutlined,
  BarcodeOutlined,
  UserAddOutlined,
  PrinterOutlined,
  UserOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  CarOutlined,
} from "@ant-design/icons";
import moment from "moment";
import NumberFormat from "react-number-format";

import axios from "axios";
import Config from "../../Config";

import { connect } from "react-redux";
import {
  initHandler,
  ajaxViewHandler,
  ajaxHandler,
  setConfig,
} from "../../store/actions";
import { Helmet } from "react-helmet";
import queryString from 'query-string';

const FormItem = Form.Item;

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 19,
      offset: 5,
    },
    lg: {
      span: 19,
      offset: 5,
    },
  },
};

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const configClass = {
  title: "form penetapan",
  apiUrl: {
    penetapan: "transaksi/api/tagihan/",
  },
  pageUrl: {
    cetak_no: "/addon/cetak_kutipan_skp/",
    redirect_page: "/admin/transaksi/pendaftaran",
  },
  breadCrumb: [
    <span>
      <CarOutlined /> transaksi
    </span>,
    "penetapan",
  ],
};

const config = new Config();

class FormPenetapanBiaya extends React.Component {
  formRef = React.createRef();
  formRefScroll = React.createRef();
  formRefFocus = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      errComponent: false,
      errTitle: "",
      errMsg: "",
      loadingButton: false,

      isReadonly: true,

      cari_no_pol: undefined,

      isOpenForm: false,

      aksi: "tambah",
      method: "post",
      idLama: "",

      status: '1',

      pokok_bbnkb: 0,
      denda_bbnkb: 0,
      tunggakan_pokok_bbnkb: 0,
      tunggakan_denda_bbnkb: 0,

      pokok_pkb: 0,
      denda_pkb: 0,
      tunggakan_pokok_pkb: 0,
      tunggakan_denda_pkb: 0,

      pokok_swdkllj: 0,
      denda_swdkllj: 0,
      tunggakan_pokok_swdkllj: 0,
      tunggakan_denda_swdkllj: 0,

      pokok_admstnk: 0,
      denda_admstnk: 0,
      tunggakan_pokok_admstnk: 0,
      tunggakan_denda_admstnk: 0,

      pokok_admtnk: 0,
      denda_admtnk: 0,
      tunggakan_pokok_admtnk: 0,
      tunggakan_denda_admtnk: 0,

      pokok_sp3: 0,
      denda_sp3: 0,
      tunggakan_pokok_sp3: 0,
      tunggakan_denda_sp3: 0,

      isSukses: false,

      dataPenetapan: null,

    };
  }

  setReadOnly() {
    const temp = this.state.isReadonly;
    this.setState({
      isReadonly: false
    }, () =>{
      setTimeout(() =>{
        // this.formRefFocus.current && this.formRefFocus.current.focus()
      }, 1000);
    })
  }

  UNSAFE_componentWillMount() {
    this.props.initHandler();
  }

  componentDidMount() {
    if (queryString.parse(this.props.location.search).no_pol != undefined) {
      const noPol = queryString.parse(this.props.location.search).no_pol.toUpperCase();
      this.searchKendaraan(noPol);
      this.setState({
        cari_no_pol: noPol
      })
    }
  }

  searchKendaraan = (no_polisi) => {
    this.previewData(no_polisi);
  };

  async previewData(no_polisi) {
    message.loading({
      content: "Proses pencarian...",
      duration: 0,
      key: "toast",
    });

    this.setState({
      errTitle: '',
      errMsg: '',
    });

    this.props
      .ajaxViewHandler(
        "get",
        configClass.apiUrl.penetapan + "?nomor_polisi=KH" + no_polisi
      )
      .then(() => {
        const datas = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (datas.results.length == 0) {
            this.setState({
              isOpenForm: false,
            });
            message.error({ content: "nomor kendaraan tidak ditemukan", duration: 2, key: "toast" });
          } else {
            this.setState({
              isOpenForm: true,
            }, () => {
              this.formRefScroll.current.scrollTo(0, 0);
            });
            const data = datas.results[0];
            this.formRef.current.setFieldsValue(data);
            this.setState({
              isReadonly: true,
              dataPenetapan: data,

              pokok_bbnkb: data.pokok_bbnkb,
              denda_bbnkb: data.denda_bbnkb,
              tunggakan_pokok_bbnkb: data.tunggakan_pokok_bbnkb,
              tunggakan_denda_bbnkb: data.tunggakan_denda_pkb,

              pokok_pkb: data.pokok_pkb,
              denda_pkb: data.denda_pkb,
              tunggakan_pokok_pkb: data.tunggakan_pokok_pkb,
              tunggakan_denda_pkb: data.tunggakan_denda_pkb,

              pokok_swdkllj: data.pokok_swdkllj,
              denda_swdkllj: data.denda_swdkllj,
              tunggakan_pokok_swdkllj: data.tunggakan_pokok_swdkllj,
              tunggakan_denda_swdkllj: data.tunggakan_denda_swdkllj,

              pokok_admstnk: data.pokok_admstnk,
              denda_admstnk: data.denda_admstnk,
              tunggakan_pokok_admstnk: data.tunggakan_pokok_admstnk,
              tunggakan_denda_admstnk: data.tunggakan_denda_admstnk,

              pokok_admtnk: data.pokok_admtnk,
              denda_admtnk: data.denda_admtnk,
              tunggakan_pokok_admtnk: data.tunggakan_pokok_admtnk,
              tunggakan_denda_admtnk: data.tunggakan_denda_admtnk,

              pokok_sp3: data.pokok_sp3,
              denda_sp3: data.denda_sp3,
              tunggakan_pokok_sp3: data.tunggakan_pokok_sp3,
              tunggakan_denda_sp3: data.tunggakan_denda_sp3,
            })
            const total_pokok_berjalan = data.pokok_bbnkb + data.pokok_pkb + data.pokok_swdkllj + data.pokok_admstnk + data.pokok_admtnk + data.pokok_sp3;
            const total_denda_berjalan = data.denda_bbnkb + data.denda_pkb + data.denda_swdkllj + data.denda_admstnk + data.denda_admtnk + data.denda_sp3;
            const total_jumlah_berjalan = data.jumlah_bbnkb + data.jumlah_pkb + data.jumlah_swdkllj + data.jumlah_admstnk + data.jumlah_admtnk + data.jumlah_sp3;
            const total_pokok_tunggakan = data.tunggakan_pokok_bbnkb + data.tunggakan_pokok_pkb + data.tunggakan_pokok_swdkllj + data.tunggakan_pokok_admstnk + data.tunggakan_pokok_admtnk + data.tunggakan_pokok_sp3;
            const total_denda_tunggakan = data.tunggakan_denda_bbnkb + data.tunggakan_denda_pkb + data.tunggakan_denda_swdkllj + data.tunggakan_denda_admstnk + data.tunggakan_denda_admtnk + data.tunggakan_denda_sp3;
            const total_jumlah_tunggakan = data.tunggakan_jumlah_bbnkb + data.tunggakan_jumlah_pkb + data.tunggakan_jumlah_swdkllj + data.tunggakan_jumlah_admstnk + data.tunggakan_jumlah_admtnk + data.tunggakan_jumlah_sp3;

            this.formRef.current.setFieldsValue({
              total_pokok_berjalan: total_pokok_berjalan, //data.pokok_bbnkb+data.pokok_pkb+data.pokok_swdkllj+data.pokok_admstnk+data.pokok_admtnk,
              total_denda_berjalan: total_denda_berjalan, //data.denda_bbnkb+data.denda_pkb+data.denda_swdkllj+data.denda_admstnk+data.denda_admtnk,
              total_jumlah_berjalan: total_jumlah_berjalan, //data.jumlah_bbnkb+data.jumlah_pkb+data.jumlah_swdkllj+data.jumlah_admstnk+data.jumlah_admtnk,

              total_pokok_tunggakan: total_pokok_tunggakan, //data.tunggakan_pokok_bbnkb+data.tunggakan_pokok_pkb+data.tunggakan_pokok_swdkllj+data.tunggakan_pokok_admstnk+data.tunggakan_pokok_admtnk,
              total_denda_tunggakan: total_denda_tunggakan, //data.tunggakan_denda_bbnkb+data.tunggakan_denda_pkb+data.tunggakan_denda_swdkllj+data.tunggakan_denda_admstnk+data.tunggakan_denda_admtnk,
              total_jumlah_tunggakan: total_jumlah_tunggakan, //data.tunggakan_jumlah_bbnkb+data.tunggakan_jumlah_pkb+data.tunggakan_jumlah_swdkllj+data.tunggakan_jumlah_admstnk+data.tunggakan_jumlah_admtnk,

              grand_total: total_jumlah_berjalan + total_jumlah_tunggakan,
            });
            for (var key in data) {
              const dateRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4}$/;
              const dateTimeRegex =
                /^[0-9]{2}\-[0-9]{2}\-[0-9]{4} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;

              const dateRegex2 = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
              const dateTimeRegex2 =
                /^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
              if (dateRegex.test(data[key])) {
                //jika format tanggal
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key]),//.format("DD-MM-YYYY"),
                  [key]: data[key]
                  //[key]: moment(data[key], 'DD-MM-YYYY')
                });
              } else if (dateTimeRegex.test(data[key])) {
                //jika format tanggal dan jam
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key]),//.format("DD-MM-YYYY HH:mm:ss"),
                  [key]: data[key]
                });
              } else if (dateRegex2.test(data[key])) {
                //jika format tanggal
                // console.log(key, data[key]);
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key]),
                  [key]: data[key]
                  //[key]: moment(data[key], 'DD-MM-YYYY')
                });
              } else if (dateTimeRegex2.test(data[key])) {
                //jika format tanggal dan jam
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data[key]),
                  [key]: data[key]
                });
              } else {

                if (key == "tgl_daftar" || key == "tgl_penetapan" || key == "masa_pajak_mulai" || key == "masa_pajak_sampai" || key == "tanggal_pembayaran") {
                  this.formRef.current.setFieldsValue({
                    // [key + "_picker"]: moment(data[key]),// "DD-MM-YYYY"),
                    [key]: moment(data[key]).format("DD-MM-YYYY"),
                  });
                } else if (key == "pokok_bbnkb" || key == "denda_bbnkb" || key == "jumlah_bbnkb" || key == "tunggakan_pokok_bbnkb" || key == "tunggakan_denda_bbnkb" || key == "tunggakan_jumlah_bbnkb") {
                  this.formRef.current.setFieldsValue({
                    [key + "_preview"]: data[key]
                  });
                } else if (key == "pokok_pkb" || key == "denda_pkb" || key == "jumlah_pkb" || key == "tunggakan_pokok_pkb" || key == "tunggakan_denda_pkb" || key == "tunggakan_jumlah_pkb") {
                  this.formRef.current.setFieldsValue({
                    [key + "_preview"]: data[key]
                  });
                } else if (key == "pokok_swdkllj" || key == "denda_swdkllj" || key == "jumlah_swdkllj" || key == "tunggakan_pokok_swdkllj" || key == "tunggakan_denda_swdkllj" || key == "tunggakan_jumlah_swdkllj") {
                  this.formRef.current.setFieldsValue({
                    [key + "_preview"]: data[key]
                  });
                } else if (key == "pokok_admstnk" || key == "denda_admstnk" || key == "jumlah_admstnk" || key == "tunggakan_pokok_admstnk" || key == "tunggakan_denda_admstnk" || key == "tunggakan_jumlah_admstnk") {
                  this.formRef.current.setFieldsValue({
                    [key + "_preview"]: data[key]
                  });
                } else if (key == "pokok_admtnk" || key == "denda_admtnk" || key == "jumlah_admtnk" || key == "tunggakan_pokok_admtnk" || key == "tunggakan_denda_admtnk" || key == "tunggakan_jumlah_admtnk") {
                  this.formRef.current.setFieldsValue({
                    [key + "_preview"]: data[key]
                  });
                } else if (key == "pokok_sp3" || key == "denda_sp3" || key == "jumlah_sp3" || key == "tunggakan_pokok_sp3" || key == "tunggakan_denda_sp3" || key == "tunggakan_jumlah_sp3") {
                  this.formRef.current.setFieldsValue({
                    [key + "_preview"]: data[key] != undefined && data[key] != null && data[key] != "" ? data[key] : 0
                  });
                } else if (key == "layanan_list") {
                  this.formRef.current.setFieldsValue({
                    layanan_nama: data[key][0] != undefined ? data[key][0].nama : '',
                    layanan_nama2: data[key][1] != undefined ? data[key][1].nama : '',
                    layanan_nama3: data[key][3] != undefined ? data[key][3].nama : '',
                    layanan_nama4: data[key][4] != undefined ? data[key][4].nama : ''
                  });
                } else if (key == 'status') {
                  this.setState({
                    status: data[key]
                  });
                }
              }

              /*else {
               if (key == "pemilik_nama") {
                 this.formRef.current.setFieldsValue({
                   nama: data[key],
                 });
               } else if (key == "pemilik_alamat") {
                 this.formRef.current.setFieldsValue({
                   alamat: data[key],
                 });
               } else if (key == "pemilik_no_hp") {
                 this.formRef.current.setFieldsValue({
                   no_hp: data[key],
                 });
               } else if (key == "pemilik_nik") {
                 this.formRef.current.setFieldsValue({
                   nik: data[key],
                 });
               }else if (key == "nomor_polisi_tengah") {
                 this.formRef.current.setFieldsValue({
                   no_polisi_tengah: data[key],
                 });
               }else if (key == "nomor_polisi_akhir") {
                 this.formRef.current.setFieldsValue({
                   no_polisi_belakang: data[key],
                 });
               } else {
                 this.formRef.current.setFieldsValue({
                   [key]: data[key],
                 });
               }
             }*/
              // if(key == 'gambar'){
              // 	this.setState({
              // 		preview_gambar: data[key]
              // 	});
              // }else{
              // 	this.formRef.current.setFieldsValue({
              // 		[key]: data[key]
              // 	});
              // }
            }
            message.destroy();
            this.setState({
              aksi: "edit",
              method: "put",
              idLama: data.id + "/",
            });
          }
        } else {
          const errTitle = "error preview data lama";
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
          message.error({ content: errTitle, duration: 2, key: "toast" });
        }
      })
      .catch((response) => {
        console.log("gagal_get_preview", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  handleSubmit = (values) => {
    let data = values;
    data = { ...data, is_edit: this.state.isReadonly ? false : true };
    this.postDataPenetapan(data);
  };

  async getPasienById(val) {
    message.loading({
      content: "Display data pasien...",
      duration: 0,
      key: "toast",
    });
    this.setState({
      errTitle: "",
      errMsg: "",
      dataPasien: null,
      skeletonPasien: true,
    });
    this.props
      .ajaxViewHandler("get", configClass.apiUrl.pasien + "/" + val)
      .then(() => {
        this.setState({
          skeletonPasien: false,
        });
        if (this.props.isSuccess) {
          message.destroy();

          var response = this.props.responseMsg;
          this.setState({
            dataPasien: response,
          });
        } else {
          const errTitle = "gagal menampilkan data pasien";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
        }
      })
      .catch((response) => {
        console.log("gagal_preview_pasien", response);
        this.setState({
          skeletonPasien: false,
        });
        message.error({
          content: "kesalahan pengiriman data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  reset = () => {
    this.setState({
      cari_no_pol: undefined,
      isOpenForm: false,

      isSukses: true,
    });
  };

  async postDataPenetapan(datas) {
    message.loading({ content: "Memproses...", duration: 0, key: "toast" });
    this.setState({
      errTitle: "",
      errMsg: "",
      loadingButton: true,
    });
    this.props
      .ajaxHandler("put", configClass.apiUrl.penetapan + this.state.idLama, datas, false, true)
      .then(() => {
        if (this.props.isSuccess) {
          var response = this.props.responseMsg;
          var that = this;

          message.success({ content: 'berhasil menyimpan data penetapan', duration: 2, key: 'toast' });
          this.setState({
            loadingButton: false,
            isReadonly: true,
          });

          Modal.confirm({
            title: 'Penetapan Biaya Berhasil',
            icon: <CheckCircleOutlined style={{ color: 'green' }} />,
            // content: 'tekan cetak untuk mencetak, tekan selesai untuk melanjutkan',
            content: 'penetapan biaya berhasil di proses, tekan ok atau selesai',
            okText: 'Ok',
            // okText: <a href={configClass.pageUrl.cetak_no+response.id} target="_blank"><PrinterOutlined /> Cetak</a>,
            cancelText: 'Selesai',
            onOk() {
              that.reset()
            },
            onCancel() {
              that.reset()
            }
          });

        } else {
          const errTitle = "gagal menyimpan data penetapan";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            loadingButton: false,
            errTitle: errTitle,
            errMsg: JSON.stringify(this.props.responseMsg),
          });
        }
      })
      .catch((response) => {
        console.log("gagal_post_penetapan", response);
        this.setState({
          loadingButton: false,
        });
        message.error({
          content: "kesalahan pengiriman data penetapan, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  batalkanPenetapan() {
    message.loading({ content: 'Memproses...', key: 'toast' });

    this.props.ajaxHandler('delete', configClass.apiUrl.penetapan + this.state.idLama)
      .then(() => {
        this.setState({
          loadingButton: false
        });
        if (this.props.isSuccess) {
          var response = this.props.responseMsg;
          var that = this;

          message.success({ content: 'berhasil membatalkan data penetapan', duration: 2, key: 'toast' });
          this.setState({
            loadingButton: false,
          });

          Modal.confirm({
            title: 'Pembatalan Penetapan Biaya Berhasil',
            icon: <CheckCircleOutlined style={{ color: 'green' }} />,
            // content: 'tekan cetak untuk mencetak, tekan selesai untuk melanjutkan',
            content: 'data penetapan berhahsil dibatalkan, tekan ok atau selesai',
            okText: 'Ok',
            // okText: <a href={configClass.pageUrl.cetak_no+response.id} target="_blank"><PrinterOutlined /> Cetak</a>,
            cancelText: 'Selesai',
            onOk() {
              that.reset()
            },
            onCancel() {
              that.reset()
            }
          });
        } else {
          const errTitle = 'gagal membatalkan penetapan'
          message.error({ content: errTitle, duration: 2, key: 'toast' });
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg
          });
        }
      }).catch((response) => {
        console.log('gagal_delete', response);
        this.setState({
          loadingButton: false
        });
        message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
      });

  }

  // async postDataKendaraan(datas, idPemilik) {
  //   var dataKendaraan = datas;
  //   dataKendaraan.pemilik = idPemilik;
  //   dataKendaraan.nomor_polisi =
  //     datas.no_polisi_depan + datas.no_polisi_tengah + datas.no_polisi_belakang;
  //   dataKendaraan.nomor_polisi_lama =
  //     datas.no_polisi_depan_lama +
  //     datas.no_polisi_tengah_lama +
  //     datas.no_polisi_belakang_lama;

  //   message.loading({ content: "Memproses...", duration: 0, key: "toast" });
  //   this.props
  //     .ajaxHandler(
  //       this.state.method,
  //       configClass.apiUrl.kendaraan + this.state.idLama,
  //       dataKendaraan,
  //       true,
  //       true
  //     )
  //     .then(() => {
  //       if (this.props.isSuccess) {
  //         message.success({
  //           content: "berhasil menyimpan pendaftaran",
  //           duration: 2,
  //           key: "toast",
  //         });
  //         this.setState({
  //           loadingButton: false,
  //         });
  //       } else {
  //         const errTitle = "gagal menyimpan data kendaraan";
  //         message.error({ content: errTitle, duration: 2, key: "toast" });
  //         this.setState({
  //           loadingButton: false,
  //           errTitle: errTitle,
  //           errMsg: JSON.stringify(this.props.responseMsg),
  //         });
  //       }
  //     })
  //     .catch((response) => {
  //       console.log("gagal_post_kendaraan", response);
  //       this.setState({
  //         loadingButton: false,
  //       });
  //       message.error({
  //         content: "kesalahan pengiriman data kendaraan, coba lagi",
  //         duration: 2,
  //         key: "toast",
  //       });
  //     });
  // }

  render() {
    // if(this.props.layanan_str.length>0 && this.props.layanan_str.includes('Pembelian / Pemesanan Obat') == false){
    // 	return <Redirect to="/admin/forbidden" />
    // }
    if (this.state.isSukses) {
      return <Redirect to={configClass.pageUrl.redirect_page} />
    }

    return (
      <div>
        <Helmet>
          <title>
            {configClass.title + " | " + config.get_site_info().name}
          </title>
        </Helmet>
        <Breadcrumb>
          {configClass.breadCrumb.map((item) => (
            <Breadcrumb.Item>{item}</Breadcrumb.Item>
          ))}
          {this.props.printer != undefined && <Tag color="blue">default printer : {this.props.nama_printer}</Tag>}
        </Breadcrumb>
        <div className="card" id="panel-transaksi">
          <Card
            className="konten"
            title={null}
          >
            <Form
              layout="horizontal"
              name="fdata"
              onFinish={this.handleSubmit}
              size="small"
              ref={this.formRef}
              initialValues={{
                kode_bbn_kb: "001",
                nama_bbn_kb: "BBN KB",
                kode_pkb: "002",
                nama_pkb: "PKB",
                kode_swdkllj: "003",
                nama_swdkllj: "SWDKLLJ",
                kode_admstnk: "004",
                nama_admstnk: "BIAYA ADM.STNK",
                kode_admtnk: "005",
                nama_admtnk: "BIAYA ADM.TNKB",
                kode_sp3: "006",
                nama_sp3: "SP3",
                status: "2",
                label_jumlah_total: "JUMLAH TOTAL",
                label_grand_total: "Total Pajak Berjalan & Tunggakan",
                nomor_polisi_awal: "KH"
              }}
            >
              <Row className="isi">
                {this.state.errTitle != null && this.state.errTitle != "" && (
                  <Alert
                    message={this.state.errTitle}
                    description={this.state.errMsg}
                    type="error"
                    showIcon
                    style={{
                      marginBottom: 10,
                      width: "100%"
                    }}
                  />
                )}

                <Form.Item name="status" style={{ display: "none" }}>
                  <Input type="hidden" />
                </Form.Item>

                <Col span={24}>
                  <Row justify="start">
                    <Col span={24}>
                      <Input.Group compact>

                        <Input
                          allowClear
                          style={{ width: "100%" }}
                          prefix="KH"
                          placeholder="input nomer polisi, cth : KH1234XY"
                          onChange={(e) => {
                            const regex = /^\d+\.?\d*$/;
                            let startIdx = 0;
                            if (regex.test(e.target.value.charAt(0))) {
                              startIdx = 0;
                            } else {
                              if (regex.test(e.target.value.charAt(1))) {
                                startIdx = 1;
                              } else {
                                startIdx = 2;
                              }
                            }

                            const noKendaraan = e.target.value.substring(startIdx);

                            this.setState({
                              // cari_no_pol: e.target.value,
                              cari_no_pol: noKendaraan,
                            });
                          }}
                          value={this.state.cari_no_pol}
                          name="cari_no_pol"
                          size="large"
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              this.searchKendaraan(this.state.cari_no_pol);
                            }
                          }}

                        />

                      </Input.Group>
                      <Divider />
                    </Col>
                  </Row>
                </Col>

                {this.state.isOpenForm == true && (
                  <Col
                    span={24}
                    style={{
                      overflowY: "auto",
                      maxHeight: "calc(100vh - 205px)",
                      // background: "rgb(11,93,37, 0.2)", 
                      padding: 10,
                      // boxShadow: "5px 10px"
                    }}
                    ref={this.formRefScroll}>

                    <Form.Item name="is_penetapan" style={{ display: "none" }}>
                      <Input type="hidden" />
                    </Form.Item>
                    <Form.Item name="pemilik" style={{ display: "none" }}>
                      <Input type="hidden" />
                    </Form.Item>
                    <Form.Item name="kendaraan" style={{ display: "none" }}>
                      <Input type="hidden" />
                    </Form.Item>
                    <Row className="form-penetapan-biaya">
                      <Col span={24}>
                        <Row justify="start">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Status
                          </Col>
                          <Col span={7} style={{ paddingLeft: 5, paddingBottom: 5 }}>
                            {(this.state.status == "1" &&
                              <Tag color="red">pendaftaran</Tag>
                            )}
                            {(this.state.status == "2" &&
                              <Tag color="yellow">penetapan</Tag>
                            )}
                            {(this.state.status == "3" &&
                              <Tag color="green">pembayaran</Tag>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="form-penetapan-biaya">
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Jenis Daftar 1
                          </Col>
                          <Col span={7} style={{ paddingLeft: 5 }}>
                            <Form.Item name="layanan_nama">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={4} style={{ textAlign: "right" }}>
                            Jenis Daftar 2
                          </Col>
                          <Col span={10} style={{ paddingLeft: 5 }}>
                            <Form.Item name="layanan_nama2">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Jenis Daftar 3
                          </Col>
                          <Col span={7} style={{ paddingLeft: 5 }}>
                            <Form.Item name="layanan_nama3">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={4} style={{ textAlign: "right" }}>
                            Jenis Daftar 4
                          </Col>
                          <Col span={10} style={{ paddingLeft: 5 }}>
                            <Form.Item name="layanan_nama4">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Nomor Polisi
                          </Col>
                          <Col span={2} style={{ paddingLeft: 5 }}>
                            <Form.Item name="nomor_polisi_awal">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={3} style={{ paddingLeft: 5 }}>
                            <Form.Item name="nomor_polisi_tengah">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ paddingLeft: 5 }}>
                            <Form.Item name="nomor_polisi_akhir">
                              <Input disabled />
                            </Form.Item>
                          </Col>

                          <Col span={4} style={{ textAlign: "right" }}>
                            No Daftar
                          </Col>
                          <Col span={3} style={{ paddingLeft: 5 }}>
                            <Form.Item name="no_daftar">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={3} style={{ textAlign: "right" }}>
                            Tgl. Daftar
                          </Col>
                          <Col span={4} style={{ paddingLeft: 5 }}>
                            <Form.Item name="tgl_daftar">
                              <Input disabled />
                            </Form.Item>
                            {/*
                            <Form.Item name="tgl_daftar" style={{display: "none"}}>
                              <Input type="hidden" />
                            </Form.Item>
                            */}
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            No. SKUM
                          </Col>
                          <Col span={7} style={{ paddingLeft: 5 }}>
                            <Form.Item name="no_skum">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={4} style={{ textAlign: "right" }}>
                            No. Kohir
                          </Col>
                          <Col span={10} style={{ paddingLeft: 5 }}>
                            <Form.Item name="no_kohir">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row>
                          <Col span={24}>
                            <Divider>
                              Wajib Pajak &amp; Obyek Pajak
                            </Divider>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Nama WP/Pemilik
                          </Col>
                          <Col span={7} style={{ paddingLeft: 5 }}>
                            <Form.Item name="nama_wp">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={4} style={{ textAlign: "right" }}>
                            Alamat WP/Pemilik
                          </Col>
                          <Col span={10} style={{ paddingLeft: 5 }}>
                            <Form.Item name="alamat_wp">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Jenis Kendaraan
                          </Col>
                          <Col span={7} style={{ paddingLeft: 5 }}>
                            <Form.Item name="jenis_kendaraan">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={4} style={{ textAlign: "right" }}>
                            Merk Kendaraan
                          </Col>
                          <Col span={10} style={{ paddingLeft: 5 }}>
                            <Form.Item name="merk_kendaraan">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Tipe Kendaraan
                          </Col>
                          <Col span={7} style={{ paddingLeft: 5 }}>
                            <Form.Item name="tipe_kendaraan">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={4} style={{ textAlign: "right" }}>
                            Thn. Buat/Rakit
                          </Col>
                          <Col span={3} style={{ paddingLeft: 5 }}>
                            <Form.Item name="thn_buat">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={1} style={{ textAlign: "left", paddingLeft: 5 }}>
                            Warna
                          </Col>
                          <Col span={6} style={{ paddingLeft: 5 }}>
                            <Form.Item name="warna">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Warna TNK
                          </Col>

                          <Col span={2} style={{ paddingLeft: 5 }}>
                            <Form.Item name="warna_tnk">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={1} style={{ paddingRight: 5, textAlign: "right" }}>
                            Ke
                          </Col>
                          <Col span={1} style={{ paddingLeft: 5 }}>
                            <Form.Item name="ke">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={1} style={{ paddingRight: 5, textAlign: "right" }}>
                            CC
                          </Col>
                          <Col span={2} style={{ paddingLeft: 5 }}>
                            <Form.Item name="cc">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={4} style={{ textAlign: "right" }}>
                            Kend. JR
                          </Col>
                          <Col span={2} style={{ paddingLeft: 5 }}>
                            <Form.Item name="golongan_kode">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={8} style={{ paddingLeft: 5 }}>
                            <Form.Item name="golongan_nama">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Tgl. Kwitansi
                          </Col>
                          <Col span={2} style={{ paddingLeft: 5 }}>
                            <Form.Item name="tanggal_kwitansi">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ paddingRight: 5, textAlign: "right" }}>
                            Tgl. Fiskal
                          </Col>
                          <Col span={3} style={{ paddingLeft: 5 }}>
                            <Form.Item name="tanggal_fiskal">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={4} style={{ textAlign: "right" }}>
                            Tgl. SK
                          </Col>
                          <Col span={2} style={{ paddingLeft: 5 }}>
                            <Form.Item name="stnk_akhir">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ textAlign: "right" }}>
                            Tgl. JR yl
                          </Col>
                          <Col span={6} style={{ paddingLeft: 5 }}>
                            <Form.Item name="sd_swdkllj">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Harga Risalah
                          </Col>
                          <Col span={2} style={{ paddingLeft: 5 }}>
                            <Form.Item name="harga_risalah">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ paddingRight: 5, textAlign: "right" }}>
                            Tgl. Faktur
                          </Col>
                          <Col span={3} style={{ paddingLeft: 5 }}>
                            <Form.Item name="tanggal_faktur">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={4} style={{ textAlign: "right" }}>
                            Masa Berlaku Notice
                          </Col>
                          <Col span={2} style={{ paddingLeft: 5 }}>
                            <Form.Item name="masa_pajak_mulai">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ textAlign: "right" }}>
                            S.d.
                          </Col>
                          <Col span={6} style={{ paddingLeft: 5 }}>
                            <Form.Item name="masa_pajak_sampai">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} style={{ position: "relative" }}>
                        {this.state.isReadonly &&
                          <Tooltip title={this.state.isReadonly ? "edit nominal penetapan, berjalan, biaya" : "cancel edit nominal"}>
                            <Button
                              type="primary"
                              shape="circle"
                              icon={
                                this.state.isReadonly
                                  ?
                                  <EditOutlined />
                                  :
                                  <CloseCircleOutlined />
                              }
                              htmlType="button"
                              style={{
                                position: "absolute",
                                left: 10,
                                top: 10,
                                width: 50,
                                height: 50,
                                zIndex: 10
                              }}
                              onClick={() => {
                                this.setReadOnly();
                              }}
                            />
                          </Tooltip>
                        }
                        <Row justify="space-between">
                          <Col span={8} style={{ border: "1px solid #cccccc", borderRight: "none" }}>
                            <Row>
                              <Col span={24}>
                                <Divider>PENETAPAN</Divider>
                              </Col>
                              <Col span={13} style={{ textAlign: "right", paddingRight: 5 }}>
                                Tgl. bayar Thn. Lalu
                              </Col>
                              <Col span={11} style={{ paddingRight: 5 }}>
                                <Form.Item name="tanggal_pembayaran">
                                  <Input disabled />
                                </Form.Item>
                              </Col>
                              <Col span={13} style={{ textAlign: "right", paddingRight: 5 }}>
                                Tgl. Penetapan
                              </Col>
                              <Col span={11} style={{ paddingRight: 5 }}>
                                <Form.Item name="tgl_penetapan">
                                  <Input disabled />
                                </Form.Item>
                                <Form.Item name="tgl_penetapan" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={24} style={{ background: "#F5F5F5", textAlign: "center", padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", fontWeight: "bold" }}>
                                Kode dan Keterangan
                              </Col>
                              <Col span={4} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="kode_bbn_kb">
                                  <Input readOnly />
                                </Form.Item>
                              </Col>
                              <Col span={20} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="nama_bbn_kb">
                                  <Input readOnly />
                                </Form.Item>
                              </Col>
                              <Col span={4} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="kode_pkb">
                                  <Input readOnly />
                                </Form.Item>
                              </Col>
                              <Col span={20} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="nama_pkb">
                                  <Input readOnly />
                                </Form.Item>
                              </Col>
                              <Col span={4} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="kode_swdkllj">
                                  <Input readOnly />
                                </Form.Item>
                              </Col>
                              <Col span={20} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="nama_swdkllj">
                                  <Input readOnly />
                                </Form.Item>
                              </Col>
                              <Col span={4} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="kode_admstnk">
                                  <Input readOnly />
                                </Form.Item>
                              </Col>
                              <Col span={20} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="nama_admstnk">
                                  <Input readOnly />
                                </Form.Item>
                              </Col>
                              <Col span={4} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="kode_admtnk">
                                  <Input readOnly />
                                </Form.Item>
                              </Col>
                              <Col span={20} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="nama_admtnk">
                                  <Input readOnly />
                                </Form.Item>
                              </Col>
                              <Col span={4} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none", borderBottom: "none" }}>
                                <Form.Item name="kode_sp3">
                                  <Input readOnly />
                                </Form.Item>
                              </Col>
                              <Col span={20} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                <Form.Item name="nama_sp3">
                                  <Input readOnly />
                                </Form.Item>
                              </Col>
                              <Col span={24} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none" }}>
                                <Form.Item name="label_jumlah_total">
                                  <Input value="JUMLAH TOTAL" readonly style={{ border: "none" }} />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={8} style={{ border: "1px solid #cccccc", borderRight: "none" }}>
                            <Row>
                              <Col span={24}>
                                <Divider>BERJALAN</Divider>
                              </Col>
                              <Col span={13} style={{ textAlign: "right", paddingRight: 5 }}>
                                No. SKPD Th. Lalu
                              </Col>
                              <Col span={11} style={{ paddingRight: 5 }}>
                                <Form.Item name="no_skpd_th_lalu">
                                  <Input disabled />
                                </Form.Item>
                              </Col>
                              <Col span={13} style={{ textAlign: "right", paddingRight: 5 }}>
                                No. SKPD
                              </Col>
                              <Col span={11} style={{ paddingRight: 5 }}>
                                <Form.Item name="no_skpd">
                                  <Input
                                    onChange={(e) => this.formRef.current.setFieldsValue({ no_skpd2: e.target.value })}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ background: "#F5F5F5", textAlign: "center", padding: 5, border: "1px solid #cccccc", borderLeft: "none", fontWeight: "bold" }}>
                                Pokok
                              </Col>
                              <Col span={8} style={{ background: "#F5F5F5", textAlign: "center", padding: 5, border: "1px solid #cccccc", borderLeft: "none", fontWeight: "bold" }}>
                                Denda
                              </Col>
                              <Col span={8} style={{ background: "#F5F5F5", textAlign: "center", padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", fontWeight: "bold" }}>
                                Jumlah
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="pokok_bbnkb_preview">
                                  <NumberFormat
                                    ref={this.formRefFocus}
                                    readOnly={this.state.isReadonly}
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        pokok_bbnkb: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          pokok_bbnkb: value,
                                          jumlah_bbnkb: this.state.pokok_bbnkb + this.state.denda_bbnkb,
                                          jumlah_bbnkb_preview: parseFloat(this.state.pokok_bbnkb) + parseFloat(this.state.denda_bbnkb),

                                          total_pokok_berjalan: parseFloat(this.state.pokok_bbnkb) + parseFloat(this.state.pokok_pkb) + parseFloat(this.state.pokok_swdkllj) + parseFloat(this.state.pokok_admstnk) + parseFloat(this.state.pokok_admtnk) + parseFloat(this.state.pokok_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="pokok_bbnkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="denda_bbnkb_preview">
                                  <NumberFormat
                                    readOnly={this.state.isReadonly}
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        denda_bbnkb: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          denda_bbnkb: value,
                                          jumlah_bbnkb: this.state.pokok_bbnkb + this.state.denda_bbnkb,
                                          jumlah_bbnkb_preview: parseFloat(this.state.pokok_bbnkb) + parseFloat(this.state.denda_bbnkb),
                                          total_denda_berjalan: parseFloat(this.state.denda_bbnkb) + parseFloat(this.state.denda_pkb) + parseFloat(this.state.denda_swdkllj) + parseFloat(this.state.denda_admstnk) + parseFloat(this.state.denda_admtnk) + parseFloat(this.state.denda_sp3)
                                        });
                                      });
                                      // this.formRef.current.setFieldsValue({
                                      //   denda_bbnkb: value
                                      // });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="denda_bbnkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="jumlah_bbnkb_preview">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        jumlah_bbnkb: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="jumlah_bbnkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="pokok_pkb_preview">
                                  <NumberFormat
                                    readOnly={this.state.isReadonly}
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        pokok_pkb: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          pokok_pkb: value,
                                          jumlah_pkb: this.state.pokok_pkb + this.state.denda_pkb,
                                          jumlah_pkb_preview: parseFloat(this.state.pokok_pkb) + parseFloat(this.state.denda_pkb),
                                          total_pokok_berjalan: parseFloat(this.state.pokok_bbnkb) + parseFloat(this.state.pokok_pkb) + parseFloat(this.state.pokok_swdkllj) + parseFloat(this.state.pokok_admstnk) + parseFloat(this.state.pokok_admtnk) + parseFloat(this.state.pokok_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="pokok_pkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="denda_pkb_preview">
                                  <NumberFormat
                                    readOnly={this.state.isReadonly}
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        denda_pkb: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          denda_pkb: value,
                                          jumlah_pkb: this.state.pokok_pkb + this.state.denda_pkb,
                                          jumlah_pkb_preview: parseFloat(this.state.pokok_pkb) + parseFloat(this.state.denda_pkb),
                                          total_denda_berjalan: parseFloat(this.state.denda_bbnkb) + parseFloat(this.state.denda_pkb) + parseFloat(this.state.denda_swdkllj) + parseFloat(this.state.denda_admstnk) + parseFloat(this.state.denda_admtnk) + parseFloat(this.state.denda_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="denda_pkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="jumlah_pkb_preview">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        jumlah_pkb: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="jumlah_pkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="pokok_swdkllj_preview">
                                  <NumberFormat
                                    readOnly={this.state.isReadonly}
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        pokok_swdkllj: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          pokok_swdkllj: value,
                                          jumlah_swdkllj: this.state.pokok_swdkllj + this.state.denda_swdkllj,
                                          jumlah_swdkllj_preview: parseFloat(this.state.pokok_swdkllj) + parseFloat(this.state.denda_swdkllj),
                                          total_pokok_berjalan: parseFloat(this.state.pokok_bbnkb) + parseFloat(this.state.pokok_pkb) + parseFloat(this.state.pokok_swdkllj) + parseFloat(this.state.pokok_admstnk) + parseFloat(this.state.pokok_admtnk) + parseFloat(this.state.pokok_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="pokok_swdkllj" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="denda_swdkllj_preview">
                                  <NumberFormat
                                    readOnly={this.state.isReadonly}
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        denda_swdkllj: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          denda_swdkllj: value,
                                          jumlah_swdkllj: this.state.pokok_swdkllj + this.state.denda_swdkllj,
                                          jumlah_swdkllj_preview: parseFloat(this.state.pokok_swdkllj) + parseFloat(this.state.denda_swdkllj),
                                          total_denda_berjalan: parseFloat(this.state.denda_bbnkb) + parseFloat(this.state.denda_pkb) + parseFloat(this.state.denda_swdkllj) + parseFloat(this.state.denda_admstnk) + parseFloat(this.state.denda_admtnk) + parseFloat(this.state.denda_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="denda_swdkllj" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="jumlah_swdkllj_preview">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        jumlah_swdkllj: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="jumlah_swdkllj" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="pokok_admstnk_preview">
                                  <NumberFormat
                                    readOnly={this.state.isReadonly}
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        pokok_admstnk: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          pokok_admstnk: value,
                                          jumlah_admstnk: this.state.pokok_admstnk + this.state.denda_admstnk,
                                          jumlah_admstnk_preview: parseFloat(this.state.pokok_admstnk) + parseFloat(this.state.denda_admstnk),
                                          total_pokok_berjalan: parseFloat(this.state.pokok_bbnkb) + parseFloat(this.state.pokok_pkb) + parseFloat(this.state.pokok_swdkllj) + parseFloat(this.state.pokok_admstnk) + parseFloat(this.state.pokok_admtnk) + parseFloat(this.state.pokok_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="pokok_admstnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="denda_admstnk_preview">
                                  <NumberFormat
                                    readOnly={this.state.isReadonly}
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        denda_admstnk: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          denda_admstnk: value,
                                          jumlah_admstnk: this.state.pokok_admstnk + this.state.denda_admstnk,
                                          jumlah_admstnk_preview: parseFloat(this.state.pokok_admstnk) + parseFloat(this.state.denda_admstnk),
                                          total_denda_berjalan: parseFloat(this.state.denda_bbnkb) + parseFloat(this.state.denda_pkb) + parseFloat(this.state.denda_swdkllj) + parseFloat(this.state.denda_admstnk) + parseFloat(this.state.denda_admtnk) + parseFloat(this.state.denda_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="denda_admstnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="jumlah_admstnk_preview">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        jumlah_admstnk: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="jumlah_admstnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="pokok_admtnk_preview">
                                  <NumberFormat
                                    readOnly={this.state.isReadonly}
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        pokok_admtnk: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          pokok_admtnk: value,
                                          jumlah_admtnk: this.state.pokok_admtnk + this.state.denda_admtnk,
                                          jumlah_admtnk_preview: parseFloat(this.state.pokok_admtnk) + parseFloat(this.state.denda_admtnk),
                                          total_pokok_berjalan: parseFloat(this.state.pokok_bbnkb) + parseFloat(this.state.pokok_pkb) + parseFloat(this.state.pokok_swdkllj) + parseFloat(this.state.pokok_admstnk) + parseFloat(this.state.pokok_admtnk) + parseFloat(this.state.pokok_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="pokok_admtnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="denda_admtnk_preview">
                                  <NumberFormat
                                    readOnly={this.state.isReadonly}
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        denda_admtnk: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          denda_admtnk: value,
                                          jumlah_admtnk: this.state.pokok_admtnk + this.state.denda_admtnk,
                                          jumlah_admtnk_preview: parseFloat(this.state.pokok_admtnk) + parseFloat(this.state.denda_admtnk),
                                          total_denda_berjalan: parseFloat(this.state.denda_bbnkb) + parseFloat(this.state.denda_pkb) + parseFloat(this.state.denda_swdkllj) + parseFloat(this.state.denda_admstnk) + parseFloat(this.state.denda_admtnk) + parseFloat(this.state.denda_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="denda_admtnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="jumlah_admtnk_preview">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        jumlah_admtnk: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="jumlah_admtnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none", borderBottom: "none" }}>
                                <Form.Item name="pokok_sp3_preview">
                                  <NumberFormat
                                    readOnly={this.state.isReadonly}
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        pokok_sp3: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          pokok_sp3: value,
                                          jumlah_sp3: this.state.pokok_sp3 + this.state.denda_sp3,
                                          jumlah_sp3_preview: parseFloat(this.state.pokok_sp3) + parseFloat(this.state.denda_sp3),
                                          total_pokok_berjalan: parseFloat(this.state.pokok_bbnkb) + parseFloat(this.state.pokok_pkb) + parseFloat(this.state.pokok_swdkllj) + parseFloat(this.state.pokok_admstnk) + parseFloat(this.state.pokok_admtnk) + parseFloat(this.state.pokok_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="pokok_sp3" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none", borderBottom: "none" }}>
                                <Form.Item name="denda_sp3_preview">
                                  <NumberFormat
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        denda_sp3: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          denda_sp3: value,
                                          jumlah_sp3: this.state.pokok_sp3 + this.state.denda_sp3,
                                          jumlah_sp3_preview: parseFloat(this.state.pokok_sp3) + parseFloat(this.state.denda_sp3),
                                          total_denda_berjalan: parseFloat(this.state.denda_bbnkb) + parseFloat(this.state.denda_pkb) + parseFloat(this.state.denda_swdkllj) + parseFloat(this.state.denda_admstnk) + parseFloat(this.state.denda_admtnk) + parseFloat(this.state.denda_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="denda_sp3" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                <Form.Item name="jumlah_sp3_preview">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        jumlah_sp3: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="jumlah_sp3" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none" }}>
                                <Form.Item name="total_pokok_berjalan">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      // this.formRef.current.setFieldsValue({
                                      //   jumlah_sp3: value
                                      // });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none" }}>
                                <Form.Item name="total_denda_berjalan">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      // this.formRef.current.setFieldsValue({
                                      //   jumlah_sp3: value
                                      // });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none" }}>
                                <Form.Item name="total_jumlah_berjalan">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      // this.formRef.current.setFieldsValue({
                                      //   jumlah_sp3: value
                                      // });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={24} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none", borderBottom: "none", fontWeight: 'bold', textAlign: 'right' }}>
                                <Form.Item name="label_grand_total">
                                  <Input readonly style={{ border: "none", fontWeight: 'bold' }} />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={8} style={{ border: "1px solid #cccccc" }}>
                            <Row>
                              <Col span={24}>
                                <Divider>TUNGGAKAN</Divider>
                              </Col>
                              <Col span={24} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item name="lama_tunggakan">
                                  <Input style={{ width: "100%" }} disabled />
                                </Form.Item>
                              </Col>
                              <Col span={15} style={{ textAlign: "right", paddingRight: 5 }}>
                                No. SKPD
                              </Col>
                              <Col span={9} style={{ paddingRight: 5 }}>
                                <Form.Item name="no_skpd2">
                                  <Input disabled />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ background: "#F5F5F5", textAlign: "center", padding: 5, border: "1px solid #cccccc", borderLeft: "none", fontWeight: "bold", borderTop: "none" }}>
                                Pokok
                              </Col>
                              <Col span={8} style={{ background: "#F5F5F5", textAlign: "center", padding: 5, border: "1px solid #cccccc", borderLeft: "none", fontWeight: "bold", borderTop: "none" }}>
                                Denda
                              </Col>
                              <Col span={8} style={{ background: "#F5F5F5", textAlign: "center", padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", fontWeight: "bold", borderTop: "none" }}>
                                Jumlah
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_pokok_bbnkb_preview">
                                  <NumberFormat
                                    readOnly={this.state.isReadonly}
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_pokok_bbnkb: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_pokok_bbnkb: value,
                                          tunggakan_jumlah_bbnkb: this.state.tunggakan_pokok_bbnkb + this.state.tunggakan_denda_bbnkb,
                                          tunggakan_jumlah_bbnkb_preview: parseFloat(this.state.tunggakan_pokok_bbnkb) + parseFloat(this.state.tunggakan_denda_bbnkb),
                                          
                                          total_pokok_tunggakan: parseFloat(this.state.tunggakan_pokok_bbnkb) + parseFloat(this.state.tunggakan_pokok_pkb) + parseFloat(this.state.tunggakan_pokok_swdkllj) + parseFloat(this.state.tunggakan_pokok_admstnk) + parseFloat(this.state.tunggakan_pokok_admtnk) + parseFloat(this.state.tunggakan_pokok_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_pokok_bbnkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_denda_bbnkb_preview">
                                  <NumberFormat
                                    readOnly={this.state.isReadonly}
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_denda_bbnkb: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_denda_bbnkb: value,
                                          tunggakan_jumlah_bbnkb: this.state.tunggakan_pokok_bbnkb + this.state.tunggakan_denda_bbnkb,
                                          tunggakan_jumlah_bbnkb_preview: parseFloat(this.state.tunggakan_pokok_bbnkb) + parseFloat(this.state.tunggakan_denda_bbnkb),

                                          total_denda_tunggakan: parseFloat(this.state.tunggakan_denda_bbnkb) + parseFloat(this.state.tunggakan_denda_pkb) + parseFloat(this.state.tunggakan_denda_swdkllj) + parseFloat(this.state.tunggakan_denda_admstnk) + parseFloat(this.state.tunggakan_denda_admtnk) + parseFloat(this.state.tunggakan_denda_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_denda_bbnkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_jumlah_bbnkb_preview">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        tunggakan_jumlah_bbnkb: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_jumlah_bbnkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_pokok_pkb_preview">
                                  <NumberFormat
                                    readOnly={this.state.isReadonly}
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_pokok_pkb: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_pokok_pkb: value,
                                          tunggakan_jumlah_pkb: this.state.tunggakan_pokok_pkb + this.state.tunggakan_denda_pkb,
                                          tunggakan_jumlah_pkb_preview: parseFloat(this.state.tunggakan_pokok_pkb) + parseFloat(this.state.tunggakan_denda_pkb),

                                          total_pokok_tunggakan: parseFloat(this.state.tunggakan_pokok_bbnkb) + parseFloat(this.state.tunggakan_pokok_pkb) + parseFloat(this.state.tunggakan_pokok_swdkllj) + parseFloat(this.state.tunggakan_pokok_admstnk) + parseFloat(this.state.tunggakan_pokok_admtnk) + parseFloat(this.state.tunggakan_pokok_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_pokok_pkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_denda_pkb_preview">
                                  <NumberFormat
                                    readOnly={this.state.isReadonly}
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_denda_pkb: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_denda_pkb: value,
                                          tunggakan_jumlah_pkb: this.state.tunggakan_pokok_pkb + this.state.tunggakan_denda_pkb,
                                          tunggakan_jumlah_pkb_preview: parseFloat(this.state.tunggakan_pokok_pkb) + parseFloat(this.state.tunggakan_denda_pkb),

                                          total_denda_tunggakan: parseFloat(this.state.tunggakan_denda_bbnkb) + parseFloat(this.state.tunggakan_denda_pkb) + parseFloat(this.state.tunggakan_denda_swdkllj) + parseFloat(this.state.tunggakan_denda_admstnk) + parseFloat(this.state.tunggakan_denda_admtnk) + parseFloat(this.state.tunggakan_denda_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_denda_pkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_jumlah_pkb_preview">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        tunggakan_jumlah_pkb: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_jumlah_pkb" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_pokok_swdkllj_preview">
                                  <NumberFormat
                                    readOnly={this.state.isReadonly}
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_pokok_swdkllj: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_pokok_swdkllj: value,
                                          tunggakan_jumlah_swdkllj: this.state.tunggakan_pokok_swdkllj + this.state.tunggakan_denda_swdkllj,
                                          tunggakan_jumlah_swdkllj_preview: parseFloat(this.state.tunggakan_pokok_swdkllj) + parseFloat(this.state.tunggakan_denda_swdkllj),

                                          total_pokok_tunggakan: parseFloat(this.state.tunggakan_pokok_bbnkb) + parseFloat(this.state.tunggakan_pokok_pkb) + parseFloat(this.state.tunggakan_pokok_swdkllj) + parseFloat(this.state.tunggakan_pokok_admstnk) + parseFloat(this.state.tunggakan_pokok_admtnk) + parseFloat(this.state.tunggakan_pokok_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_pokok_swdkllj" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_denda_swdkllj_preview">
                                  <NumberFormat
                                    readOnly={this.state.isReadonly}
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_denda_swdkllj: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_denda_swdkllj: value,
                                          tunggakan_jumlah_swdkllj: this.state.tunggakan_pokok_swdkllj + this.state.tunggakan_denda_swdkllj,
                                          tunggakan_jumlah_swdkllj_preview: parseFloat(this.state.tunggakan_pokok_swdkllj) + parseFloat(this.state.tunggakan_denda_swdkllj),

                                          total_denda_tunggakan: parseFloat(this.state.tunggakan_denda_bbnkb) + parseFloat(this.state.tunggakan_denda_pkb) + parseFloat(this.state.tunggakan_denda_swdkllj) + parseFloat(this.state.tunggakan_denda_admstnk) + parseFloat(this.state.tunggakan_denda_admtnk) + parseFloat(this.state.tunggakan_denda_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_denda_swdkllj" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_jumlah_swdkllj_preview">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        tunggakan_jumlah_swdkllj: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_jumlah_swdkllj" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_pokok_admstnk_preview">
                                  <NumberFormat
                                    readOnly={this.state.isReadonly}
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_pokok_admstnk: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_pokok_admstnk: value,
                                          tunggakan_jumlah_admstnk: this.state.tunggakan_pokok_admstnk + this.state.tunggakan_denda_admstnk,
                                          tunggakan_jumlah_admstnk_preview: parseFloat(this.state.tunggakan_pokok_admstnk) + parseFloat(this.state.tunggakan_denda_admstnk),

                                          total_pokok_tunggakan: parseFloat(this.state.tunggakan_pokok_bbnkb) + parseFloat(this.state.tunggakan_pokok_pkb) + parseFloat(this.state.tunggakan_pokok_swdkllj) + parseFloat(this.state.tunggakan_pokok_admstnk) + parseFloat(this.state.tunggakan_pokok_admtnk) + parseFloat(this.state.tunggakan_pokok_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_pokok_admstnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_denda_admstnk_preview">
                                  <NumberFormat
                                    readOnly={this.state.isReadonly}
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_denda_admstnk: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_denda_admstnk: value,
                                          tunggakan_jumlah_admstnk: this.state.tunggakan_pokok_admstnk + this.state.tunggakan_denda_admstnk,
                                          tunggakan_jumlah_admstnk_preview: parseFloat(this.state.tunggakan_pokok_admstnk) + parseFloat(this.state.tunggakan_denda_admstnk),

                                          total_denda_tunggakan: parseFloat(this.state.tunggakan_denda_bbnkb) + parseFloat(this.state.tunggakan_denda_pkb) + parseFloat(this.state.tunggakan_denda_swdkllj) + parseFloat(this.state.tunggakan_denda_admstnk) + parseFloat(this.state.tunggakan_denda_admtnk) + parseFloat(this.state.tunggakan_denda_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_denda_admstnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_jumlah_admstnk_preview">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        tunggakan_jumlah_admstnk: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_jumlah_admstnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_pokok_admtnk_preview">
                                  <NumberFormat
                                    readOnly={this.state.isReadonly}
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_pokok_admtnk: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_pokok_admtnk: value,
                                          tunggakan_jumlah_admtnk: this.state.tunggakan_pokok_admtnk + this.state.tunggakan_denda_admtnk,
                                          tunggakan_jumlah_admtnk_preview: parseFloat(this.state.tunggakan_pokok_admtnk) + parseFloat(this.state.tunggakan_denda_admtnk),

                                          total_pokok_tunggakan: parseFloat(this.state.tunggakan_pokok_bbnkb) + parseFloat(this.state.tunggakan_pokok_pkb) + parseFloat(this.state.tunggakan_pokok_swdkllj) + parseFloat(this.state.tunggakan_pokok_admstnk) + parseFloat(this.state.tunggakan_pokok_admtnk) + parseFloat(this.state.tunggakan_pokok_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_pokok_admtnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_denda_admtnk_preview">
                                  <NumberFormat
                                    readOnly={this.state.isReadonly}
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_denda_admtnk: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_denda_admtnk: value,
                                          tunggakan_jumlah_admtnk: this.state.tunggakan_pokok_admtnk + this.state.tunggakan_denda_admtnk,
                                          tunggakan_jumlah_admtnk_preview: parseFloat(this.state.tunggakan_pokok_admtnk) + parseFloat(this.state.tunggakan_denda_admtnk),

                                          total_denda_tunggakan: parseFloat(this.state.tunggakan_denda_bbnkb) + parseFloat(this.state.tunggakan_denda_pkb) + parseFloat(this.state.tunggakan_denda_swdkllj) + parseFloat(this.state.tunggakan_denda_admstnk) + parseFloat(this.state.tunggakan_denda_admtnk) + parseFloat(this.state.tunggakan_denda_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_denda_admtnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                <Form.Item name="tunggakan_jumlah_admtnk_preview">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        tunggakan_jumlah_admtnk: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_jumlah_admtnk" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none", borderBottom: "none" }}>
                                <Form.Item name="tunggakan_pokok_sp3_preview">
                                  <NumberFormat
                                    readOnly={this.state.isReadonly}
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_pokok_sp3: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_pokok_sp3: value,
                                          tunggakan_jumlah_sp3: this.state.tunggakan_pokok_sp3 + this.state.tunggakan_denda_sp3,
                                          tunggakan_jumlah_sp3_preview: parseFloat(this.state.tunggakan_pokok_sp3) + parseFloat(this.state.tunggakan_denda_sp3),

                                          total_pokok_tunggakan: parseFloat(this.state.tunggakan_pokok_bbnkb) + parseFloat(this.state.tunggakan_pokok_pkb) + parseFloat(this.state.tunggakan_pokok_swdkllj) + parseFloat(this.state.tunggakan_pokok_admstnk) + parseFloat(this.state.tunggakan_pokok_admtnk) + parseFloat(this.state.tunggakan_pokok_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_pokok_sp3" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderTop: "none", borderBottom: "none" }}>
                                <Form.Item name="tunggakan_denda_sp3_preview">
                                  <NumberFormat
                                    readOnly={this.state.isReadonly}
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.setState({
                                        tunggakan_denda_sp3: value,
                                      }, () => {
                                        this.formRef.current.setFieldsValue({
                                          tunggakan_denda_sp3: value,
                                          tunggakan_jumlah_sp3: this.state.tunggakan_pokok_sp3 + this.state.tunggakan_denda_sp3,
                                          tunggakan_jumlah_sp3_preview: parseFloat(this.state.tunggakan_pokok_sp3) + parseFloat(this.state.tunggakan_denda_sp3),

                                          total_denda_tunggakan: parseFloat(this.state.tunggakan_denda_bbnkb) + parseFloat(this.state.tunggakan_denda_pkb) + parseFloat(this.state.tunggakan_denda_swdkllj) + parseFloat(this.state.tunggakan_denda_admstnk) + parseFloat(this.state.tunggakan_denda_admtnk) + parseFloat(this.state.tunggakan_denda_sp3)
                                        });
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_pokok_sp3" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                <Form.Item name="tunggakan_jumlah_sp3_preview">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      this.formRef.current.setFieldsValue({
                                        tunggakan_jumlah_sp3: value
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item name="tunggakan_jumlah_sp3" style={{ display: "none" }}>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>

                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderBottom: "none" }}>
                                <Form.Item name="total_pokok_tunggakan">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      // this.formRef.current.setFieldsValue({
                                      //   jumlah_sp3: value
                                      // });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderBottom: "none" }}>
                                <Form.Item name="total_denda_tunggakan">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      // this.formRef.current.setFieldsValue({
                                      //   jumlah_sp3: value
                                      // });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderRight: "none", borderBottom: "none" }}>
                                <Form.Item name="total_jumlah_tunggakan">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      // this.formRef.current.setFieldsValue({
                                      //   jumlah_sp3: value
                                      // });
                                    }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={24} style={{ padding: 5, border: "1px solid #cccccc", borderLeft: "none", borderBottom: "none" }}>
                                <Form.Item name="grand_total">
                                  <NumberFormat
                                    readOnly
                                    style={{ textTransform: 'none', fontWeight: 'bold' }}
                                    autoComplete="off"
                                    thousandSeparator={true}
                                    className="ant-input ant-input-sm"
                                    onValueChange={(values) => {
                                      const { formattedValue, value } = values;
                                      // this.formRef.current.setFieldsValue({
                                      //   jumlah_sp3: value
                                      // });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={24}>
                            <Row justify="end">
                              <Col span={3} style={{ padding: 3 }}>
                                <Button
                                  style={{ width: "100%" }}
                                  key="btn-simpan"
                                  loading={this.state.loadingButton}
                                  type="primary"
                                  htmlType="submit"
                                  disabled={this.state.errComponent ? true : false}
                                >
                                  {!this.state.loadingButton && (
                                    <CheckCircleOutlined />
                                  )}
                                  Simpan
                                </Button>
                              </Col>
                              <Col span={5} style={{ padding: 3 }}>
                                <Button
                                  type="danger"
                                  key="btn-cancel"
                                  disabled={this.state.loadingButton ? true : false}
                                  style={{ width: "100%" }}
                                  htmlType="button"
                                  onClick={() => {
                                    Modal.confirm({
                                      title: 'Konfirmasi Pembatalan?',
                                      icon: <ExclamationCircleOutlined />,
                                      content: 'yakin membatalkan data penetapan?',
                                      okText: 'Ya',
                                      okType: 'danger',
                                      cancelText: 'Tidak',
                                      onOk: () => this.batalkanPenetapan(),
                                      onCancel() {
                                        //console.log('Cancel');
                                      },
                                    });

                                  }}
                                >
                                  <CloseCircleOutlined /> Batalkan Penetapan
                                </Button>
                              </Col>
                              <Col span={3} style={{ padding: 3 }}>
                                <Button
                                  htmlType="button"
                                  key="btn-reset"
                                  disabled={this.state.loadingButton ? true : false}
                                  style={{ width: "100%" }}
                                >
                                  <CloseCircleOutlined /> Reset
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>

                      {/*
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Tgl. bayar Thn Lalu
                          </Col>
                          <Col span={5} style={{ paddingLeft: 5 }}>
                            <Form.Item name="tgl_bayar_thn_lalu">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={3} style={{ textAlign: "right" }}>
                            No. SKPD Th. Lalu
                          </Col>
                          <Col span={5} style={{ paddingLeft: 5 }}>
                            <Form.Item name="no_skpd_th_lalu">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={6} style={{ textAlign: "right" }}>
                            8 Tahun 9 BUlan 10 Hari Denda Jr
                          </Col>
                          <Col span={2} style={{ paddingLeft: 5 }}>
                            0 Hari
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={3} style={{ textAlign: "right" }}>
                            Tgl Penetapan
                          </Col>
                          <Col span={5} style={{ paddingLeft: 5 }}>
                            <Form.Item name="tgl_penetapan">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={3} style={{ textAlign: "right" }}>
                            No. SKPD
                          </Col>
                          <Col span={5} style={{ paddingLeft: 5 }}>
                            <Form.Item name="no_skpd">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={4} style={{ textAlign: "right" }}>
                            No. SKPD
                          </Col>
                          <Col span={4} style={{ paddingLeft: 5 }}>
                            <Form.Item name="no_skpd2_th_lalu">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <table style={{width: "100%"}} border={1}>
                          <thead>
                            <tr>
                              <th colspan="2">Kode dan Keterangan</th>
                              <th>Pokok</th>
                              <th>Denda</th>
                              <th>Jumlah</th>
                              <th>Pokok</th>
                              <th>Denda</th>
                              <th>Jumlah</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>001</td>
                              <td>BBN KB</td>
                              <td>
                                <Form.Item
                                  name="bbn_pkb"
                                >
                                  <Input />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  name="denda_bbn_pkb"
                                >
                                  <Input />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  name="tunggakan_bbn_pkb"
                                >
                                  <Input />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  name="tunggakan_denda_bbn_pkb"
                                >
                                  <Input />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  name="tunggakan_jumlah_bbn_pkb"
                                >
                                  <Input />
                                </Form.Item>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                      <Col span={24}>
                        <Row justify="end">
                          <Col span={3} style={{ padding: 3 }}>
                            <Button
                              style={{ width: "100%" }}
                              key="btn-simpan"
                              loading={this.state.loadingButton}
                              type="primary"
                              htmlType="submit"
                              disabled={this.state.errComponent ? true : false}
                            >
                              {!this.state.loadingButton && (
                                <CheckCircleOutlined />
                              )}
                              Simpan
                            </Button>
                          </Col>
                          <Col span={3} style={{ padding: 3 }}>
                            <Button
                              type="danger"
                              key="btn-cancel"
                              disabled={this.state.loadingButton ? true : false}
                              style={{ width: "100%" }}
                            >
                              <CloseCircleOutlined /> Reset
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      */}
                    </Row>
                  </Col>
                )}
              </Row>
            </Form>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  initHandler,
  setConfig,
  ajaxViewHandler,
  ajaxHandler,
})(withRouter(FormPenetapanBiaya));
