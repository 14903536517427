import React from 'react';
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import Highlighter from 'react-highlight-words';
import { Link } from "react-router-dom";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Input,
	Button,
	Table,
	Modal,
	message,
	Alert,
	Tag,
	Form,
	DatePicker
} from 'antd';
import moment from 'moment';
import {
	TeamOutlined,
	SearchOutlined,
	PlusOutlined,
	FormOutlined,
	CloseSquareOutlined,
	ExclamationCircleOutlined,
	DiffOutlined,
	RedoOutlined,
	SettingOutlined,
	CheckCircleOutlined,
	InfoCircleOutlined,
	SplitCellsOutlined,
	CloseCircleOutlined
} from '@ant-design/icons';

import axios from 'axios';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";

import Config from '../../Config';
import { Helmet } from "react-helmet";

const configClass = {
	title: 'data migrasi',
	apiUrl: {
		data: 'kendaraan/api/migrasi/',

	},
	breadCrumb: [
		(
			<span>
				<InfoCircleOutlined /> informasi
			</span>
		),
		'migrasi'
	]

}

const config = new Config();

class DataMigrasi extends React.Component {
	formRefOveride = React.createRef();

	constructor(props) {
		super(props);
		this.state = {
			isModalKoreksi: false,
			modifiedField: {},
			nomor_polisi: "",
			errMsg: '',
			loadingData: false,
			pagination: { pageSize: 25 },
			searchText: '',
			searchColumn: '',
			dataSource: [],
			columns: [
				{
					title: 'NIK',
					width: 150,
					dataIndex: 'pemilik_nik',
					key: 'pemilik_nik'
				},
				{
					title: 'NAMA',
					width: 150,
					dataIndex: 'nama_wp',
					key: 'nama_wp'
				},
				{
					title: 'TGL TERAKHIR BAYAR',
					width: 150,
					dataIndex: 'sd_notice',
					key: 'sd_notice'
				},
				{
					title: 'STNK AKHIR',
					width: 150,
					dataIndex: 'stnk_akhir',
					key: 'stnk_akhir'
				},
				{
					title: 'ALAMAT WP',
					width: 150,
					dataIndex: 'alamat_wp',
					key: 'alamat_wp'
				},
				{
					title: 'PEMILIK',
					width: 150,
					dataIndex: 'pemilik_nama',
					key: 'pemilik_nama'
				},
				{
					title: 'NO HP',
					width: 150,
					dataIndex: 'pemilik_no_hp',
					key: 'pemilik_no_hp'
				},
				{
					title: 'NOMOR MESIN',
					width: 150,
					dataIndex: 'nomor_mesin',
					key: 'nomor_mesin'
				},
				{
					title: 'TAHUN BUAT',
					width: 150,
					dataIndex: 'tahun_buat',
					key: 'tahun_buat'
				},
				{
					title: 'AKSI',
					width: 80,
					dataIndex: 'id',
					key: 'aksi',
					fixed: 'right',
					render: (value, row, index) => {
						return (
							<Row key={"space-aksi-" + index} justify="center">
								<Col style={{ padding: 5 }}>
									<Button className="btn-pilih" size="small" onClick={() => this.showPilihConfirm(row)} shape="circle" title="proses migrasi">
										<SplitCellsOutlined />
									</Button>
								</Col>
							</Row>
						)
					},
				}

			]
		}
	}

	UNSAFE_componentWillMount() {
		this.props.initHandler();
	}

	componentDidMount() {
		this.getData();
	}

	showDeleteConfirm(id) {
		Modal.confirm({
			title: 'Konfirmasi Hapus?',
			icon: <ExclamationCircleOutlined />,
			content: 'yakin menghapus data?',
			okText: 'Ya',
			okType: 'danger',
			cancelText: 'Tidak',
			onOk: () => this.prosesHapus(id),
			onCancel() {
				//console.log('Cancel');
			},
		});
	}

	checkValidDate(dateValue) {
		const dateRegex = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
		const dateTimeRegex = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
		let result = false;
		if (dateRegex.test(dateValue)) {
			if (dateValue == "0000-00-00") {
				result = false;
			} else {
				result = true;
			}
		} else if (dateTimeRegex.test(dateValue)) {
			if (dateValue == "0000-00-00 00:00:00") {
				result = false;
			} else {
				result = true;
			}
		}
		return result;
	}

	showPilihConfirm(data) {
		const forCheck = ["sd_notice", "stnk_akhir", "sd_swdkllj","pembayaran_terakhir"];
		let tempForEdit = {};
		Object.entries(data).forEach(([key, value]) => {
			if (forCheck.includes(key)) {
				// if (this.checkValidDate(value) == false) {//jika mau pengecekan format harus valid baru muncul popoup
				// alert(key+": "+forCheck.includes(key)+"="+value)
				tempForEdit = { ...tempForEdit, [key]: value }
				// }
			}
		})
		// alert(JSON.stringify(tempForEdit))
		if (Object.keys(tempForEdit).length > 0) {
			this.setState({
				isModalKoreksi: true,
				modifiedField: tempForEdit,
				nomor_polisi: data.nomor_polisi
			}, () => {
				// alert(JSON.stringify(this.state.modifiedField))
				setTimeout(() => {
					Object.entries(data).forEach(([key, value]) => {
						if (forCheck.includes(key)) {
							if (this.checkValidDate(value)) {//pengecekan format valid set datepicker
								// alert(key+": "+forCheck.includes(key)+"="+value)
								this.formRefOveride.current.setFieldsValue({
									[key+"_picker"]: moment(value),
									[key]: value
								})
							}else{
								this.formRefOveride.current.setFieldsValue({
									[key+"_picker"]: undefined,
									[key]: ""
								})
							}
						}
					})
				}, 500)
			})
		} else {
			Modal.confirm({
				title: 'Konfirmasi Proses Migrasi?',
				icon: <ExclamationCircleOutlined />,
				content: 'yakin memproses migrasi?',
				okText: 'Ya',
				okType: 'danger',
				cancelText: 'Tidak',
				onOk: () => this.prosesPilih(data),
				onCancel() {
					//console.log('Cancel');
				},
			});
		}
	}

	prosesHapus(id) {
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler('delete', configClass.apiUrl.data + id + '/')
			.then(() => {
				this.setState({
					loadingButton: false
				});
				if (this.props.isSuccess) {
					message.success({ content: 'berhasil menghapus data', duration: 2, key: 'toast' });
					this.getData();
				} else {
					const errTitle = 'gagal menghapus data'
					message.error({ content: errTitle, duration: 2, key: 'toast' });
					this.setState({
						errTitle: errTitle,
						errMsg: this.props.responseMsg
					});
				}
			}).catch((response) => {
				console.log('gagal_delete', response);
				this.setState({
					loadingButton: false
				});
				message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
			});
	}

	prosesPilih(data) {
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler('get', configClass.apiUrl.data + data.id + '/migrate/')
			.then(() => {
				this.setState({
					loadingButton: false
				});
				if (this.props.isSuccess) {
					message.success({ content: 'berhasil memproses', duration: 2, key: 'toast' });
					this.getData();
				} else {
					const errTitle = 'gagal menghapus data'
					message.error({ content: errTitle, duration: 2, key: 'toast' });
					this.setState({
						errTitle: errTitle,
						errMsg: this.props.responseMsg
					});
				}
			}).catch((response) => {
				console.log('gagal_delete', response);
				this.setState({
					loadingButton: false
				});
				message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
			});
	}

	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={node => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Button
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90, marginRight: 8 }}
				>
					Search
				</Button>
				<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
					Reset
				</Button>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex]
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
		render: text =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text.toString()}
				/>
			) : (
				text
			),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	handleReset = clearFilters => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	async getData(limit = this.state.pagination.pageSize, offset = 0, search = this.state.searchText) {
		this.setState({ loadingData: true });
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.data + '?limit=' + limit + '&offset=' + offset + '&search=' + search)
			.then(() => {
				const data = this.props.responseMsg;
				if (this.props.isSuccess) {
					const pagination = { ...this.state.pagination };
					pagination.total = data.count;
					this.setState({
						dataSource: data.results,
						pagination
					});
				} else {
					const errTitle = 'error get data';
					this.setState({
						errTitle: errTitle,
						errMsg: this.props.responseMsg
					});
					message.error({ content: errTitle, duration: 2, key: 'toast' });
				}
				this.setState({ loadingData: false });
			}).catch((response) => {
				console.log('gagal_get_preview', response);
				message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
				this.setState({ loadingData: false });
			});
	}

	async getProfile() {
		this.props.ajaxViewHandler('get', configClass.apiUrl.userProfile)
			.then(() => {
				const data = this.props.responseMsg;
				// console.log('data_profile', data);
				if (data != undefined) {
					this.props.setConfig({
						username: data.username,
						id: data.id,
						// pp: this.config.get_site_info().logo,
						nama: data.pegawai[0].nama,
						akses: data.access,
						printer: data.pegawai[0].serial_printer != undefined ? data.pegawai[0].serial_printer : "pengaturan printer belum dipilih",
						nama_printer: data.pegawai[0].nama_printer != undefined ? data.pegawai[0].nama_printer : "pengaturan printer belum dipilih",
					});

					// askForPermissioToReceiveNotifications(data[0].tempatTugas);

					// if(!this.props.switchable){
					// 	// this.props.setConfig({
					// 	// 	isSuperadmin: data[0].is_superuser
					// 	// });
					// }
				}
			}).catch((response) => {
				console.log('gagal_getprofile', response);
				message.error({ content: 'gagal request data profile, coba lagi', key: 'toast' });
			});
	}

	handleTableChange = async (pagination, filters, sorter) => {
		const newPagination = { ...this.state.pagination };
		newPagination.pageSize = pagination.pageSize;
		this.setState({
			loadingData: true,
			pagination
		});
		if (pagination.current == 1) {
			this.getData(pagination.pageSize);
		} else {
			this.getData(pagination.pageSize, (pagination.current - 1) * pagination.pageSize);
		}


	}

	handleSubmitOveride = (values) => {
		this.postData(values);
	}

	async postData(datas) {
		this.setState({
			errTitle: "",
			errMsg: "",
			loadingButton: true,
		})
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler("post", configClass.apiUrl.data + this.state.nomor_polisi+"/force_migrate/", datas, false, true)
			.then(() => {
				this.setState({
					loadingButton: false
				});
				if (this.props.isSuccess) {
					message.success({ content: 'berhasil menyimpan data', duration: 2, key: 'toast' });
					this.setState({
						isModalKoreksi: false
					}, () =>{
						// this.getData();
					})
				} else {
					const errTitle = 'gagal menyimpan data'
					message.error({ content: errTitle, duration: 2, key: 'toast' });
					this.setState({
						errTitle: errTitle,
						errMsg: this.props.responseMsg
					});
				}
			}).catch((response) => {
				console.log('gagal_post', response);
				this.setState({
					loadingButton: false
				});
				message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
			});
	}

	render() {
		// if(this.props.isSuperadmin == false){
		// 	return <Redirect to="/admin/forbidden" />
		// }
		return (
			<div>
				<Helmet>
					<title>{configClass.title + " | " + config.get_site_info().name}</title>
				</Helmet>
				<Breadcrumb>
					{configClass.breadCrumb.map(item => (
						<Breadcrumb.Item>
							{item}
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
				<div className="card">
					<Row className="isi">
						<Col span={24}>
							<Card
								className="konten"
								title={
									<Row>
										<Col lg={{ span: 20 }} xs={{ span: 24 }}>
											<span className="konten-judul">
												{configClass.title}
												{(this.state.loadingData &&
													<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="30" alt="loading" />
												)}
											</span>
										</Col>
										<Col lg={{ span: 4 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ textAlign: 'right' }}>
											<Button
												loading={this.state.loadingButton}
												className="btn-refresh"
												size="small"
												onClick={() => this.getData()}
											>
												<RedoOutlined />
											</Button>

										</Col>
									</Row>
								}
							>
								{(this.state.errMsg != '' &&
									<Alert
										message={this.state.errTitle}
										description={JSON.stringify(this.state.errMsg)}
										type="error"
										showIcon
										style={{
											marginBottom: 10
										}}
									/>
								)}
								<Table
									title={() =>
										<Row>
											<Col lg={8} sm={24} xs={24}><span className="konten-judul">Total {this.state.pagination.total} record</span></Col>
											<Col lg={{ span: 8, offset: 8 }} sm={24} xs={24}>
												<Input.Search
													size="small"
													placeholder="ketikkan pencarian"
													onSearch={value => {
														this.setState({
															searchText: value
														}, () => {
															this.getData();
														});
													}}
													allowClear
												/>
											</Col>
										</Row>
									}
									bordered
									columns={this.state.columns}
									dataSource={this.state.dataSource}
									loading={this.state.loadingData}
									scroll={{ x: 240, y: 450 }}
									size="small"
									pagination={this.state.pagination}
									onChange={this.handleTableChange}
								/>
							</Card>
						</Col>
					</Row>
				</div>
				<Modal
					title="Koreksi Data Migrasi"
					visible={this.state.isModalKoreksi}
					footer={[]}
					closable={true}
					width={"50%"}
					onCancel={() => this.setState({isModalKoreksi: false})}
				>
					<Form
						layout="vertical"
						name="fdata"
						onFinish={this.handleSubmitOveride}
						size="small"
						ref={this.formRefOveride}
					>
						<Row>
							{(this.state.errMsg != '' &&
								<Col span={24}>
									<Alert
										message={this.state.errTitle}
										description={JSON.stringify(this.state.errMsg)}
										type="error"
										showIcon
										style={{
											marginBottom: 10
										}}
									/>
								</Col>
							)}

							{Object.entries(this.state.modifiedField).map(([key, value]) => (
								<Col lg={24} sm={24} xs={24} key={"mod" + key}>
									<Form.Item
										label={key.replace(/_/ig, " ")}
										name={key + "_picker"}
										rules={[
											{
												required: true,
												message: 'harus diisi'
											}
										]}
									>
										<DatePicker
											placeholder="dd-mm-yyyy"
											format="YYYY-MM-DD"
											style={{ width: "100%" }}
											onChange={(date, dateString) => {
												this.formRefOveride.current.setFieldsValue({
													[key]: dateString,
												});
											}}
										/>
									</Form.Item>
									<Form.Item
										name={key}
										style={{
											display: "none",
										}}
									>
										<Input type="hidden" />
									</Form.Item>
								</Col>
							))}
						</Row>
						<Row justify="end">
							<Col style={{ padding: 5 }}>
								<Button
									key="btn-simpan"
									loading={this.state.loadingButton}
									type="primary"
									htmlType="submit"
									disabled={this.state.errComponent ? true : false}
									size="small"
								>
									{(!this.state.loadingButton && <CheckCircleOutlined />)}
									Simpan
								</Button>
							</Col>
							<Col style={{ padding: 5 }}>
								<Button
									type="danger"
									key="btn-cancel"
									disabled={this.state.loadingButton ? true : false}
									size="small"
									onClick={() => {
										this.setState({
											isModalKoreksi: false
										})
									}}
								>
									<CloseCircleOutlined /> Cancel
								</Button>
							</Col>
						</Row>
					</Form>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = function (state) {
	return state.configReducers
}

export default connect(
	mapStateToProps,
	{
		initHandler,
		setConfig,
		ajaxViewHandler,
		ajaxHandler
	}
)(DataMigrasi);