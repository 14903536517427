import React from 'react';
import { Link, Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Avatar,
	Form,
	Input,
	Button,
	Space,
	Select,
	DatePicker,
	message,
	Alert,
	Spin
} from 'antd';
import {
	DiffOutlined,
	CheckCircleOutlined,
	CloseCircleOutlined,
	SettingOutlined
} from '@ant-design/icons';
import moment from 'moment';

import NumberFormat from "react-number-format";

import axios from 'axios';
import Config from '../../Config';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";
import { Helmet } from "react-helmet";

import LocationPicker from 'react-location-picker';

const FormItem = Form.Item

const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 19,
			offset: 5,
		},
		lg: {
			span: 19,
			offset: 5,
		}
	},
};

const configClass = {
	title: 'form njkb',
	apiUrl: {
		preview: "kendaraan/api/perhitungan/",
		merek: "master/api/merek/",
		tipe: "master/api/tipe/",
		golongan: "master/api/golongan/",
	},
	pageUrl: {
		data: '/admin/pengaturan/njkb/'
	},
	breadCrumb: [
		(
			<span>
				<SettingOutlined /> pengaturan
			</span>
		),
		'njkb',
		'form'
	]

}

const config = new Config();

class FormNjkb extends React.Component {
	formRef = React.createRef();

	constructor(props) {
		super(props);

		this.state = {
			errComponent: false,
			errTitle: '',
			errMsg: '',
			loadingButton: false,

			loadingMerek: false,
			listMerek: null,

			loadingTipe: false,
			listTipe: null,

			loadingGolongan: false,
			listGolongan: null,

			aksi: 'tambah',
			method: 'post',
			idLama: '',
		}
	}

	UNSAFE_componentWillMount() {
		this.props.initHandler();
	}

	componentDidMount() {
		this.loadMasterList();
	}

	async preview(id) {
		message.loading({ content: 'Menampilkan data sebelumnya...', duration: 0, key: 'toast' });

		this.props.ajaxViewHandler('get', configClass.apiUrl.preview + id)
			.then(() => {
				const data = this.props.responseMsg;
				if (this.props.isSuccess) {
					this.formRef.current.setFieldsValue(data);
					for (var key in data) {
						const dateRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4}$/;
						const dateTimeRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
						if (dateRegex.test(data[key])) {//jika format tanggal			    	
							this.formRef.current.setFieldsValue({
								[key + '_picker']: moment(data[key], 'DD-MM-YYYY'),
								//[key]: moment(data[key], 'DD-MM-YYYY')
							});
						} else if (dateTimeRegex.test(data[key])) {//jika format tanggal dan jam	    	
							this.formRef.current.setFieldsValue({
								[key + '_picker']: moment(data[key], 'DD-MM-YYYY HH:mm:ss'),
							});
						} else if (key == "njkb") {//jika format tanggal dan jam	    	
							this.formRef.current.setFieldsValue({
								[key + '_preview']: data[key],
							});
						} else if (key == 'tipe') {
							this.setState(
								{
									listTipe: null,
								},
								() => {
									const rule = {
										destination: {
											stateProgressName: "loadingTipe",
											endPoint:
												configClass.apiUrl.tipe + "?merek=" + data.merek,
											stateDestination: "listTipe",
										},
										nextDestination: null,
									};
									this.getMasterList(rule);
								}
							);
						}

					}
					message.destroy();
					this.setState({
						aksi: 'edit',
						method: 'put',
						idLama: id + '/'
					});
				} else {
					const errTitle = 'error preview data lama';
					this.setState({
						errTitle: errTitle,
						errMsg: this.props.responseMsg
					});
					message.error({ content: errTitle, duration: 2, key: 'toast' });
				}
			}).catch((response) => {
				console.log('gagal_get_preview', response);
				message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			});
	}

	handleSubmit = (values) => {
		var datas = values;
		this.postData(datas);
	}

	async postData(datas) {
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler(this.state.method, configClass.apiUrl.preview + this.state.idLama, datas, false, true)
			.then(() => {
				this.setState({
					loadingButton: false
				});
				if (this.props.isSuccess) {
					message.success({ content: 'berhasil menyimpan data', duration: 2, key: 'toast' });
				} else {
					const errTitle = 'gagal menyimpan data'
					message.error({ content: errTitle, duration: 2, key: 'toast' });
					this.setState({
						errTitle: errTitle,
						errMsg: this.props.responseMsg
					});
				}
			}).catch((response) => {
				console.log('gagal_post', response);
				this.setState({
					loadingButton: false
				});
				message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
			});
	}

	loadMasterList() {
		const rule = [
			{
				destination: {
					stateProgressName: 'loadingMerek',
					endPoint: configClass.apiUrl.merek,
					stateDestination: 'listMerek',
				},
				nextDestination: {
					stateProgressName: 'loadingGolongan',
					endPoint: configClass.apiUrl.golongan,
					stateDestination: 'listGolongan',
					next: null,
				}
			}
		];

		this.setState({
			errComponent: false
		});

		rule.map((item, index) => {
			if (index == rule.length - 1) {
				this.getMasterList(item, true);
			} else {
				this.getMasterList(item);
			}
		});
	}

	async getMasterList(rule, isFinal = false) {
		var destination = rule.destination;
		var nextDestination = rule.nextDestination;
		this.setState({ [destination.stateProgressName]: true });
		this.props
			.ajaxViewHandler("get", destination.endPoint)
			.then(() => {
				const data = this.props.responseMsg;
				if (this.props.isSuccess) {
					if (data.count != undefined) {
						let newState = null;
						if (this.state[destination.stateDestination] == null) {
							newState = data;
						} else {
							if (destination.stateProgressName.includes("_searching")) {
								newState = data;
							} else {
								const oldState = this.state[destination.stateDestination];
								newState = {
									count: data.count,
									next: data.next,
									previous: data.previous,
									results: [...oldState.results, ...data.results],
								};
							}
						}
						// console.log('state_name', destination.stateDestination);
						// console.log('state_value', newState);
						this.setState({
							[destination.stateDestination]: newState,
						});
					} else if (data.results != undefined) {
						this.setState({
							[destination.stateDestination]: data.results,
						});
					} else {
						if (Array.isArray(data)) {
							this.setState({
								[destination.stateDestination]: data,
							});
						} else {
							this.setState({
								[destination.stateDestination]: [data],
							});
						}
					}
					if (isFinal) {
						if (this.props.match.params.id != undefined) {
							this.preview(this.props.match.params.id);
						}
					}
				} else {
					this.setState({
						errComponent: true,
					});
				}
				this.setState({ [destination.stateProgressName]: false });
			})
			.catch((response) => {
				//console.log('gagal_get_master', response);
				message.error({
					content: "gagal request data, coba lagi",
					duration: 2,
					key: "toast",
				});
				this.setState({
					errTitle: "gagal request data master",
					errMsg: response,
				});
				this.setState({ [destination.stateProgressName]: false });
			});
		//next function
		if (nextDestination != null) {
			const next = {
				destination: {
					stateProgressName: nextDestination.stateProgressName,
					endPoint: nextDestination.endPoint,
					stateDestination: nextDestination.stateDestination,
				},
				nextDestination: nextDestination.next,
			};
			this.getMasterList(next);
		}
	}

	generateSelect(stateName, url, key, value) {
		// console.log([stateName], this.state["list"+stateName].next);
		return this.state["loading" + stateName] ? (
			<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
		) : (
			<Select
				showSearch
				placeholder="pilih"
				optionFilterProp="children"
				onSearch={(val) => {
					const rule = {
						destination: {
							stateProgressName: "loading" + stateName + "_searching",
							endPoint: url + "?search=" + val,
							stateDestination: "list" + stateName,
						},
						nextDestination: null,
					};
					this.getMasterList(rule);
				}}
				onPopupScroll={(e) => {
					e.persist();
					let target = e.target;
					if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
						if (
							this.state["list" + stateName].next != undefined &&
							this.state["list" + stateName].next != null
						) {
							const rule = {
								destination: {
									stateProgressName: "loading" + stateName + "Paging",
									endPoint: this.state["list" + stateName].next,
									stateDestination: "list" + stateName,
								},
								nextDestination: null,
							};
							this.getMasterList(rule);
						}
					}
				}}
				onChange={(val) => {
					if (stateName == "Merek") {
						this.setState(
							{
								listTipe: null,
							},
							() => {
								const rule = {
									destination: {
										stateProgressName: "loadingTipe",
										endPoint:
											configClass.apiUrl.tipe + "?merek=" + val,
										stateDestination: "listTipe",
									},
									nextDestination: null,
								};
								this.getMasterList(rule);
							}
						);
					}
				}}
			>
				{this.state["loading" + stateName + "_searching"] && (
					<Select.Option value="">
						<Spin size="small" />
					</Select.Option>
				)}
				{this.state["list" + stateName].count != undefined
					? this.state["list" + stateName].results.map((item) => (
						<Select.Option value={item[key]}>
							{item.kode + " - " + item[value]}
						</Select.Option>
					))
					: this.state["list" + stateName].results.map((item) => (
						<Select.Option value={item[key]}>
							{item.kode + " - " + item[value]}
						</Select.Option>
					))}
				{this.state["list" + stateName] != undefined &&
					this.state["list" + stateName].next != undefined && (
						<Select.Option value="">
							<Spin size="small" />
						</Select.Option>
					)}
			</Select>
		);
	}

	generateEmptySelect() {
		return (
			<Select
				placeholder="pilih"
				optionFilterProp="children"
			>
			</Select>
		);
	}

	render() {
		// if(this.props.isSuperadmin == false){
		// 	return <Redirect to="/admin/forbidden" />
		// }
		if (this.props.isRedirect) {
			return <Redirect to={configClass.pageUrl.data} />
		}

		return (
			<div>
				<Helmet>
					<title>{configClass.title + " | " + config.get_site_info().name}</title>
				</Helmet>
				<Breadcrumb>
					{configClass.breadCrumb.map(item => (
						<Breadcrumb.Item>
							{item}
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
				<div className="card">
					<Form
						layout="vertical"
						name="fdata"
						onFinish={this.handleSubmit}
						size="small"
						ref={this.formRef}
					>
						{/*data dasar*/}
						<Row className="isi">
							<Col lg={24} sm={24} xs={24}>
								<Card
									className="konten"
									title={
										<span className="konten-judul">
											{configClass.title}
											{(this.state.loadingButton &&
												<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
											)}
										</span>
									}
								>
									{(this.state.errMsg != '' &&
										<Alert
											message={this.state.errTitle}
											description={JSON.stringify(this.state.errMsg)}
											type="error"
											showIcon
											style={{
												marginBottom: 10
											}}
										/>
									)}
									<Row justify="space-between">
										<Col lg={24} sm={24} xs={24}>
											<Form.Item
												label="MEREK"
												name="merek"
												rules={[
													{
														required: true,
														message: 'harus diisi'
													}
												]}
											>
												{this.state.listMerek != null
													?
													this.generateSelect(
														"Merek",
														configClass.apiUrl.merek,
														"id",
														"nama"
													)
													:
													this.generateEmptySelect()
												}
											</Form.Item>
										</Col>
									</Row>
									<Row justify="space-between">
										<Col lg={24} sm={24} xs={24}>
											<Form.Item
												label="TIPE"
												name="tipe"
												rules={[
													{
														required: true,
														message: 'harus diisi'
													}
												]}
											>
												{this.state.listTipe != null
													?
													this.generateSelect(
														"Tipe",
														configClass.apiUrl.tipe,
														"id",
														"nama"
													)
													:
													this.generateEmptySelect()
												}
											</Form.Item>
										</Col>
									</Row>
									<Row justify="space-between">
										<Col lg={24} sm={24} xs={24}>
											<Form.Item
												label="GOLONGAN"
												name="golongan"
												rules={[
													{
														required: true,
														message: 'harus diisi'
													}
												]}
											>
												{this.state.listGolongan != null
													?
													this.generateSelect(
														"Golongan",
														configClass.apiUrl.golongan,
														"id",
														"nama"
													)
													:
													this.generateEmptySelect()
												}
											</Form.Item>
										</Col>
									</Row>
									<Row justify="space-between">
										<Col lg={24} sm={24} xs={24}>
											<Form.Item
												label="TAHUN BUAT"
												name="tahun_buat"
												rules={[
													{
														required: true,
														message: 'harus diisi'
													}
												]}
											>
												<Input
													style={{ textTransform: 'none' }}
													placeholder="tahun buat"
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row justify="space-between">
										<Col lg={24} sm={24} xs={24}>
											<Form.Item
												label="NJKB"
												name="njkb_preview"
												rules={[
													{
														required: true,
														message: 'harus diisi'
													}
												]}
											>
												<NumberFormat
													style={{ textTransform: 'none' }}
													autoComplete="off"
													thousandSeparator={true}
													className="ant-input ant-input-sm"
													onValueChange={(values) => {
														const { formattedValue, value } = values;
														this.formRef.current.setFieldsValue({
															njkb: value
														});
													}}
													placeholder="nilai NKJB"
												/>
											</Form.Item>
											<Form.Item name="njkb" style={{ display: "none" }}>
												<Input type="hidden" />
											</Form.Item>
										</Col>
									</Row>
									<Row justify="space-between">
										<Col lg={24} sm={24} xs={24}>
											<Form.Item
												label="BOBOT"
												name="bobot"
												rules={[
													{
														required: true,
														message: 'harus diisi'
													}
												]}
											>
												<Input
													type="number"
													step="0.01"
													style={{ textTransform: 'none' }}
													placeholder="bobot (pisahkan dengan titik .), cth: 1.05"
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row justify="end">
										<Col style={{ padding: 5 }}>
											<Button
												key="btn-simpan"
												loading={this.state.loadingButton}
												type="primary"
												htmlType="submit"
												disabled={this.state.errComponent ? true : false}
												size="small"
											>
												{(!this.state.loadingButton && <CheckCircleOutlined />)}
												Simpan
											</Button>
										</Col>
										<Col style={{ padding: 5 }}>
											<Link to={configClass.pageUrl.data}>
												<Button
													type="danger"
													key="btn-cancel"
													disabled={this.state.loadingButton ? true : false}
													size="small"
												>
													<CloseCircleOutlined /> Cancel
												</Button>
											</Link>
										</Col>
									</Row>

								</Card>
							</Col>
						</Row>
					</Form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = function (state) {
	return state.configReducers
}

export default connect(
	mapStateToProps,
	{
		initHandler,
		setConfig,
		ajaxViewHandler,
		ajaxHandler
	}
)(FormNjkb);