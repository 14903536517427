import React, { PureComponent } from 'react'
import { Redirect, Link } from "react-router-dom";
import { 
  Button, 
  Row, 
  Col,
  Input, 
  Layout, 
  Form, 
  Alert,
  Modal,
  message
} from 'antd';
import '@ant-design/compatible/assets/index.css'

import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Config from '../Config';
import {Helmet} from "react-helmet";

import { connect } from 'react-redux';
import { 
  setConfig, 
  ajaxHandler, 
  ajaxViewHandler 
} from "../store/actions";

import axios from 'axios';
import Cookies from 'js-cookie';
import QRCode from 'qrcode.react';

import 'antd/dist/antd.css';
import '../assets/loginStyle.css';

const configClass = {
  apiUrl: {
    cekLogin: "api/token/"
  }
}

class AuthLayout extends PureComponent {
  config;
  timeoutGetQr;
  timeOutPushQr;

  constructor(props){
    super(props);
    this.config = new Config();
    this.state = {
      msg: '',
      loading: false,
      loadingRedirect: false,
      logo: this.config.get_site_info().logo_landscape
    }
  }

  componentDidMount(){

  }

  handleSubmit = (values) => {
    // values.preventDefault();
    var params = new FormData();
    params.append("username", values.username);
    params.append("password", values.password);
    this.postLogin(params);
  }

  // doLoginPrototype(){
  //   Cookies.set(this.config.get_cookie_name(), 'Bearer testing', { expires: 1 });
  //   this.setState({
  //         loading: false,
  //         loadingRedirect: true
  //   });
  //   setTimeout(() => {
  //         this.props.setConfig({
  //           isLogin: true
  //         });
  //   }, 3000);
  // }

  async postLogin(datas) {
    //message.loading({ content: 'Memproses...', duration: 2, key: 'toast' });
    this.setState({
        loading: true
    });

    this.props.ajaxHandler('post', configClass.apiUrl.cekLogin, datas, true, true)
    .then(() =>{
      this.setState({
        loading: false
      });
      if(this.props.isSuccess){
        Cookies.set(this.config.get_cookie_name(), 'Bearer '+this.props.responseMsg.access, { expires: 1 });
        this.setState({
          loading: false,
          loadingRedirect: true
        });
        /*
        setTimeout(() => {
          this.props.setConfig({
            isLogin: true
          });
        }, 3000);
        */
        window.location.replace("/admin");
      }else{
        this.setState({
          msg: this.props.responseMsg.access != undefined ? this.props.responseMsg.access : (this.props.responseCode == 401 ? "invalid user" : this.props.responseMsg)
        });
      }
    }).catch((response) => {
      console.log('gagal_post', response);
      this.setState({
          loading: false
        });
      message.error({ content: 'kesalahan pengiriman data, code: '+response.responseCode+'coba lagi', duration: 2, key: 'toast' });
    });
  }

  render() {
    /*
    if(this.props.isLogin){
      return <Redirect to="/admin/index" />
    }
    */
    
    return (
      <div>
        <Helmet>
          <title>login | {this.config.get_site_info().name}</title>
        </Helmet>
        <Layout>
            {(this.state.loadingRedirect)?(
              <div className="splash">
                <div className="eclipse"></div>
                <div className="logo-splash">
                  <img alt="logo" src={this.state.logo} className="logo-splash" height="400" />
                  {/*<img src={process.env.PUBLIC_URL + "/icon.svg"} alt="logo" className="logo-splash" width="120" height="120" />*/}
                  <br />
                  <img src={process.env.PUBLIC_URL + "/loading.gif"} alt="loading" width="200" />
                </div>
                <div className="eclipse-bawah"></div>
              </div>
            ):(
              <div className="form">
                <div className="logo-login" style={{marginBottom: 10}}>
                  <Link to="/">
                    <img alt="logo" src={this.state.logo} />
                    {/*<span style={{fontSize: 28}}>{this.state.namaDesa}</span>*/}
                  </Link>
                </div>
                {this.state.msg!='' &&
                  <Row justify="center" style={{marginBottom: 10}}>
                    <Col span="24">
                      <Alert message={this.state.msg} type="error" showIcon />
                    </Col>
                  </Row>
                }
                <Form 
                  name="login"
                  layout="vertical"
                  onFinish={this.handleSubmit}
                >
                  <Form.Item
                    name="username"
                    rules={[{ required: true, message: 'username harus diisi!' }]}
                  >
                      <Input
                        placeholder="Username"
                        style={{textTransform: 'none'}}
                      />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'password harus diisi!' }]}
                  >
                    <Input
                      type="password"
                      placeholder="Password"
                      style={{textTransform: 'none'}}
                    />
                  </Form.Item>
                  <Row>
                    <Col span={24}>
                      <Button
                        className="btn-login"
                        loading={this.state.loading}
                        htmlType="submit"
                        block
                      >
                        Login
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} style={{textAlign: 'center', paddingTop: 20}}>
                      <img src={process.env.PUBLIC_URL + "/samsat-gabung.png"} height={30} />
                    </Col>
                  </Row>
                  <div style={{textAlign: 'center', marginTop: 10}}>
                      &copy; 2021 {this.config.get_site_info().name}
                  </div>
                </Form>
              </div>
            )}
        </Layout>
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return state.configReducers
}

export default connect(mapStateToProps, 
                        {
                          setConfig,
                          ajaxHandler,
                          ajaxViewHandler
                        }
                  )(AuthLayout);
