import React from 'react';
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import Highlighter from 'react-highlight-words';
import { Link } from "react-router-dom";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Input,
	Button,
	Table,
	Modal,
	message,
	Alert,
	Select,
	Spin,
	Form,
	DatePicker,
} from 'antd';
import {
	TeamOutlined,
	SearchOutlined,
	PlusOutlined,
	FormOutlined,
	CloseSquareOutlined,
	ExclamationCircleOutlined,
	DiffOutlined,
	RedoOutlined,
	CopyOutlined,
	PrinterOutlined
} from '@ant-design/icons';

import axios from 'axios';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";

import Config from '../../Config';
import { Helmet } from "react-helmet";

import moment from "moment";

import '../../assets/laporan.css';

const configClass = {
	title: 'LAPORAN PENETAPAN PKB DAN BPNKB',
	apiUrl: {
		data: 'laporan/api/penetapan/',
		warna_plat: "master/api/plat",
		jenis_kendaraan: "master/api/jenis/kendaraan/",
		payment: "master/api/payment/",
		upt: "pegawai/api/upt/",
		kabupaten: "master/api/kabupaten/",
	},
	pageUrl: {
		cetak: '/laporan/harian-pkb'
	},
	breadCrumb: [
		(
			<span>
				<CopyOutlined /> laporan
			</span>
		),
		'penetapan pkb dan bbnkb'
	]

}

const config = new Config();

class LapPenetapanPkb extends React.Component {
	formRef = React.createRef();

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			data: null,
			loadingUpt: false,
			listUpt: [],
			loadingKabupaten: null,
			listKabupaten: [],
			kabupaten_id: "",
			//  data: {
			// "data":[
			// {
			// "nama":"PENDAFTARAN BARU",
			// "total_pokok_bbnkb":693720000.0,
			// "total_pokok_pkb":29630700.0,
			// "total_pokok_swdkllj":5863000.0,
			// "total_denda_bbnkb":173430000.0,
			// "total_denda_pkb":9880100.0,
			// "total_denda_swdkllj":2835000.0,
			// "items":[
			// {
			// "id":837,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090HH",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":253000.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-06-09T07:56:22.975417Z",
			// "tgl_penetapan":"2022-06-09T07:56:22.974084Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140057,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":836,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090HH",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":253000.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-06-09T07:51:11.711180Z",
			// "tgl_penetapan":"2022-06-09T07:51:11.709920Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140057,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":835,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090HH",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":253000.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-06-09T07:31:10.999976Z",
			// "tgl_penetapan":"2022-06-09T07:31:10.999529Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140057,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":834,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090HH",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":253000.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-06-09T07:11:06.254508Z",
			// "tgl_penetapan":"2022-06-09T07:11:06.253871Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140057,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":833,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":253000.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-06-09T06:53:47.214934Z",
			// "tgl_penetapan":"2022-06-09T06:53:47.214526Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":832,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":253000.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-06-09T06:53:38.085093Z",
			// "tgl_penetapan":"2022-06-09T06:53:38.083824Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":831,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":253000.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-06-09T06:52:38.048100Z",
			// "tgl_penetapan":"2022-06-09T06:52:38.046608Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":830,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":253000.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-06-09T06:52:13.069182Z",
			// "tgl_penetapan":"2022-06-09T06:52:13.067645Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":829,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-06-06T02:53:56.154090Z",
			// "tgl_penetapan":"2022-06-06T02:53:56.152856Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":828,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-06-05T13:40:23.517333Z",
			// "tgl_penetapan":"2022-06-05T13:40:23.516015Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":827,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-06-05T13:21:08.010362Z",
			// "tgl_penetapan":"2022-06-05T13:21:08.009175Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":826,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-06-05T13:16:53.493205Z",
			// "tgl_penetapan":"2022-06-05T13:16:53.491973Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":825,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-06-05T12:22:20.283991Z",
			// "tgl_penetapan":"2022-06-05T12:22:20.283572Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":824,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-06-05T12:21:10.612748Z",
			// "tgl_penetapan":"2022-06-05T12:21:10.611258Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":823,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-30T02:16:28.199092Z",
			// "tgl_penetapan":"2022-05-30T02:16:28.196979Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":822,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-25T03:50:38.283118Z",
			// "tgl_penetapan":"2022-05-25T03:50:38.281731Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":821,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-25T03:11:13.692656Z",
			// "tgl_penetapan":"2022-05-25T03:11:13.690490Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":820,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-25T03:04:07.697086Z",
			// "tgl_penetapan":"2022-05-25T03:04:07.695879Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":819,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-25T03:01:56.572803Z",
			// "tgl_penetapan":"2022-05-25T03:01:56.571584Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":818,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-25T03:00:45.289894Z",
			// "tgl_penetapan":"2022-05-25T03:00:45.287775Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":817,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:42:03.717231Z",
			// "tgl_penetapan":"2022-05-24T06:42:03.714722Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":816,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:39:58.051538Z",
			// "tgl_penetapan":"2022-05-24T06:39:58.049191Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":815,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:38:31.913146Z",
			// "tgl_penetapan":"2022-05-24T06:38:31.911914Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":814,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:37:51.988712Z",
			// "tgl_penetapan":"2022-05-24T06:37:51.987145Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":813,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:24:22.024073Z",
			// "tgl_penetapan":"2022-05-24T06:24:22.022824Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":812,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:22:58.397279Z",
			// "tgl_penetapan":"2022-05-24T06:22:58.395998Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":811,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:21:37.342189Z",
			// "tgl_penetapan":"2022-05-24T06:21:37.340995Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":810,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:21:18.073813Z",
			// "tgl_penetapan":"2022-05-24T06:21:18.072486Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":809,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:20:23.481992Z",
			// "tgl_penetapan":"2022-05-24T06:20:23.480752Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":808,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:19:15.247644Z",
			// "tgl_penetapan":"2022-05-24T06:19:15.246403Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":807,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:18:02.297013Z",
			// "tgl_penetapan":"2022-05-24T06:18:02.294122Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":806,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:17:43.199904Z",
			// "tgl_penetapan":"2022-05-24T06:17:43.197802Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":805,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:16:38.061744Z",
			// "tgl_penetapan":"2022-05-24T06:16:38.060223Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":804,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:11:59.576561Z",
			// "tgl_penetapan":"2022-05-24T06:11:59.575210Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":803,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:10:23.322605Z",
			// "tgl_penetapan":"2022-05-24T06:10:23.321115Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":802,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:10:03.623618Z",
			// "tgl_penetapan":"2022-05-24T06:10:03.621981Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":801,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:07:36.075730Z",
			// "tgl_penetapan":"2022-05-24T06:07:36.074366Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":800,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:06:22.567494Z",
			// "tgl_penetapan":"2022-05-24T06:06:22.566313Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":799,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:05:45.572577Z",
			// "tgl_penetapan":"2022-05-24T06:05:45.571200Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":788,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T05:58:47.671346Z",
			// "tgl_penetapan":"2022-05-24T05:58:47.670133Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// },
			// {
			// "id":787,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":224100.0,
			// "denda_swdkllj":35000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-04-11T17:15:20.164025Z",
			// "tgl_penetapan":"2022-04-11T17:15:20.162583Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// 1
			// ]
			// }
			// ]
			// },
			// {
			// "nama":null,
			// "total_pokok_bbnkb":169200000.0,
			// "total_pokok_pkb":7227000.0,
			// "total_pokok_swdkllj":1430000.0,
			// "total_denda_bbnkb":42300000.0,
			// "total_denda_pkb":2385000.0,
			// "total_denda_swdkllj":700000.0,
			// "items":[
			// {
			// "id":798,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:04:20.709727Z",
			// "tgl_penetapan":"2022-05-24T06:04:20.708414Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// ]
			// },
			// {
			// "id":797,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:03:53.204016Z",
			// "tgl_penetapan":"2022-05-24T06:03:53.202670Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// ]
			// },
			// {
			// "id":796,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:03:34.339236Z",
			// "tgl_penetapan":"2022-05-24T06:03:34.338039Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// ]
			// },
			// {
			// "id":795,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:03:21.932159Z",
			// "tgl_penetapan":"2022-05-24T06:03:21.929686Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// ]
			// },
			// {
			// "id":794,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:03:02.948167Z",
			// "tgl_penetapan":"2022-05-24T06:03:02.946839Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// ]
			// },
			// {
			// "id":793,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:01:59.189598Z",
			// "tgl_penetapan":"2022-05-24T06:01:59.188320Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// ]
			// },
			// {
			// "id":792,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:01:38.269160Z",
			// "tgl_penetapan":"2022-05-24T06:01:38.267804Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// ]
			// },
			// {
			// "id":791,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:01:18.114686Z",
			// "tgl_penetapan":"2022-05-24T06:01:18.113443Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// ]
			// },
			// {
			// "id":790,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:00:45.798494Z",
			// "tgl_penetapan":"2022-05-24T06:00:45.797216Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// ]
			// },
			// {
			// "id":789,
			// "nama_wp":"DINAS PENDIDIKAN KAB. MURA",
			// "pemilik_nik":"KH3132MY",
			// "pemilik_nama":"DINAS PENDIDIKAN KAB. MURA",
			// "nomor_rangka":"smxadkadxm",
			// "nomor_mesin":"max3423zxa",
			// "alamat_wp":null,
			// "bahan_bakar_nama":"BENSIN",
			// "warna":"merah",
			// "thn_buat":"2005",
			// "jenis_kendaraan":"PICK UP DOUBLE CAB BOC",
			// "merk_kendaraan":"HONDA ",
			// "tipe_nama":"ALL NEW CITY 1.5 IDSI A/T ",
			// "masa_pajak_mulai":"2022-02-08T00:00:00Z",
			// "masa_pajak_sampai":"2023-02-08T00:00:00Z",
			// "bbm":"BENSIN",
			// "nomor_polisi":"KH9090hh",
			// "detail":{
			// },
			// "no_daftar":"002",
			// "pokok_bbnkb":16920000.0,
			// "pokok_pkb":722700.0,
			// "pokok_swdkllj":143000.0,
			// "pokok_admstnk":200000.0,
			// "pokok_admtnk":100000.0,
			// "denda_bbnkb":4230000.0,
			// "denda_pkb":238500.0,
			// "denda_swdkllj":70000.0,
			// "denda_admstnk":0.0,
			// "denda_admtnk":0.0,
			// "tunggakan_pokok_bbnkb":0.0,
			// "tunggakan_pokok_pkb":0.0,
			// "tunggakan_pokok_swdkllj":0.0,
			// "tunggakan_pokok_admstnk":0.0,
			// "tunggakan_pokok_admtnk":0.0,
			// "tunggakan_denda_bbnkb":0.0,
			// "tunggakan_denda_pkb":0.0,
			// "tunggakan_denda_swdkllj":0.0,
			// "tunggakan_denda_admstnk":0.0,
			// "tunggakan_denda_admtnk":0.0,
			// "tgl_daftar":"2022-05-24T06:00:17.311534Z",
			// "tgl_penetapan":"2022-05-24T06:00:17.310159Z",
			// "paid_on":null,
			// "status":1,
			// "alamat_tujuan":null,
			// "nama_tujuan":null,
			// "nomor_fiskal":null,
			// "kode_tujuan":0,
			// "tanggal_fiskal":null,
			// "notice_number":7234234230,
			// "is_notice_failed":false,
			// "stnk_akhir":null,
			// "kode_notice":"M",
			// "is_print_kutipan":false,
			// "is_print_stnk":false,
			// "serial_printer":null,
			// "nomor_surat":null,
			// "kendaraan":1140056,
			// "pemilik":347361,
			// "korektor":1,
			// "penetapan_by":1,
			// "payment_type":null,
			// "kabupaten":null,
			// "layanan":[
			// ]
			// }
			// ],
			// "summary":{
			// "bbnkb_pokok":862920000.0,
			// "bbnkb_denda":215730000.0,
			// "bbnkb_jumlah":862920000.0,
			// "pkb_pokok":36857700.0,
			// "pkb_denda":24530200.0,
			// "pkb_jumlah":36857700.0,
			// "swdkllj_pokok":7293000.0,
			// "swdkllj_denda":3535000.0,
			// "swdkllj_jumlah":10828000.0
			// }
			// }
			// ]
			// },
			err: false,

			loadingWarnaPlat: false,
			listWarnaPlat: [],

			loadingJenisKendaraan: false,
			listJenisKendaraan: [],

			loadingPayment: false,
			listPayment: [],

			namauptppd: 'SAMSAT PALANGKARAYA',

			filter: "",


		}
	}

	UNSAFE_componentWillMount() {
		this.props.initHandler();
	}

	componentDidMount() {
		this.loadMasterList();
		// this.getData();
	}

	loadMasterList() {
		const rule = [
			{
				destination: {
					stateProgressName: "loadingJenisKendaraan",
					endPoint: configClass.apiUrl.jenis_kendaraan,
					stateDestination: "listJenisKendaraan",
				},
				nextDestination: {
					stateProgressName: "loadingWarnaPlat",
					endPoint: configClass.apiUrl.warna_plat,
					stateDestination: "listWarnaPlat",
					next: null,
				},
			},
			{
				destination: {
					stateProgressName: "loadingPayment",
					endPoint: configClass.apiUrl.payment,
					stateDestination: "listPayment",
				},
				nextDestination: {
					stateProgressName: "loadingKabupaten",
					endPoint: configClass.apiUrl.kabupaten,
					stateDestination: "listKabupaten",
					next: null,
				},
			},
		];

		this.setState({
			errComponent: false,
		});

		rule.map((item, index) => {
			if (index == rule.length - 1) {
				this.getMasterList(item, true);
			} else {
				this.getMasterList(item);
			}
		});
	}

	async getMasterList(rule, isFinal = false) {
		var destination = rule.destination;
		var nextDestination = rule.nextDestination;
		this.setState({ [destination.stateProgressName]: true });
		this.props
			.ajaxViewHandler("get", destination.endPoint)
			.then(() => {
				const data = this.props.responseMsg;
				if (this.props.isSuccess) {
					if (data.count != undefined) {
						let newState = null;
						if (this.state[destination.stateDestination].length == 0) {
							newState = data;
						} else {
							if (destination.stateProgressName.includes("_searching")) {
								newState = data;
							} else {
								const oldState = this.state[destination.stateDestination];
								newState = {
									count: data.count,
									next: data.next,
									previous: data.previous,
									results: [...oldState.results, ...data.results],
								};
							}
						}
						this.setState({
							[destination.stateDestination]: newState,
						});
					} else if (data.results != undefined) {
						this.setState({
							[destination.stateDestination]: data.results,
						});
					} else {
						if (Array.isArray(data)) {
							this.setState({
								[destination.stateDestination]: data,
							});
						} else {
							this.setState({
								[destination.stateDestination]: [data],
							});
						}
					}
					if (isFinal) {
						if (this.props.match.params.id != undefined) {
							// this.preview(this.props.match.params.id);
						}
					}
				} else {
					this.setState({
						errComponent: true,
					});
				}
				this.setState({ [destination.stateProgressName]: false });
			})
			.catch((response) => {
				//console.log('gagal_get_master', response);
				message.error({
					content: "gagal request data, coba lagi",
					duration: 2,
					key: "toast",
				});
				this.setState({
					errTitle: "gagal request data master",
					errMsg: response,
				});
				this.setState({ [destination.stateProgressName]: false });
			});
		//next function
		if (nextDestination != null) {
			const next = {
				destination: {
					stateProgressName: nextDestination.stateProgressName,
					endPoint: nextDestination.endPoint,
					stateDestination: nextDestination.stateDestination,
				},
				nextDestination: nextDestination.next,
			};
			this.getMasterList(next);
		}
	}

	generateSelect(stateName, url, key, value, keyDisplay = "") {
		return this.state["loading" + stateName] ? (
			<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
		) : (
			<Select
				autoFocus
				showSearch
				placeholder={"Cari / Pilih " + stateName}
				optionFilterProp="children"
				onSearch={(val) => {
					let urls = url + "?search=" + val;
					if (stateName == "Upt") {
						urls = `${url}?kabupaten=${this.state.kabupaten_id}&search=${val}`;
					}
					const rule = {
						destination: {
							stateProgressName: "loading" + stateName + "_searching",
							endPoint: urls,
							stateDestination: "list" + stateName,
						},
						nextDestination: null,
					};
					this.getMasterList(rule);
				}}
				onPopupScroll={(e) => {
					e.persist();
					let target = e.target;
					if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
						if (
							this.state["list" + stateName].next != undefined &&
							this.state["list" + stateName].next != null
						) {
							const rule = {
								destination: {
									stateProgressName: "loading" + stateName + "Paging",
									endPoint: this.state["list" + stateName].next,
									stateDestination: "list" + stateName,
								},
								nextDestination: null,
							};
							this.getMasterList(rule);
						}
					}
				}}
				allowClear
				style={{
					width: "100%",
				}}
				onChange={(val) => {
					if (stateName == "Kabupaten") {
						this.setState({
							kabupaten_id: val
						}, () => {
							const rule = {
								destination: {
									stateProgressName: "loadingUpt",
									endPoint: configClass.apiUrl.upt + "?kabupaten=" + val,
									stateDestination: "listUpt",
								},
								nextDestination: null,
							};
							this.getMasterList(rule);
						});
					}
				}}
				size={"small"}
			>
				{this.state["loading" + stateName + "_searching"] == true && (
					<Select.Option value="">
						<Spin size="small" />
					</Select.Option>
				)}
				{this.state["list" + stateName].count != undefined
					? this.state["list" + stateName].results.map((item) => (
						<Select.Option value={item[key]}>
							{keyDisplay == ""
								? item[key] + " - " + item[value]
								: item[keyDisplay] + " - " + item[value]}
						</Select.Option>
					))
					: this.state["list" + stateName].map((item) => (
						<Select.Option value={item[key]}>
							{keyDisplay == ""
								? item[key] + " - " + item[value]
								: item[keyDisplay] + " - " + item[value]}
						</Select.Option>
					))}
				{this.state["list" + stateName] != undefined &&
					this.state["list" + stateName].next != undefined && (
						<Select.Option value="">
							<Spin size="small" />
						</Select.Option>
					)}
			</Select>
		);
	}

	handleSubmit = (values) => {
		var filter = "";
		for (var key in values) {
			if (values[key] != undefined) {
				if (filter == "") {
					filter = "?" + key + "=" + values[key];
				} else {
					filter += "&" + key + "=" + values[key];
				}
			}
		}
		this.setState({
			filter: filter
		});
		this.getData(filter)
	};

	async getData(filter) {
		message.loading({ content: 'Menampilkan laporan...', duration: 0, key: 'toast' });
		this.setState({
			loading: true,
			err: false,
			data: null,
		});

		// this.props.ajaxViewHandler('get', configClass.apiUrl.data+"?warna_plat=1&jenis_kendaraan&roda=2&mulai_dari=23-10-2020&sampai_dengan=23-10-2021")
		this.props.ajaxViewHandler('get', configClass.apiUrl.data + filter)
			.then(() => {
				const data = this.props.responseMsg;
				if (this.props.isSuccess) {
					message.destroy();
					this.setState({
						loading: false,
						data: data,
						err: false
					});
					setTimeout(function () {
						// window.print();

					}, 1000);
				} else {
					const errTitle = 'error get laporan';
					this.setState({
						loading: false,
						data: null,
						err: true,
						errMsg: this.props.responseMsg
					});
					message.error({ content: errTitle, duration: 2, key: 'toast' });
				}
			}).catch((response) => {
				this.setState({
					loading: false,
					data: null,
					err: true,
					errMsg: 'unknown response'
				});
				console.log('gagal_get_laporan', response);
				message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			});
	}

	render() {
		// if(this.props.isSuperadmin == false){
		// 	return <Redirect to="/admin/forbidden" />
		// }
		var total_pokok_bbnkb = 0;
		var total_denda_bbnkb = 0;
		var total_total_bbnkb = 0;

		var total_pokok_pkb = 0;
		var total_denda_pkb = 0;
		var total_total_pkb = 0;

		var total_pokok_swdkllj = 0;
		var total_denda_swdkllj = 0;
		var total_total_swdkllj = 0;

		var no = 0;

		return (
			<div>
				<Helmet>
					<title>{configClass.title + " | " + config.get_site_info().name}</title>
				</Helmet>
				<Breadcrumb>
					{configClass.breadCrumb.map(item => (
						<Breadcrumb.Item>
							{item}
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
				<div className="card">
					<Row className="isi">
						<Col span={24}>
							<Card
								className="konten"
								style={{ overflow: "auto" }}
							>
								<Form
									layout="vertical"
									name="fdata"
									onFinish={this.handleSubmit}
									size="small"
									ref={this.formRef}
								>
									<Row justify="space-between">

										<Col span={4} className="titik-dua">Kabupaten</Col>
										<Col span={20}>
											<Form.Item
												name="kabupaten"
											>
												{this.generateSelect(
													"Kabupaten",
													configClass.apiUrl.kabupaten,
													"id",
													"name"
												)}
											</Form.Item>
										</Col>

									</Row>
									<Row>
										<Col span={4} className="titik-dua">UPT</Col>
										<Col span={20}>
											<Form.Item name="upt">
												{this.generateSelect(
													"Upt",
													configClass.apiUrl.upt,
													"id",
													"nama",
													"id"
												)}
											</Form.Item>
										</Col>
									</Row>
									<Row>
										<Col span={4} className="titik-dua">Jenis Kendaraan</Col>
										<Col span={20}>
											<Form.Item name="jenis_kendaraan">
												{this.generateSelect(
													"JenisKendaraan",
													configClass.apiUrl.jenis_kendaraan,
													"id",
													"nama",
													"kode"
												)}
											</Form.Item>
										</Col>
									</Row>
									<Row>
										<Col span={4} className="titik-dua">Warna Plat</Col>
										<Col span={20}>
											<Form.Item name="warna_plat">
												{this.generateSelect(
													"WarnaPlat",
													configClass.apiUrl.warna_plat,
													"id",
													"nama",
													"kode"
												)}
											</Form.Item>
										</Col>
									</Row>
									<Row>
										<Col span={4} className="titik-dua">Jumlah Roda</Col>
										<Col span={20}>
											<Form.Item name="roda">
												<Input placeholder="cth: 2" size="small" />
											</Form.Item>
										</Col>
									</Row>
									<Row>
										<Col span={4} className="titik-dua">Jenis Pembayaran</Col>
										<Col span={20}>
											<Form.Item
												name="payment_type"
											>
												{this.generateSelect(
													"Payment",
													configClass.apiUrl.jenis_layanan,
													"id",
													"nama",
													"kode"
												)}
											</Form.Item>
										</Col>
									</Row>
									<Row>
										<Col span={4} className="titik-dua">Periode</Col>
										<Col span={8}>
											<Form.Item name="mulai_dari_picker">
												<DatePicker
													placeholder="dd-mm-yyyy"
													format="DD-MM-YYYY"
													style={{ width: "100%" }}
													onChange={(date, dateString) => {
														this.formRef.current.setFieldsValue({
															mulai_dari: dateString,
														});
													}}
												/>
											</Form.Item>
											<Form.Item
												name="mulai_dari"
												style={{
													display: "none",
												}}
											>
												<Input type="hidden" />
											</Form.Item>
										</Col>
										<Col span={4} className="titik-dua" style={{ textAlign: "right" }}>Sampai Dengan</Col>
										<Col span={8}>
											<Form.Item name="sampai_dengan_picker">
												<DatePicker
													placeholder="dd-mm-yyyy"
													format="DD-MM-YYYY"
													style={{ width: "100%" }}
													onChange={(date, dateString) => {
														this.formRef.current.setFieldsValue({
															sampai_dengan: dateString,
														});
													}}
												/>
											</Form.Item>
											<Form.Item
												name="sampai_dengan"
												style={{
													display: "none",
												}}
											>
												<Input type="hidden" />
											</Form.Item>
										</Col>
									</Row>
									<Row>
										<Col span={4}></Col>
										<Col style={{ padding: 5 }}>
											<Button
												key="btn-simpan"
												loading={this.state.loading}
												type="primary"
												htmlType="submit"
												disabled={this.state.loading ? true : false}
												size="small"
											>
												{(!this.state.loading && <SearchOutlined />)}
												Proses
											</Button>
										</Col>
										{(this.state.data != null &&
											<Col style={{ padding: 5 }}>
												<a href={configClass.pageUrl.cetak + this.state.filter} target="_blank">
													<Button
														key="btn-cetak"
														loading={this.state.loading}
														type="default"
														htmlType="button"
														disabled={this.state.loading ? true : false}
														size="small"
													>
														{(!this.state.loading && <PrinterOutlined />)}
														Cetak
													</Button>
												</a>
											</Col>
										)}
									</Row>
								</Form>
							</Card>
						</Col>

						<Col span={24}>
							<Card
								className="konten"
								style={{ overflow: "auto" }}
							>
								{(this.state.loading == true &&
									<div style={{ color: '#72BBFF', fontSize: 17 }}>
										<Spin style={{ marginRight: 10 }} /> memproses laporan....
									</div>
								)}
								{(this.state.data != null &&
									<div className="kertas-laporan-besar">
										<div className="judul-laporan teks-tengah">
											LAPORAN HARIAN PENETAPAN PKB DAN BBNKB<br />
											KEPOLISIAN NEGARA REPUBLIK INDONESIA<br />
											DIREKTORAT LALU LINTAS<br />
											DAERAH KALIMANTAN TENGAH
										</div>
										<table className="laporan-table" border="1" cellPadding="5">
											<thead>
												<tr>
													<th rowspan="2">No</th>
													<th rowspan="2">NAMA DAN ALAMAT PEMILIK</th>
													<th rowspan="2">JATUH<br />TEMPO</th>
													<th rowspan="2">NO POLISI</th>
													<th rowspan="2">JENIS</th>
													<th rowspan="2">MERK DAN TIPE</th>
													<th rowspan="2">THN</th>
													<th colspan="3">BBN KB</th>
													<th colspan="3">PKB</th>
													<th colspan="3">SWDKLLJ</th>
												</tr>
												<tr>
													<th>POKOK</th>
													<th>DENDA</th>
													<th>TOTAL</th>
													<th>POKOK</th>
													<th>DENDA</th>
													<th>TOTAL</th>
													<th>POKOK</th>
													<th>DENDA</th>
													<th>TOTAL</th>
												</tr>
											</thead>
											<tbody>
												{(this.state.data != null && this.state.data.data.map((item, id) => (
													// (total_pokok_bbnkb += item.pokok_bbnkb) &&
													// (total_denda_bbnkb += item.denda_bbnkb) &&
													// (total_total_bbnkb += (item.pokok_bbnkb+item.denda_bbnkb)) &&

													// (total_pokok_pkb += item.pokok_pkb) &&
													// (total_denda_pkb += item.denda_pkb) &&
													// (total_total_pkb += (item.pokok_pkb+item.denda_pkb)) &&

													// (total_pokok_swdkllj += item.pokok_swdkllj) &&
													// (total_denda_swdkllj += item.denda_swdkllj) &&
													// (total_total_swdkllj += (item.pokok_swdkllj+item.denda_swdkllj)) &&
													<>
														<tr>
															<td colSpan={16} style={{ fontWeight: "bold" }}>{item.nama != null ? item.nama : "LAINNYA"}</td>
														</tr>
														{item.items.map((detail, idxDetail) => (
															<tr>
																<td className="teks-kanan">{no += 1}</td>
																<td>{detail.alamat_wp == null ? detail.nama_wp : detail.nama_wp + ", " + detail.alamat_wp}</td>
																<td width={100}>{moment(detail.masa_pajak_mulai).format('DD-MM-YYYY')}</td>
																<td className="teks-tengah">{detail.nomor_polisi.toUpperCase()}</td>
																<td>{detail.jenis_kendaraan}</td>
																<td>{detail.merk_kendaraan + " " + detail.tipe_nama}</td>
																<td className="teks-tengah">{detail.thn_buat}</td>

																<td>{(detail.pokok_bbnkb).toLocaleString('id')}</td>
																<td>{(detail.denda_bbnkb).toLocaleString('id')}</td>
																<td>{(detail.pokok_bbnkb + detail.denda_bbnkb).toLocaleString('id')}</td>

																<td>{detail.pokok_pkb.toLocaleString('id')}</td>
																<td>{detail.denda_pkb.toLocaleString('id')}</td>
																<td>{(detail.pokok_pkb + detail.denda_pkb).toLocaleString('id')}</td>

																<td>{detail.pokok_swdkllj.toLocaleString('id')}</td>
																<td>{detail.denda_swdkllj.toLocaleString('id')}</td>
																<td>{(detail.pokok_swdkllj + detail.denda_swdkllj).toLocaleString('id')}</td>
															</tr>
														))}
														<tr>
															<td colSpan={6} style={{ textAlign: "right", fontWeight: "bold" }}>{item.nama != null ? "Jumlah (" + item.nama + ")" : "Jumlah (LAINNYA)"}</td>
															<td></td>
															<td>{item.total_pokok_bbnkb.toLocaleString('id')}</td>
															<td>{item.total_denda_bbnkb.toLocaleString('id')}</td>
															<td>{(item.total_pokok_bbnkb + item.total_denda_bbnkb).toLocaleString('id')}</td>

															<td>{item.total_pokok_pkb.toLocaleString('id')}</td>
															<td>{item.total_denda_pkb.toLocaleString('id')}</td>
															<td>{(item.total_pokok_pkb + item.total_denda_pkb).toLocaleString('id')}</td>

															<td>{item.total_pokok_swdkllj.toLocaleString('id')}</td>
															<td>{item.total_denda_swdkllj.toLocaleString('id')}</td>
															<td>{(item.total_pokok_swdkllj + item.total_denda_swdkllj).toLocaleString('id')}</td>
														</tr>
													</>


												)))}
											</tbody>
											{(this.state.data != null &&
												<tfoot>
													<tr>
														<td colSpan={16}></td>
													</tr>
													<tr>
														<td colSpan={7}>Total</td>
														<td>{this.state.data.summary.bbnkb_pokok.toLocaleString('id')}</td>
														<td>{this.state.data.summary.bbnkb_denda.toLocaleString('id')}</td>
														<td>{this.state.data.summary.bbnkb_jumlah.toLocaleString('id')}</td>

														<td>{this.state.data.summary.pkb_pokok.toLocaleString('id')}</td>
														<td>{this.state.data.summary.pkb_denda.toLocaleString('id')}</td>
														<td>{this.state.data.summary.pkb_jumlah.toLocaleString('id')}</td>

														<td>{this.state.data.summary.swdkllj_pokok.toLocaleString('id')}</td>
														<td>{this.state.data.summary.swdkllj_denda.toLocaleString('id')}</td>
														<td>{this.state.data.summary.swdkllj_jumlah.toLocaleString('id')}</td>
													</tr>
												</tfoot>
											)}
										</table>
										{/*(this.state.data != null && this.state.data.ttd != undefined &&
									  				<Row justify="space-between" style={{marginTop: 10}}>
									  					{(this.state.data.ttd.kiri != undefined &&
									  							<Col span={10} className="teks-tengah">
									  								MENGETAHUI<br />
													  				{this.state.data.ttd.kiri.jabatan}
													  				<div style={{marginTop: 50}}>
													  					<u>{this.state.data.ttd.kiri.nama}</u><br />
													  					NIP : {this.state.data.ttd.kiri.nip}
													  				</div>
													  			</Col>
									  					)}
									  					{(this.state.data.ttd.kanan != undefined &&
												  			<Col span={10} className="teks-tengah">
												  				{this.state.namauptppd}, {moment().format('DD-MM-YYYY')}<br />
												  				{this.state.data.ttd.kanan.jabatan}
												  				<div style={{marginTop: 50}}>
												  					<u>{this.state.data.ttd.kanan.nama}</u><br />
												  					NIP : {this.state.data.ttd.kanan.nip}
												  				</div>
												  			</Col>
											  			)}
											  		</Row>
									  		)*/}
									</div>
								)}
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

const mapStateToProps = function (state) {
	return state.configReducers
}

export default connect(
	mapStateToProps,
	{
		initHandler,
		setConfig,
		ajaxViewHandler,
		ajaxHandler
	}
)(LapPenetapanPkb);