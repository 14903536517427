import React from 'react';
import { Link, Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Avatar,
	Form, 
	Input,
	Button,
	Space,
	Select,
	DatePicker,
	message,
	Alert,
	Spin
} from 'antd';
import {
  DiffOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import NumberFormat from 'react-number-format';

import axios from 'axios';
import Config from '../../Config';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";
import {Helmet} from "react-helmet";

const FormItem = Form.Item

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 19,
      offset: 5,
    },
    lg: {
      span: 19,
      offset: 5,
    }
  },
};

const configClass = {
	title: 'form tanda tangan',
	apiUrl: {
		preview: "master/api/tanda/tangan/",
	},
	pageUrl: {
		data: '/admin/tanda_tangan/'
	},
	breadCrumb: [
		(
			<span>
				<DiffOutlined /> master
			</span>
		),
		'tanda tangan',
		'form'
	]

}

const config = new Config();

class FormTandaTangan extends React.Component {
	formRef = React.createRef();

	constructor(props){
		super(props);

		this.state = {
			errComponent: false,
			errTitle: '',
			errMsg: '',
			loadingButton: false,

			gambar: null,
			
			aksi: 'tambah',
			method: 'post',
			idLama: '',
			harga: 0
		}
	}

	UNSAFE_componentWillMount(){
		this.props.initHandler();
	}

	componentDidMount(){
		if(this.props.match.params.id != undefined){
			this.preview(this.props.match.params.id);
		}
		
	}

	setGambar = (e) => {
	    this.setState({
			'gambar': e.target.files[0]
		});
	}

	async preview(id) {
		message.loading({ content: 'Menampilkan data sebelumnya...', duration: 0, key: 'toast' });

		this.props.ajaxViewHandler('get', configClass.apiUrl.preview+id)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				//this.formRef.current.setFieldsValue(data);
				for ( var key in data ) {
				    const dateRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4}$/;
				    const dateTimeRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;

				    const dateRegex2 = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
				    const dateTimeRegex2 = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
				    if(dateRegex.test(data[key])){//jika format tanggal			    	
				    	this.formRef.current.setFieldsValue({
				    		[key+'_picker']: moment(data[key], 'DD-MM-YYYY'),
							//[key]: moment(data[key], 'DD-MM-YYYY')
						});
				    }else if(dateTimeRegex.test(data[key])){//jika format tanggal dan jam	    	
				    	this.formRef.current.setFieldsValue({
				    		[key+'_picker']: moment(data[key], 'DD-MM-YYYY HH:mm:ss'),
						});
				    }else if(dateRegex2.test(data[key])){//jika format tanggal			    	
				    	this.formRef.current.setFieldsValue({
				    		[key+'_picker']: moment(data[key], 'DD-MM-YYYY'),
							//[key]: moment(data[key], 'DD-MM-YYYY')
						});
				    }else if(dateTimeRegex2.test(data[key])){//jika format tanggal dan jam	    	
				    	this.formRef.current.setFieldsValue({
				    		[key+'_picker']: moment(data[key], 'DD-MM-YYYY HH:mm:ss'),
						});
				    }/*else{
				    	this.formRef.current.setFieldsValue({
				    		[key]: data[key]
				    	});
				    }*/
				    if(key == 'gambar'){
				    	this.setState({
				    		preview_gambar: data[key]
				    	});
				    }else{
				    	this.formRef.current.setFieldsValue({
				    		[key]: data[key]
				    	});
				    }
	        	}
	        	message.destroy();
	        	this.setState({
	        		aksi: 'edit',
	        		method: 'put',
	        		idLama: id+'/'
	        	});
	        }else{
	        	const errTitle = 'error preview data lama';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	handleSubmit = (values) =>{
		var datas = new FormData();
		for ( var key in values ) {
			if(values[key] == undefined){
				datas.append(key, '');
			}else{
				datas.append(key, values[key].toString());
			}
		}
		// datas.append('provider', this.props.id);
		datas.append('harga', this.state.harga);
		if(this.state.gambar != null){
			datas.append('gambar', this.state.gambar, this.state.gambar.name);
		}else{
			datas.append('gambar', '');
		}
		this.postData(datas);
	}

	async postData(datas) {
		message.loading({ content: 'Memproses...', duration: 0, key: 'toast' });

		this.props.ajaxHandler(this.state.method, configClass.apiUrl.preview+this.state.idLama, datas, true, true)
		.then(() =>{
			this.setState({
				loadingButton: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil menyimpan data', duration: 2, key: 'toast' });
			}else{
				const errTitle = 'gagal menyimpan data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_post', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	
	render() {
		
		if(this.props.isRedirect){
			return <Redirect to={configClass.pageUrl.data} />
		}

		return(
			<div>
				<Helmet>
					<title>{configClass.title+" | "+config.get_site_info().name}</title>
				</Helmet>
				<Breadcrumb>
					{configClass.breadCrumb.map(item => (
						<Breadcrumb.Item>
							{item}
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
				<div className="card">
					<Form 
						layout="vertical"
						name="fdata"
					    onFinish={this.handleSubmit}
					    size="small"
					    ref={this.formRef}
					    
					>
						{/*data dasar*/}
						<Row className="isi">
					       	<Col lg={24} sm={24} xs={24}>
					        	<Card
					        		className="konten"
						            title={
						            	<span className="konten-judul">
						            		{configClass.title}
						            		{(this.state.loadingButton &&
								        		<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
								        	)}
						            	</span>
						            }
						        >
						        		{(this.state.errMsg!='' &&
								        	<Alert
										      message={this.state.errTitle}
										      description={JSON.stringify(this.state.errMsg)}
										      type="error"
										      showIcon
										      style={{
										      	marginBottom: 10
										      }}
										    />
										)}
										
										<Row justify="space-between">
						                	<Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="KATEGORI"
										            name="kategori"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus dipilih' 
										            	}
										            ]}
										        >
										            <Select
                                  placeholder="Pilih"
                                  optionFilterProp="children"
                                  allowClear
                                  showSearch
                                  optionFilterProp="children"
                                 >
                                    <Select.Option value={1}>1 - Polisi</Select.Option>
                                    <Select.Option value={2}>2 - Bapenda</Select.Option>
                                    <Select.Option value={3}>3 - Jasa Raharja</Select.Option>
                                </Select>
										        </Form.Item>
										    </Col>
										</Row>
					          <Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="NAMA"
										            name="nama"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										            <Input
										            	style={{textTransform: 'none'}}
										                placeholder="nama"
										            />
										        </Form.Item>
										    </Col>
										</Row>
										<Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="JABATAN"
										            name="jabatan"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										            <Input
										            	style={{textTransform: 'none'}}
										                placeholder="jabatan"
										            />
										        </Form.Item>
										    </Col>
										</Row>
										<Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="NIP"
										            name="nip"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										            <Input
										            	style={{textTransform: 'none'}}
										                placeholder="nip"
										            />
										        </Form.Item>
										    </Col>
										</Row>
																				
										<Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="GAMBAR"
										            name="gambar"
										        >
										            <Input
										            	accept="image/png, image/jpeg"
										            	type="file"
										                placeholder="pilih gambar"
										                onChange={this.setGambar}
										            />
										        </Form.Item>
										        {(this.state.aksi == 'edit' && this.state.preview_gambar != null &&
										        	<img src ={this.state.preview_gambar} width="100" height="100" style={{objectFit: 'cover'}} />
										    	)}
										    </Col>
										</Row>
										

										<Row justify="end">
									        <Col style={{padding: 5}}>
									        	<Button 
													key="btn-simpan"
													loading={this.state.loadingButton}
													type="primary" 
													htmlType="submit"
													disabled={this.state.errComponent?true:false}
													size="small"
												>
													{(!this.state.loadingButton && <CheckCircleOutlined />)}
													Simpan
												</Button>
											</Col>
											<Col style={{padding: 5}}>
												<Link to={configClass.pageUrl.data}>
													<Button 
														type="danger"
														key="btn-cancel"
														disabled={this.state.loadingButton?true:false}
													    size="small"
													>
														<CloseCircleOutlined /> Cancel
													</Button>
												</Link>
										    </Col>
										</Row>

						        </Card>
					        </Col>
					    </Row>
					</Form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler
		}
	)(FormTandaTangan);