import React from 'react';
import { Link, Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Avatar,
	Form,
	Input,
	Button,
	Space,
	Select,
	DatePicker,
	message,
	Alert,
	Spin,
	TimePicker,
	Switch 
} from 'antd';
import {
	DiffOutlined,
	CheckCircleOutlined,
	CloseCircleOutlined,
	UserOutlined
} from '@ant-design/icons';
import moment from 'moment';

import axios from 'axios';
import Config from '../../Config';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";
import { Helmet } from "react-helmet";

import LocationPicker from 'react-location-picker';

const FormItem = Form.Item

const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 19,
			offset: 5,
		},
		lg: {
			span: 19,
			offset: 5,
		}
	},
};

const configClass = {
	title: 'form upt',
	apiUrl: {
		preview: "pegawai/api/upt/",
		kabupaten: "master/api/kabupaten/",
		pegawai: "pegawai/api/pegawai/",
	},
	pageUrl: {
		data: '/admin/upt/'
	},
	breadCrumb: [
		(
			<span>
				<UserOutlined /> pegawai
			</span>
		),
		'upt',
		'form'
	]

}

const config = new Config();

class FormUpt extends React.Component {
	formRef = React.createRef();

	constructor(props) {
		super(props);

		this.state = {
			errComponent: false,
			errTitle: '',
			errMsg: '',
			loadingButton: false,

			aksi: 'tambah',
			method: 'post',
			idLama: '',

			loadingKabupaten: null,
			listKabupaten: [],

			loadingPegawai: null,
			listPegawai: [],

			kabupatenDipilih: null,

			is_baypass: false,
			is_pusat: false,
		}
	}

	UNSAFE_componentWillMount() {
		this.props.initHandler();
	}

	componentDidMount() {
		//if(this.props.match.params.id != undefined){
		//this.preview(this.props.match.params.id);
		//}
		this.loadMasterList();
	}

	loadMasterList() {
		const rule = [
			{
				destination: {
					stateProgressName: "loadingKabupaten",
					endPoint: configClass.apiUrl.kabupaten,
					stateDestination: "listKabupaten",
				},
				//nextDestination: null
				nextDestination: {
				  stateProgressName: "loadingPegawai",
				  endPoint: configClass.apiUrl.pegawai,
				  stateDestination: "listPegawai",
				  next: null,
				},
			},
		];

		this.setState({
			errComponent: false,
		});

		rule.map((item, index) => {
			if (index == rule.length - 1) {
				this.getMasterList(item, true);
			} else {
				this.getMasterList(item);
			}
		});
	}

	async getMasterList(rule, isFinal = false) {
		var destination = rule.destination;
		var nextDestination = rule.nextDestination;
		this.setState({ [destination.stateProgressName]: true });
		this.props
			.ajaxViewHandler("get", destination.endPoint)
			.then(() => {
				const data = this.props.responseMsg;
				if (this.props.isSuccess) {
					if (data.count != undefined) {
						let newState = null;
						if (this.state[destination.stateDestination].length == 0) {
							newState = data;
						} else {
							if (destination.stateProgressName.includes("_searching")) {
								newState = data;
							} else {
								const oldState = this.state[destination.stateDestination];
								newState = {
									count: data.count,
									next: data.next,
									previous: data.previous,
									results: [...oldState.results, ...data.results],
								};
							}
						}
						this.setState({
							[destination.stateDestination]: newState,
						});
					} else if (data.results != undefined) {
						this.setState({
							[destination.stateDestination]: data.results,
						});
					} else {
						if (Array.isArray(data)) {
							this.setState({
								[destination.stateDestination]: data,
							});
						} else {
							this.setState({
								[destination.stateDestination]: [data],
							});
						}
					}
					if (isFinal) {
						if (this.props.match.params.id != undefined) {
							this.preview(this.props.match.params.id);
						}
					}
				} else {
					this.setState({
						errComponent: true,
					});
				}
				this.setState({ [destination.stateProgressName]: false });
			})
			.catch((response) => {
				//console.log('gagal_get_master', response);
				message.error({
					content: "gagal request data, coba lagi",
					duration: 2,
					key: "toast",
				});
				this.setState({
					errTitle: "gagal request data master",
					errMsg: response,
				});
				this.setState({ [destination.stateProgressName]: false });
			});
		//next function
		if (nextDestination != null) {
			const next = {
				destination: {
					stateProgressName: nextDestination.stateProgressName,
					endPoint: nextDestination.endPoint,
					stateDestination: nextDestination.stateDestination,
				},
				nextDestination: nextDestination.next,
			};
			this.getMasterList(next);
		}
	}

	async preview(id) {
		message.loading({ content: 'Menampilkan data sebelumnya...', duration: 0, key: 'toast' });

		this.props.ajaxViewHandler('get', configClass.apiUrl.preview + id)
			.then(() => {
				const data = this.props.responseMsg;
				if (this.props.isSuccess) {
					this.formRef.current.setFieldsValue(data);
					for (var key in data) {
						const dateRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4}$/;
						const dateTimeRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
						const timeRegex = /^[0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
						if (dateRegex.test(data[key])) {//jika format tanggal			    	
							this.formRef.current.setFieldsValue({
								[key + '_picker']: moment(data[key], 'DD-MM-YYYY'),
								//[key]: moment(data[key], 'DD-MM-YYYY')
							});
						} else if (dateTimeRegex.test(data[key])) {//jika format tanggal dan jam	    	
							this.formRef.current.setFieldsValue({
								[key + '_picker']: moment(data[key], 'DD-MM-YYYY HH:mm:ss'),
							});
						}else if (timeRegex.test(data[key])) {//jika format jam	    	
							this.formRef.current.setFieldsValue({
								[key + '_picker']: moment(data[key], 'HH:mm'),
								//[key]: moment(data[key]).format('HH:mm'),
							});
						}else if(key == "is_bypass"){
							this.setState({
								is_baypass: data[key]
							});
						}else if(key == "is_pusat"){
							this.setState({
								is_pusat: data[key]
							})
						}
						/*
				    	this.formRef.current.setFieldsValue({
				    		[key]: data[key]
				    	});
				    }*/

					}
					message.destroy();
					this.setState({
						aksi: 'edit',
						method: 'put',
						idLama: id + '/'
					});
				} else {
					const errTitle = 'error preview data lama';
					this.setState({
						errTitle: errTitle,
						errMsg: this.props.responseMsg
					});
					message.error({ content: errTitle, duration: 2, key: 'toast' });
				}
			}).catch((response) => {
				console.log('gagal_get_preview', response);
				message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			});
	}

	handleSubmit = (values) => {
		var datas = values;
		this.postData(datas);
	}

	async postData(datas) {
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler(this.state.method, configClass.apiUrl.preview + this.state.idLama, datas, false, true)
			.then(() => {
				this.setState({
					loadingButton: false
				});
				if (this.props.isSuccess) {
					message.success({ content: 'berhasil menyimpan data', duration: 2, key: 'toast' });
				} else {
					const errTitle = 'gagal menyimpan data'
					message.error({ content: errTitle, duration: 2, key: 'toast' });
					this.setState({
						errTitle: errTitle,
						errMsg: this.props.responseMsg
					});
				}
			}).catch((response) => {
				console.log('gagal_post', response);
				this.setState({
					loadingButton: false
				});
				message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
			});
	}

	generateSelect(stateName, url, key, value, keyDisplay = "") {
		return this.state["loading" + stateName] ? (
			<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
		) : (
			<Select
				// autoFocus
				showSearch
				placeholder={"Cari / Pilih " + stateName}
				optionFilterProp="children"
				onSearch={(val) => {
					if (stateName == "Tipe") {
						const rule = {
							destination: {
								stateProgressName: "loading" + stateName + "_searching",
								endPoint: url + "?search=" + val + "&merek=" + this.state.merek_dipilih,
								stateDestination: "list" + stateName,
							},
							nextDestination: null,
						};
						this.getMasterList(rule);
					} else {
						const rule = {
							destination: {
								stateProgressName: "loading" + stateName + "_searching",
								endPoint: url + "?search=" + val,
								stateDestination: "list" + stateName,
							},
							nextDestination: null,
						};
						this.getMasterList(rule);
					}
				}}
				onPopupScroll={(e) => {
					e.persist();
					let target = e.target;
					if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
						if (
							this.state["list" + stateName].next != undefined &&
							this.state["list" + stateName].next != null
						) {
							const rule = {
								destination: {
									stateProgressName: "loading" + stateName + "Paging",
									endPoint: this.state["list" + stateName].next,
									stateDestination: "list" + stateName,
								},
								nextDestination: null,
							};
							this.getMasterList(rule);
						}
					}
				}}
				allowClear
				style={{
					width: "100%",
				}}
				onChange={(val) => {
					if (stateName == "Kabupaten") {
						// const rule = {
						// 	destination: {
						// 		stateProgressName: "loadingKecamatan",
						// 		endPoint: configClass.apiUrl.kecamatan + "?regencies=" + val,
						// 		stateDestination: "listKecamatan",
						// 	},
						// 	nextDestination: null,
						// };
						// this.getMasterList(rule);
					} else if (stateName == "Merek") {
						this.setState({
							merek_dipilih: val
						});
						const rule = {
							destination: {
								stateProgressName: "loadingTipe",
								endPoint: configClass.apiUrl.tipe + "?merek=" + val,
								stateDestination: "listTipe",
							},
							nextDestination: null,
						};
						this.getMasterList(rule);
					}
				}}
				size={"small"}
			>
				{this.state["loading" + stateName + "_searching"] == true && (
					<Select.Option value="">
						<Spin size="small" />
					</Select.Option>
				)}
				{this.state["list" + stateName].count != undefined
					? this.state["list" + stateName].results.map((item) => (
						<Select.Option value={item[key]}>
							{keyDisplay == ""
								? item[key] + " - " + item[value]
								: item[keyDisplay] + " - " + item[value]}
						</Select.Option>
					))
					: this.state["list" + stateName].map((item) => (
						<Select.Option value={item[key]}>
							{keyDisplay == ""
								? item[key] + " - " + item[value]
								: item[keyDisplay] + " - " + item[value]}
						</Select.Option>
					))}
				{this.state["list" + stateName] != undefined &&
					this.state["list" + stateName].next != undefined && (
						<Select.Option value="">
							<Spin size="small" />
						</Select.Option>
					)}
			</Select>
		);
	}

	generateSelectHari() {
		return (
			<Select
				// autoFocus
				mode="multiple"
				showSearch
				placeholder={"Pilih Hari"}
				optionFilterProp="children"
				allowClear
				style={{
					width: "100%",
				}}
				onChange={(val) => {

				}}
				size={"small"}
			>
				<Select.Option value={0}>Senin</Select.Option>
				<Select.Option value={1}>Selasa</Select.Option>
				<Select.Option value={2}>Rabu</Select.Option>
				<Select.Option value={3}>Kamis</Select.Option>
				<Select.Option value={4}>Jumat</Select.Option>
				<Select.Option value={5}>Sabtu</Select.Option>
				<Select.Option value={6}>Minggu</Select.Option>
			</Select>
		);
	}

	render() {
		// if(this.props.isSuperadmin == false){
		// 	return <Redirect to="/admin/forbidden" />
		// }
		if (this.props.isRedirect) {
			return <Redirect to={configClass.pageUrl.data} />
		}

		return (
			<div>
				<Helmet>
					<title>{configClass.title + " | " + config.get_site_info().name}</title>
				</Helmet>
				<Breadcrumb>
					{configClass.breadCrumb.map(item => (
						<Breadcrumb.Item>
							{item}
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
				<div className="card">
					<Form
						layout="vertical"
						name="fdata"
						onFinish={this.handleSubmit}
						size="small"
						ref={this.formRef}
					>
						{/*data dasar*/}
						<Row className="isi">

							<Col lg={24} sm={24} xs={24}>
								<Card
									className="konten"
									title={
										<span className="konten-judul">
											{configClass.title}
											{(this.state.loadingButton &&
												<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
											)}
										</span>
									}
								>
									{(this.state.errMsg != '' &&
										<Alert
											message={this.state.errTitle}
											description={JSON.stringify(this.state.errMsg)}
											type="error"
											showIcon
											style={{
												marginBottom: 10
											}}
										/>
									)}
									<Row justify="space-between">
										<Col lg={24} sm={24} xs={24}>
											<Form.Item
												label="KABUPATEN"
												name="kabupaten"
												rules={[
													{
														required: true,
														message: 'harus dipilih'
													}
												]}
											>
												{this.generateSelect(
													"Kabupaten",
													configClass.apiUrl.kabupaten,
													"id",
													"name"
												)}
											</Form.Item>
										</Col>
									</Row>
									<Row justify="space-between">
										<Col lg={24} sm={24} xs={24}>
											<Form.Item
												label="IS PUSAT"
												name="is_pusat_picker"
											>
												<Switch
													checked={this.state.is_pusat}
													onChange={(isChecked) =>{
														this.setState({
															is_pusat: isChecked
														})
														this.formRef.current.setFieldsValue({
															is_pusat: isChecked
														})
													}}
												/>
											</Form.Item>
											<Form.Item
												name="is_pusat"
												style={{display: "none"}}
											>
												<Input type="hidden" />
											</Form.Item>
										</Col>
									</Row>
									<Row justify="space-between">
										<Col lg={24} sm={24} xs={24}>
											<Form.Item
												label="BYPASS"
												name="is_bypass_picker"
											>
												<Switch
													checked={this.state.is_baypass}
													onChange={(isChecked) =>{
														this.setState({
															is_baypass: isChecked
														})
														this.formRef.current.setFieldsValue({
															is_baypass: isChecked
														})
													}}
												/>
											</Form.Item>
											<Form.Item
												name="is_bypass"
												style={{display: "none"}}
											>
												<Input type="hidden" />
											</Form.Item>
										</Col>
									</Row>
									<Row justify="space-between">
										<Col lg={24} sm={24} xs={24}>
											<Form.Item
												label="HARI"
												name="hari"
												rules={[
													{
														required: true,
														message: 'harus dipilih'
													}
												]}
											>
												{this.generateSelectHari()}
											</Form.Item>
										</Col>
									</Row>
									<Row justify="left">
										<Col lg={4} sm={4} xs={24}>
											<Form.Item
												label="MULAI DARI"
												name="mulai_dari_picker"
												rules={[
													{
														required: true,
														message: 'harus dipilih'
													}
												]}
											>
												<TimePicker 
													onChange={(dateMoment, dateString) => {
														this.formRef.current.setFieldsValue({
															mulai_dari: dateString
														})
													}} 
													format={"HH:mm"}
												/>
											</Form.Item>
											<Form.Item
												style={{display: "none"}}
												name="mulai_dari"
											>
												<Input type="hidden" />
											</Form.Item>
										</Col>
										<Col lg={4} sm={4} xs={24}>
											<Form.Item
												label="SAMPAI DENGAN"
												name="sampai_dengan_picker"
												rules={[
													{
														required: true,
														message: 'harus dipilih'
													}
												]}
											>
												<TimePicker 
													onChange={(dateMoment, dateString) => {
														this.formRef.current.setFieldsValue({
															sampai_dengan: dateString
														})
													}} 
													format={"HH:mm"}
												/>
											</Form.Item>
											<Form.Item
												style={{display: "none"}}
												name="sampai_dengan"
											>
												<Input type="hidden" />
											</Form.Item>
										</Col>
									</Row>
									<Row justify="space-between">
										<Col lg={24} sm={24} xs={24}>
											<Form.Item
												label="KODE UPT"
												name="kode"
												rules={[
													{
														required: true,
														message: 'harus diisi'
													}
												]}
											>
												<Input
													style={{ textTransform: 'none' }}
													placeholder="kode upt"
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row justify="space-between">
										<Col lg={24} sm={24} xs={24}>
											<Form.Item
												label="KODE JASARAHARJA"
												name="kode_jasaraharja"
												rules={[
													{
														required: true,
														message: 'harus diisi'
													}
												]}
											>
												<Input
													style={{ textTransform: 'none' }}
													placeholder="kode jasaraharja"
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row justify="space-between">
										<Col lg={24} sm={24} xs={24}>
											<Form.Item
												label="NAMA"
												name="nama"
												rules={[
													{
														required: true,
														message: 'harus diisi'
													}
												]}
											>
												<Input
													style={{ textTransform: 'none' }}
													placeholder="nama"
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row justify="space-between">
										<Col lg={24} sm={24} xs={24}>
											<Form.Item
												label="ALAMAT"
												name="alamat"
												rules={[
													{
														required: true,
														message: 'harus diisi'
													}
												]}
											>
												<Input
													style={{ textTransform: 'none' }}
													placeholder="alamat"
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row justify="space-between">
										<Col lg={24} sm={24} xs={24}>
											<Form.Item
												label="TELPON"
												name="telpon"
												rules={[
													{
														required: true,
														message: 'harus diisi'
													}
												]}
											>
												<Input
													style={{ textTransform: 'none' }}
													placeholder="telpon"
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row justify="space-between">
										<Col lg={24} sm={24} xs={24}>
											<Form.Item
												label="KOREKTOR"
												name="korektor"
												rules={[
													{
														required: true,
														message: 'harus dipilih'
													}
												]}
											>
												{this.generateSelect(
													"Pegawai",
													configClass.apiUrl.pegawai,
													"id",
													"nama"
												)}
											</Form.Item>
										</Col>
									</Row>
									<Row justify="end">
										<Col style={{ padding: 5 }}>
											<Button
												key="btn-simpan"
												loading={this.state.loadingButton}
												type="primary"
												htmlType="submit"
												disabled={this.state.errComponent ? true : false}
												size="small"
											>
												{(!this.state.loadingButton && <CheckCircleOutlined />)}
												Simpan
											</Button>
										</Col>
										<Col style={{ padding: 5 }}>
											<Link to={configClass.pageUrl.data}>
												<Button
													type="danger"
													key="btn-cancel"
													disabled={this.state.loadingButton ? true : false}
													size="small"
												>
													<CloseCircleOutlined /> Cancel
												</Button>
											</Link>
										</Col>
									</Row>

								</Card>
							</Col>
						</Row>
					</Form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = function (state) {
	return state.configReducers
}

export default connect(
	mapStateToProps,
	{
		initHandler,
		setConfig,
		ajaxViewHandler,
		ajaxHandler
	}
)(FormUpt);