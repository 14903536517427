import React from 'react';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Input,
	Button,
	Table,
	Modal,
	message,
	Alert,
	Select,
	Tooltip,
	Spin,
	Form,
	Skeleton
} from 'antd';

import moment from 'moment';

import Config from '../../Config';
import {Helmet} from "react-helmet";
import queryString from 'query-string';

import '../../assets/laporan.css';


const configClass = {
	title: 'LAPORAN NOTICE RUSAK',
	apiUrl: {
		preview: 'laporan/api/notice/rusak/',
		userProfile: "auth/api/user/info"
	},

}

class LapNoticeRusakPrint extends React.Component {
	config;
	
	constructor(props){
	    super(props);
	    this.config = new Config();
	    this.state = {
	    	loading: false,
	    	data: null,
	    	err: false,

	    	namauptppd: 'SAMSAT PALANGKARAYA',
	    }

	    // this.preview(this.props.match.params.id);
	    
	}

	componentDidMount(){
		var uri_params = "";
		const uri_string = queryString.parse(this.props.location.search);
		var uri_params = "";
		for (var key in uri_string) {
			if(uri_params == ""){
				uri_params = "?"+key+"="+uri_string[key];
			}else{
				uri_params += "&"+key+"="+uri_string[key];
			}
		}
		this.preview(uri_params);
	}

	async preview(uri_params) {
		message.loading({ content: 'Menampilkan laporan...', duration: 0, key: 'toast' });
		this.setState({
			loading: true,
			err: false,
			data: null,
		});

		this.props.ajaxViewHandler('get', configClass.apiUrl.preview+uri_params)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				message.destroy();
				this.setState({
					loading: false,
					data: data,
					err: false
				});
				setTimeout(function(){
					window.print();

				}, 1300);
	        }else{
	        	const errTitle = 'error get laporan';
	        	this.setState({
	        		loading: false,
	        		data: null,
	        		err: true,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
		}).catch((response) => {
			this.setState({
	        		loading: false,
	        		data: null,
	        		err: true,
	        		errMsg: 'unknown response'
	        });
			console.log('gagal_get_laporan', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	render() {		
    	return (
			<>
			  	<Helmet>
			  		<title>{configClass.title}</title>
			  	</Helmet>
			  	<div className="kertas-laporan">
			  		<div className="judul-laporan teks-tengah" style={{borderBottom: "double 5px #000000"}}>
									  			PEMERINTAH PROPINSI KALIMANTAN TENGAH<br />
									  			<span className="tebal">BADAN PENDAPATAN DAERAH</span><br />
									  			<span className="tebal">UPT PPD PALANGKARAYA</span><br />
									  			<span style={{fontSize: 11}}>Jalan R.T.A. Milono Km. 5,5 Telp 0536-3227523</span><br />
									  			<span className="tebal">{this.state.namauptppd}</span>
									  		</div>
			  		<Row>
									  			<Col span={12} className="teks-tengah tebal">BERITA ACARA SKPD (NOTICE) RUSAK</Col>
									  			{/*
									  			<Col span={5} className="titik-dua">TANGGAL</Col>
									  			<Col span={19}>07-10-2021</Col>
									  			<Col span={5} className="titik-dua">TAHUN ANGGARAN</Col>
									  			<Col span={19}>2021</Col>
									  			*/}
									  		</Row>
			  		<table className="laporan-table" border="1" cellPadding="5">
									  			<thead>
									  				<tr>
									  					<th>No.</th>
									  					<th>Nomor Seri SKPD</th>
									  					<th>Jumlah</th>
									  					<th>Keterangan</th>
									  				</tr>
									  			</thead>
									  			<tbody>
									  				{(this.state.data != null && this.state.data.data.map((item, id) => (
									  					<tr>
										  					<td className="teks-tengah">{id+1}</td>
										  					<td className="teks-tengah">{item.nomor_seri}</td>
										  					<td className="teks-tengah">{item.jumlah}</td>
										  					<td className="teks-tengah">{item.keterangan}</td>
										  				</tr>
									  				)))}
									  			</tbody>
									  		</table>
			  		{(this.state.data != null && this.state.data.ttd != undefined &&
									  				<Row justify="space-between" style={{marginTop: 10}}>
									  					{(this.state.data.ttd.kiri != undefined &&
									  							<Col span={10} className="teks-tengah">
									  								MENGETAHUI<br />
													  				{this.state.data.ttd.kiri.jabatan}
													  				<div style={{marginTop: 50}}>
													  					<u>{this.state.data.ttd.kiri.nama}</u><br />
													  					NIP : {this.state.data.ttd.kiri.nip}
													  				</div>
													  			</Col>
									  					)}
									  					{(this.state.data.ttd.kanan != undefined &&
												  			<Col span={10} className="teks-tengah">
												  				{this.state.namauptppd}, {moment().format('DD-MM-YYYY')}<br />
												  				{this.state.data.ttd.kanan.jabatan}
												  				<div style={{marginTop: 50}}>
												  					<u>{this.state.data.ttd.kanan.nama}</u><br />
												  					NIP : {this.state.data.ttd.kanan.nip}
												  				</div>
												  			</Col>
											  			)}
											  		</Row>
									  		)}
			  	</div>
			</>
    	)
    }

}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler,
			setConfig
		}
	)(LapNoticeRusakPrint);