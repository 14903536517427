import React from 'react';
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import Highlighter from 'react-highlight-words';
import { Link } from "react-router-dom";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Input,
	Button,
	Table,
	Modal,
	message,
	Alert,
	Select,
	Spin,
	Form,
	DatePicker,
} from 'antd';
import {
	TeamOutlined,
	SearchOutlined,
	PlusOutlined,
	FormOutlined,
	CloseSquareOutlined,
	ExclamationCircleOutlined,
	DiffOutlined,
	RedoOutlined,
	CopyOutlined,
	PrinterOutlined
} from '@ant-design/icons';

import axios from 'axios';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";

import Config from '../../Config';
import { Helmet } from "react-helmet";

import moment from "moment";

import '../../assets/laporan.css';

const configClass = {
	title: 'STSS Harian',
	apiUrl: {
		data: 'laporan/api/sts_harian/',
		warna_plat: "master/api/plat",
		jenis_kendaraan: "master/api/jenis/kendaraan/",
		payment: "master/api/payment/",
		upt: "pegawai/api/upt/",
		kabupaten: "master/api/kabupaten/",
	},
	pageUrl: {
		cetak: '/laporan/stss-harian'
	},
	breadCrumb: [
		(
			<span>
				<CopyOutlined /> laporan
			</span>
		),
		'stss harian'
	]

}

const config = new Config();

class StssHarian extends React.Component {
	formRef = React.createRef();

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			data: null,
			err: false,

			loadingWarnaPlat: false,
			listWarnaPlat: [],

			loadingJenisKendaraan: false,
			listJenisKendaraan: [],

			loadingPayment: false,
			listPayment: [],

			namauptppd: 'SAMSAT PALANGKARAYA',

			filter: "",

			tgl_dari: "17-10-2023",

			loadingUpt: false,
			listUpt: [],

			loadingKabupaten: null,
			listKabupaten: [],

			kabupaten_id: "",



		}
	}

	UNSAFE_componentWillMount() {
		this.props.initHandler();
	}

	componentDidMount() {
		// this.getData("?mulai_dari=" + this.state.tgl_dari);
	}

	handleSubmit = (values) => {
		var filter = "";
		for (var key in values) {
			if (values[key] != undefined) {
				if (filter == "") {
					filter = "?" + key + "=" + values[key];
				} else {
					filter += "&" + key + "=" + values[key];
				}
			}
		}
		this.setState({
			filter: filter
		});
		this.getData(filter)
	};

	async getData(filter) {
		message.loading({ content: 'Menampilkan laporan...', duration: 0, key: 'toast' });
		this.setState({
			loading: true,
			err: false,
			data: null,
		});

		// this.props.ajaxViewHandler('get', configClass.apiUrl.data+"?warna_plat=1&jenis_kendaraan&roda=2&mulai_dari=23-10-2020&sampai_dengan=23-10-2021")
		this.props.ajaxViewHandler('get', configClass.apiUrl.data + filter)
			.then(() => {
				const data = this.props.responseMsg;
				if (this.props.isSuccess) {
					message.destroy();
					this.setState({
						loading: false,
						data: data,
						err: false
					});
					setTimeout(function () {
						// window.print();

					}, 1000);
				} else {
					const errTitle = 'error get laporan';
					this.setState({
						loading: false,
						data: null,
						err: true,
						errMsg: this.props.responseMsg
					});
					message.error({ content: errTitle, duration: 2, key: 'toast' });
				}
			}).catch((response) => {
				this.setState({
					loading: false,
					data: null,
					err: true,
					errMsg: 'unknown response'
				});
				console.log('gagal_get_laporan', response);
				message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			});
	}

	render() {
		// if(this.props.isSuperadmin == false){
		// 	return <Redirect to="/admin/forbidden" />
		// }
		return (
			<div>
				<Helmet>
					<title>{configClass.title + " | " + config.get_site_info().name}</title>
				</Helmet>
				<Breadcrumb>
					{configClass.breadCrumb.map(item => (
						<Breadcrumb.Item>
							{item}
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
				<div className="card">
					<Row className="isi">
						<Col span={24}>
							<Card
								className="konten"
								style={{ overflow: "auto" }}
							>
								<Form
									layout="vertical"
									name="fdata"
									onFinish={this.handleSubmit}
									size="small"
									ref={this.formRef}
								>

									<Row>
										<Col span={4} className="titik-dua">Pilih Tanggal</Col>
										<Col span={8}>
											<Form.Item name="mulai_dari_picker">
												<DatePicker
													placeholder="dd-mm-yyyy"
													format="DD-MM-YYYY"
													style={{ width: "100%" }}
													onChange={(date, dateString) => {
														this.formRef.current.setFieldsValue({
															mulai_dari: dateString,
														});
														this.setState({
															tgl_dari: dateString
														})
													}}
												/>
											</Form.Item>
											<Form.Item
												name="mulai_dari"
												style={{
													display: "none",
												}}
											>
												<Input type="hidden" />
											</Form.Item>
										</Col>
										<Col style={{ paddingLeft: 5 }}>
											<Button
												key="btn-simpan"
												loading={this.state.loading}
												type="primary"
												htmlType="submit"
												disabled={this.state.loading ? true : false}
												size="small"
											>
												{(!this.state.loading && <SearchOutlined />)}
												Proses
											</Button>
										</Col>
										{(this.state.data != null &&
											<Col style={{ paddingLeft: 5 }}>
												<a href={configClass.pageUrl.cetak + this.state.filter} target="_blank">
													<Button
														key="btn-cetak"
														loading={this.state.loading}
														type="default"
														htmlType="button"
														disabled={this.state.loading ? true : false}
														size="small"
													>
														{(!this.state.loading && <PrinterOutlined />)}
														Cetak
													</Button>
												</a>
											</Col>
										)}
									</Row>
								</Form>
							</Card>
						</Col>

						<Col span={24}>
							<Card
								className="konten"
								style={{ overflow: "auto" }}
							>
								{(this.state.loading == true &&
									<div style={{ color: '#72BBFF', fontSize: 17 }}>
										<Spin style={{ marginRight: 10 }} /> memproses laporan....
									</div>
								)}
								{(this.state.data != null && this.state.data.map((item, idx) => (
									<div className="kertas-laporan pagebreak">
										<div className="judul-laporan-sts" style={{ borderBottom: "double 5px #000000" }}>
											<img src={config.get_site_info().logo_bw} />
											PEMERINTAH PROPINSI KALIMANTAN TENGAH<br />
											SURAT TANDA SETORAN<br />
											(STS)
										</div>
										<Row>
											<Col span={13} className='setoran-ke'>
												Setoran yang ke : {item.nomor}
											</Col>
											<Col span={11} className='kepada'>
												<span className='samsat'>{item.kabupaten}</span><br />
												Kas Daerah Propinsi Kalimantan Tengah<br />
												di. - <br />
												PALANGKARAYA

											</Col>
										</Row>
										<div className='deskripsi'>
											Harap Menerima Uang Sebesar: {item.jumlah.toLocaleString("ID")}<br />
											{"# " + config.pembilang(item.jumlah) + " #"}<br />
											Yaitu Penerima: KAS UMUM DAERAH PROPINSI KALIMANTAN TENGAH NO. REK {item.akun_bank}
										</div>

										{item.data.map((subItem, subIdx) => (
											<Row>
												<Col span={6}>{subItem.nomor_rekening}</Col>
												<Col span={6}>{subItem.nama}</Col>
												<Col span={6}>{subItem.jumlah + " Unit"}</Col>
												<Col span={6} className='samsat'>{subItem.total.toLocaleString("id")}</Col>
											</Row>
										))}

										<Row className='total-sts bold'>
											<Col span={6} offset={18}>
												{item.jumlah.toLocaleString("ID")}
											</Col>
										</Row>

										{(item.ttd != undefined &&
											<Row justify="space-between" style={{ marginTop: 10 }}>
												<Col span={24} className='text-center'>
													{item.kabupaten_asal}, TGL {this.state.tgl_dari}
												</Col>
												<Col span={8} className="teks-tengah">
													{item.ttd.ttd.kiri.jabatan}<br />
													<div style={{ marginTop: 50 }}>
														<u>{item.ttd.ttd.kiri.nama}</u><br />
														NIP : {item.ttd.ttd.kiri.nip}
													</div>
												</Col>
												<Col span={8} className="teks-tengah">
													{item.ttd.ttd.kanan.jabatan}<br />
													<div style={{ marginTop: 50 }}>
														<u>{item.ttd.ttd.kanan.nama}</u><br />
														NIP : {item.ttd.ttd.kanan.nip}
													</div>
												</Col>
												<Col span={8} className="teks-tengah">
													{item.kabupaten_asal},<br />
													{"TGL " + this.state.tgl_dari}
												</Col>

											</Row>
										)}

									</div>
								)))}
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

const mapStateToProps = function (state) {
	return state.configReducers
}

export default connect(
	mapStateToProps,
	{
		initHandler,
		setConfig,
		ajaxViewHandler,
		ajaxHandler
	}
)(StssHarian);